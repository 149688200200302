import PropTypes from "prop-types"
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Input, Modal } from "reactstrap";

function TestConditionReading({ parentOpen, testCondition, interactionsData,cicleTitle}){

    const [isOpen,setIsOpen] = useState(false)


    function closeModal(){
        setIsOpen(false)
    }

    useEffect(()=>{
        if(parentOpen && cicleTitle === 'B'&&  interactionsData.length === 0){
            const  timeout = setTimeout(function(){
                setIsOpen(true)
            },1000)
            return () => clearTimeout(timeout)
        }
    },[parentOpen,interactionsData, cicleTitle])

    return<>
    <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        autoFocus={false}
    >
        <div className="modal-header">
            <h1 className="mb-2 text-red">
                ATENÇÃO:
                <br></br>
                <br></br>
                <span className="text-primary mb-0 mt-2">Lembre-se de seguir as seguintes condições do teste</span>
            </h1>
            <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => closeModal()}
            >
            <span aria-hidden={true}>×</span>
            </button>
        </div>
        <Card>
            <CardBody>
            <Input
                id="exampleFormControlTextarea1"
                type="textarea"
                rows="20"
                value={testCondition}
                readOnly
                />
            </CardBody>
        </Card>
        <div className="modal-footer">
           
            <Button
              color="success"
              type="button"
              onClick={(e) =>closeModal()}
            >
              Entendi
            </Button>
          </div>
        </Modal>
       
    </>
}

TestConditionReading.propTypes = {
  closeModal: PropTypes.func,
  testCondition: PropTypes.string,
  parentOpen: PropTypes.bool,
}


export default TestConditionReading