import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"

class Project extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <div className="form-row">
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Código
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Código"
                            type="text"
                            value={this.props.code}
                            readOnly={this.props.crud === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.newCodeState === "valid"
                            }
                            invalid={
                              this.props.customStyles.newCodeState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "newCode")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o código do projeto.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Nome
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Nome do projeto"
                            type="text"
                            readOnly={this.props.crud === "D"}
                            disabled={this.props.saving}
                            value={this.props.name}
                            valid={
                              this.props.customStyles.newNameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.newNameState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "newName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o nome do projeto.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Cliente
                          </label>
                          <Input
                            id="validationCustom03"
                            type="text"
                            value={this.props.customerShortName}
                            readOnly
                            onChange={(e) =>
                              this.props.stylesForm(e, "customerShortName")
                            }
                          />
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Responsável
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.responsible}
                            disabled={
                              this.props.saving || this.props.crud === "D"
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "newResponsible")
                            }
                            options={{
                              placeholder: "Usuário responsável",
                              language: {
                                noResults: function () {
                                  return "Nenhum usuário encontrado."
                                },
                              },
                            }}
                            data={this.props.usersList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.newResponsibleState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.newResponsibleState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Selecione um responsável.
                          </div>
                        </Col>

                        <Col
                          className="mb-3"
                          md="6"
                          hidden={!this.props.isManagerConsulting}
                        >
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Pasta do drive
                          </label>
                          <Input
                            id="validationCustom03"
                            type="text"
                            value={this.props.driveFolder}
                            onChange={(e) =>
                              this.props.stylesForm(e, "newDriveFolder")
                            }
                          />
                        </Col>
                        <Col md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom05"
                          >
                            Service Layer
                          </label>
                          <Input
                            id="validationCustom03"
                            type="text"
                            value={this.props.serviceLayerUrl}
                            onChange={(e) =>
                              this.props.stylesForm(e, "serviceLayerUrl")
                            }
                          />
                        </Col>
                        <Col md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom06"
                          >
                            Base
                          </label>
                          <Input
                            id="validationCustom03"
                            type="text"
                            value={this.props.serviceLayerDataBase}
                            onChange={(e) =>
                              this.props.stylesForm(e, "serviceLayerDataBase")
                            }
                            valid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles
                                .serviceLayerDataBaseState === "valid"
                            }
                            invalid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles
                                .serviceLayerDataBaseState === "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Cadastre a base da SL.
                          </div>
                        </Col>
                        <Col md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom07"
                          >
                            Usuário
                          </label>
                          <Input
                            id="validationCustom03"
                            type="text"
                            value={this.props.serviceLayerUser}
                            onChange={(e) =>
                              this.props.stylesForm(e, "serviceLayerUser")
                            }
                            valid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles.serviceLayerUserState ===
                                "valid"
                            }
                            invalid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles.serviceLayerUserState ===
                                "invalid"
                            }
                          />

                          <div className="invalid-feedback">
                            Cadastre o usuário da SL.
                          </div>
                        </Col>
                        <Col md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Senha
                          </label>
                          <Input
                            id="validationCustom08"
                            type="text"
                            value={this.props.serviceLayerPassword}
                            onChange={(e) =>
                              this.props.stylesForm(e, "serviceLayerPassword")
                            }
                            valid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles
                                .serviceLayerPasswordState === "valid"
                            }
                            invalid={
                              this.props.customStyles.serviceLayerUrlState ===
                                "valid" &&
                              this.props.customStyles
                                .serviceLayerPasswordState === "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Cadastre a senha da SL.
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Project
