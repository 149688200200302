import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
} from "reactstrap"

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"

//Custom components
import Loading from "components/Modals/Loading.js"
import api from "services/api"
import GenericSearch from "components/Modals/GenericSearch"

class NewList extends React.Component {
  state = {
    parentId: "",
    name: "",
    project: "",
    projectName: "",
    customer: "",
    customerShortName: "",
    managerCG: "",
    managerCR: "",
    saving: false,
    alert: null,
    managerCGList: [],
    managerCRList: [],
    listModel: null,

    //Modal fields validations
    customStyles: {
      nameState: null,
      managerCGState: null,
      managerCRState: null,
    },

    listSearchColumns: [
      {
        dataField: "_id",
        text: "Id",
        hidden: true,
      },
      {
        dataField: "customer.shortName",
        text: "Cliente",
        sort: true,
      },
      {
        dataField: "project.code",
        text: "Projeto",
        sort: true,
      },
      {
        dataField: "name",
        text: "Nome",
        sort: true,
      },
      {
        dataField: "managerCG.fullName",
        text: "Gerente Golive",
        sort: true,
      },
      {
        dataField: "managerCR.fullName",
        text: "Gerente Cliente",
        sort: true,
      },
      {
        dataField: "actions",
        editable: false,
        text: "Ações",
        isDummyField: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <div>
              <a
                className="table-action"
                id="tooltipSelectCicle"
                href="#selecionar"
                onClick={(e) => this.listSearchClose(e, row, "select")}
              >
                <i className="fas fa-check" />
              </a>
              <UncontrolledTooltip delay={0} target="tooltipSelectCicle">
                Selecionar
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],
  }

  componentDidMount() {
    this.loadData(this.props.match.params.parentId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.parentId) {
      this.loadData(nextProps.match.params.parentId)
    }
  }

  loadData = async (paramId) => {
    const parentId = paramId

    this.setState({
      parentId: parentId,
      name: "",
      project: "",
      projectName: "",
      customer: "",
      customerShortName: "",
      managerCG: "",
      managerCR: "",
      isLoading: true,
    })

    const response = await api.get("/smartflowproject/read/" + parentId)

    if (response.data) {
      this.setState({
        customer: response.data.customer,
        customerShortName: response.data.customerShortName,
        project: response.data._id,
        projectName: response.data.name,
      })
    }

    this.loadLists()

    this.setState({ isLoading: false })
  }

  loadLists = async () => {
    var user = {}
    var users = []
    var managerCGList = []
    var managerCRList = []

    //Managers Consulting
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
      kind: "M",
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (managerCGList.length === 0) {
        managerCGList = [user]
      } else {
        managerCGList.push(user)
      }
    }

    //Managers Customer
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      kind: "M",
      customer: this.state.customer,
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (managerCRList.length === 0) {
        managerCRList = [user]
      } else {
        managerCRList.push(user)
      }
    }

    this.setState({
      managerCGList: managerCGList,
      managerCRList: managerCRList,
    })
  }

  save = async (e) => {
    this.setState({ saving: true })

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    //Field content validations
    if (newState.name === "") {
      newState.customStyles.nameState = "invalid"
      letSave = false
    } else {
      newState.customStyles.nameState = "valid"
    }

    if (newState.managerCG === "") {
      newState.customStyles.managerCGState = "invalid"
      letSave = false
    } else {
      newState.customStyles.managerCGState = "valid"
    }

    if (newState.managerCR === "") {
      newState.customStyles.managerCRState = "invalid"
      letSave = false
    } else {
      newState.customStyles.managerCRState = "valid"
    }

    this.setState({ customStyles: newState.customStyles })

    if (!letSave) {
      this.setState({ saving: false })
      return
    } else {
      newData = {
        customer: this.state.customer,
        project: this.state.project,
        name: this.state.name,
        managerCG: this.state.managerCG,
        managerCR: this.state.managerCR,
        lastInteraction: null,
        listModel: this.state.listModel,
      }

      var newList = {}

      try {
        newList = await api.post("/list/create", newData)
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar incluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
      }

      if (success) {
        localStorage.setItem(
          process.env.REACT_APP_NEWREGID_KEY,
          newList.data._id
        )
        localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "LI")
        window.location.reload()
      }
    }
  }

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid"
    } else {
      newState[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })
  }
  listSearchOpen = async (e) => {
    var response = []

    response = await api.post("/list/fullSearch", {
      deleted: "N",
    })
    this.setState({
      listSearchData: response.data,
      listSearchOpened: true,
      listSearchTitle: "Modelo de lista",
    })
  }
  listSearchClose(e, row, action) {
    
    if (action !== "abort") {
      this.setState({
        listModel: row._id,
        listModelName:
          "Cliente: " +
          row.customer?.shortName +
          " | Projeto: " +
          row.project?.name +
          " | Nome: " +
          row.name +
          " | Gerente GL: " +
          row.managerCG?.fullName +
          " | Gerente CL: " +
          row.managerCR?.fullName,
      })
    } else {
    }

    this.setState({ listSearchOpened: false })
  }
  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Nova Lista"
          items={[
            {
              level: "parent",
              name: this.state.customerShortName,
            },
            {
              level: "parent",
              name: this.state.projectName,
            },
            {
              level: "nav",
              name: "Nova Lista",
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Nova Lista</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Nome
                        </label>
                        <Input
                          autoFocus
                          id="validationCustom01"
                          placeholder="Nome da lista"
                          type="text"
                          disabled={this.state.saving}
                          value={this.state.name}
                          valid={this.state.customStyles.nameState === "valid"}
                          invalid={
                            this.state.customStyles.nameState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "name")}
                        />
                        <div className="invalid-feedback">
                          Digite o nome da lista.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Cliente
                        </label>
                        <Input
                          id="validationCustom02"
                          type="text"
                          value={this.state.customerShortName}
                          readOnly
                          onChange={(e) =>
                            this.stylesForm(e, "customerFullName")
                          }
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Projeto
                        </label>
                        <Input
                          id="validationCustom02"
                          type="text"
                          value={this.state.projectName}
                          readOnly
                          onChange={(e) => this.stylesForm(e, "projectName")}
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Gestor Golive
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.managerCG}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "managerCG")}
                          options={{
                            placeholder: "Selecione um gestor da Golive",
                            language: {
                              noResults: function () {
                                return "Nenhum gestor encontrado."
                              },
                            },
                          }}
                          data={this.state.managerCGList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.managerCGState === "valid"
                          }
                          invalid={
                            this.state.customStyles.managerCGState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Gestor Golive inválido ou não encontrado.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Gestor Cliente
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.managerCR}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "managerCR")}
                          options={{
                            placeholder: "Selecione um gestor do cliente",
                            language: {
                              noResults: function () {
                                return "Nenhum usuário encontrado."
                              },
                            },
                          }}
                          data={this.state.managerCRList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.managerCRState === "valid"
                          }
                          invalid={
                            this.state.customStyles.managerCRState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Gestor inválido ou não encontrado.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Modelo
                        </label>
                        <InputGroup className="mb-3">
                          <Input
                            placeholder="Clique no botão para pesquisar"
                            type="text"
                            value={this.state.listModelName}
                            onChange={(e) =>
                              this.stylesForm(e, "cicleModelName")
                            }
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              className="btn-icon btn-2"
                              color="primary"
                              outline
                              disabled={this.state.saving}
                              onClick={(e) => this.listSearchOpen(e)}
                            >
                              <span className="btn-inner--icon">
                                <i className="ni ni-folder-17" />
                              </span>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12" className="text-right">
                        <Button
                          color="success"
                          type="button"
                          disabled={this.state.saving}
                          onClick={(e) => this.save(e)}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
          <GenericSearch
            searchOpened={this.state.listSearchOpened}
            searchTitle={this.state.listSearchTitle}
            data={this.state.listSearchData}
            columns={this.state.listSearchColumns}
            closeSearch={this.listSearchClose.bind(this)}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default NewList
