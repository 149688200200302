import React from "react";
// reactstrap components
import { Media, Badge } from "reactstrap";
import Avatar from "react-avatar";
import Moment from "moment";

import TagDocument from "components/TaskElements/TagDocument";

class TaskInteractionMedia extends React.Component {
  componentDidMount() {
    Moment.locale("pt-br");
  }

  getDocs = (files) => {
    return files.map((prop, key) => {
      return <TagDocument name={prop.name} url={prop.url} key={key} />;
    });
  };

  getTime = (originalValue) => {
    const splitTime = originalValue?.split?.("T")?.[1]?.split?.(":");

    return `${splitTime[0]}:${splitTime[1]}`;
  };

  render() {
    return (
      <>
        <Media className="media-comment">
          {this.props.user.image ? (
            <img
              alt="..."
              className="avatar avatar-lg media-comment-avatar rounded-circle"
              src={this.props.user.image.url}
            />
          ) : (
            <a
              className="avatar avatar-lg media-comment-avatar rounded-circle"
              href="#gl8"
            >
              <Avatar
                name={this.props.user.fullName}
                maxInitials={2}
                round={true}
                size={48}
                title={" "}
                color={"#172b4d"}
                fgColor={"#11cdef"}
              />
            </a>
          )}
          <Media>
            <div className="media-comment-text">
              <div className="d-flex justify-content-between pt-1">
                <h6 className="h5 mt-0">{this.props.user.fullName}</h6>
                <div className="text-right">
                  <small className="text-muted">
                    <i className="fas fa-clock mr-1" />
                    {Moment(this.props.createdAt).format("D MMM YYYY")}{" "}
                    {this.getTime(this.props.createdAt)}
                  </small>
                </div>
              </div>
              <p className="text-sm lh-160">{this.props.comments}</p>
              <div className="mt-3">
                <Badge
                  color={this.props.statusIndex[this.props.status + "_Color"]}
                  pill
                >
                  {this.props.statusIndex[this.props.status]}
                </Badge>
                <Badge
                  color={this.props.statusIndex[this.props.status + "_Color"]}
                  pill
                >
                  {this.props.percentage || 0}%
                </Badge>
              </div>
              {this.props.files.lenth > 0 ? (
                <p className="text-sm lh-160"> </p>
              ) : null}
              <div className="icon-actions text-right">
                {this.getDocs(this.props.files)}
              </div>
            </div>
          </Media>
        </Media>
      </>
    );
  }
}

export default TaskInteractionMedia;
