import React, { useCallback, useEffect, useState } from "react"
import api from "services/api"
import SimpleHeader from "components/Headers/SimpleHeader.js"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import Loading from "components/Modals/Loading"

export default function Customers() {
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchCustomers = useCallback(async () => {
    setLoading(true)
    const customersResponse = await api.post("customer/search", {
      deleted: "N",
    })
    setCustomers(customersResponse.data)
    setLoading(false)
  }, [])

  const changeUserSidebarStatus = async (id, value, index) => {
    let newCustomers = [...customers]
    newCustomers[index].smartflow_sidebar_active = value
    setCustomers(newCustomers)
    await api.put("customer/update/" + id, {
      smartflow_sidebar_active: value,
    })
  }
  useEffect(() => {
    fetchCustomers()
  }, [fetchCustomers])

  return (
    <>
      <SimpleHeader
        name="Clientes"
        items={[{ level: "nav", name: "Listagem de Clientes" }]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col xs="6">
                    <h5 className="h3 mb-0">Clientes</h5>
                  </Col>
                  <Col xs="6" style={{ textAlignLast: "end" }}>
                    <Button onClick={() => window.location.reload()}>
                      Recarregar página
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Ativo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, index) => {
                      return (
                        <tr>
                          <td>{customer.shortName}</td>
                          <td>
                            {" "}
                            <label className="custom-toggle mr-1">
                              <input
                                checked={
                                  customer.smartflow_sidebar_active === true
                                }
                                type="checkbox"
                                value={customer.smartflow_sidebar_active}
                                onChange={(e) =>
                                  changeUserSidebarStatus(
                                    customer._id,
                                    !customer.smartflow_sidebar_active,
                                    index
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
    </>
  )
}
