import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "reactstrap"

import PlayYouTubeVideo from "components/Modals/PlayYouTubeVideo.js"

//core components
import SimpleHeader from "components/Headers/SimpleHeader.js"

const questions = [
  {
    text: "O que é o portal e como acessar?",
    videoLink: " https://youtu.be/JTk6heeDxVQ",
  },
  {
    text: "Quem terá acesso e como abrir seu plano de testes??",
    description: "",
    videoLink: "https://youtu.be/UqLF0qBLOXY",
  },
  {
    text: "Como entender meu item de teste??",
    description: "",
    videoLink: "https://youtu.be/HlAHp1baC_w",
  },
  {
    text: "Como fazer minha capacitação em vídeo?",
    description: "",
    videoLink: "https://youtu.be/eaTLCbNMBKQ",
  },
  {
    text: "Como fazer a interação do meu item de teste?",
    description: "",
    videoLink: "https://youtu.be/iBFydnVQ5oI",
  },
  {
    text: "Quando devo aprovar meu ciclo de teste?",
    description: "",
    videoLink: "https://youtu.be/mtP02dC5Xu4",
  },
  {
    text: "Quando devo utilizar o status em andamento?",
    description: "",
    videoLink: "https://youtu.be/ckj6z6b2ooc",
  },
  {
    text: "Quando devo utilizar o status cancelado?",
    description: "",
    videoLink: "https://youtu.be/tAloSMVryXc",
  },
  {
    text: "Como informar um problema no meu ciclo de teste?",
    description: "",
    videoLink: "https://youtu.be/2Z2WBMS4XnE",
  },
  {
    text: "Como filtrar o status de teste?",
    description: "",
    videoLink: "https://youtu.be/KtAvoV-eMPk",
  },
]
class Help extends React.Component {
  state = {
    //Play video modal
    modalPlayVideoTitle: "",
    modalPlayYouTubeVideoOpened: false,
    modalPlayVideoOpened: false,
    videoSource: "",
  }

  playYouTubeVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayYouTubeVideoOpened: true,
      videoSource: row.videoLink,
    })
  }

  modalYouTubeVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayYouTubeVideoOpened: false,
      videoSource: "",
    })
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Ajuda"
          items={[
            { level: "parent", name: "Dashboards" },
            { level: "nav", name: "Ajude-me" },
          ]}
        />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12">
                  <h5 className="h3 mb-0">Procure sua dúvida</h5>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ListGroup>
                {questions.map((question, index) => (
                  <ListGroupItem key={index}>
                    <a
                      className="font-weight-bold"
                      href={"#playYTVideo"}
                      onClick={(e) => this.playYouTubeVideo(e, question)}
                    >
                      {question.text}
                    </a>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </CardBody>
          </Card>
        </Container>
        <PlayYouTubeVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalYouTubeVideoClose.bind(this)}
          modalOpened={this.state.modalPlayYouTubeVideoOpened}
          source={this.state.videoSource}
        />
      </>
    )
  }
}

export default Help
