import React from "react";
import Select2 from "react-select2-wrapper";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Input,
  Row,
  Badge,
  CardText,
  Media,
  Form,
} from "reactstrap";
import classnames from "classnames";

import Moment from "moment";
import MultipleFilesDropzone from "components/Dropzones/MultipleFilesDropzone.js";

import PlayVideo from "components/Modals/PlayVideo.js";
import PlayYouTubeVideo from "components/Modals/PlayYouTubeVideo.js";
import ImageZoom from "components/Modals/ImageZoom.js";
import AvatarLinkGL8 from "components/ListElements/AvatarLinkGL8";
import TaskInteractionMedia from "components/TaskElements/TaskInteractionMedia";
import { parse } from "dotenv";

class TaskConsole extends React.Component {
  state = {
    //Play video modal
    modalPlayVideoTitle: "",
    modalPlayVideoOpened: false,
    modalPlayYouTubeVideoOpened: false,
    videoSource: "",

    //View image modal
    modalViewImageOpened: false,
    modalViewImageTitle: "",
    viewImageUrl: "",
  };

  getInteractions = (interactions) => {
    return interactions.map((prop, key) => {
      return (
        <TaskInteractionMedia
          user={prop.user}
          key={key}
          statusIndex={this.props.statusIndex}
          comments={prop.comments}
          status={prop.status}
          createdAt={prop.createdAt}
          files={prop.files}
          percentage={prop.percentage}
        />
      );
    });
  };

  playYouTubeVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayYouTubeVideoOpened: true,
      videoSource: row.url,
    });
  }

  modalYouTubeVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayYouTubeVideoOpened: false,
      videoSource: "",
    });
  }

  playVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayVideoOpened: true,
      videoSource: row.url,
    });
  }

  modalVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayVideoOpened: false,
      videoSource: "",
    });
  }

  viewImage(e, row) {
    this.setState({
      modalViewImageOpened: true,
      modalViewImageTitle: row.description,
      viewImageUrl: row.url,
    });
  }

  modalViewImageClose(e) {
    this.setState({
      modalViewImageOpened: false,
      modalViewImageTitle: "",
      viewImageUrl: "",
    });
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {"#" + this.props.taskCode + " - " + this.props.taskName}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Card>
            <CardBody>
              <Row>
                <Col xs="3">
                  <AvatarLinkGL8
                    id={"imgRequesterTask" + this.props.id}
                    filterId={this.props.taskRequester}
                    fullName={this.props.taskRequesterFullName}
                    image={this.props.taskRequesterImage}
                    size={32} //xs = 24 | sm = 36 | -- = 48 | lg = 58 | xl = 74
                    avatarClass="avatar avatar-sm rounded-circle"
                    filterName="filterRequester"
                    onClick={this.props.avatarFilterClick.bind(this.props)}
                  />
                  <AvatarLinkGL8
                    id={"imgResponsibleTask" + this.props.id}
                    filterId={this.props.taskResponsible}
                    fullName={this.props.taskResponsibleFullName}
                    image={this.props.taskResponsibleImage}
                    size={32} //xs = 24 | sm = 36 | -- = 48 | lg = 58 | xl = 74
                    avatarClass="avatar avatar-sm rounded-circle"
                    filterName="filterResponsible"
                    onClick={this.props.avatarFilterClick.bind(this.props)}
                  />
                </Col>
                <Col xs="9" className="text-right">
                  <Badge
                    color={
                      this.props.statusIndex[this.props.taskStatus + "_Color"]
                    }
                  >
                    {this.props.statusIndex[this.props.taskStatus]}
                  </Badge>
                  <Badge
                    hidden={
                      this.props.taskConclusionDate &&
                      this.props.taskConclusionDate !== ""
                    }
                    color="info"
                  >
                    Prazo:
                    {" " + Moment(this.props.taskDueDate).format("D MMM YYYY")}
                  </Badge>
                  <Badge
                    hidden={
                      !this.props.taskConclusionDate ||
                      this.props.taskConclusionDate === ""
                    }
                    color="success"
                  >
                    {Moment(this.props.taskConclusionDate).format("D MMM YYYY")}
                  </Badge>
                  <Badge
                    color={
                      this.props.priorityIndex[
                        this.props.taskPriority + "_Color"
                      ]
                    }
                  >
                    Prioridade {" " + this.props.taskPriority}
                  </Badge>
                  <Badge color="info">{this.props.taskModule}</Badge>
                </Col>
              </Row>
              <CardText>{this.props.taskDescription}</CardText>
            </CardBody>
            <Card>
              <CardHeader>
                <Nav
                  className="nav-fill flex-column flex-md-row"
                  id="tabs-icons-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={this.props.taskActiveTab === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.props.taskActiveTab === 1,
                      })}
                      onClick={(e) =>
                        this.props.taskInteractionsToggleNavs(e, 1)
                      }
                      href="#interactions"
                      role="tab"
                    >
                      <i className="ni ni-controller mr-2" />
                      Interações
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={this.props.taskActiveTab === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.props.taskActiveTab === 2,
                      })}
                      onClick={(e) =>
                        this.props.taskInteractionsToggleNavs(e, 2)
                      }
                      href="#docs"
                      role="tab"
                    >
                      <i className="ni ni-books mr-2" />
                      Documentos
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody>
                <TabContent activeTab={"tabs" + this.props.taskActiveTab}>
                  <TabPane tabId="tabs1">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="6">
                            <h5 className="h3 mb-0">Últimas interações</h5>
                          </Col>
                          <Col
                            xs="6"
                            className="text-right"
                            hidden={
                              this.props.newCommentOpened ||
                              this.props.taskStatus === "CL"
                            }
                          >
                            <Button
                              color="warning"
                              size="sm"
                              onClick={(e) => this.props.openNewComment(e)}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">
                                Nova Interação
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <div className="mb-1">
                          <Media hidden={!this.props.newCommentOpened}>
                            <Media body>
                              <Form>
                                <Row>
                                  <Col className="mb-3" md="12">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom01"
                                    >
                                      Novo Comentário
                                    </label>
                                    <Input
                                      id="validationCustom01"
                                      rows="2"
                                      type="textarea"
                                      value={this.props.newComment}
                                      disabled={this.props.saving}
                                      valid={
                                        this.props.customStyles
                                          .newCommentState === "valid"
                                      }
                                      invalid={
                                        this.props.customStyles
                                          .newCommentState === "invalid"
                                      }
                                      onChange={(e) =>
                                        this.props.stylesForm(e, "newComment")
                                      }
                                    />
                                    <div className="invalid-feedback">
                                      Digite um comentário antes de salvar.
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-3" md="6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom02"
                                    >
                                      Status
                                    </label>
                                    <Select2
                                      className="form-control"
                                      type="text"
                                      value={
                                        this.props.newStatus ||
                                        this.props.taskInteractions?.[0]?.status
                                      }
                                      disabled={this.props.saving}
                                      onSelect={(e) =>
                                        this.props.stylesForm(e, "newStatus")
                                      }
                                      options={{
                                        placeholder: "Selecione um status",
                                        language: {
                                          noResults: function () {
                                            return "Nenhum status encontrado.";
                                          },
                                        },
                                      }}
                                      data={this.props.statusList}
                                    />
                                    <Input
                                      hidden
                                      valid={
                                        this.props.customStyles
                                          .newStatusState === "valid"
                                      }
                                      invalid={
                                        this.props.customStyles
                                          .newStatusState === "invalid"
                                      }
                                    />
                                    <div className="invalid-feedback">
                                      Status inválido ou não selecionado.
                                    </div>
                                  </Col>
                                  <Col className="mb-3" md="6">
                                    <label
                                      className="form-control-label"
                                      htmlFor="validationCustom01"
                                    >
                                      Percentual
                                    </label>
                                    <Input
                                      disabled
                                      id="validationCustom01"
                                      type="number"
                                      value={
                                        this.props.percentage ||
                                        this.props.STATUS_PERCENTAGES[
                                          this.props.taskInteractions?.[0]
                                            ?.status
                                        ] ||
                                        0
                                      }
                                      valid={
                                        this.props.customStyles.percentage ===
                                        "valid"
                                      }
                                      invalid={
                                        this.props.customStyles.percentage ===
                                        "invalid"
                                      }
                                      onChange={(e) => {
                                        this.props.stylesForm(e, "percentage");
                                      }}
                                    />
                                    <div className="invalid-feedback">
                                      Digite uma porcentagem.
                                    </div>
                                  </Col>
                                </Row>
                                <Row></Row>
                                <Row>
                                  <Col className="mb-3" md="12">
                                    <label className="form-control-label">
                                      Anexos
                                    </label>
                                    <MultipleFilesDropzone
                                      instructions="Clique para localizar ou arraste e solte arquivos."
                                      maxFiles={5}
                                      acceptedFiles={null}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="mb-3 text-right" md="12">
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={(e) =>
                                        this.props.closeNewComment(e)
                                      }
                                    >
                                      <span className="btn-inner--text">
                                        Cancelar
                                      </span>
                                    </Button>
                                    <Button
                                      color="success"
                                      size="sm"
                                      onClick={(e) =>
                                        this.props.saveNewInteraction(e)
                                      }
                                    >
                                      <span className="btn-inner--text">
                                        Salvar
                                      </span>
                                    </Button>
                                  </Col>
                                </Row>
                              </Form>
                            </Media>
                          </Media>
                          <hr hidden={!this.props.newCommentOpened} />
                          {this.getInteractions(this.props.taskInteractions)}
                        </div>
                      </CardBody>
                    </Card>
                  </TabPane>
                  <TabPane tabId="tabs2">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col xs="6">
                            <h5 className="h3 mb-0">Documentos Relacionados</h5>
                          </Col>
                          <Col xs="6" className="text-right">
                            <Button
                              color="primary"
                              size="sm"
                              onClick={(e) =>
                                this.props.openModalDocument(e, null, "C")
                              }
                              hidden={!this.props.isConsulting}
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">
                                Incluir Documento
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <ToolkitProvider
                              data={this.props.taskDocuments}
                              keyField="_id"
                              columns={[
                                {
                                  dataField: "_id",
                                  text: "Id",
                                  hidden: true,
                                },
                                {
                                  dataField: "document",
                                  text: "Document",
                                  hidden: true,
                                },
                                {
                                  dataField: "kind",
                                  text: "Tipo",
                                  hidden: true,
                                },
                                {
                                  dataField: "url",
                                  text: "Url",
                                  hidden: true,
                                },
                                {
                                  dataField: "description",
                                  text: "Descrição do documento",
                                  formatter: (cell, row, rowIndex) => {
                                    let url = row.url;

                                    url = url.toLowerCase();

                                    if (row.kind === "U") {
                                      return (
                                        <a
                                          className="font-weight-bold"
                                          href={"#playYTVideo"}
                                          onClick={(e) =>
                                            this.playYouTubeVideo(e, row)
                                          }
                                        >
                                          {row.description}
                                        </a>
                                      );
                                    } else {
                                      if (
                                        row.kind === "V" &&
                                        url.includes(".mp4")
                                      ) {
                                        return (
                                          <a
                                            className="font-weight-bold"
                                            href={"#playVideo"}
                                            onClick={(e) =>
                                              this.playVideo(e, row)
                                            }
                                          >
                                            {row.description}
                                          </a>
                                        );
                                      } else {
                                        if (row.kind === "I") {
                                          return (
                                            <a
                                              className="font-weight-bold"
                                              href={"#viewImage"}
                                              onClick={(e) =>
                                                this.viewImage(e, row)
                                              }
                                            >
                                              {row.description}
                                            </a>
                                          );
                                        } else {
                                          return (
                                            <a
                                              className="font-weight-bold"
                                              href={row.url}
                                              rel="noopener noreferrer"
                                              target="_blank"
                                            >
                                              {row.description}
                                            </a>
                                          );
                                        }
                                      }
                                    }
                                  },
                                },
                                {
                                  dataField: "name",
                                  text: "Arquivo",
                                },
                                {
                                  dataField: "removeDoc",
                                  text: "Excluir",
                                  hidden: !this.props.isConsulting,
                                  formatter: (cell, row) => {
                                    return (
                                      <a
                                        className="table-action"
                                        href="#docs"
                                        id={"btn-removeDoc-" + row._id}
                                        onClick={(e) =>
                                          this.props.openModalDocument(
                                            e,
                                            row,
                                            "D"
                                          )
                                        }
                                      >
                                        <i className="fas fa-trash" />
                                      </a>
                                    );
                                  },
                                },
                              ]}
                            >
                              {(props) => (
                                <div className="py-4 table-responsive">
                                  <BootstrapTable
                                    ref={(el) => (this.componentRef = el)}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={true}
                                    hover
                                    condensed
                                    responsive
                                    id="react-bs-table-interactions"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Card>
          <div className="modal-footer">
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              disabled={this.props.saving}
            >
              Fechar
            </Button>
          </div>
        </Modal>
        <PlayVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalVideoClose.bind(this)}
          modalOpened={this.state.modalPlayVideoOpened}
          source={this.state.videoSource}
        />
        <PlayYouTubeVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalYouTubeVideoClose.bind(this)}
          modalOpened={this.state.modalPlayYouTubeVideoOpened}
          source={this.state.videoSource}
        />
        <ImageZoom
          title={this.state.modalViewImageTitle}
          url={this.state.viewImageUrl}
          crud={"R"}
          modalOpened={this.state.modalViewImageOpened}
          modalClose={this.modalViewImageClose.bind(this)}
          isConsulting={false}
        />
      </>
    );
  }
}

export default TaskConsole;
