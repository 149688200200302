import React from "react"

import Moment from "moment"
import TaskCard from "./TaskCard"
import { Row } from "reactstrap"

class TaskRow extends React.Component {
  componentDidMount() {
    Moment.locale("pt-br")
  }

  getTasks = (tasks) => {
    return tasks.map((prop, key) => {
      return (
        <TaskCard
          id={prop.id}
          code={prop.code}
          percentage={prop.percentage}
          requester={prop.requester}
          requesterFullName={prop.requesterFullName}
          requesterImage={prop.requesterImage}
          name={prop.name}
          description={prop.description}
          date={prop.date}
          dueDate={prop.dueDate}
          conclusionDate={prop.conclusionDate}
          status={prop.status}
          responsible={prop.responsible}
          responsibleFullName={prop.responsibleFullName}
          responsibleImage={prop.responsibleImage}
          priority={prop.priority}
          module={prop.module}
          module_legacy={prop.module_legacy}
          key={key}
          statusIndex={this.props.statusIndex}
          priorityIndex={this.props.priorityIndex}
          isManagerConsulting={this.props.isManagerConsulting}
          isResourceConsulting={this.props.isResourceConsulting}
          openModalTask={this.props.openModalTask.bind(this.props)}
          openModalTaskConsole={this.props.openModalTaskConsole.bind(
            this.props
          )}
          isLoading={this.props.isLoading}
          avatarFilterClick={this.props.avatarFilterClick.bind(this.props)}
          hasDocs={prop.hasDocs}
          hasInteractions={prop.hasInteractions}
        />
      )
    })
  }

  render() {
    return <Row>{this.getTasks(this.props.tasks)}</Row>
  }
}

export default TaskRow
