import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"

class Test extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.modalOpened}
          autoFocus={false}
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Sequencial
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            type="text"
                            value={this.props.testSequence}
                            readOnly={
                              this.props.readOnly ||
                              this.props.cicleTitle !== "A"
                            }
                            maxLength="5"
                            valid={
                              this.props.customStyles.testSequenceState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testSequenceState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "testSequence")
                            }
                          />
                          <div className="invalid-feedback">
                            Sequencial inválido.
                          </div>
                        </Col>
                        <Col className="mb-3" md="5">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Descrição
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Descrição do teste (ex. Incluir)"
                            type="text"
                            value={this.props.testDescription}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.testDescriptionState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testDescriptionState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "testDescription")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a descrição do teste.
                          </div>
                        </Col>
                        <Col className="mb-3" md="5">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Status
                          </label>
                          <Input
                            id="validationCustom03"
                            type="select"
                            value={this.props.testStatus}
                            disabled={true}
                            onChange={(e) =>
                              this.props.stylesForm(e, "testStatus")
                            }
                          >
                            {this.props.getStatusList(this.props.statusList)}
                          </Input>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Analista
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.testAnalyst}
                            disabled={this.props.readOnly || this.props.saving}
                            onSelect={(e) =>
                              this.props.stylesForm(e, "testAnalyst")
                            }
                            options={{
                              placeholder: "Selecione um analista",
                              language: {
                                noResults: function () {
                                  return "Nenhum analista encontrado."
                                },
                              },
                            }}
                            data={this.props.analystList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.testAnalystState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testAnalystState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Analista inválido ou não selecionado
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Usuário Chave
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.testKeyUser}
                            disabled={this.props.readOnly || this.props.saving}
                            onSelect={(e) =>
                              this.props.stylesForm(e, "testKeyUser")
                            }
                            options={{
                              placeholder: "Selecione um usuário",
                              language: {
                                noResults: function () {
                                  return "Nenhum usuário encontrado."
                                },
                              },
                            }}
                            data={this.props.keyUserList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.testKeyUserState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testKeyUserState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Usuário inválido ou não selecionado.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Usuário Chave opcional
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.testOpitionalKeyUser}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              !this.props.testKeyUser
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "testOpitionalKeyUser")
                            }
                            options={{
                              placeholder: "Selecione um usuário",
                              language: {
                                noResults: function () {
                                  return "Nenhum usuário encontrado."
                                },
                              },
                            }}
                            data={this.props.keyUserList?.filter((user) => {
                              if (user.id !== this.props.testKeyUser) {
                                return user
                              }
                            })}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.testKeyUserState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testKeyUserState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Usuário inválido ou não selecionado.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Condição do teste
                          </label>
                          <Input
                            id="exampleFormControlTextarea1"
                            rows="3"
                            type="textarea"
                            value={this.props.testCondition}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.testConditionState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.testConditionState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "testCondition")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a condição detalhada do teste.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Observações
                          </label>
                          <Input
                            id="exampleFormControlTextarea1"
                            rows="3"
                            type="textarea"
                            value={this.props.testComments}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            onChange={(e) =>
                              this.props.stylesForm(e, "testComments")
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="10">
                          <label
                            className="form-control-label"
                            htmlFor="sqlQuery"
                          >
                            SQL Query
                          </label>
                          <Input
                            id="sqlQuery"
                            rows="5"
                            type="textarea"
                            value={this.props.sqlQuery}
                            readOnly={this.props.readOnly}
                            disabled={
                              (this.props.saving,
                              !localStorage.getItem("sl_session_id"))
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "sqlQuery")
                            }
                          />
                          {/* <Button
                            className="mt-2"
                            color="info"
                            type="button"
                            disabled={
                              !this.props.sqlQuery ||
                              !localStorage.getItem("sl_session_id")
                            }
                            onClick={(e) => this.props.testSqlQuery()}
                          >
                            Cadastrar Query
                          </Button> */}
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="baseNumber"
                          >
                            Número de Base
                          </label>
                          <Input
                            id="baseNumber"
                            type="number"
                            value={this.props.baseNumber}
                            readOnly={this.props.readOnly}
                            disabled={
                              this.props.saving ||
                              !localStorage.getItem("sl_session_id") ||
                              !this.props.sqlQuery
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "baseNumber")
                            }
                          />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Test
