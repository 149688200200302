import React from "react";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Media,
  Row,
  //   Table
} from "reactstrap";

import Avatar from "react-avatar";
import { Progress } from "reactstrap";
import Col from "reactstrap/lib/Col";

class LatestInteractions extends React.Component {
  getInteractions = (interactions) => {
    if (interactions) {
      return interactions.map((prop, key) => {
        return (
          <div className="timeline-block" key={key}>
            {prop.status === "NS" ? (
              <span className="timeline-step badge-danger">
                <i className="ni ni-bell-55" />
              </span>
            ) : null}
            {prop.status === "CA" ? (
              <span className="timeline-step badge-secondary">
                <i className="ni ni-fat-remove" />
              </span>
            ) : null}
            {prop.status === "AP" ? (
              <span className="timeline-step badge-success">
                <i className="ni ni-check-bold" />
              </span>
            ) : null}
            {prop.status === "NC" ||
            prop.status === "NK" ||
            prop.status === "NF" ? (
              <span className="timeline-step badge-info">
                <i className="ni ni-support-16" />
              </span>
            ) : null}
            {prop.test && prop.status === "IP" ? (
              <span className="timeline-step badge-warning">
                <i className="ni ni-user-run" />
              </span>
            ) : null}
            {prop.status === "WA" ? (
              <span className="timeline-step badge-info">
                <i className="ni ni-fat-delete" />
              </span>
            ) : null}

            {prop.status === "PN" ? (
              <span className="timeline-step badge-danger">
                <i className="ni ni-bell-55" />
              </span>
            ) : null}
            {prop.status === "CL" ? (
              <span className="timeline-step badge-success">
                <i className="ni ni-check-bold" />
              </span>
            ) : null}
            {prop.status === "NA" || prop.status === "DC" ? (
              <span className="timeline-step badge-warning">
                <i className="ni ni-support-16" />
              </span>
            ) : null}
            {prop.task && prop.status === "IP" ? (
              <span className="timeline-step badge-info">
                <i className="ni ni-user-run" />
              </span>
            ) : null}
            {prop.status === "IV" ? (
              <span className="timeline-step badge-primary">
                <i className="ni ni-user-run" />
              </span>
            ) : null}

            <div className="timeline-content">
              <Media className="media-comment">
                {prop.image ? (
                  <img
                    alt="..."
                    className="avatar avatar-lg media-comment-avatar rounded-circle"
                    src={prop.image.url}
                  />
                ) : (
                  <a
                    className="avatar avatar-lg media-comment-avatar rounded-circle"
                    href="#gl8"
                  >
                    <Avatar
                      name={prop.userFullName}
                      maxInitials={2}
                      round={true}
                      size={48}
                      title={" "}
                      color={"#172b4d"}
                      fgColor={"#11cdef"}
                    />
                  </a>
                )}
                <Media>
                  <div className="media-comment-text">
                    <h6 className="h5 mt-0">{prop.userFullName}</h6>
                    <small className="text-muted font-weight-bold">
                      <i className="fas fa-clock mr-1" />
                      {prop.date + " " + prop.time} 
                    </small>
                    {prop.status === "WA" ? (
                      <h5 className="mt-3 mb-1">
                        Sem interações nos últimos dias.
                      </h5>
                    ) : prop.test ? (
                      <h5 className="mt-3 mb-1">
                        <a
                          href={"#planConsole"}
                          onClick={(e) => {
                            this.props.history.push(
                              "/admin/planConsole/" +
                                prop.plan +
                                "/" +
                                prop.cicle +
                                "/" +
                                prop.item +
                                "/" +
                                prop.test +
                                "/" +
                                prop.id
                            );
                          }}
                        >
                          {prop.customerShortName +
                            " > " +
                            prop.projectName +
                            " > " +
                            prop.planBusinessArea +
                            " > " +
                            prop.itemProcess +
                            " > " +
                            prop.testDescription}
                        </a>
                      </h5>
                    ) : (
                      <h5 className="mt-3 mb-1">
                        <a
                          href={"#listConsole"}
                          onClick={(e) => {
                            this.props.history.push(
                              "/admin/listConsole/" +
                                prop.list +
                                "/" +
                                prop.task
                            );
                          }}
                        >
                          {prop.customerShortName +
                            " > " +
                            prop.projectName +
                            " > " +
                            prop.listName +
                            " > " +
                            prop.taskName}
                        </a>
                      </h5>
                    )}
                    {prop.status === "WA" ? (
                      <p className="text-sm lh-160">
                        Aguardando interações de analistas/usuários chave.
                      </p>
                    ) : (
                      <p className="text-sm lh-160">{prop.comments}</p>
                    )}
                    <div className="mt-3">
                      {prop.status !== "WA" ? (
                        <Badge color="info" pill>
                          {prop.test ? "teste" : "atividade"}
                        </Badge>
                      ) : null}
                      {prop.status === "NS" ? (
                        <Badge color="danger" pill>
                          não iniciado
                        </Badge>
                      ) : null}
                      {prop.status === "CA" ? (
                        <Badge color="secondary" pill>
                          cancelado
                        </Badge>
                      ) : null}
                      {prop.status === "AP" ? (
                        <Badge color="success" pill>
                          aprovado
                        </Badge>
                      ) : null}
                      {prop.status === "NC" ? (
                        <Badge color="info" pill>
                          cenário parado
                        </Badge>
                      ) : null}
                      {prop.status === "NK" ? (
                        <Badge color="info" pill>
                          cenário continua
                        </Badge>
                      ) : null}
                      {prop.status === "NF" ? (
                        <Badge color="info" pill>
                          cenário corrigido
                        </Badge>
                      ) : null}
                      {prop.status === "IP" ? (
                        <Badge color="info" pill>
                          em andamento
                        </Badge>
                      ) : null}
                      {prop.status === "WA" ? (
                        <Badge color="info" pill>
                          sem interações
                        </Badge>
                      ) : null}
                      {prop.status === "PN" ? (
                        <Badge color="danger" pill>
                          pendente
                        </Badge>
                      ) : null}
                      {prop.status === "CL" ? (
                        <Badge color="success" pill>
                          concluído
                        </Badge>
                      ) : null}
                      {prop.status === "NA" ? (
                        <Badge color="warning" pill>
                          não aprovado
                        </Badge>
                      ) : null}
                      {prop.status === "IV" ? (
                        <Badge color="primary" pill>
                          em validação
                        </Badge>
                      ) : null}
                      {prop.status === "DC" ? (
                        <Badge color="warning" pill>
                          descontinuado
                        </Badge>
                      ) : null}
                    </div>
                  </div>
                </Media>
              </Media>
            </div>
          </div>
        );
      });
    }
  };

  render() {
    return (
      <>
        <Row>
          <Col xs="12">
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">{this.props.title}</h3>
              </CardHeader>
              <CardBody hidden={!this.props.isLoading}>
                <div className="text-center text-muted my-4">
                  <Progress animated color="danger" value="100" />
                </div>
              </CardBody>
              <CardBody hidden={this.props.isLoading}>
                <div
                  className="timeline"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {this.getInteractions(this.props.interactions)}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default LatestInteractions;
