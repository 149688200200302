import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"

import MultipleFilesDropzone from "components/Dropzones/MultipleFilesDropzone.js"
import { isConsulting } from "services/auth"

class Interaction extends React.Component {
  state = {
    isUserConsulting: false,
  }

  componentDidMount() {
    this.setState({
      isUserConsulting: isConsulting(),
    })
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.modalOpened}
          autoFocus={false}
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Status
                          </label>
                          <Input
                            id="validationCustom03"
                            type="select"
                            value={this.props.interactionStatus}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              !this.props.canChangeStatus
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "interactionStatus")
                            }
                            valid={
                              this.props.customStyles.interactionStatusState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.interactionStatusState ===
                              "invalid"
                            }
                          >
                            {this.props.getStatusList(
                              this.props.possibleStatusList
                            )}
                          </Input>
                          <div className="invalid-feedback">
                            Selecione um status.
                          </div>
                        </Col>
                        <Col
                          className="mb-3"
                          md="8"
                          hidden={!this.state.isUserConsulting}
                        >
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Não Conformidade
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.nonconformity}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              (this.props.interactionStatus !== "NC" &&
                                this.props.interactionStatus !== "NK") ||
                              !this.state.isUserConsulting
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "nonconformity")
                            }
                            options={{
                              placeholder: "Selecione uma não conformidade",
                              language: {
                                noResults: function () {
                                  return "Nenhuma não conformidade encontrada."
                                },
                              },
                            }}
                            data={this.props.nonconformityList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.nonconformityState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.nonconformityState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Não conformidade inválida ou não selecionada.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Comentário
                          </label>
                          <Input
                            id="exampleFormControlTextarea1"
                            placeholder="Digite um comentário, por exemplo: Teste concluído sem erros."
                            rows="3"
                            type="textarea"
                            value={this.props.interactionComments}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .interactionCommentsState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .interactionCommentsState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "interactionComments")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite os comentários da interação.
                          </div>
                        </Col>
                      </Row>
                      {this.props.crud === "C" ? (
                        <Row>
                          <Col className="mb-3" md="12">
                            <label className="form-control-label">Anexos</label>
                            <MultipleFilesDropzone
                              instructions="Clique para localizar ou arraste e solte arquivos."
                              maxFiles={10}
                              acceptedFiles={null}
                            />
                          </Col>
                        </Row>
                      ) : null}
                      {this.props.crud !== "C" ? (
                        <Row>
                          <Col className="mb-3" md="12">
                            <label className="form-control-label">Anexos</label>
                            <ToolkitProvider
                              data={this.props.documentData}
                              keyField="_id"
                              columns={[
                                {
                                  dataField: "_id",
                                  text: "Id",
                                  hidden: true,
                                },
                                {
                                  dataField: "url",
                                  text: "Url",
                                  hidden: true,
                                },
                                {
                                  dataField: "name",
                                  text: "Arquivo",
                                  formatter: (cell, row, rowIndex) => {
                                    return (
                                      <a
                                        className="font-weight-bold"
                                        href={row.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {row.name}
                                      </a>
                                    )
                                  },
                                },
                              ]}
                            >
                              {(props) => (
                                <div className="py-4 table-responsive">
                                  <BootstrapTable
                                    ref={(el) => (this.componentRef = el)}
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={true}
                                    hover
                                    condensed
                                    responsive
                                    id="react-bs-table-int-docs"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          </Col>
                        </Row>
                      ) : null}
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "save")}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Interaction
