import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

class Nonconformity extends React.Component {
  
  render() {
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="xl"
            isOpen={this.props.modalOpened}
            autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate autoComplete="off">
                      <div className="form-row">
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Título
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Nome da não conformidade"
                            type="text"
                            value={this.props.name}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.nameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.nameState === "invalid"
                            }
                            onChange={e =>
                              this.props.stylesForm(e, "name")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o título da não conformidade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="8">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Descrição Complementar
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Descrição complementar, ou observações"
                            type="text"
                            value={this.props.description}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.descriptionState === "valid"
                            }
                            invalid={
                              this.props.customStyles.descriptionState === "invalid"
                            }
                            onChange={e => this.props.stylesForm(e, "description")}
                          />
                          <div className="invalid-feedback">
                            Digite uma descrição complementar.
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.closeModal(e, "abort")}
                disabled={this.props.saving}
            >
                Cancelar
            </Button>
            <Button
                color="success"
                type="button"
                onClick={(e) => this.props.modalSave(e)}
                hidden={this.props.crud === "R"}
                disabled={this.props.saving}
            >
                {this.props.crud === "D" ? ("Excluir"):("Salvar")}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Nonconformity;