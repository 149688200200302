import React from "react"
import { Button, Card, Modal, Row, Col } from "reactstrap"
import NewPlanDataCard from "components/Cards/NewPlanDataCard.js"
import NewListDataCard from "components/Cards/NewListDataCard"

class NewMultiplePlans extends React.Component {
  getPlans = (plans) => {
    return plans.map((prop, key) => {
      return (
        <NewPlanDataCard
          id={prop.id}
          businessArea={prop.businessArea}
          analyst={prop.analyst}
          keyUser={prop.keyUser}
          manager={prop.manager}
          analystList={this.props.analystList}
          keyUserList={this.props.keyUserList}
          managerList={this.props.managerList}
          copyPlan={prop.copyPlan}
          customStyles={prop.customStyles}
          stylesForm={this.props.stylesForm.bind(this)}
          key={key}
        />
      )
    })
  }
  getLists = (lists) => {
    return lists.map((prop, key) => {
      return (
        <NewListDataCard
          id={prop.id}
          name={prop.name}
          managerCG={prop.managerCG}
          managerCR={prop.managerCR}
          analystList={this.props.analystList}
          keyUserList={this.props.keyUserList}
          key={key}
          copyList={prop.copyList}
          stylesForm={this.props.stylesFormList.bind(this)}
        />
      )
    })
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <Col xs="12">
              <h3 className="ml-4">Planos </h3>

              <Card>{this.getPlans(this.props.plans)}</Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <h3 className="ml-4">Listas </h3>

              <Card>{this.getLists(this.props.lists)}</Card>
            </Col>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
            >
              Continuar
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default NewMultiplePlans
