import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
// reactstrap components
import {
  Card,
  Progress,
  CardBody,
  Col,
  Row,
  Button,
  UncontrolledCollapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"

class ItemCard extends React.Component {
  render() {
    const rowEvents = {
      onDoubleClick: (e, row, rowIndex) =>
        this.props.openModalTestInteractions(e, row, this.props.item, 1),
    }

    return (
      <>
        <Card hidden={this.props.hiddenItem}>
          <CardBody>
            <Row className="align-items-center">
              <Col className="col-auto">
                {this.props.status === "P" ? (
                  <div className="icon-xl icon-shape bg-gradient-danger text-white rounded-circle shadow">
                    <i className="ni ni-bell-55" />
                  </div>
                ) : null}
                {this.props.status === "I" ? (
                  <div className="icon-xl icon-shape bg-gradient-warning text-white rounded-circle shadow">
                    <i className="ni ni-user-run" />
                  </div>
                ) : null}
                {this.props.status === "C" ? (
                  <div className="icon-xl icon-shape bg-gradient-success text-white rounded-circle shadow">
                    <i className="ni ni-check-bold" />
                  </div>
                ) : null}
              </Col>
              <div className="col ml--2">
                <div className="progress-wrapper">
                  <h4 className="mb-0">
                    <a href="#tests" id={"linkToggler" + this.props.item.id}>
                      {this.props.sequence + " - " + this.props.process}
                    </a>
                  </h4>
                  <div className="progress-info-left">
                    {this.props.status === "P" ? (
                      <Badge color="danger">Pendente</Badge>
                    ) : null}
                    {this.props.status === "I" ? (
                      <Badge color="warning">Em andamento</Badge>
                    ) : null}
                    {this.props.status === "C" ? (
                      <Badge color="success">Concluído</Badge>
                    ) : null}
                    <Badge
                      color="info"
                      hidden={this.props.lastInteractionDate === ""}
                    >
                      {"Última interação: " + this.props.lastInteractionDate}
                    </Badge>
                    <Badge
                      color="primary"
                      hidden={this.props.lastInteractionUserFullName === ""}
                    >
                      {this.props.lastInteractionUserFullName}
                    </Badge>
                    <div className="progress-percentage">
                      <span>{this.props.conclusion + "%"}</span>
                    </div>
                  </div>
                  {this.props.status === "P" ? (
                    <Progress
                      max="100"
                      value={this.props.conclusion}
                      color="danger"
                    />
                  ) : null}
                  {this.props.status === "I" ? (
                    <Progress
                      max="100"
                      value={this.props.conclusion}
                      color="warning"
                    />
                  ) : null}
                  {this.props.status === "C" ? (
                    <Progress
                      max="100"
                      value={this.props.conclusion}
                      color="success"
                    />
                  ) : null}
                </div>
              </div>
              <Col className="col-auto">
                <Button
                  color="secondary"
                  size="sm"
                  id={"buttonToggler" + this.props.item.id}
                  //onClick={alert("a")}
                >
                  <i className="ni ni-bold-down" />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={"buttonToggler" + this.props.item.id}
                  >
                    Exibir/Ocultar Testes
                  </UncontrolledTooltip>
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  id={"buttonNewTest" + this.props.item.id}
                  onClick={(e) =>
                    this.props.openModalTest(e, null, "C", this.props.item)
                  }
                  hidden={
                    this.props.item.cicle !== this.props.item.planCicle ||
                    this.props.isCustomer ||
                    !this.props.isOwner ||
                    this.props.planStatus === "C" ||
                    this.props.status === "C"
                  }
                >
                  <i className="fas fa-plus" />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={"buttonNewTest" + this.props.item.id}
                  >
                    Novo Teste
                  </UncontrolledTooltip>
                </Button>
                <Button
                  color="secondary"
                  size="sm"
                  id={"buttonItemDocs" + this.props.item.id}
                  onClick={(e) =>
                    this.props.openModalItemDocuments(e, this.props.item)
                  }
                  hidden={!this.props.item.hasDocs && this.props.isCustomer}
                >
                  <i className="ni ni-books" />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={"buttonItemDocs" + this.props.item.id}
                  >
                    Documentos de Apoio
                  </UncontrolledTooltip>
                </Button>
                <UncontrolledDropdown
                  hidden={
                    this.props.item.cicle !== this.props.item.planCicle ||
                    this.props.isCustomer ||
                    !this.props.isOwner ||
                    this.props.planStatus === "C" ||
                    this.props.status === "C"
                  }
                >
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      onClick={(e) =>
                        this.props.openModalItem(e, "U", this.props.item)
                      }
                    >
                      <i className="fas fa-edit" />
                      <span>Alterar Processo</span>
                    </DropdownItem>
                    <DropdownItem
                      hidden={this.props.status !== "P"}
                      onClick={(e) =>
                        this.props.openModalItem(e, "D", this.props.item)
                      }
                    >
                      <i className="fas fa-trash" />
                      <span>Excluir Processo</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
            <UncontrolledCollapse
              defaultOpen={this.props.defaultOpen}
              toggler={
                "#showAllTests,#buttonToggler" +
                this.props.item.id +
                ",#linkToggler" +
                this.props.item.id
              }
            >
              <div>
                <ToolkitProvider
                  keyField="_id"
                  search
                  data={this.props.tests}
                  columns={[
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "sequence",
                      text: "Sequencial",
                    },
                    {
                      dataField: "testDescription",
                      text: "Descrição",
                    },
                    {
                      dataField: "testCondition",
                      text: "Condição",
                      hidden: true,
                    },
                    {
                      dataField: "nonconformity",
                      text: "Não conformidade",
                      hidden: true,
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      formatter: (cell, row) => {
                        for (let i = 0; i < this.props.statusList.length; i++) {
                          if (this.props.statusList[i].value === row.status) {
                            if (row.status === "NS") {
                              return (
                                <span className="text-danger mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "CA") {
                              return (
                                <span className="text-muted mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "AP") {
                              return (
                                <span className="text-success mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "NC") {
                              return (
                                <span className="text-info mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "NK") {
                              return (
                                <span className="text-info mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "NF") {
                              return (
                                <span className="text-info mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }

                            if (row.status === "IP") {
                              return (
                                <span className="text-warning mb-0">
                                  {this.props.statusList[i].text}
                                </span>
                              )
                            }
                          }
                        }
                      },
                    },
                    {
                      dataField: "analystFullName",
                      text: "Analista",
                    },
                    {
                      dataField: "keyUserFullName",
                      text: "Usuário Chave",
                    },
                    {
                      dataField: "opitionalKeyUserFullName",
                      text: "Usuário Chave opcional",
                    },
                    {
                      dataField: "Comments",
                      text: "ObservaçõesT",
                      hidden: true,
                    },
                    {
                      dataField: "hasDocs",
                      text: "Doc.",
                      formatter: (cell, row) => {
                        if (row.hasDocs) {
                          return (
                            <a
                              className="table-action"
                              href="#docs"
                              id={"btn-docs-" + row._id}
                              onClick={(e) =>
                                this.props.openModalTestInteractions(
                                  e,
                                  row,
                                  this.props.item,
                                  2
                                )
                              }
                            >
                              <i className="ni ni-books" />
                              <UncontrolledTooltip
                                delay={0}
                                target={"btn-docs-" + row._id}
                              >
                                Documentos de apoio
                              </UncontrolledTooltip>
                            </a>
                          )
                        } else {
                          return null
                        }
                      },
                    },
                    {
                      dataField: "queryResult",
                      text: "No. Testes",
                    },
                    {
                      dataField: "baseNumber",
                      text: "Num Base",
                    },
                    {
                      dataField: "hasInteractions",
                      text: "Doc.",
                      hidden: true,
                    },
                    {
                      dataField: "actions",
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                hidden={
                                  this.props.item.cicle !==
                                    this.props.item.planCicle ||
                                  this.props.isCustomer ||
                                  row.status === "AP" ||
                                  row.status === "CA"
                                }
                                onClick={(e) =>
                                  this.props.openModalTest(
                                    e,
                                    row,
                                    "U",
                                    this.props.item
                                  )
                                }
                              >
                                <i className="fas fa-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                hidden={
                                  this.props.item.cicle !==
                                    this.props.item.planCicle ||
                                  this.props.isCustomer ||
                                  !this.props.isOwner ||
                                  row.hasInteractions
                                }
                                onClick={(e) =>
                                  this.props.openModalTest(
                                    e,
                                    row,
                                    "D",
                                    this.props.item
                                  )
                                }
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) =>
                                  this.props.openModalTest(
                                    e,
                                    row,
                                    "R",
                                    this.props.item
                                  )
                                }
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) =>
                                  this.props.openModalTestInteractions(
                                    e,
                                    row,
                                    this.props.item,
                                    1
                                  )
                                }
                              >
                                <i className="ni ni-controller" />
                                <span>Interagir</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={true}
                        hover
                        condensed
                        responsive
                        size="sm"
                        rowEvents={rowEvents}
                        hiddenRows={this.props.hiddenRows}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </UncontrolledCollapse>
          </CardBody>
        </Card>
      </>
    )
  }
}

export default ItemCard
