import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
// reactstrap components
import { Button, Card, CardHeader, CardBody, Modal, Col, Row } from "reactstrap"

import PlayVideo from "components/Modals/PlayVideo.js"
import PlayYouTubeVideo from "components/Modals/PlayYouTubeVideo.js"
import ImageZoom from "components/Modals/ImageZoom.js"

class ItemDocuments extends React.Component {
  state = {
    //Play video modal
    modalPlayVideoTitle: "",
    modalPlayYouTubeVideoOpened: false,
    modalPlayVideoOpened: false,
    videoSource: "",

    //View image modal
    modalViewImageOpened: false,
    modalViewImageTitle: "",
    viewImageUrl: "",
  }

  playYouTubeVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayYouTubeVideoOpened: true,
      videoSource: row.url,
    })
  }

  modalYouTubeVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayYouTubeVideoOpened: false,
      videoSource: "",
    })
  }

  playVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayVideoOpened: true,
      videoSource: row.url,
    })
  }

  modalVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayVideoOpened: false,
      videoSource: "",
    })
  }

  viewImage(e, row) {
    this.setState({
      modalViewImageOpened: true,
      modalViewImageTitle: row.description,
      viewImageUrl: row.url,
    })
  }

  modalViewImageClose(e) {
    this.setState({
      modalViewImageOpened: false,
      modalViewImageTitle: "",
      viewImageUrl: "",
    })
  }

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h4 className="mb-2">
              Documentos de apoio do processo:
              <span className="text-primary mb-0">
                {" " + this.props.title}
              </span>
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Card>
            <CardBody>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="12" className="text-left">
                      {/* <h4 className="mb-2"> */}
                      <p className="description">
                        Aqui estão alguns documentos que poderão ajudar a
                        realizar os testes deste processo. Clique no nome do
                        documento para visualizá-lo ou para fazer o download.
                      </p>
                      {/* </h4> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xs="4" className="text-left">
                      <h4 className="mb-2">Documentos disponíveis</h4>
                    </Col>
                    <Col xs="8" className="text-right">
                      <Button
                        color="info"
                        size="sm"
                        onClick={(e) =>
                          this.props.openModalDocument(e, null, "C", "item")
                        }
                        hidden={!this.props.isConsulting}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Documento</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ToolkitProvider
                        data={this.props.documentsData}
                        keyField="_id"
                        columns={[
                          {
                            dataField: "_id",
                            text: "Id",
                            hidden: true,
                          },
                          {
                            dataField: "document",
                            text: "Document",
                            hidden: true,
                          },
                          {
                            dataField: "kind",
                            text: "Tipo",
                            hidden: true,
                          },
                          {
                            dataField: "url",
                            text: "Url",
                            hidden: true,
                          },
                          {
                            dataField: "description",
                            text: "Descrição do documento",
                            formatter: (cell, row, rowIndex) => {
                              let url = row.url

                              url = url.toLowerCase()

                              if (row.kind === "U") {
                                return (
                                  <a
                                    className="font-weight-bold"
                                    href={"#playYTVideo"}
                                    onClick={(e) =>
                                      this.playYouTubeVideo(e, row)
                                    }
                                  >
                                    {row.description}
                                  </a>
                                )
                              } else {
                                if (row.kind === "V" && url.includes(".mp4")) {
                                  return (
                                    <a
                                      className="font-weight-bold"
                                      href={"#playVideo"}
                                      onClick={(e) => this.playVideo(e, row)}
                                    >
                                      {row.description}
                                    </a>
                                  )
                                } else {
                                  if (row.kind === "I") {
                                    return (
                                      <a
                                        className="font-weight-bold"
                                        href={"#viewImage"}
                                        onClick={(e) => this.viewImage(e, row)}
                                      >
                                        {row.description}
                                      </a>
                                    )
                                  } else {
                                    return (
                                      <a
                                        className="font-weight-bold"
                                        href={row.url}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        {row.description}
                                      </a>
                                    )
                                  }
                                }
                              }
                            },
                          },
                          {
                            dataField: "name",
                            text: "Arquivo",
                          },
                          {
                            dataField: "removeDoc",
                            text: "Excluir",
                            hidden: !this.props.isConsulting,
                            formatter: (cell, row) => {
                              return (
                                <a
                                  className="table-action"
                                  href="#docs"
                                  id={"btn-removeDoc-" + row._id}
                                  onClick={(e) =>
                                    this.props.openModalDocument(
                                      e,
                                      row,
                                      "D",
                                      "item"
                                    )
                                  }
                                >
                                  <i className="fas fa-trash" />
                                </a>
                              )
                            },
                          },
                          {
                            dataField: "changeDoc",
                            text: "Alterar",
                            hidden:
                              !this.props.isConsulting ||
                              !this.props.goliveCustomer,
                            formatter: (cell, row) => {
                              return (
                                <a
                                  className="table-action"
                                  href="#docs"
                                  id={"btn-removeDoc-" + row._id}
                                  onClick={(e) =>
                                    this.props.openModalDocument(
                                      e,
                                      row,
                                      "U",
                                      "item"
                                    )
                                  }
                                >
                                  <i className="fas fa-edit" />
                                </a>
                              )
                            },
                          },
                        ]}
                      >
                        {(props) => (
                          <div className="py-4 table-responsive">
                            <BootstrapTable
                              ref={(el) => (this.componentRef = el)}
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={true}
                              hover
                              condensed
                              responsive
                              id="react-bs-table-interactions"
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
            >
              Fechar
            </Button>
          </div>
        </Modal>
        <PlayVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalVideoClose.bind(this)}
          modalOpened={this.state.modalPlayVideoOpened}
          source={this.state.videoSource}
        />
        <PlayYouTubeVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalYouTubeVideoClose.bind(this)}
          modalOpened={this.state.modalPlayYouTubeVideoOpened}
          source={this.state.videoSource}
        />
        <ImageZoom
          title={this.state.modalViewImageTitle}
          url={this.state.viewImageUrl}
          crud={"R"}
          modalOpened={this.state.modalViewImageOpened}
          modalClose={this.modalViewImageClose.bind(this)}
          isConsulting={false}
        />
      </>
    )
  }
}

export default ItemDocuments
