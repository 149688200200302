import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import classnames from "classnames";

import PlayVideo from "components/Modals/PlayVideo.js";
import PlayYouTubeVideo from "components/Modals/PlayYouTubeVideo.js";
import ImageZoom from "components/Modals/ImageZoom.js";
import TestConditionReading from "./TestConditionReading";

class TestInteractions extends React.Component {
  state = {
    //Play video modal
    modalPlayVideoTitle: "",
    modalPlayVideoOpened: false,
    modalPlayYouTubeVideoOpened: false,
    videoSource: "",

    //View image modal
    modalViewImageOpened: false,
    modalViewImageTitle: "",
    viewImageUrl: "",

    // ReadtestCondition
    testConditionreadingOpen: false,
  };
  componentDidUpdate() {
    if (
      this.props.modalOpened &&
      this.props.testCondition &&
      !this.state.testConditionreadingOpen
    ) {
      this.setState({ ...this.state, testConditionreadingOpen: true });
    }
  }

  playYouTubeVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayYouTubeVideoOpened: true,
      videoSource: row.url,
    });
  }

  modalYouTubeVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayYouTubeVideoOpened: false,
      videoSource: "",
    });
  }

  playVideo(e, row) {
    this.setState({
      modalPlayVideoTitle: row.description,
      modalPlayVideoOpened: true,
      videoSource: row.url,
    });
  }

  modalVideoClose(e) {
    this.setState({
      modalPlayVideoTitle: "",
      modalPlayVideoOpened: false,
      videoSource: "",
    });
  }

  viewImage(e, row) {
    this.setState({
      modalViewImageOpened: true,
      modalViewImageTitle: row.description,
      viewImageUrl: row.url,
    });
  }

  modalViewImageClose(e) {
    this.setState({
      modalViewImageOpened: false,
      modalViewImageTitle: "",
      viewImageUrl: "",
    });
  }

  render() {
    const interactionRowEvents = {
      onDoubleClick: (e, row, rowIndex) =>
        this.props.openModalInteraction(e, row, "R"),
    };

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h4 className="mb-2">
              Você está em:
              <span className="text-primary mb-0">
                {" " + this.props.title}
              </span>
            </h4>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Card>
            <CardHeader>
              <Nav
                className="nav-fill flex-column flex-md-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.props.interactionsActiveTab === 1}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.props.interactionsActiveTab === 1,
                    })}
                    onClick={(e) => this.props.interactionsToggleNavs(e, 1)}
                    href="#interactions"
                    role="tab"
                  >
                    <i className="ni ni-controller mr-2" />
                    Interações
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.props.interactionsActiveTab === 2}
                    className={classnames("mb-sm-3 mb-md-0", {
                      active: this.props.interactionsActiveTab === 2,
                    })}
                    onClick={(e) => this.props.interactionsToggleNavs(e, 2)}
                    href="#docs"
                    role="tab"
                  >
                    <i className="ni ni-books mr-2" />
                    Documentos de Apoio
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={"tabs" + this.props.interactionsActiveTab}>
                <TabPane tabId="tabs1">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="12" className="text-right">
                          {this.props.canInteract ? (
                            <h4 className="mb-2 text-left">
                              Condição do teste
                              <p className="description">
                                Siga as instruções abaixo para execução do
                                teste. Registre o resultado clicando no botão{" "}
                                <span className="text-warning">
                                  [ + Nova Interação ]
                                </span>
                                .
                              </p>
                            </h4>
                          ) : (
                            <h4 className="mb-2 text-left">
                              Condição do teste
                              <p className="description">
                                Siga as instruções abaixo para execução do
                                teste.
                              </p>
                            </h4>
                          )}
                          <Input
                            id="exampleFormControlTextarea1"
                            rows="10"
                            type="textarea"
                            value={this.props.testCondition}
                            readOnly
                            onChange={(e) =>
                              this.props.stylesForm(e, "interactionComments")
                            }
                          />
                          <small className="text-right text-primary">
                            Se necessário, ajuste o tamanho do campo acima para
                            visualizar todas as instruções.
                          </small>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlTextarea1"
                          >
                            Observações
                          </label>
                          <Input
                            id="exampleFormControlTextarea1"
                            rows="3"
                            type="textarea"
                            value={this.props.testComments}
                            readOnly
                            disabled={this.props.saving}
                            onChange={(e) =>
                              this.props.stylesForm(e, "interactionComments")
                            }
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="4" className="text-left">
                          <h4 className="mb-2">Interações realizadas</h4>
                        </Col>
                        <Col xs="8" className="text-right">
                          <Button
                            color="warning"
                            size="sm"
                            onClick={(e) =>
                              this.props.openModalInteraction(e, null, "C")
                            }
                            hidden={!this.props.canInteract}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">
                              Nova Interação
                            </span>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ToolkitProvider
                            data={this.props.interactionsData}
                            keyField="_id"
                            columns={[
                              {
                                dataField: "_id",
                                text: "Id",
                                hidden: true,
                              },
                              {
                                dataField: "date",
                                text: "Data",
                                sort: true,
                              },
                              {
                                dataField: "time",
                                text: "Hora",
                              },
                              {
                                dataField: "userFullName",
                                text: "Autor",
                                sort: true,
                              },
                              {
                                dataField: "comments",
                                text: "Comentários",
                                hidden: true,
                              },
                              {
                                dataField: "status",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  for (
                                    let i = 0;
                                    i < this.props.statusList.length;
                                    i++
                                  ) {
                                    if (
                                      this.props.statusList[i].value ===
                                      row.status
                                    ) {
                                      if (row.status === "NS") {
                                        return (
                                          <span className="text-danger mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "CA") {
                                        return (
                                          <span className="text-muted mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "AP") {
                                        return (
                                          <span className="text-success mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "NC") {
                                        return (
                                          <span className="text-info mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "NK") {
                                        return (
                                          <span className="text-info mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "NF") {
                                        return (
                                          <span className="text-info mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }

                                      if (row.status === "IP") {
                                        return (
                                          <span className="text-warning mb-0">
                                            {this.props.statusList[i].text}
                                          </span>
                                        );
                                      }
                                    }
                                  }
                                },
                              },
                              {
                                dataField: "nonconformity",
                                text: "Nonconformity ID",
                                hidden: true,
                              },
                              {
                                dataField: "nonconformityName",
                                text: "Não conformidade",
                                sort: true,
                              },
                              {
                                dataField: "interactionDocs",
                                text: "Anexos",
                                formatter: (cell, row) => {
                                  if (row.hasDocs) {
                                    return (
                                      <a
                                        className="table-action"
                                        href="#docs"
                                        id={"btn-docs-" + row._id}
                                        onClick={(e) =>
                                          this.props.openModalInteraction(
                                            e,
                                            row,
                                            "R"
                                          )
                                        }
                                      >
                                        <i className="ni ni-image" />
                                      </a>
                                    );
                                  } else {
                                    return null;
                                  }
                                },
                              },
                              {
                                dataField: "actions",
                                text: "Ações",
                                isDummyField: true,
                                formatter: (cell, row, rowIndex) => {
                                  return (
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        className="btn-icon-only text-light"
                                        color=""
                                        role="button"
                                        size="sm"
                                      >
                                        <i className="fas fa-ellipsis-v" />
                                      </DropdownToggle>
                                      <DropdownMenu
                                        className="dropdown-menu-arrow"
                                        right
                                      >
                                        <DropdownItem
                                          onClick={(e) =>
                                            this.props.openModalInteraction(
                                              e,
                                              row,
                                              "R"
                                            )
                                          }
                                        >
                                          <i className="fas fa-glasses" />
                                          <span>Visualizar</span>
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  );
                                },
                              },
                            ]}
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <BootstrapTable
                                  ref={(el) => (this.componentRef = el)}
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={true}
                                  hover
                                  condensed
                                  responsive
                                  id="react-bs-table-interactions"
                                  rowEvents={interactionRowEvents}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
                <TabPane tabId="tabs2">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col xs="12" className="text-left">
                          <h4 className="mb-2">
                            Documentos de Apoio
                            <p className="description">
                              Aqui estão alguns documentos que poderão ajudar
                              você durante a execução desse teste. Clique no
                              nome do documento para visualizá-lo ou para fazer
                              o download.
                            </p>
                          </h4>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="4" className="text-left">
                          <h4 className="mb-2">
                            Lista de documentos disponíveis
                          </h4>
                        </Col>
                        <Col xs="8" className="text-right">
                          <Button
                            color="info"
                            size="sm"
                            onClick={(e) =>
                              this.props.openModalDocument(e, null, "C", "test")
                            }
                            hidden={!this.props.isConsulting}
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">
                              Novo Documento
                            </span>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ToolkitProvider
                            data={this.props.documentsData}
                            keyField="_id"
                            columns={[
                              {
                                dataField: "_id",
                                text: "Id",
                                hidden: true,
                              },
                              {
                                dataField: "document",
                                text: "Document",
                                hidden: true,
                              },
                              {
                                dataField: "kind",
                                text: "Tipo",
                                hidden: true,
                              },
                              {
                                dataField: "url",
                                text: "Url",
                                hidden: true,
                              },
                              {
                                dataField: "description",
                                text: "Descrição do documento",
                                formatter: (cell, row, rowIndex) => {
                                  let url = row.url;

                                  url = url.toLowerCase();

                                  if (row.kind === "U") {
                                    return (
                                      <a
                                        className="font-weight-bold"
                                        href={"#playYTVideo"}
                                        onClick={(e) =>
                                          this.playYouTubeVideo(e, row)
                                        }
                                      >
                                        {row.description}
                                      </a>
                                    );
                                  } else {
                                    if (
                                      row.kind === "V" &&
                                      url.includes(".mp4")
                                    ) {
                                      return (
                                        <a
                                          className="font-weight-bold"
                                          href={"#playVideo"}
                                          onClick={(e) =>
                                            this.playVideo(e, row)
                                          }
                                        >
                                          {row.description}
                                        </a>
                                      );
                                    } else {
                                      if (row.kind === "I") {
                                        return (
                                          <a
                                            className="font-weight-bold"
                                            href={"#viewImage"}
                                            onClick={(e) =>
                                              this.viewImage(e, row)
                                            }
                                          >
                                            {row.description}
                                          </a>
                                        );
                                      } else {
                                        return (
                                          <a
                                            className="font-weight-bold"
                                            href={row.url}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            {row.description}
                                          </a>
                                        );
                                      }
                                    }
                                  }
                                },
                              },
                              {
                                dataField: "name",
                                text: "Arquivo",
                              },
                              {
                                dataField: "removeDoc",
                                text: "Excluir",
                                hidden: !this.props.isConsulting,
                                formatter: (cell, row) => {
                                  return (
                                    <a
                                      className="table-action"
                                      href="#docs"
                                      id={"btn-removeDoc-" + row._id}
                                      onClick={(e) =>
                                        this.props.openModalDocument(
                                          e,
                                          row,
                                          "D",
                                          "test"
                                        )
                                      }
                                    >
                                      <i className="fas fa-trash" />
                                    </a>
                                  );
                                },
                              },
                            ]}
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <BootstrapTable
                                  ref={(el) => (this.componentRef = el)}
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={true}
                                  hover
                                  condensed
                                  responsive
                                  id="react-bs-table-interactions"
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
            >
              Fechar
            </Button>
          </div>
        </Modal>
        <PlayVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalVideoClose.bind(this)}
          modalOpened={this.state.modalPlayVideoOpened}
          source={this.state.videoSource}
        />
        <PlayYouTubeVideo
          title={this.state.modalPlayVideoTitle}
          modalClose={this.modalYouTubeVideoClose.bind(this)}
          modalOpened={this.state.modalPlayYouTubeVideoOpened}
          source={this.state.videoSource}
        />
        <ImageZoom
          title={this.state.modalViewImageTitle}
          url={this.state.viewImageUrl}
          crud={"R"}
          modalOpened={this.state.modalViewImageOpened}
          modalClose={this.modalViewImageClose.bind(this)}
          isConsulting={false}
        />
        <TestConditionReading
          parentOpen={this.props.modalOpened}
          testCondition={this.props.testCondition}
          interactionsData={this.props.interactionsData}
          cicleTitle={this.props.cicleTitle}
        ></TestConditionReading>
      </>
    );
  }
}

export default TestInteractions;
