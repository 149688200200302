/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap"

import { isTokenValid } from "services/auth"

class IndexHeader extends React.Component {
  state = {
    toPortal: "/auth/login",
    isTokenValid: false,
  }

  componentDidMount() {
    isTokenValid().then((result) => {
      if (!result) {
        this.setState({ toPortal: "/auth/login", isTokenValid: true })
      } else {
        this.setState({ toPortal: "/admin/feed", isTokenValid: false })
      }
    })
  }

  render() {
    return (
      <>
        <div className="header bg-danger pt-2 pb-7">
          <Container>
            <div className="header-body">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-5">
                    <img
                      alt="..."
                      src={require("assets/img/brand/golive_white.png")}
                    />
                    <h1 className="display-2 text-white font-weight-bold mb-0">
                      SmartFlow
                    </h1>
                    <h2 className="display-4 text-white font-weight-light">
                      Nossa metodologia de trabalho e implantação em formato
                      digital.
                    </h2>
                    <p className="text-white mt-4">
                      O SmartFlow unifica a metodologia de trabalho da Golive
                      Consultoria com praticidade de documentação de testes,
                      validações e controle de atividades relacionadas aos
                      nossos projetos.
                    </p>
                    <div className="mt-5">
                      <Button
                        // className="btn-neutral my-2"
                        className="my-2"
                        color="default"
                        to={this.state.toPortal}
                        tag={Link}
                      >
                        Acessar o SmartFlow
                      </Button>
                      {/* {this.state.isTokenValid ? (
                        <Button
                          className="my-2"
                          color="default"
                          to="/auth/register"
                          tag={Link}
                        >
                          Solicitar Acesso
                        </Button>
                      ):(null)} */}
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Row className="pt-5">
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                            <i className="ni ni-collection" />
                          </div>
                          <h5 className="h3">Plano de Protótipo</h5>
                          <p>
                            Roteiro de testes em projetos de implantação e
                            desenvolvimento específico.
                          </p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                            <i className="ni ni-books" />
                          </div>
                          <h5 className="h3">Documentos</h5>
                          <p>
                            Grave e visualize arquivos relevantes a cada
                            processo, atividade e teste.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="pt-lg-5 pt-4" md="6">
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                            <i className="ni ni-bullet-list-67" />
                          </div>
                          <h5 className="h3">Atividades</h5>
                          <p>
                            Controle de atividades (to do) de projetos com
                            interações e documentos.
                          </p>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                            <i className="ni ni-chart-pie-35" />
                          </div>
                          <h5 className="h3">Dashboards</h5>
                          <p>
                            Acompanhe a evolução dos seus testes e da sua
                            equipe, bem como o andamento da etapa de testes do
                            projeto.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </>
    )
  }
}

export default IndexHeader
