import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Bar, Doughnut } from "react-chartjs-2";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  UncontrolledCollapse,
  CardBody,
  Form,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

import { Progress } from "reactstrap";

import { chartOptions, parseOptions } from "variables/charts.js";

//Custom components
import api from "services/api";
import { isManager } from "services/auth";
import { isConsulting } from "services/auth";
import { isDemo } from "services/auth";
import Avatar from "react-avatar";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class DashList extends React.Component {
  constructor(props) {
    super(props);
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  state = {
    isLoading: false,
    buttonFiltersLabel: "Mais filtros",
    buttonShowTableModuleCaption: "Ocultar",
    buttonShowTableModuleIcon: "ni ni-bold-up",
    buttonShowTablePriorityCaption: "Ocultar",
    buttonShowTablePriorityIcon: "ni ni-bold-up",

    buttonShowTableRequesterCaption: "Ocultar",
    buttonShowTableRequesterIcon: "ni ni-bold-up",
    buttonShowTableResponsibleCaption: "Ocultar",
    buttonShowTableResponsibleIcon: "ni ni-bold-up",

    buttonShowTableTasksCaption: "Ocultar",
    buttonShowTableTasksIcon: "ni ni-bold-up",
    statusList: {
      PN: "Pendente",
      CL: "Concluído",
      NA: "Não Aprovado",
      IP: "Em Andamento",
      IV: "Em Validação",
      DC: "Descontinuado",
    },
    priorityList: {
      A: "A",
      B: "B",
      C: "C",
    },
    list: "",
    listsList: [],
    moduleList: [],
    requesterList: [],
    responsibleList: [],
    overviewChartData: [],
    overviewChartLabels: [],
    overviewChartColors: [],
    priorityChartDatasets: [],
    priorityChartLabels: [],
    moduleChartDatasets: [],
    moduleChartLabels: [],
    rowsTotalModule: [],
    rowsTotalPriority: [],
    rowsTotalRequester: [],
    rowsTotalResponsible: [],
    rowsTasks: [],
    //filters
    statusSelection: [],
    statusFilter: false,
    prioritySelection: [],
    priorityFilter: false,
    moduleSelection: [],
    moduleFilter: false,
    requesterSelection: [],
    requesterFilter: false,
    responsibleSelection: [],
    responsibleFilter: false,
    taskDateFrom: "",
    taskDateTo: "",
    taskDateFilter: false,
    conclusionDateFrom: "",
    conclusionDateTo: "",
    conclusionDateFilter: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const isDemoAccess = isDemo();

    var searchList = { deleted: "N" };

    const isManagerConsulting = isManager() && isConsulting();

    if (!isManagerConsulting) {
      const currentUserId = localStorage.getItem(
        process.env.REACT_APP_USERID_KEY
      );
      const userProfile = await api.get("/user/read/" + currentUserId);

      if (userProfile.data.mainUser) {
        searchList = { ...searchList, customer: userProfile.data.customer };
      } else {
        searchList = { ...searchList, responsible: currentUserId };
      }
    }

    const lists = await api.post("/list/fullSearch", searchList);

    var listsList = [];

    if (lists.data) {
      for (let i = 0; i < lists.data.length; i++) {
        if (
          (isDemoAccess &&
            lists.data[i].customer.shortName !== "Cliente de Demonstração") ||
          (!isDemoAccess &&
            lists.data[i].customer.shortName === "Cliente de Demonstração")
        )
          continue;

        let list = {
          id: lists.data[i]._id,
          text:
            lists.data[i].customer?.shortName +
            " | Projeto: " +
            (lists.data[i].project?.name || "") +
            " | Lista: " +
            lists.data[i].name,
        };

        if (listsList.length === 0) {
          listsList = [list];
        } else {
          listsList.push(list);
        }
      }

      this.setState({ listsList: listsList });
    }
  };

  buttonFilterClick(e) {
    var label = this.state.buttonFiltersLabel;

    if (label === "Mais filtros") {
      this.setState({ buttonFiltersLabel: "Menos filtros" });
    } else {
      this.setState({ buttonFiltersLabel: "Mais filtros" });
    }
  }

  buttonShowTableClick(e, id) {
    var caption;

    if (id === "module") {
      caption = this.state.buttonShowTableModuleCaption;

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableModuleCaption: "Ocultar",
          buttonShowTableModuleIcon: "ni ni-bold-up",
        });
      } else {
        this.setState({
          buttonShowTableModuleCaption: "Exibir",
          buttonShowTableModuleIcon: "ni ni-bold-down",
        });
      }
    }

    if (id === "priority") {
      caption = this.state.buttonShowTablePriorityCaption;

      if (caption === "Exibir") {
        this.setState({
          buttonShowTablePriorityCaption: "Ocultar",
          buttonShowTablePriorityIcon: "ni ni-bold-up",
        });
      } else {
        this.setState({
          buttonShowTablePriorityCaption: "Exibir",
          buttonShowTablePriorityIcon: "ni ni-bold-down",
        });
      }
    }

    if (id === "requester") {
      caption = this.state.buttonShowTableRequesterCaption;

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableRequesterCaption: "Ocultar",
          buttonShowTableRequesterIcon: "ni ni-bold-up",
        });
      } else {
        this.setState({
          buttonShowTableRequesterCaption: "Exibir",
          buttonShowTableRequesterIcon: "ni ni-bold-down",
        });
      }
    }

    if (id === "responsible") {
      caption = this.state.buttonShowTableResponsibleCaption;

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableResponsibleCaption: "Ocultar",
          buttonShowTableResponsibleIcon: "ni ni-bold-up",
        });
      } else {
        this.setState({
          buttonShowTableResponsibleCaption: "Exibir",
          buttonShowTableResponsibleIcon: "ni ni-bold-down",
        });
      }
    }

    if (id === "tasks") {
      caption = this.state.buttonShowTableTasksCaption;

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableTasksCaption: "Ocultar",
          buttonShowTableTaskIcon: "ni ni-bold-up",
        });
      } else {
        this.setState({
          buttonShowTableTasksCaption: "Exibir",
          buttonShowTableTaskIcon: "ni ni-bold-down",
        });
      }
    }
  }

  getRows = (rows) => {
    return rows.map((prop, key) => {
      return (
        <tr key={key}>
          <td className="table-user">
            {prop.image ? (
              <img
                alt="..."
                className="avatar rounded-circle mr-3"
                src={prop.image.url}
              />
            ) : (
              <Avatar
                className="avatar rounded-circle mr-3"
                name={prop.name}
                maxInitials={2}
                round={true}
                size={48}
                title={" "}
                color={"#172b4d"}
                fgColor={"#11cdef"}
              />
            )}
            <b>{prop.name}</b>
          </td>
          <td>
            <span className="text-muted">{prop.total}</span>
          </td>
          <td>
            <span className="text-danger mb-0">{prop.totalPN}</span>
          </td>
          <td>
            <span className="text-success mb-0">{prop.totalCL}</span>
          </td>
          <td>
            <span className="text-warning mb-0">{prop.totalNA}</span>
          </td>
          <td>
            <span className="text-info mb-0">{prop.totalIP}</span>
          </td>
          <td>
            <span className="text-primary mb-0">{prop.totalIV}</span>
          </td>
          <td>
            <span className="text-primary mb-0">{prop.totalDC}</span>
          </td>
        </tr>
      );
    });
  };

  selectList = async (e) => {
    let list = e.target.value;

    if (!list || list === null || list === "") return;

    this.unSelectAll();

    this.setState({
      list: list,
      isLoading: true,
    });

    const dashboardList = await api.post("/task/dashboardList", {
      list: list,
      status: [],
      priorities: [],
      modules: [],
      requesters: [],
      responsibles: [],
      taskDateFrom: "",
      taskDateTo: "",
      conclusionDateFrom: "",
      conclusionDateTo: "",
    });

    if (dashboardList) {
      this.setState({
        rowsTasks: dashboardList.data.tasksList,
        overviewChartData: dashboardList.data.overviewChartData,
        overviewChartLabels: dashboardList.data.overviewChartLabels,
        overviewChartColors: dashboardList.data.overviewChartColors,
        priorityChartDatasets: dashboardList.data.priorityChartDatasets,
        priorityChartLabels: dashboardList.data.priorityChartLabels,
        moduleChartDatasets: dashboardList.data.moduleChartDatasets,
        moduleChartLabels: dashboardList.data.moduleChartLabels,
        rowsTotalModule: dashboardList.data.rowsTotalModule,
        rowsTotalPriority: dashboardList.data.rowsTotalPriority,
        rowsTotalRequester: dashboardList.data.rowsTotalRequester,
        rowsTotalResponsible: dashboardList.data.rowsTotalResponsible,
        moduleList: dashboardList.data.moduleList,
        requesterList: dashboardList.data.requesterList,
        responsibleList: dashboardList.data.responsibleList,
        isLoading: false,
      });
    }
  };

  getFilterList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.value} key={key}>
          {prop.text}
        </option>
      );
    });
  };

  filter = async (e, filterName) => {
    let state = this.state;
    let list = state.list;

    if (!list || list === null || list === "") return;

    let selection = [];
    let options = null;

    if (e && e !== null) {
      if (
        filterName === "taskDateFrom" ||
        filterName === "taskDateTo" ||
        filterName === "conclusionDateFrom" ||
        filterName === "conclusionDateTo"
      ) {
        state[filterName] = e.target.value;
        state.taskDateFilter =
          state.taskDateFrom !== "" && state.taskDateTo !== "";
        state.conclusionDateFilter =
          state.conclusionDateFrom !== "" && state.conclusionDateTo !== "";

        this.setState({
          [filterName]: e.target.value,
          taskDateFilter: state.taskDateFilter,
          conclusionDateFilter: state.conclusionDateFilter,
        });
      } else {
        if (e.target.options) {
          options = e.target.options;

          for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
              selection.push(options[i].value);
            }
          }

          this.setState({
            isLoading: true,
            [filterName + "Selection"]: selection,
            [filterName + "Filter"]: selection.length > 0,
          });

          state = {
            ...state,
            [filterName + "Selection"]: selection,
            [filterName + "Filter"]: selection.length > 0,
          };
        }
      }
    }

    let req = {
      list: state.list,
      status: state.statusSelection,
      priorities: state.prioritySelection,
      modules: state.moduleSelection,
      requesters: state.requesterSelection,
      responsibles: state.responsibleSelection,
      taskDateFrom: "",
      taskDateTo: "",
      conclusionDateFrom: "",
      conclusionDateTo: "",
    };

    if (state.taskDateFilter) {
      req.taskDateFrom = state.taskDateFrom;
      req.taskDateTo = state.taskDateTo;
    }

    if (state.conclusionDateFilter) {
      req.conclusionDateFrom = state.conclusionDateFrom;
      req.conclusionDateTo = state.conclusionDateTo;
    }

    const dashboardList = await api.post("/task/dashboardList", req);

    if (dashboardList) {
      this.setState({
        rowsTasks: dashboardList.data.tasksList,
        overviewChartData: dashboardList.data.overviewChartData,
        overviewChartLabels: dashboardList.data.overviewChartLabels,
        overviewChartColors: dashboardList.data.overviewChartColors,
        priorityChartDatasets: dashboardList.data.priorityChartDatasets,
        priorityChartLabels: dashboardList.data.priorityChartLabels,
        moduleChartDatasets: dashboardList.data.moduleChartDatasets,
        moduleChartLabels: dashboardList.data.moduleChartLabels,
        rowsTotalModule: dashboardList.data.rowsTotalModule,
        rowsTotalPriority: dashboardList.data.rowsTotalPriority,
        rowsTotalRequester: dashboardList.data.rowsTotalRequester,
        rowsTotalResponsible: dashboardList.data.rowsTotalResponsible,
        isLoading: false,
      });
    }
  };

  removeFilters = async (e) => {
    this.unSelectAll();

    this.setState({
      isLoading: true,
      taskDateFrom: "",
      taskDateTo: "",
      taskDateFilter: false,
      conclusionDateFrom: "",
      conclusionDateTo: "",
      conclusionDateFilter: false,
    });

    let list = this.state.list;

    if (!list || list === null || list === "") return;

    const dashboardList = await api.post("/task/dashboardList", {
      list: this.state.list,
      priorities: [],
      status: [],
      modules: [],
      requesters: [],
      responsibles: [],
      taskDateFrom: "",
      taskDateTo: "",
      conclusionDateFrom: "",
      conclusionDateTo: "",
    });

    if (dashboardList) {
      this.setState({
        rowsTasks: dashboardList.data.tasksList,
        overviewChartData: dashboardList.data.overviewChartData,
        overviewChartLabels: dashboardList.data.overviewChartLabels,
        overviewChartColors: dashboardList.data.overviewChartColors,
        priorityChartDatasets: dashboardList.data.priorityChartDatasets,
        priorityChartLabels: dashboardList.data.priorityChartLabels,
        moduleChartDatasets: dashboardList.data.moduleChartDatasets,
        moduleChartLabels: dashboardList.data.moduleChartLabels,
        rowsTotalModule: dashboardList.data.rowsTotalModule,
        rowsTotalPriority: dashboardList.data.rowsTotalPriority,
        rowsTotalRequester: dashboardList.data.rowsTotalRequester,
        rowsTotalResponsible: dashboardList.data.rowsTotalResponsible,
        isLoading: false,
      });
    }
  };

  unSelectAll() {
    this.setState({
      statusSelection: [],
      statusFilter: false,
      prioritySelection: [],
      priorityFilter: false,
      moduleSelection: [],
      moduleFilter: false,
      requesterSelection: [],
      requesterFilter: false,
      responsibleSelection: [],
      responsibleFilter: false,
    });

    var select = document.getElementById("selectStatus");

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false;
      }
    }

    select = document.getElementById("selectPriority");

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false;
      }
    }

    select = document.getElementById("selectModule");

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false;
      }
    }

    select = document.getElementById("selectRequester");

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false;
      }
    }

    select = document.getElementById("selectResponsible");

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false;
      }
    }
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Projeto"
          items={[
            { level: "parent", name: "Dashboards" },
            { level: "nav", name: "Análise de Atividades" },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h5 className="h3 mb-0">Filtros</h5>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button
                        color="secondary"
                        size="sm"
                        id="buttonToggler"
                        hidden={this.state.list === "" || this.state.isLoading}
                        onClick={(e) => this.buttonFilterClick(e)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-filter" />
                        </span>
                        <span className="btn-inner--text">
                          {this.state.buttonFiltersLabel}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-12" md="12">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Lista
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.list}
                          disabled={this.state.isLoading}
                          onSelect={(e) => this.selectList(e)}
                          options={{
                            placeholder: "Selecione a lista",
                            language: {
                              noResults: function () {
                                return "Nenhuma lista encontrada.";
                              },
                            },
                          }}
                          data={this.state.listsList}
                        />
                      </Col>
                    </div>
                    <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
                      <div className="form-row">
                        <Col className="mb-2" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Status
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectStatus"
                            onChange={(e) => this.filter(e, "status")}
                          >
                            <option value="CL">Concluído</option>
                            <option value="IP">Em Andamento</option>
                            <option value="IV">Em Validação</option>
                            <option value="NA">Não Aprovado</option>
                            <option value="PN">Pendente</option>
                            <option value="DC">Descontinuado</option>
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-2" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Prioridade
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectPriority"
                            onChange={(e) => this.filter(e, "priority")}
                          >
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-2" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Módulo
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectModule"
                            onChange={(e) => this.filter(e, "module")}
                          >
                            {this.getFilterList(this.state.moduleList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Solicitante
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectRequester"
                            onChange={(e) => this.filter(e, "requester")}
                          >
                            {this.getFilterList(this.state.requesterList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Responsável
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectResponsible"
                            onChange={(e) => this.filter(e, "responsible")}
                          >
                            {this.getFilterList(this.state.responsibleList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="taskDateFrom"
                          >
                            Data Inclusão De
                          </label>
                          <Input
                            id="taskDateFrom"
                            type="date"
                            value={this.state.taskDateFrom}
                            onChange={(e) => this.filter(e, "taskDateFrom")}
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="taskDateTo"
                          >
                            Data Inclusão Até
                          </label>
                          <Input
                            id="taskDateTo"
                            type="date"
                            value={this.state.taskDateTo}
                            onChange={(e) => this.filter(e, "taskDateTo")}
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="conclusionDateFrom"
                          >
                            Data Conclusão De
                          </label>
                          <Input
                            id="conclusionDateFrom"
                            type="date"
                            value={this.state.conclusionDateFrom}
                            onChange={(e) =>
                              this.filter(e, "conclusionDateFrom")
                            }
                          />
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="conclusionDateTo"
                          >
                            Data Conclusão Até
                          </label>
                          <Input
                            id="conclusionDateTo"
                            type="date"
                            value={this.state.conclusionDateTo}
                            onChange={(e) => this.filter(e, "conclusionDateTo")}
                          />
                        </Col>
                      </div>
                    </UncontrolledCollapse>
                  </Form>
                </CardBody>
                <CardBody hidden={!this.state.isLoading}>
                  <div className="text-center text-muted my-4">
                    <Progress animated color="danger" value="100" />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row
            hidden={
              this.state.list === "" ||
              this.state.isLoading ||
              (!this.state.statusFilter &&
                !this.state.priorityFilter &&
                !this.state.moduleFilter &&
                !this.state.requesterFilter &&
                !this.state.responsibleFilter &&
                !this.state.taskDateFilter &&
                !this.state.conclusionDateFilter)
            }
          >
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="1" className="text-left">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters1"
                        onClick={(e) => this.removeFilters(e)}
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters1"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                    <Col xs="10" className="text-center">
                      <small className="text-danger mb-0">
                        Atenção! Os dados abaixo estão filtrados.
                      </small>
                    </Col>
                    <Col xs="1" className="text-right">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters2"
                        onClick={(e) => this.removeFilters(e)}
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters2"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row hidden={this.state.list === "" || this.state.isLoading}>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Status Lista de Atividades</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={{
                        labels: this.state.overviewChartLabels,
                        datasets: [
                          {
                            label: "Status / Lista",
                            data: this.state.overviewChartData,
                            backgroundColor: this.state.overviewChartColors,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        cutoutPercentage: 0,
                        legend: {
                          position: "bottom",
                          display: true,
                        },
                        animation: {
                          animateScale: true,
                          animateRotate: true,
                        },
                      }}
                      className="chart-canvas"
                      id="chart-pie"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Status</h6>
                  <h5 className="h3 mb-0">Por Prioridade</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={{
                        datasets: this.state.priorityChartDatasets,
                        labels: this.state.priorityChartLabels,
                      }}
                      options={{
                        tooltips: {
                          mode: "index",
                          intersect: false,
                        },
                        responsive: true,
                        scales: {
                          xAxes: [
                            {
                              stacked: true,
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      className="chart-canvas"
                      id="chart-bar-stacked"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              xl="12"
              hidden={this.state.list === "" || this.state.isLoading}
            >
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Status</h6>
                  <h5 className="h3 mb-0">Por Módulo</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={{
                        datasets: this.state.moduleChartDatasets,
                        labels: this.state.moduleChartLabels,
                      }}
                      options={{
                        tooltips: {
                          mode: "index",
                          intersect: false,
                        },
                        responsive: true,
                        scales: {
                          xAxes: [
                            {
                              stacked: true,
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      className="chart-canvas"
                      id="chart-bar-stacked"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card hidden={this.state.list === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Prioridade</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalPriority"
                    onClick={(e) => this.buttonShowTableClick(e, "priority")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTablePriorityIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTablePriorityCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalPriority"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Prioridade</th>
                    <th>Total</th>
                    <th>Pendentes</th>
                    <th>Concluídos</th>
                    <th>Não Aprovados</th>
                    <th>Em Andamento</th>
                    <th>Em Validação</th>
                    <th>Descontinuado</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalPriority)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.list === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Módulo</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalModule"
                    onClick={(e) => this.buttonShowTableClick(e, "module")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableModuleIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableModuleCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalModule"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Módulo</th>
                    <th>Total</th>
                    <th>Pendentes</th>
                    <th>Concluídos</th>
                    <th>Não Aprovados</th>
                    <th>Em Andamento</th>
                    <th>Em Validação</th>
                    <th>Descontinuado</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalModule)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.list === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Solicitante</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalRequester"
                    onClick={(e) => this.buttonShowTableClick(e, "requester")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableRequesterIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableRequesterCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalRequester"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Solicitante</th>
                    <th>Total</th>
                    <th>Pendentes</th>
                    <th>Concluídos</th>
                    <th>Não Aprovados</th>
                    <th>Em Andamento</th>
                    <th>Em Validação</th>
                    <th>Descontinuado</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalRequester)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.list === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Responsável</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalResponsible"
                    onClick={(e) => this.buttonShowTableClick(e, "responsible")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i
                        className={this.state.buttonShowTableResponsibleIcon}
                      />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableResponsibleCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalResponsible"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Responsável</th>
                    <th>Total</th>
                    <th>Pendentes</th>
                    <th>Concluídos</th>
                    <th>Não Aprovados</th>
                    <th>Em Andamento</th>
                    <th>Em Validação</th>
                    <th>Descontinuado</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalResponsible)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.list === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Lista de Atividades</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTasks"
                    onClick={(e) => this.buttonShowTableClick(e, "tasks")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableTasksIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableTasksCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse toggler="#togglerTasks" defaultOpen="true">
              <ToolkitProvider
                data={this.state.rowsTasks}
                keyField="id"
                columns={[
                  {
                    dataField: "id",
                    text: "id",
                    hidden: true,
                  },
                  {
                    dataField: "taskName",
                    text: "Atividade",
                    sort: true,
                  },
                  {
                    dataField: "requesterName",
                    text: "Solicitante",
                    sort: true,
                  },
                  {
                    dataField: "responsibleName",
                    text: "Responsável",
                    sort: true,
                  },
                  {
                    dataField: "module",
                    text: "Módulo",
                    sort: true,
                  },
                  {
                    dataField: "priority",
                    text: "Prioridade",
                    sort: true,
                  },
                  {
                    dataField: "dueDate",
                    text: "Prazo",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    formatter: (cell, row) => {
                      return row.status === "PN" ? (
                        <span className="text-danger mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "CL" ? (
                        <span className="text-success mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "NA" ? (
                        <span className="text-warning mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "IP" ? (
                        <span className="text-info mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "DC" ? (
                        <span className="text-info mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : (
                        <span className="text-primary mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      );
                    },
                    sort: true,
                  },
                  {
                    dataField: "percentage",
                    text: "Percentual",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-left"
                          >
                            <label>
                              Pesquisar:
                              <SearchBar
                                className="form-control-sm"
                                placeholder="Pesquisar por..."
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={(el) => (this.componentRef = el)}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      hover
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </UncontrolledCollapse>
          </Card>
        </Container>
      </>
    );
  }
}

export default DashList;
