import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
  Table,
} from "reactstrap"

class Customer extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="xl"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autocomplete="off"
                    >
                      <div className="form-row">
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            CNPJ
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="CNPJ do cliente"
                            type="text"
                            value={this.props.cnpj}
                            readOnly={this.props.crud === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.newCnpjState === "valid"
                            }
                            invalid={
                              this.props.customStyles.newCnpjState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "newCnpj")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o CNPJ do cliente.
                          </div>
                        </Col>
                        <Col className="mb-3" md="5">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Razão Social
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Razão social do cliente"
                            type="text"
                            value={this.props.fullName}
                            readOnly={this.props.crud === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.newFullNameState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.newFullNameState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "newFullName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a razão social do cliente.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Nome Fantasia
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Nome fantasia do cliente"
                            type="text"
                            value={this.props.shortName}
                            readOnly={this.props.crud === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.newShortNameState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.newShortNameState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "newShortName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o nome fantasia do cliente.
                          </div>
                        </Col>
                        {this.props.customerProjects.length > 0 ? (
                          <Col className="mb-3" md="12">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Ativo</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.customerProjects.map(
                                  (project, index) => {
                                    return (
                                      <tr>
                                        <td>{project.name}</td>
                                        <td>
                                          {" "}
                                          <label className="custom-toggle mr-1">
                                            <input
                                              checked={
                                                project.smartflow_sidebar_active
                                              }
                                              type="checkbox"
                                              value={
                                                project.smartflow_sidebar_active
                                              }
                                              onChange={(e) =>
                                                this.props.changeUserSidebarStatus(
                                                  project._id,
                                                  !project.smartflow_sidebar_active,
                                                  index
                                                )
                                              }
                                            />
                                            <span
                                              className="custom-toggle-slider rounded-circle"
                                              data-label-off="Não"
                                              data-label-on="Sim"
                                            />
                                          </label>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Customer
