import React from "react";
// reactstrap components
import Moment from "moment";

class TaskInteractionMedia extends React.Component {

    componentDidMount(){
    
        Moment.locale( "pt-br" )
    
    }

    render() {

        return (
            
            <>
                <a
                    className="like active"
                    href={this.props.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="ni ni-image" />
                    <small className="text-muted">{" " + this.props.name}</small>
                </a>
            </>
        );
    }
    
}

export default TaskInteractionMedia;