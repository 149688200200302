

import React from 'react';
import 'video-react/dist/video-react.css'; // import css
import {
    Modal,
} from "reactstrap";

import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton
} from 'video-react';

class PlayVideo extends React.Component {

    render() {
      return (
        <>
          <Modal
              className="modal-dialog-centered"
              size="xl"
              isOpen={this.props.modalOpened}
              autoFocus={false}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.modalClose(e)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
                <Player
                    ref={player => {this.player = player;}}
                    autoPlay
                >
                    <source src={this.props.source} />
                        <ControlBar>
                        <ReplayControl seconds={10} order={1.1} />
                        <ForwardControl seconds={30} order={1.2} />
                        <CurrentTimeDisplay order={4.1} />
                        <TimeDivider order={4.2} />
                        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                        <VolumeMenuButton disabled />
                    </ControlBar>
                </Player>
            </div>
          </Modal>
        </>
      );
    }
  }
  
  export default PlayVideo;