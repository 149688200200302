import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"

//Custom components
import GenericSearch from "components/Modals/GenericSearch.js"
import NewMultiplePlans from "components/Modals/NewMultiplePlans.js"
import Loading from "components/Modals/Loading.js"
import api from "services/api"

class NewProject extends React.Component {
  state = {
    lists: [],
    parentId: "",
    code: "",
    name: "",
    customer: "",
    customerShortName: "",
    responsible: "",
    projectModel: "",
    projectModelName: "",
    responsibleList: [],
    saving: false,
    alert: null,

    //Modal fields validations
    customStyles: {
      codeState: null,
      nameState: null,
      customerShortName: null,
      responsibleState: null,
    },

    //Modal projects search variables
    projectSearchOpened: false,
    projectSearchTitle: "Modelos de Projeto",
    projectSearchData: [],
    projectSearchColumns: [
      {
        dataField: "_id",
        text: "Id",
        hidden: true,
      },
      {
        dataField: "code",
        text: "Código",
        sort: true,
      },
      {
        dataField: "name",
        text: "Nome",
        sort: true,
      },
      {
        dataField: "customerShortName",
        text: "Cliente",
        sort: true,
      },
      {
        dataField: "actions",
        editable: false,
        text: "Ações",
        isDummyField: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <div>
              <a
                className="table-action"
                id="tooltipSelectProject"
                href="#selecionar"
                onClick={(e) => this.projectSearchClose(e, row, "select")}
              >
                <i className="fas fa-check" />
              </a>
              <UncontrolledTooltip delay={0} target="tooltipSelectProject">
                Selecionar
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],

    //Modal plans from model list
    newPlans: [],
    newLists: [],
    newPlansOpened: false,
    newPlansTitle: "",
  }

  componentDidMount() {
    this.loadData(this.props.match.params.parentId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.parentId) {
      this.loadData(nextProps.match.params.parentId)
    }
  }

  loadData = async (paramId) => {
    this.setState({ isLoading: true })

    const parentId = paramId

    this.setState({
      parentId: parentId,
      code: "",
      name: "",
      responsible: "",
      projectModel: "",
      projectModelName: "",
    })

    const response = await api.get("/customer/read/" + parentId)

    if (response.data) {
      this.setState({
        customer: response.data._id,
        customerShortName: response.data.shortName,
      })
    }

    this.setState({
      customStyles: {
        codeState: null,
        nameState: null,
        customerShortName: null,
        responsibleState: null,
      },
    })

    this.loadLists()

    this.setState({ isLoading: false })
  }

  loadLists = async () => {
    var user = {}
    var users = []
    var responsibleList = []
    var analystList = []
    var keyUserList = []
    var managerList = []

    //Responsble
    users = await api.post("/user/search", {
      deleted: "N",
      customer: this.state.parentId,
      kind: "M",
      class: "CR",
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (responsibleList.length === 0) {
        responsibleList = [user]
      } else {
        responsibleList.push(user)
      }
    }

    //Analysts
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (analystList.length === 0) {
        analystList = [user]
      } else {
        analystList.push(user)
      }
    }

    //Key users
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      customer: this.state.parentId,
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (keyUserList.length === 0) {
        keyUserList = [user]
      } else {
        keyUserList.push(user)
      }
    }

    //Managers
    users = await api.post("/user/search", {
      deleted: "N",
      kind: "M",
      class: "CR",
      customer: this.state.parentId,
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (managerList.length === 0) {
        managerList = [user]
      } else {
        managerList.push(user)
      }
    }

    this.setState({
      responsibleList: responsibleList,
      analystList: analystList,
      keyUserList: keyUserList,
      managerList: managerList,
    })
  }

  save = async (e) => {
    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    this.setState({ saving: true })

    //Field content validations
    if (newState.code === "") {
      newState.customStyles.codeState = "invalid"
      letSave = false
    } else {
      newState.customStyles.codeState = "valid"
    }

    if (newState.name === "") {
      newState.customStyles.nameState = "invalid"
      letSave = false
    } else {
      newState.customStyles.nameState = "valid"
    }

    if (newState.responsible === "") {
      newState.customStyles.responsibleState = "invalid"
      letSave = false
    } else {
      newState.customStyles.nameState = "valid"
    }

    this.setState({
      customStyles: newState.customStyles,
    })

    if (!letSave) {
      this.setState({ saving: false })
      return
    } else {
      var responsibleFullName = ""

      for (let i = 0; i < this.state.responsibleList.length; i++) {
        if (this.state.responsibleList[i].id === this.state.responsible) {
          responsibleFullName = this.state.responsibleList[i].text
          break
        }
      }

      var newProject = {}

      newData = {
        code: this.state.code,
        name: this.state.name,
        customer: this.state.customer,
        customerShortName: this.state.customerShortName,
        responsible: this.state.responsible,
        responsibleFullName: responsibleFullName,
      }

      if (this.state.projectModel && this.state.projectModel !== "") {
        newData = {
          ...newData,
          projectModel: this.state.projectModel,
          projectModelName: this.state.projectModelName,
          newPlansModels: this.state.newPlans,
          newListsModels: this.state.lists,
          serviceLayerUrl: this.state.serviceLayerUrl,
          serviceLayerDataBase: this.state.serviceLayerDataBase,
          serviceLayerPassword: this.state.serviceLayerPassword,
          serviceLayerUser: this.state.serviceLayerUser,
        }
      }

      this.setState({ isLoading: true })

      try {
        newProject = await api.post("/smartflowproject/create", newData)
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar incluir. Tente novamente.")
        success = false
      }

      this.setState({ isLoading: false })

      if (success) {
        localStorage.setItem(
          process.env.REACT_APP_NEWREGID_KEY,
          newProject.data._id
        )
        localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "PR")
        window.location.reload()
      }
    }
  }

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid"
    } else {
      newState[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })
  }

  validNewPlans = async (e, stateName, planId) => {
    var newPlans = this.state.newPlans

    for (let i = 0; i < newPlans.length; i++) {
      if (newPlans[i].id === planId) {
        if (stateName === "copyPlan") {
          newPlans[i].copyPlan = !newPlans[i].copyPlan
        } else {
          let newState = newPlans[i].customStyles

          if (e.target.value === null || e.target.value === "") {
            newState[stateName + "State"] = "invalid"
          } else {
            newState[stateName + "State"] = "valid"
          }

          newPlans[i][stateName] = e.target.value
        }

        break
      }
    }

    this.setState({
      newPlans: newPlans,
    })
  }

  validNewLists = async (e, stateName, listId) => {
    var newLists = this.state.lists
    for (let i = 0; i < newLists.length; i++) {
      if (newLists[i].id === listId) {
        if (stateName === "copyList") {
          newLists[i].copyList = !newLists[i].copyList
        } else {
          newLists[i][stateName] = e.target.value
        }

        break
      }
    }

    this.setState({
      newLists: newLists,
    })
  }
  projectSearchOpen = async (e) => {
    var response = []

    response = await api.post("/smartflowproject/search", {
      deleted: "N",
    })

    this.setState({
      projectSearchData: response.data,
      projectSearchOpened: true,
    })
  }

  projectSearchClose = async (e, row, action) => {
    var plans = []
    var newLists = []
    var newPlans = []
    var newPlan = {}
    var newList = {}

    this.setState({ projectSearchOpened: false })

    if (action !== "abort") {
      this.setState({
        projectModel: row._id,
        projectModelName:
          "Projeto: " +
          row.code +
          " - " +
          row.name +
          " | Cliente: " +
          row.customerShortName,
        newPlansTitle: "Configurações dos Novos Planos",
      })

      plans = await api.post("/plan/search", {
        deleted: "N",
        project: row._id,
      })

      for (let i = 0; i < plans.data.length; i++) {
        newPlan = {
          id: plans.data[i]._id,
          businessArea: plans.data[i].businessArea,
          cicle: plans.data[i].cicle,
          analyst: "",
          keyUser: "",
          manager: "",
          analystFullName: "",
          keyUserFullName: "",
          managerFullName: "",
          copyPlan: true,
          customStyles: {
            analystState: null,
            keyUserState: null,
            managerState: null,
          },
        }

        if (newPlans.length === 0) {
          newPlans = [newPlan]
        } else {
          newPlans.push(newPlan)
        }
      }

      let lists = await api.post("/list/search", {
        deleted: "N",
        project: row._id,
      })

      for (let i = 0; i < lists.data.length; i++) {
        newList = {
          id: lists.data[i]._id,
          name: lists.data[i].name,
          managerCG: "",
          managerCR: "",
          copyList: true,
        }

        if (newLists.length === 0) {
          newLists = [newList]
        } else {
          newLists.push(newList)
        }
      }

      this.setState({
        newPlans: newPlans,
        lists: newLists,
        newPlansOpened: true,
        serviceLayerUrl: row.serviceLayerUrl,
        serviceLayerDataBase: row.serviceLayerDataBase,
        serviceLayerPassword: row.serviceLayerPassword,
        serviceLayerUser: row.serviceLayerUser,
      })
    }
  }

  newMultiplePlansClose(e, action) {
    var letSave = true

    if (action === "abort") {
      this.setState({
        projectModel: null,
        projectModelName: "",
        newPlansOpened: false,
        newPlansTitle: "",
      })
    } else {
      var newPlans = this.state.newPlans

      for (let i = 0; i < newPlans.length; i++) {
        if (newPlans[i].copyPlan) {
          if (newPlans[i].analyst === "") {
            newPlans[i].customStyles.analystState = "invalid"
            letSave = false
          } else {
            newPlans[i].customStyles.analystState = "valid"

            for (let j = 0; j < this.state.analystList.length; j++) {
              if (newPlans[i].analyst === this.state.analystList[j].id) {
                newPlans[i].analystFullName = this.state.analystList[j].text
                break
              }
            }
          }

          if (newPlans[i].keyUser === "") {
            newPlans[i].customStyles.keyUserState = "invalid"
            letSave = false
          } else {
            newPlans[i].customStyles.keyUserState = "valid"

            for (let j = 0; j < this.state.keyUserList.length; j++) {
              if (newPlans[i].keyUser === this.state.keyUserList[j].id) {
                newPlans[i].keyUserFullName = this.state.keyUserList[j].text
                break
              }
            }
          }

          if (newPlans[i].manager === "") {
            newPlans[i].customStyles.managerState = "invalid"
            letSave = false
          } else {
            newPlans[i].customStyles.managerState = "valid"

            for (let j = 0; j < this.state.managerList.length; j++) {
              if (newPlans[i].manager === this.state.managerList[j].id) {
                newPlans[i].managerFullName = this.state.managerList[j].text
                break
              }
            }
          }
        }
      }

      this.setState({
        newPlans: newPlans,
      })

      if (letSave) {
        this.setState({
          newPlansOpened: false,
        })
      }
    }
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Novo Projeto"
          items={[
            {
              level: "parent",
              name: this.state.customerShortName,
            },
            {
              level: "nav",
              name: "Novo Projeto",
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Novo Projeto</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="2">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Código
                        </label>
                        <Input
                          autoFocus
                          id="validationCustom01"
                          placeholder="Código"
                          type="text"
                          value={this.state.code}
                          disabled={this.state.saving}
                          valid={this.state.customStyles.codeState === "valid"}
                          invalid={
                            this.state.customStyles.codeState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "code")}
                        />
                        <div className="invalid-feedback">
                          Digite o código do projeto.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Nome
                        </label>
                        <Input
                          id="validationCustom02"
                          placeholder="Nome do projeto"
                          type="text"
                          disabled={this.state.saving}
                          value={this.state.name}
                          valid={this.state.customStyles.nameState === "valid"}
                          invalid={
                            this.state.customStyles.nameState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "name")}
                        />
                        <div className="invalid-feedback">
                          Digite o nome do projeto.
                        </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom03"
                        >
                          Cliente
                        </label>
                        <Input
                          id="validationCustom03"
                          type="text"
                          value={this.state.customerShortName}
                          readOnly
                          onChange={(e) =>
                            this.stylesForm(e, "customerShortName")
                          }
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Responsável
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.responsible}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "responsible")}
                          options={{
                            placeholder: "Usuário responsável",
                            language: {
                              noResults: function () {
                                return "Nenhum usuário encontrado."
                              },
                            },
                          }}
                          data={this.state.responsibleList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.responsibleState === "valid"
                          }
                          invalid={
                            this.state.customStyles.responsibleState ===
                            "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Selecione um responsável.
                        </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom05"
                        >
                          Modelo
                        </label>
                        <InputGroup className="mb-3">
                          <Input
                            placeholder="Clique no botão para pesquisar"
                            type="text"
                            value={this.state.projectModelName}
                            onChange={(e) =>
                              this.stylesForm(e, "projectModelName")
                            }
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              className="btn-icon btn-2"
                              color="primary"
                              outline
                              disabled={this.state.saving}
                              onClick={(e) => this.projectSearchOpen(e)}
                            >
                              <span className="btn-inner--icon">
                                <i className="ni ni-folder-17" />
                              </span>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12" className="text-right">
                        <Button
                          color="success"
                          type="button"
                          disabled={this.state.saving}
                          onClick={(e) => this.save(e)}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
          <GenericSearch
            searchOpened={this.state.projectSearchOpened}
            searchTitle={this.state.projectSearchTitle}
            data={this.state.projectSearchData}
            columns={this.state.projectSearchColumns}
            closeSearch={this.projectSearchClose.bind(this)}
          />
          <NewMultiplePlans
            modalOpened={this.state.newPlansOpened}
            title={this.state.newPlansTitle}
            data={this.state.projectSearchData}
            columns={this.state.projectSearchColumns}
            modalSave={this.newMultiplePlansClose.bind(this)}
            plans={this.state.newPlans}
            lists={this.state.lists}
            analystList={this.state.analystList}
            keyUserList={this.state.keyUserList}
            managerList={this.state.managerList}
            stylesForm={this.validNewPlans.bind(this)}
            stylesFormList={this.validNewLists.bind(this)}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default NewProject
