import React from "react"
// reactstrap components
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  UncontrolledTooltip,
  CardTitle,
  Input,
  Badge,
} from "reactstrap"

import AvatarLinkGL8 from "components/ListElements/AvatarLinkGL8"
import Moment from "moment"

class TaskCard extends React.Component {
  componentDidMount() {
    Moment.locale("pt-br")
  }

  render() {
    // const rowEvents = {
    //   onDoubleClick: (e, row, rowIndex) => this.props.openModalTestInteractions(e, row, this.props.item, 1)
    // };

    return (
      <>
        <Col xs="4" hidden={this.props.isLoading}>
          <Card>
            <CardBody cursor="pointer">
              <CardTitle className="text-uppercase text-muted mb-3" tag="h5">
                <a
                  className="like active"
                  href={"#" + this.props.id}
                  onClick={(e) =>
                    this.props.openModalTaskConsole(e, this.props, 1)
                  }
                >
                  #{this.props.code} - {this.props.name}
                </a>
              </CardTitle>
              <CardTitle className="mb-3">
                <Badge
                  color={this.props.statusIndex[this.props.status + "_Color"]}
                >
                  {this.props.statusIndex[this.props.status]}
                </Badge>
                <Badge
                  color={this.props.priorityIndex[this.props.status + "_Color"]}
                >
                  {this.props.percentage}%
                </Badge>
                <Badge color="info">
                  P/{" " + Moment(this.props.dueDate).format("D MMM YYYY")}
                </Badge>
                {/* <Badge hidden={this.props.conclusionDate && this.props.conclusionDate !== ""} color="info">P/{" " + Moment(this.props.dueDate).format('D MMM YYYY')}</Badge> */}
                {/* <Badge hidden={!this.props.conclusionDate || this.props.conclusionDate === ""} color="success">{Moment(this.props.conclusionDate).format('D MMM YYYY')}</Badge> */}
                <Badge
                  color={
                    this.props.priorityIndex[this.props.priority + "_Color"]
                  }
                >
                  Prior.{" " + this.props.priority}
                </Badge>
              </CardTitle>
              <CardTitle>
                <Input
                  className="form-control-flush"
                  // className="form-control-alternative"
                  id={"textArea" + this.props.id}
                  rows="3"
                  type="textarea"
                  value={this.props.description}
                  // readOnly
                  resize="none"
                  // onClick={(e) => e.stopPropagation()}
                />
              </CardTitle>
              <Row>
                <Col xs="4">
                  <div className="avatar-group">
                    <AvatarLinkGL8
                      id={"imgRequesterTask" + this.props.id}
                      filterId={this.props.requester}
                      fullName={this.props.requesterFullName}
                      image={this.props.requesterImage}
                      size={32} //xs = 24 | sm = 36 | -- = 48 | lg = 58 | xl = 74
                      avatarClass="avatar avatar-sm rounded-circle"
                      filterName="filterRequester"
                      onClick={this.props.avatarFilterClick.bind(this.props)}
                    />
                    <AvatarLinkGL8
                      id={"imgResponsibleTask" + this.props.id}
                      filterId={this.props.responsible}
                      fullName={this.props.responsibleFullName}
                      image={this.props.responsibleImage}
                      size={32} //xs = 24 | sm = 36 | -- = 48 | lg = 58 | xl = 74
                      avatarClass="avatar avatar-sm rounded-circle"
                      filterName="filterResponsible"
                      onClick={this.props.avatarFilterClick.bind(this.props)}
                    />
                  </div>
                </Col>
                <Col xs="8" className="text-right">
                  <Button
                    color="secondary"
                    href={"#editTask" + this.props.id}
                    size="sm"
                    id={"buttonEditTask" + this.props.id}
                    hidden={!this.props.isManagerConsulting}
                    onMouseDown={(e) =>
                      this.props.openModalTask(e, "U", this.props)
                    }
                  >
                    <i className="fas fa-edit" />
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={"buttonEditTask" + this.props.id}
                    >
                      Alterar a Atividade
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    color="secondary"
                    href={"#deleteTask" + this.props.id}
                    size="sm"
                    id={"buttonDeleteTask" + this.props.id}
                    hidden={
                      !this.props.isManagerConsulting ||
                      this.props.hasInteractions
                    }
                    onMouseDown={(e) =>
                      this.props.openModalTask(e, "D", this.props)
                    }
                  >
                    <i className="fas fa-trash" />
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={"buttonDeleteTask" + this.props.id}
                    >
                      Excluir a Atividade
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    color="secondary"
                    href={"#interactTask" + this.props.id}
                    size="sm"
                    id={"buttonInteractTask" + this.props.id}
                    onMouseDown={(e) =>
                      this.props.openModalTaskConsole(e, this.props, 1)
                    }
                  >
                    <i className="ni ni-controller" />
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={"buttonInteractTask" + this.props.id}
                    >
                      Interagir
                    </UncontrolledTooltip>
                  </Button>
                  <Button
                    color="secondary"
                    href={"#docsTask" + this.props.id}
                    size="sm"
                    id={"buttonDocsTask" + this.props.id}
                    hidden={
                      !this.props.isManagerConsulting &&
                      !this.props.isResourceConsulting &&
                      !this.props.hasDocs
                    }
                    onMouseDown={(e) =>
                      this.props.openModalTaskConsole(e, this.props, 2)
                    }
                  >
                    <i className="ni ni-books" />
                    <UncontrolledTooltip
                      delay={0}
                      placement="top"
                      target={"buttonDocsTask" + this.props.id}
                    >
                      Documentos
                    </UncontrolledTooltip>
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}

export default TaskCard
