import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactBSAlert from "react-bootstrap-sweetalert";

//Custom components
import Nonconformity from "components/Modals/Nonconformity.js";
import Loading from "components/Modals/Loading.js";
import api from "services/api";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 10,
  paginationTotalRenderer: ( from, to, size ) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas { from } a { to } de { size } ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class Nonconformities extends React.Component {

  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,
    
    //Modal variables
    modalOpened: false,
    modalTitle: "",
    id: "",
    crud: "",
    readOnly: false,
    name: "",
    description: "",

    //Modal fields validations
    customStyles: {
      nameState: null,
      descriptionState: null,
    },
    
  };

  componentDidMount(){

    this.loadData();

  }

  loadData = async () => {

    this.setState( { isLoading: true } );

    var response = await api.get( '/nonconformity/all' );

    this.setState( { data: response.data, isLoading: false } );

  }

  openModal = async (e, row, crud) => {
    
    this.setState({crud: crud});

    if( crud === "C" ){
      this.setState({modalTitle: "Nova Não conformidade"})
    } else if ( crud === "R" ) {
      this.setState({modalTitle: "Visualizando Não conformidade"})
    } else if ( crud === "U" ) {
      this.setState({modalTitle: "Alterando Não conformidade"})
    } else if ( crud === "D" ) {
      this.setState({modalTitle: "Excluindo Não conformidade"})
    }
    
    if ( crud === "C" ){
      
      this.setState( {

        crud: crud,

      } );

    } else {
      
      this.setState( {

        id: row._id,
        crud: crud,
        readOnly: crud === "R" || crud === "D",
        name: row.name,
        description: row.description

      } );

    }

    this.setState( { modalOpened: true } );

  }

  closeModal = async (e, action) => {

    this.setState( { modalOpened: false } );

    this.clearModalState();

  }

  clearModalState(){

    this.setState( {
      id: "",
      crud: "",
      readOnly: false,
      name: "",
      description: "",
      saving: false,
      //Validations
      customStyles: {
        nameState: null,
        descriptionState: null
      }
    } );

  }

  modalSave = async (e) => {

    this.setState({saving: true})

    let newState = this.state;

    var letSave = true;
    var newData = {};
    var success = true;

    if( this.state.crud === "D" ){

      var response = await api.post( "/interaction/search", {
        deleted: "N",
        nonconformity: this.state.id
      } )

      if( response.data.length > 0 ){

        this.warningAlert( "Essa não conformidade já foi usada em interações/testes." )

      }

      try{
        await api.put( '/nonconformity/update/' + this.state.id, { deleted: "Y" } );
      } catch( err ) {
          this.warningAlert('Ocorreu um erro ao tentar excluir. Tente novamente.');
          success = false;
          this.setState({saving: false})
          return
      }

      if( success ){
        this.loadData();
      }

    } else if( this.state.crud === "C" || this.state.crud === "U" ) {

      //Field content validations
      if (newState.name === "") {
        newState.customStyles.nameState = "invalid";
        letSave = false;
      } else {
        newState.customStyles.nameState = "valid";
      }
  
      if (newState.description === "") {
        newState.customStyles.descriptionState = "invalid";
        letSave = false;
      } else {
        newState.customStyles.descriptionState = "valid";
      }
  
      this.setState({
        customStyles: newState.customStyles
      });

      if(!letSave){
        this.setState({saving: false})
        return
      
      } else {

        newData = {
          name: this.state.name,
          description: this.state.description,
        }

        if( this.state.crud === "C" ){

          try{
          
           await api.post( "/nonconformity/create", newData );

          } catch( err ) {
            this.warningAlert('Ocorreu um erro ao tentar incluir. Tente novamente.');
            success = false;
            this.setState({saving: false})
            return
          }
    
          if( success ){
            this.loadData();
          }

        } else {
          
          try{
            await api.put( "/nonconformity/update/" + this.state.id, newData )
          } catch( err ) {
            this.warningAlert('Ocorreu um erro ao tentar alterar. Tente novamente.');
            success = false;
            this.setState({saving: false})
            return
          }
    
          if( success ){
            this.loadData();
          }

        }

      }

    }

    this.closeModal();

  };

  stylesForm = async (e, stateName) => {

    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState
    });

  };

  warningAlert = ( message ) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  
  render() {
    return (
      <>
        <SimpleHeader
          name="Não Conformidades"
          items={[
            {
              level: "parent",
              name: "Cadastros"
            },
            {
              level: "nav",
              name: "Não Conformidades"
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Não conformidades</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-neutral btn-round btn-icon"
                        color="default"
                        id="tooltip969372949"
                        onClick={(e) => this.openModal(e, null,"C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Cadastro</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true
                    },
                    {
                      dataField: "name",
                      text: "Título",
                      sort: true,
                    },
                    {
                      dataField: "description",
                      text: "Descrição Complementar",
                      sort: true
                    },
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => this.openModal(e, row, "U")}
                              >
                                <i className="fas fa-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModal(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModal(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={el => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
          <Nonconformity
            {...this.props}
            modalOpened={this.state.modalOpened}
            closeModal={this.closeModal.bind(this)}
            modalTitle={this.state.modalTitle}
            id={this.state.id}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            name={this.state.name}
            description={this.state.description}
            customStyles={this.state.customStyles}
            modalSave={this.modalSave.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
          />
          <Loading
            modalOpened={this.state.isLoading}
          />
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default Nonconformities;
