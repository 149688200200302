import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardBody,
  Form,
  Modal,
  Row,
  Col,
} from "reactstrap"

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 5,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class GenericSearch extends React.Component {
  render() {
    const rowEvents = {
      onDoubleClick: (e, row, rowIndex) =>
        this.props.closeSearch(e, row, rowIndex),
    }

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.searchOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.searchTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeSearch(e, null, "abort")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate>
                      <div className="form-row">
                        <Col className="mb-3" md="12">
                          <ToolkitProvider
                            data={this.props.data}
                            keyField="_id"
                            columns={this.props.columns}
                            search
                          >
                            {(props) => (
                              <div className="py-4 table-responsive">
                                <Container fluid>
                                  <Row>
                                    <Col xs={12}>
                                      <div
                                        id="datatable-basic_filter"
                                        className="dataTables_filter px-4 pb-1 float-left"
                                      >
                                        <label>
                                          Pesquisar:
                                          <SearchBar
                                            className="form-control-sm"
                                            placeholder="Pesquisar por..."
                                            {...props.searchProps}
                                          />
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                </Container>
                                <BootstrapTable
                                  ref={(el) => (this.componentRef = el)}
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  pagination={pagination}
                                  bordered={true}
                                  hover
                                  condensed
                                  responsive
                                  id="react-bs-table-search"
                                  rowEvents={rowEvents}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeSearch(e, null, "abort")}
            >
              Fechar
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default GenericSearch
