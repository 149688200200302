import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
import { uniqueId } from "lodash"
import filesize from "filesize"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"

//Custom components
import GenericSearch from "components/Modals/GenericSearch.js"
import Loading from "components/Modals/Loading.js"
import api from "services/api"

class NewPlan extends React.Component {
  state = {
    parentId: "",
    businessArea: "",
    project: "",
    projectName: "",
    customer: "",
    customerShortName: "",
    analyst: "",
    keyUser: "",
    manager: "",
    cicleModel: "",
    cicleModelName: "",
    flowchartFile: null,
    saving: false,
    alert: null,

    //Modal fields validations
    customStyles: {
      businessAreaState: null,
      projectNameState: null,
      customerShortNameState: null,
      analystState: null,
      keyUserState: null,
      managerState: null,
    },

    //Modal cicles search variables
    cicleSearchOpened: false,
    cicleSearchTitle: "Modelos de Plano",
    cicleSearchData: [],
    cicleSearchColumns: [
      {
        dataField: "_id",
        text: "Id",
        hidden: true,
      },
      {
        dataField: "customerShortName",
        text: "Cliente",
        sort: true,
      },
      {
        dataField: "projectName",
        text: "Projeto",
        sort: true,
      },
      {
        dataField: "planBusinessArea",
        text: "Área de Negócio",
        sort: true,
      },
      {
        dataField: "versionTitle",
        text: "Revisão",
        sort: true,
      },
      {
        dataField: "title",
        text: "Ciclo",
        sort: true,
      },
      {
        dataField: "actions",
        editable: false,
        text: "Ações",
        isDummyField: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <div>
              <a
                className="table-action"
                id="tooltipSelectCicle"
                href="#selecionar"
                onClick={(e) => this.cicleSearchClose(e, row, "select")}
              >
                <i className="fas fa-check" />
              </a>
              <UncontrolledTooltip delay={0} target="tooltipSelectCicle">
                Selecionar
              </UncontrolledTooltip>
            </div>
          )
        },
      },
    ],
  }

  componentDidMount() {
    this.loadData(this.props.match.params.parentId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.parentId) {
      this.loadData(nextProps.match.params.parentId)
    }
  }

  loadData = async (paramId) => {
    const parentId = paramId

    this.setState({
      parentId: parentId,
      businessArea: "",
      project: "",
      projectName: "",
      customer: "",
      customerShortName: "",
      analyst: "",
      keyUser: "",
      manager: "",
      cicleModel: "",
      cicleModelName: "",
      isLoading: true,
    })

    const response = await api.get("/smartflowproject/read/" + parentId)

    if (response.data) {
      this.setState({
        customer: response.data.customer,
        customerShortName: response.data.customerShortName,
        project: response.data._id,
        projectName: response.data.name,
      })
    }

    this.loadLists()

    this.setState({ isLoading: false })
  }

  loadLists = async () => {
    var user = {}
    var users = []
    var analystList = []
    var keyUserList = []
    var managerList = []

    //Analysts
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (analystList.length === 0) {
        analystList = [user]
      } else {
        analystList.push(user)
      }
    }

    //Key users
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      customer: this.state.customer,
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (keyUserList.length === 0) {
        keyUserList = [user]
      } else {
        keyUserList.push(user)
      }
    }

    //Managers
    users = await api.post("/user/search", {
      deleted: "N",
      kind: "M",
      class: "CR",
      customer: this.state.customer,
    })

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (managerList.length === 0) {
        managerList = [user]
      } else {
        managerList.push(user)
      }
    }

    this.setState({
      analystList: analystList,
      keyUserList: keyUserList,
      managerList: managerList,
    })
  }

  save = async (e) => {
    this.setState({ saving: true })

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    //Field content validations
    if (newState.businessArea === "") {
      newState.customStyles.businessAreaState = "invalid"
      letSave = false
    } else {
      newState.customStyles.businessAreaState = "valid"
    }

    if (newState.analyst === "") {
      newState.customStyles.analystState = "invalid"
      letSave = false
    } else {
      newState.customStyles.analystState = "valid"
    }

    if (newState.keyUser === "") {
      newState.customStyles.keyUserState = "invalid"
      letSave = false
    } else {
      newState.customStyles.keyUserState = "valid"
    }

    if (newState.manager === "") {
      newState.customStyles.managerState = "invalid"
      letSave = false
    } else {
      newState.customStyles.managerState = "valid"
    }

    this.setState({ customStyles: newState.customStyles })

    if (!letSave) {
      this.setState({ saving: false })
      return
    } else {
      var analystFullName = ""
      var keyUserFullName = ""
      var managerFullName = ""

      for (let i = 0; i < this.state.analystList.length; i++) {
        if (this.state.analyst === this.state.analystList[i].id) {
          analystFullName = this.state.analystList[i].text
          break
        }
      }

      for (let i = 0; i < this.state.keyUserList.length; i++) {
        if (this.state.keyUser === this.state.keyUserList[i].id) {
          keyUserFullName = this.state.keyUserList[i].text
          break
        }
      }

      for (let i = 0; i < this.state.managerList.length; i++) {
        if (this.state.manager === this.state.managerList[i].id) {
          managerFullName = this.state.managerList[i].text
          break
        }
      }

      newData = {
        businessArea: this.state.businessArea,
        project: this.state.project,
        projectName: this.state.projectName,
        customer: this.state.customer,
        customerShortName: this.state.customerShortName,
        analyst: this.state.analyst,
        analystFullName: analystFullName,
        keyUser: this.state.keyUser,
        keyUserFullName: keyUserFullName,
        manager: this.state.manager,
        managerFullName: managerFullName,
        versionTitle: "001",
        cicleTitle: "A",
        conclusion: 0,
        status: "P",
      }

      if (this.state.cicleModel && this.state.cicleModel !== "") {
        newData = {
          ...newData,
          cicleModel: this.state.cicleModel,
          cicleModelName: this.state.cicleModelName,
        }
      }

      var newPlan = {}

      try {
        newPlan = await api.post("/plan/create", newData)

        if (this.state.flowchartFile && this.state.flowchartFile !== null) {
          const file = this.state.flowchartFile

          const flowchartFile = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            upload: true,
            url: null,
          }

          var fileData = new FormData()

          fileData.append("file", flowchartFile.file, flowchartFile.name)

          await api
            .post("/document/create", fileData)
            .then(async (response) => {
              await api.put("/plan/update/" + newPlan.data._id, {
                flowchart: response.data._id,
              })
            })
            .catch(() => {
              this.warningAlert("Erro ao incluir o fluxograma")
            })
        }

        const newVersion = await api.post("/version/create", {
          title: "001",
          plan: newPlan.data._id,
          planBusinessArea: this.state.businessArea,
        })

        const newCicle = await api.post("/cicle/create", {
          title: "A",
          customer: this.state.customer,
          customerShortName: this.state.customerShortName,
          project: this.state.project,
          projectName: this.state.projectName,
          plan: newPlan.data._id,
          planBusinessArea: this.state.businessArea,
          version: newVersion.data._id,
          versionTitle: "001",
          status: "P",
          conclusion: 0,
        })

        await api.put("/plan/update/" + newPlan.data._id, {
          version: newVersion.data._id,
          cicle: newCicle.data._id,
        })

        //Makes a copy of cicle model
        if (this.state.cicleModel && this.state.cicleModel !== "") {
          const items = await api.post("item/search/", {
            deleted: "N",
            cicle: this.state.cicleModel,
          })

          var req = {}

          for (let i = 0; i < items.data.length; i++) {
            let newItem = await api.post("item/create", {
              plan: newPlan.data._id,
              version: newVersion.data._id,
              cicle: newCicle.data._id,
              sequence: items.data[i].sequence,
              process: items.data[i].process,
              hasDocs: items.data[i].hasDocs,
              status: "P",
              conclusion: 0,
            })

            let itemDocuments = await api.post("/itemDocument/search", {
              item: items.data[i]._id,
              deleted: "N",
            })

            for (let itd = 0; itd < itemDocuments.data.length; itd++) {
              await api.post("/itemDocument/create", {
                plan: newPlan.data._id,
                version: newVersion.data._id,
                cicle: newCicle.data._id,
                item: newItem.data._id,
                kind: itemDocuments.data[itd].kind,
                description: itemDocuments.data[itd].description,
                url: itemDocuments.data[itd].url,
                document: itemDocuments.data[itd].document,
              })
            }

            let tests = await api.post("test/search", {
              deleted: "N",
              item: items.data[i]._id,
            })

            for (let j = 0; j < tests.data.length; j++) {
              let newTest = await api.post("test/create", {
                customer: this.state.customer,
                project: this.state.project,
                plan: newPlan.data._id,
                version: newVersion.data._id,
                cicle: newCicle.data._id,
                item: newItem.data._id,
                sequence: tests.data[j].sequence,
                testCondition: tests.data[j].testCondition,
                testDescription: tests.data[j].testDescription,
                status: "NS",
                manager: this.state.manager,
                analyst: this.state.analyst,
                analystFullName: analystFullName,
                keyUser: this.state.keyUser,
                keyUserFullName: keyUserFullName,
                comments: tests.data[j].comments,
                hasDocs: tests.data[j].hasDocs,
                sqlQuery: tests.data[j].sqlQuery,
                baseNumber: tests.data[j].baseNumber,
              })

              let testDocuments = await api.post("/testDocument/search", {
                test: tests.data[j]._id,
                deleted: "N",
              })

              for (let k = 0; k < testDocuments.data.length; k++) {
                req = {
                  plan: newPlan.data._id,
                  version: newVersion.data._id,
                  cicle: newCicle.data._id,
                  item: newItem.data._id,
                  test: newTest.data._id,
                  kind: testDocuments.data[k].kind,
                  description: testDocuments.data[k].description,
                  url: testDocuments.data[k].url,
                }

                if (testDocuments.data[k].document)
                  req = { ...req, document: testDocuments.data[k].document }

                await api.post("/testDocument/create", req)
              }
            }
          }
        }
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar incluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
      }

      if (success) {
        localStorage.setItem(
          process.env.REACT_APP_NEWREGID_KEY,
          newPlan.data._id
        )
        localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "PL")
        window.location.reload()
      }
    }
  }

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid"
    } else {
      newState[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })
  }

  //Cicle search functions
  cicleSearchOpen = async (e) => {
    var response = []

    response = await api.post("/cicle/fullSearch", {
      deleted: "N",
    })

    this.setState({
      cicleSearchData: response.data,
      cicleSearchOpened: true,
    })
  }

  cicleSearchClose(e, row, action) {
    if (action !== "abort") {
      this.setState({
        cicleModel: row._id,
        cicleModelName:
          "Cliente: " +
          row.customerShortName +
          " | Projeto: " +
          row.projectName +
          " | Revisão: " +
          row.versionTitle +
          " | Ciclo: " +
          row.title,
      })
    } else {
      if (this.state.cicleModelName === "*") {
        this.setState({
          cicleModel: "",
          cicleModelName: "",
        })
      }
    }

    this.setState({ cicleSearchOpened: false })
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  onFileChange(e) {
    var file = null

    if (e.target.files.length > 0) {
      file = e.target.files[0]

      const fileName = file.name

      if (
        !fileName.includes(".xbm") &&
        !fileName.includes(".tif") &&
        !fileName.includes(".pjp") &&
        !fileName.includes(".svgz") &&
        !fileName.includes(".pjpeg") &&
        !fileName.includes(".gif") &&
        !fileName.includes(".png") &&
        !fileName.includes(".jpg") &&
        !fileName.includes(".jpeg")
      ) {
        this.warningAlert("Formato de imagem não suportado.")
        file = null
      }
    }

    this.setState({
      flowchartFile: file,
    })
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Novo Plano"
          items={[
            {
              level: "parent",
              name: this.state.customerShortName,
            },
            {
              level: "parent",
              name: this.state.projectName,
            },
            {
              level: "nav",
              name: "Novo Plano",
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Novo Plano</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Área de Negócio
                        </label>
                        <Input
                          autoFocus
                          id="validationCustom01"
                          placeholder="Área de negócio"
                          type="text"
                          disabled={this.state.saving}
                          value={this.state.businessArea}
                          valid={
                            this.state.customStyles.businessAreaState ===
                            "valid"
                          }
                          invalid={
                            this.state.customStyles.businessAreaState ===
                            "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "businessArea")}
                        />
                        <div className="invalid-feedback">
                          Digite a área de negócio.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Projeto
                        </label>
                        <Input
                          id="validationCustom02"
                          type="text"
                          value={this.state.projectName}
                          readOnly
                          onChange={(e) => this.stylesForm(e, "projectName")}
                        />
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Cliente
                        </label>
                        <Input
                          id="validationCustom02"
                          type="text"
                          value={this.state.customerShortName}
                          readOnly
                          onChange={(e) =>
                            this.stylesForm(e, "customerFullName")
                          }
                        />
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Analista
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.analyst}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "analyst")}
                          options={{
                            placeholder: "Selecione um analista",
                            language: {
                              noResults: function () {
                                return "Nenhum analista encontrado."
                              },
                            },
                          }}
                          data={this.state.analystList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.analystState === "valid"
                          }
                          invalid={
                            this.state.customStyles.analystState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Analista inválido ou não encontrado.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Usuário Chave
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.keyUser}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "keyUser")}
                          options={{
                            placeholder: "Selecione um usuário",
                            language: {
                              noResults: function () {
                                return "Nenhum usuário encontrado."
                              },
                            },
                          }}
                          data={this.state.keyUserList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.keyUserState === "valid"
                          }
                          invalid={
                            this.state.customStyles.keyUserState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Usuário inválido ou não encontrado.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Gerente Funcional
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.manager}
                          disabled={this.state.saving}
                          onSelect={(e) => this.stylesForm(e, "manager")}
                          options={{
                            placeholder: "Selecione um gerente",
                            language: {
                              noResults: function () {
                                return "Nenhum gerente encontrado."
                              },
                            },
                          }}
                          data={this.state.managerList}
                        />
                        <Input
                          hidden
                          valid={
                            this.state.customStyles.managerState === "valid"
                          }
                          invalid={
                            this.state.customStyles.managerState === "invalid"
                          }
                        />
                        <div className="invalid-feedback">
                          Gerente inválido ou não encontrado.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Modelo
                        </label>
                        <InputGroup className="mb-3">
                          <Input
                            placeholder="Clique no botão para pesquisar"
                            type="text"
                            value={this.state.cicleModelName}
                            onChange={(e) =>
                              this.stylesForm(e, "cicleModelName")
                            }
                            readOnly
                          />
                          <InputGroupAddon addonType="append">
                            <Button
                              className="btn-icon btn-2"
                              color="primary"
                              outline
                              disabled={this.state.saving}
                              onClick={(e) => this.cicleSearchOpen(e)}
                            >
                              <span className="btn-inner--icon">
                                <i className="ni ni-folder-17" />
                              </span>
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom05"
                        >
                          Fluxograma (imagem)
                        </label>
                        <div className="custom-file">
                          <input
                            className="custom-file-input"
                            id="customFileLang"
                            // lang="en"
                            type="file"
                            accept=".xbm,.tif,.pjp,.svgz,.pjpeg,.gif,.png,.jpg,.jpeg"
                            disabled={this.state.saving}
                            onChange={(e) => this.onFileChange(e)}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="customFileLang"
                          ></label>
                        </div>
                      </Col>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12" className="text-right">
                        <Button
                          color="success"
                          type="button"
                          disabled={this.state.saving}
                          onClick={(e) => this.save(e)}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
          <GenericSearch
            searchOpened={this.state.cicleSearchOpened}
            searchTitle={this.state.cicleSearchTitle}
            data={this.state.cicleSearchData}
            columns={this.state.cicleSearchColumns}
            closeSearch={this.cicleSearchClose.bind(this)}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default NewPlan
