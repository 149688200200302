import React from "react";
import { take, uniqueId } from "lodash";
import filesize from "filesize";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
  UncontrolledTooltip,
  Progress,
  FormGroup,
  Input,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

// default components
import Moment from "moment";

//Custom components
import api from "services/api";
import { userFullName } from "services/auth";
import { isConsulting } from "services/auth";
import { isManager } from "services/auth";

import List from "components/Modals/List.js";
import Task from "components/Modals/Task.js";
import TaskRow from "components/TaskElements/TaskRow";
import AvatarLinkGL8 from "components/ListElements/AvatarLinkGL8";
import CardFooter from "reactstrap/lib/CardFooter";
import TaskConsole from "components/Modals/TaskConsole";
import Document from "components/Modals/Document.js";
import Loading from "components/Modals/Loading.js";

class ListConsole extends React.Component {
  state = {
    changeAllTaskDueDate: null,

    isLoading: false,
    // loadingData: true,
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    isResourceCustomer: false,
    currentUserFullName: "",

    //List data
    id: "",
    customer: "",
    customerShortName: "",
    project: "",
    projectName: "",
    name: "",
    managerCG: "",
    managerCGFullName: "",
    managerCR: "",
    managerCRFullName: "",
    createdAt: "",
    lastInteraction: "",
    involvedUsers: [],
    tasksData: [],
    taskRows: [],
    buttonFiltersLabel: "Exibir filtros",
    alert: null,
    managerCGList: [],
    managerCRList: [],

    //Task data
    readOnly: false,
    saving: false,
    selectedTask: null,
    requesterList: [],
    responsibleList: [],
    plansList: [],
    statusList: [
      {
        id: "PN",
        text: "Pendente",
        selected: true,
      },
      {
        id: "CL",
        text: "Concluído",
        selected: false,
      },
      {
        id: "DC",
        text: "Descontinuado",
        selected: false,
      },
      {
        id: "IP",
        text: "Em Andamento",
        selected: true,
      },
      {
        id: "IV",
        text: "Em Validação",
        selected: true,
      },
    ],
    priorityList: [
      {
        id: "A",
        text: "A",
      },
      {
        id: "B",
        text: "B",
      },
      {
        id: "C",
        text: "C",
      },
    ],
    statusIndex: {
      PN: "Pendente",
      PN_Color: "danger",
      CL: "Concluído",
      CL_Color: "success",
      NA: "Não Aprovado",
      NA_Color: "warning",
      IP: "Em Andamento",
      IP_Color: "default",
      IV: "Em Validação",
      IV_Color: "info",
    },
    priorityIndex: {
      A_Color: "danger",
      B_Color: "warning",
      C_Color: "primary",
    },
    crud: "",

    //Modal list variables
    listModalOpened: false,
    listModalTitle: "",
    listName: "",
    listManagerCG: "",
    listManagerCR: "",

    //Modal task variables
    modalTaskOpened: false,
    modalTaskMainTitle: "",
    percentage: 0,
    taskCode: "",
    taskName: "",
    taskRequester: "",
    taskRequesterFullName: "",
    taskRequesterImage: "",
    taskDescription: "",
    taskDate: "",
    taskDueDate: "",
    taskConclusionDate: "",
    taskStatus: "",
    taskResponsible: "",
    taskResponsibleFullName: "",
    taskResponsibleImage: null,
    taskPriority: "",
    taskModule: "",

    //Modal task console variables
    modalTaskConsoleOpened: false,
    newComment: "",
    newStatus: "",
    newCommentOpened: false,
    taskInteractions: [],
    taskDocuments: [],
    taskActiveTab: 1,

    //Modal document
    documentModalTitle: "",
    documentModalOpened: false,
    documentOfId: null,
    documentId: null,
    documentDescription: "",
    documentKind: "",
    documentURL: "",
    documentFile: null,
    taskDocumentFileTypes: "",

    //Modals validations
    customStyles: {
      listNameState: null,
      listManagerCGState: null,
      listManagerCRState: null,
      //Modal task fields
      taskCodeState: null,
      taskNameState: null,
      taskRequesterState: null,
      taskDescriptionState: null,
      taskDateState: null,
      taskDueDateState: null,
      taskConclusionDateState: null,
      taskStatusState: null,
      taskResponsibleState: null,
      taskPriorityState: null,
      taskModuleState: null,
      //Modal task console fields
      newCommentState: null,
      newStatusState: null,
      //Modal document
      documentDescriptionState: null,
      documentURLState: null,
    },

    //Filters variables
    filterText: "",
    filterStatus: ["PN", "NA", "IP", "IV"],
    filterRequesterList: [],
    filterRequester: [],
    filterResponsibleList: [],
    filterResponsible: [],
    filterPriority: [],
    filterDueDateFrom: "",
    filterDueDateTo: "",
    filterOrderBy: "S",
    filterModule: null,
  };

  STATUS_PERCENTAGES = {
    PN: 0,
    NA: 0,
    IP: 50,
    IV: 75,
    CL: 100,
    DC: 100,
  };

  componentDidMount() {
    this.setState({
      isManagerConsulting: isManager() && isConsulting(),
      isResourceConsulting: !isManager() && isConsulting(),
      isManagerCustomer: isManager() && !isConsulting(),
      isResourceCustomer: !isManager() && !isConsulting(),
      currentUserFullName: userFullName(),
    });

    Moment.locale("pt-br");

    this.loadData(
      this.props.match.params.parentId,
      this.props.match.params.listId,
      this.props.match.params.taskId
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.id) {
      this.loadData(
        nextProps.match.params.parentId,
        nextProps.match.params.taskId
      );
    }
  }

  loadData = async (paramId, feedTaskId) => {
    var id = this.props.match.params.parentId;

    if (paramId && paramId !== null) {
      id = paramId;
    }

    this.setState({ id: id, isLoading: true, changeAllTaskDueDate: null });

    const list = await api.get("/list/read/" + id);

    const plansResponse = await api.post("/plan/search", {
      project: list.data.project._id,
    });

    this.setState({
      customer: list.data.customer._id,
      customerShortName: list.data.customer.shortName,
      project: list.data.project._id,
      projectName: list.data.project.name,
      name: list.data.name,
      managerCG: list.data.managerCG._id,
      managerCGFullName: list.data.managerCG.fullName,
      managerCR: list.data.managerCR._id,
      managerCRFullName: list.data.managerCR.fullName,
      createdAt: list.data.createdAt,
      lastInteraction: list.data.lastInteraction,
      plansList: plansResponse.data,
      percentage: list.data.percentage,
    });

    let response = await api.post("/task/listConsole", {
      userId: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
      listId: id,
    });

    this.loadLists(response.data);

    this.setState({
      isLoading: false,
      tasksData: response.data,
    });

    this.fillTaskRows(null, response.data, null);
  };

  fillTaskRows(e, tasksData, filter) {
    var newState = this.state;

    if (filter === "remove") {
      newState.filterText = "";
      newState.filterStatus = [];
      newState.filterResponsible = [];
      newState.filterRequester = [];
      newState.filterPriority = [];
      newState.filterDueDateFrom = "";
      newState.filterDueDateTo = "";

      //Clean status selection
      let selectTaskStatus = document.getElementById("selectTaskStatus");

      if (selectTaskStatus && selectTaskStatus !== null) {
        for (let i = 0; i < selectTaskStatus.options.length; i++) {
          selectTaskStatus.options[i].selected = false;
        }
      }

      //Clean requester selection
      let selectTaskRequester = document.getElementById("selectTaskRequester");

      if (selectTaskRequester && selectTaskRequester !== null) {
        for (let i = 0; i < selectTaskRequester.options.length; i++) {
          selectTaskRequester.options[i].selected = false;
        }
      }

      //Clean responsible selection
      let selectTaskResponsible = document.getElementById(
        "selectTaskResponsible"
      );

      if (selectTaskResponsible && selectTaskResponsible !== null) {
        for (let i = 0; i < selectTaskResponsible.options.length; i++) {
          selectTaskResponsible.options[i].selected = false;
        }
      }

      //Clean priority selection
      let selectTaskPriority = document.getElementById("selectTaskPriority");

      if (selectTaskPriority && selectTaskPriority !== null) {
        for (let i = 0; i < selectTaskPriority.options.length; i++) {
          selectTaskPriority.options[i].selected = false;
        }
      }
    } else {
      if (e && e !== null) {
        if (
          filter === "filterText" ||
          filter === "filterOrderBy" ||
          filter === "filterModule" ||
          filter === "filterDueDateFrom" ||
          filter === "filterDueDateTo"
        ) {
          newState[filter] = e.target.value;
        } else {
          if (e.target.options) {
            let options = e.target.options;
            let filterOptions = [];

            for (let i = 0; i < options.length; i++) {
              if (options[i].selected) {
                if (filterOptions.length === 0)
                  filterOptions = [options[i].value];
                else filterOptions.push(options[i].value);
              }
            }

            newState[filter] = filterOptions;
          }
        }
      }
    }

    let filterOrderBy = newState.filterOrderBy;
    let newTasksData = tasksData;

    if (filterOrderBy === "P")
      newTasksData.sort((a, b) => (a.dueDate > b.dueDate ? 1 : -1));
    if (filterOrderBy === "S") newTasksData.sort((a, b) => a.code - b.code);
    if (filterOrderBy === "T")
      newTasksData.sort((a, b) => (a.name > b.name ? 1 : -1));

    let filteringText = newState.filterText && newState.filterText !== "";
    let filteringStatus = newState.filterStatus.length > 0;
    let filteringRequester = newState.filterRequester.length > 0;
    let filteringResponsible = newState.filterResponsible.length > 0;
    let filteringPriority = newState.filterPriority.length > 0;
    let filteringDueDateFrom =
      newState.filterDueDateFrom !== null && newState.filterDueDateFrom !== "";
    let filteringDueDateTo =
      newState.filterDueDateTo !== null && newState.filterDueDateTo !== "";
    let filteringModule =
      newState.filterModule !== null &&
      newState.filterModule !== "" &&
      newState.filterModule !== "Todos";

    let task = {};

    let taskRows = [];
    let taskGroup = {
      tasks: [],
    };

    let involvedUsers = [];

    if (newTasksData && newTasksData.length > 0) {
      for (let i = 0; i < newTasksData.length; i++) {
        if (filteringModule) {
          if (
            !(
              newTasksData[i].module?._id.toString() ===
                newState.filterModule ||
              newTasksData[i].module === newState.filterModule
            )
          )
            continue;
        }
        if (filteringText) {
          let taskName = newTasksData[i].name.toLowerCase();
          let taskDescription = newTasksData[i].description.toLowerCase();
          let searchText = newState.filterText.toLowerCase();

          if (
            !(
              taskName.includes(searchText) ||
              taskDescription.includes(searchText)
            )
          )
            continue;
        }

        if (filteringStatus) {
          let canAdd = true;

          for (let j = 0; j < newState.filterStatus.length; j++) {
            canAdd = newState.filterStatus[j] === newTasksData[i].status;

            if (canAdd) break;
          }

          if (!canAdd) continue;
        }

        if (filteringRequester) {
          let canAdd = true;

          for (let j = 0; j < newState.filterRequester.length; j++) {
            canAdd =
              newState.filterRequester[j] === newTasksData[i].requester?._id;

            if (canAdd) break;
          }

          if (!canAdd) continue;
        }

        if (filteringResponsible) {
          let canAdd = true;

          for (let j = 0; j < newState.filterResponsible.length; j++) {
            canAdd =
              newState.filterResponsible[j] ===
              newTasksData[i].responsible?._id;

            if (canAdd) break;
          }

          if (!canAdd) continue;
        }

        if (filteringPriority) {
          let canAdd = true;

          for (let j = 0; j < newState.filterPriority.length; j++) {
            canAdd = newState.filterPriority[j] === newTasksData[i].priority;

            if (canAdd) break;
          }

          if (!canAdd) continue;
        }

        if (filteringDueDateFrom) {
          let canAdd =
            newTasksData[i].dueDate.substring(0, 10) >=
            Moment(newState.filterDueDateFrom).format("YYYY-MM-DD");

          if (!canAdd) continue;
        }

        if (filteringDueDateTo) {
          let canAdd =
            newTasksData[i].dueDate.substring(0, 10) <=
            Moment(newState.filterDueDateTo).format("YYYY-MM-DD");

          if (!canAdd) continue;
        }

        let conclusionDate = null;

        if (newTasksData[i].conclusionDate)
          conclusionDate = newTasksData[i].conclusionDate.substring(0, 10);
        task = {
          id: newTasksData[i]._id,
          percentage: newTasksData[i].percentage,
          code: newTasksData[i].code,
          requester: newTasksData[i].requester?._id,
          requesterFullName: newTasksData[i].requester?.fullName,
          requesterImage: newTasksData[i].requester?.image,
          name: newTasksData[i].name,
          description: newTasksData[i].description,
          date: newTasksData[i].date.substring(0, 10),
          dueDate: newTasksData[i].dueDate.substring(0, 10),
          conclusionDate: conclusionDate,
          status: newTasksData[i].status,
          responsible: newTasksData[i].responsible?._id,
          responsibleFullName: newTasksData[i].responsible?.fullName,
          responsibleImage: newTasksData[i].responsible?.image,
          priority: newTasksData[i].priority,
          module: newTasksData[i].module,
          module_legacy: newTasksData[i].module_legacy,
          files: newTasksData[i].files,
          hasDocs: newTasksData[i].hasDocs,
          hasInteractions: newTasksData[i].hasInteractions,
        };

        if (taskGroup.tasks.length < 3) {
          if (taskGroup.tasks.length === 0) {
            taskGroup.tasks = [task];
          } else {
            taskGroup.tasks.push(task);
          }
        } else {
          if (taskRows.length === 0) {
            taskRows = [taskGroup];
          } else {
            taskRows.push(taskGroup);
          }

          taskGroup = {
            tasks: [],
          };

          if (taskGroup.tasks.length === 0) {
            taskGroup.tasks = [task];
          } else {
            taskGroup.tasks.push(task);
          }
        }

        if (i === newTasksData.length - 1) {
          if (taskRows.length === 0) {
            taskRows = [taskGroup];
          } else {
            taskRows.push(taskGroup);
          }

          taskGroup = {
            tasks: [],
          };
        }

        let addRequester = true;
        let addResponsible = true;

        for (let j = 0; j < involvedUsers.length; j++) {
          if (involvedUsers[j].id === newTasksData[i].requester?._id)
            addRequester = false;

          if (involvedUsers[j].id === newTasksData[i].responsible?._id)
            addResponsible = false;
        }

        if (addRequester) {
          involvedUsers.push({
            id: newTasksData[i].requester?._id,
            fullName: newTasksData[i].requester?.fullName,
            image: newTasksData[i].requester?.image,
            filterName: "filterRequester",
          });
        }

        if (addResponsible) {
          involvedUsers.push({
            id: newTasksData[i].responsible?._id,
            fullName: newTasksData[i].responsible?.fullName,
            image: newTasksData[i].responsible?.image,
            filterName: "filterResponsible",
          });
        }
      }

      if (taskGroup.tasks.length > 0) {
        if (taskRows.length === 0) {
          taskRows = [taskGroup];
        } else {
          taskRows.push(taskGroup);
        }
      }
    }

    newState.taskRows = taskRows;
    newState.involvedUsers = involvedUsers;

    this.setState(newState);
  }

  avatarFilterClick(e, id, filter) {
    e.stopPropagation();

    this.fillTaskRows(null, this.state.tasksData, "remove");

    this.fillTaskRows(
      {
        target: {
          options: [
            {
              value: id,
              selected: true,
            },
          ],
        },
      },
      this.state.tasksData,
      filter
    );
  }

  loadLists = async (tasksData) => {
    var user = {};
    var users = [];
    var managerCGList = [];
    var managerCRList = [];
    var requesterList = [];
    var responsibleList = [];
    var filterRequesterList = [];
    var filterResponsibleList = [];

    //Requester and Responsible list for filtering use
    for (let i = 0; i < tasksData.length; i++) {
      let canAdd = true;

      for (let j = 0; j < filterRequesterList.length; j++) {
        if (tasksData[i].requester?._id === filterRequesterList[j].value) {
          canAdd = false;
          break;
        }
      }

      if (canAdd) {
        if (filterRequesterList.length === 0) {
          filterRequesterList = [
            {
              value: tasksData[i].requester?._id,
              text: tasksData[i].requester?.fullName,
            },
          ];
        } else {
          filterRequesterList.push({
            value: tasksData[i].requester?._id,
            text: tasksData[i].requester?.fullName,
          });
        }
      }

      canAdd = true;

      for (let j = 0; j < filterResponsibleList.length; j++) {
        if (tasksData[i].responsible?._id === filterResponsibleList[j].value) {
          canAdd = false;
          break;
        }
      }

      if (canAdd) {
        if (filterResponsibleList.length === 0) {
          filterResponsibleList = [
            {
              value: tasksData[i].responsible?._id,
              text: tasksData[i].responsible?.fullName,
            },
          ];
        } else {
          filterResponsibleList.push({
            value: tasksData[i].responsible?._id,
            text: tasksData[i].responsible?.fullName,
          });
        }
      }
    }

    //Managers from consulting
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
      kind: "M",
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      if (managerCGList.length === 0) {
        managerCGList = [user];
      } else {
        managerCGList.push(user);
      }
    }

    //Managers from customer
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      customer: this.state.customer,
      kind: "M",
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      if (managerCRList.length === 0) {
        managerCRList = [user];
      } else {
        managerCRList.push(user);
      }
    }

    //Requesters
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      customer: this.state.customer,
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      //Add as requester
      if (requesterList.length === 0) {
        requesterList = [user];
      } else {
        requesterList.push(user);
      }

      //Add as responsible
      if (responsibleList.length === 0) {
        responsibleList = [user];
      } else {
        responsibleList.push(user);
      }
    }

    //Responsibles from consultancy
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      //Add as requester
      if (requesterList.length === 0) {
        requesterList = [user];
      } else {
        requesterList.push(user);
      }

      //Add as responsible
      if (responsibleList.length === 0) {
        responsibleList = [user];
      } else {
        responsibleList.push(user);
      }
    }

    this.setState({
      managerCGList: managerCGList,
      managerCRList: managerCRList,
      requesterList: requesterList,
      responsibleList: responsibleList,
      filterRequesterList: filterRequesterList,
      filterResponsibleList: filterResponsibleList,
    });
  };

  getFilterStatusList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.id} key={key} selected={prop.selected}>
          {prop.text}
        </option>
      );
    });
  };

  getFilterPriorityList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.id} key={key}>
          {prop.text}
        </option>
      );
    });
  };

  getFilterRequesterList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.value} key={key}>
          {prop.text}
        </option>
      );
    });
  };

  getFilterResponsibleList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.value} key={key}>
          {prop.text}
        </option>
      );
    });
  };

  buttonFilterClick() {
    var label = this.state.buttonFiltersLabel;

    if (label === "Exibir filtros") {
      this.setState({ buttonFiltersLabel: "Ocultar filtros" });
    } else {
      this.setState({ buttonFiltersLabel: "Exibir filtros" });
    }
  }

  openModalList(e, crud) {
    var title = "";

    if (crud === "U") {
      title = "Alterando Lista";
    } else {
      title = "Excluindo Lista";
    }

    this.setState({
      crud: crud,
      readOnly: crud === "D",
      listName: this.state.name,
      listManagerCG: this.state.managerCG,
      listManagerCR: this.state.managerCR,
      listModalTitle: title,
      listModalOpened: true,
    });
  }

  closeModalList = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true });

      var newState = this.state;
      var letSave = true;
      var success = true;

      if (this.state.crud === "D") {
        //Delete list interactions documents
        var response = await api.post("/taskInteractionDocument/search", {
          list: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response.data.length; i++) {
          await api.put(
            "/taskInteractionDocument/update/" + response.data[i]._id,
            {
              deleted: "Y",
            }
          );
        }

        //Delete list interactions
        response = await api.post("/taskInteraction/search", {
          list: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response.data.length; i++) {
          await api.put("/taskInteraction/update/" + response.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete task documents
        response = await api.post("/taskDocument/search", {
          list: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response.data.length; i++) {
          await api.put("/taskDocument/update/" + response.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete tasks
        response = await api.post("/task/search", {
          list: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response.data.length; i++) {
          await api.put("/task/update/" + response.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete list
        try {
          await api.put("/list/update/" + this.state.id, {
            deleted: "Y",
          });
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          window.location.reload();
        }
      } else {
        //Field content validations
        if (newState.listName === "") {
          newState.customStyles.listNameState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.listNameState = "valid";
        }

        if (newState.listmanagerCG === "") {
          newState.customStyles.listManagerCGState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.listManagerCGState = "valid";
        }

        if (newState.listManagerCR === "") {
          newState.customStyles.listManagerCRState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.listManagerCRState = "valid";
        }

        this.setState({ customStyles: newState.customStyles });

        if (!letSave) {
          this.setState({ saving: false });
          return;
        }

        try {
          //Update list
          await api.put("/list/update/" + this.state.id, {
            name: this.state.listName,
            managerCG: this.state.listManagerCG,
            managerCR: this.state.listManagerCR,
          });
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar alterar. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          localStorage.setItem(
            process.env.REACT_APP_NEWREGID_KEY,
            this.state.id
          );
          localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "LI");
          window.location.reload();
        }
      }
    }

    this.setState({
      crud: "",
      listModalOpened: false,
      listName: null,
      listManagerCG: null,
      listManagerCR: null,
      saving: false,
      customStyles: {
        listNameState: null,
        listManagerCGState: null,
        listManagerCRState: null,
      },
    });
  };

  closeModalTask = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true });

      var newState = this.state;
      var letSave = true;
      var success = true;

      if (newState.crud === "D") {
        //Check task interactions
        var response = await api.post("/taskInteraction/search", {
          task: newState.selectedTask,
          deleted: "N",
        });

        if (response.data.length > 0) {
          this.warningAlert(
            "A atividade possui interações e não poderá ser excluída."
          );
          this.setState({ saving: false });
          return;
        }

        //Delete task documents
        response = await api.post("/taskDocument/fullSearch", {
          task: newState.selectedTask,
          deleted: "N",
        });

        for (let i = 0; i < response.data.length; i++) {
          await api.delete("/taskDocument/delete/" + response.data[i]._id);
          await api.delete("/document/delete/" + response.data[i].document._id);
        }

        //Delete task
        await api.put("/task/update/" + newState.selectedTask, {
          deleted: "Y",
        });

        if (success) {
          localStorage.setItem(
            process.env.REACT_APP_NEWREGID_KEY,
            this.state.id
          );
          localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "LI");
          window.location.reload();
        }
      } else {
        //Field content validations
        if (
          newState.taskCode === "" ||
          parseFloat(newState.taskCode) === "" ||
          !parseFloat(newState.taskCode)
        ) {
          newState.customStyles.taskCodeState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskCodeState = "valid";
        }

        if (newState.taskRequester === "") {
          newState.customStyles.taskRequesterState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskRequesterState = "valid";
        }

        if (newState.taskName === "") {
          newState.customStyles.taskNameState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskNameState = "valid";
        }

        if (newState.taskDescription === "") {
          newState.customStyles.taskDescriptionState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskDescriptionState = "valid";
        }

        if (newState.taskDate === "" || !newState.taskDate) {
          newState.customStyles.taskDateState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskDateState = "valid";
        }

        if (newState.taskDueDate === "" || !newState.taskDueDate) {
          newState.customStyles.taskDueDateState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskDueDateState = "valid";
        }

        if (
          newState.taskStatus === "CL" &&
          (newState.taskConclusionDate === "" || !newState.taskConclusionDate)
        ) {
          newState.customStyles.taskConclusionDateState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskConclusionDateState = "valid";
        }

        if (newState.taskStatus === "" || !newState.taskStatus) {
          newState.customStyles.taskStatusState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskStatusState = "valid";
        }

        if (newState.taskResponsible === "") {
          newState.customStyles.taskResponsibleState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskResponsibleState = "valid";
        }

        if (newState.taskPriority === "") {
          newState.customStyles.taskPriorityState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskPriorityState = "valid";
        }

        if (newState.taskModule === "") {
          newState.customStyles.taskModuleState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.taskModuleState = "valid";
        }

        this.setState({ customStyles: newState.customStyles });

        if (!letSave) {
          this.setState({ saving: false });
          return;
        }

        if (newState.crud === "C") {
          try {
            //Create task
            const newTask = await api.post("/task/create", {
              customer: newState.customer,
              project: newState.project,
              list: newState.id,
              code: parseFloat(newState.taskCode),
              requester: newState.taskRequester,
              name: newState.taskName,
              description: newState.taskDescription,
              date: newState.taskDate,
              dueDate: newState.taskDueDate,
              conclusionDate: newState.taskConclusionDate,
              responsible: newState.taskResponsible,
              status: newState.taskStatus,
              priority: newState.taskPriority,
              module: newState.taskModule,
            });

            await api.post("/task/newTaskEmail", {
              task: newTask.data._id,
              list: newState.id,
            });
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar incluir. Tente novamente."
            );
            success = false;
            this.setState({ saving: false });
            return;
          }
        } else {
          try {
            //Update task
            await api.put("/task/update/" + newState.selectedTask, {
              code: parseFloat(newState.taskCode),
              requester: newState.taskRequester,
              name: newState.taskName,
              description: newState.taskDescription,
              date: newState.taskDate,
              dueDate: newState.taskDueDate,
              conclusionDate: newState.taskConclusionDate,
              responsible: newState.taskResponsible,
              status: newState.taskStatus,
              priority: newState.taskPriority,
              module: newState.taskModule,
            });
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            );
            success = false;
            this.setState({ saving: false });
            return;
          }
        }

        if (success) {
          localStorage.setItem(
            process.env.REACT_APP_NEWREGID_KEY,
            this.state.id
          );
          localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "LI");
          window.location.reload();
        }
      }
    }

    this.setState({
      modalTaskOpened: false,
      crud: "",
      readOnly: false,
      selectedTask: null,
      modalTaskMainTitle: "",
      taskCode: "",
      taskName: "",
      taskRequester: "",
      taskDescription: "",
      taskDate: "",
      taskDueDate: "",
      taskConclusionDate: "",
      taskStatus: "",
      taskResponsible: "",
      taskPriority: "",
      taskModule: "",
      customStyles: {
        taskCodeState: null,
        taskNameState: null,
        taskRequesterState: null,
        taskDescriptionState: null,
        taskDateState: null,
        taskDueDateState: null,
        taskConclusionDateState: null,
        taskStatusState: null,
        taskResponsibleState: null,
        taskPriorityState: null,
        taskModuleState: null,
      },
    });
  };

  closeModalTaskConsole(e) {
    this.setState({
      modalTaskConsoleOpened: false,
    });
  }

  openModalTaskConsole = async (e, task, tab) => {
    e.stopPropagation();

    var taskInteractions = await api.post("/taskInteraction/fullSearch", {
      deleted: "N",
      task: task.id,
    });

    if (taskInteractions.data.length === 0) {
      taskInteractions = {
        data: [
          {
            user: {
              _id: "",
              fullName: ": )",
              image: null,
            },
            comments: "Sem interações nesta atividade.",
            status: task.status,
            createdAt: Date(),
            files: [],
          },
        ],
      };
    }

    var taskConclusionDate = null;

    if (task.conclusionDate)
      taskConclusionDate = task.conclusionDate.substring(0, 10);

    //Task Documents
    const response = await api.post("/taskDocument/fullSearch", {
      task: task.id,
      deleted: "N",
    });

    var documents = [];
    var document = {};

    for (let i = 0; i < response.data.length; i++) {
      if (response.data[i].kind === "U") {
        document = {
          _id: response.data[i]._id,
          description: response.data[i].description,
          kind: response.data[i].kind,
          document: null,
          name: "URL",
          url: response.data[i].url,
        };
      } else {
        document = {
          _id: response.data[i]._id,
          description: response.data[i].document.description,
          kind: response.data[i].kind,
          document: response.data[i].document._id,
          name: response.data[i].document.name,
          url: response.data[i].document.url,
        };
      }

      if (documents.length === 0) {
        documents = [document];
      } else {
        documents.push(document);
      }
    }
    this.setState({
      readOnly: task.status === "CL",
      selectedTask: task.id,
      taskCode: task.code,
      taskName: task.name,
      taskRequester: task.requester,
      taskRequesterFullName: task.requesterFullName,
      taskRequesterImage: task.requesterImage,
      taskDescription: task.description,
      taskDate: task.date,
      taskDueDate: task.dueDate,
      taskConclusionDate: taskConclusionDate,
      taskStatus: task.status,
      taskResponsible: task.responsible,
      taskResponsibleFullName: task.responsibleFullName,
      taskResponsibleImage: task.responsibleImage,
      taskPriority: task.priority,
      taskModule: task.module ? task.module.businessArea : task.module_legacy,
      taskInteractions: taskInteractions.data,
      taskDocuments: documents,
      modalTaskConsoleOpened: true,
      taskActiveTab: tab,
      percentage: 0,
    });
  };

  openModalTask = async (e, crud, task) => {
    e.stopPropagation();

    var selectedTask = null;
    var modalTaskMainTitle = "";
    var taskCode = "";
    var taskName = "";
    var taskRequester = "";
    var taskDescription = "";
    var taskDate = "";
    var taskDueDate = "";
    var taskConclusionDate = "";
    var taskStatus = "PN";
    var taskResponsible = "";
    var taskPriority = "";
    var taskModule = "";
    var taskPercentage = 0;

    if (task?.module) {
      const planResponse = await api.get("/plan/read/" + task.module._id);
      let test = this.state.plansList.find(
        (plan) => plan._id === planResponse.data._id
      );

      if (
        !this.state.plansList.find((plan) => plan._id === planResponse.data._id)
      )
        this.setState({
          plansList: [...this.state.plansList, planResponse.data],
        });
    } else if (task?.module_legacy) {
      this.setState({
        plansList: [
          ...this.state.plansList,
          { _id: task.module_legacy, businessArea: task.module_legacy },
        ],
      });
    }
    if (task?.responsible) {
      const taskResponsibleResponse = await api.get(
        "/user/read/" + task.responsible
      );
      if (
        !this.state.responsibleList.find(
          (res) => res.id === taskResponsibleResponse.data._id
        )
      ) {
        this.setState({
          responsibleList: [
            ...this.state.responsibleList,
            {
              id: taskResponsibleResponse.data._id,
              text: taskResponsibleResponse.data.fullName,
            },
          ],
        });
      }
    }
    if (task?.requester) {
      const taskRequesterResponse = await api.get(
        "/user/read/" + task.requester
      );

      if (
        !this.state.requesterList.find(
          (req) => req.id === taskRequesterResponse.data._id
        )
      ) {
        this.setState({
          requesterList: [
            ...this.state.requesterList,
            {
              id: taskRequesterResponse.data._id,
              text: taskRequesterResponse.data.fullName,
            },
          ],
        });
      }
    }

    if (crud === "C") {
      modalTaskMainTitle = "Nova Atividade";

      const response = await api.post("/task/maxCode", {
        project: this.state.project,
        list: this.state.id,
        deleted: "N",
      });

      var newCode = "1";

      if (response.data.length > 0) {
        newCode = response.data[0].code;
        newCode++;
      }

      taskCode = newCode;

      taskDate = Moment(Date()).format("YYYY-MM-DD");
    } else {
      if (crud === "R") {
        modalTaskMainTitle = "Visualizando Atividade";
      } else {
        if (crud === "U") {
          modalTaskMainTitle = "Alterando Atividade";
        } else {
          modalTaskMainTitle = "Excluindo Atividade";
        }
      }

      selectedTask = task.id;
      taskCode = task.code;
      taskName = task.name;
      taskPercentage = task.percentage;
      taskRequester = task.requester;
      taskDescription = task.description;
      taskDate = task.date.substring(0, 10);
      taskDueDate = task.dueDate.substring(0, 10);

      if (task.conclusionDate)
        taskConclusionDate = task.conclusionDate.substring(0, 10);

      taskStatus = task.status;
      taskResponsible = task.responsible;
      taskPriority = task.priority;
      taskModule = task.module ? task.module._id : task.module_legacy;
    }
    this.setState({
      crud: crud,
      readOnly: crud === "R" || crud === "D",
      selectedTask: selectedTask,
      modalTaskMainTitle: modalTaskMainTitle,
      percentage: taskPercentage,
      taskPercentage: taskPercentage,
      taskCode: taskCode,
      taskName: taskName,
      taskRequester: taskRequester,
      taskDescription: taskDescription,
      taskDate: taskDate,
      taskDueDate: taskDueDate,
      taskConclusionDate: taskConclusionDate,
      taskStatus: taskStatus,
      taskResponsible: taskResponsible,
      taskPriority: taskPriority,
      taskModule: taskModule,
      modalTaskOpened: true,
    });
  };

  openNewComment(e) {
    this.setState({
      newCommentOpened: true,
    });
  }

  closeNewComment(e) {
    this.setState({
      newCommentOpened: false,
    });
  }

  getFormattedDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  saveNewInteraction = async (e) => {
    this.setState({ saving: true });

    var newState = this.state;
    var letSave = true;

    //Field content validations
    if (newState.newComment === "") {
      newState.customStyles.newCommentState = "invalid";
      letSave = false;
    } else {
      newState.customStyles.newCommentState = "valid";
    }

    if (newState.newStatus === "") {
      newState.customStyles.newStatusState = "invalid";
      letSave = false;
    } else {
      newState.customStyles.newStatusState = "valid";
    }

    this.setState({ customStyles: newState.customStyles });

    if (!letSave) {
      this.setState({ saving: false });
      return;
    }

    const newDateTime = this.getFormattedDateTime();

    try {
      //Create task interaction
      let newTaskInteraction = await api.post("/taskInteraction/create", {
        createdAt: newDateTime,
        customer: newState.customer,
        project: newState.project,
        list: newState.id,
        task: newState.selectedTask,
        requester: newState.taskRequester,
        responsible: newState.taskResponsible,
        percentage: newState.percentage,
        comments: newState.newComment,
        status: newState.newStatus,
        user: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
      });

      await api.post("/taskInteraction/taskUpdatedEmail", {
        ...newTaskInteraction.data,
        createdAt: newDateTime,
      });
      await api.put("/task/update/" + newState.selectedTask, {
        percentage: newState.percentage,
      });

      //Create task documents
      const newFiles = document.getElementById("newFiles");

      var uploadedFile;
      var fileData;
      var newDocs = [];

      if (newFiles && newFiles !== null) {
        if (newFiles.uploadfileslist) {
          if (newFiles.uploadfileslist.length > 0) {
            for (let i = 0; i < newFiles.uploadfileslist.length; i++) {
              uploadedFile = newFiles.uploadfileslist[i];

              if (uploadedFile.upload) {
                fileData = new FormData();

                fileData.append("file", uploadedFile.file, uploadedFile.name);

                await api
                  .post("/document/create/", fileData)
                  // eslint-disable-next-line no-loop-func
                  .then(async (response) => {
                    await api.post("/taskInteractionDocument/create", {
                      task: newState.selectedTask,
                      interaction: newTaskInteraction.data._id,
                      document: response.data._id,
                    });

                    let newInteractionFile = {
                      name: response.data.name,
                      url: response.data.url,
                    };

                    if (newDocs.length === 0) {
                      newDocs = [newInteractionFile];
                    } else {
                      newDocs.push(newInteractionFile);
                    }
                  })
                  .catch(() => {
                    this.warningAlert("Erro ao anexar arquivo");
                  });
              }
            }
          }
        }
      }

      //Update task data
      var updateData = {
        status: newState.newStatus,
        hasInteractions: true,
      };

      if (newState.newStatus === "CL") {
        updateData = {
          ...updateData,
          conclusionDate: newTaskInteraction.data.createdAt,
        };
      }

      await api.put("/task/update/" + newState.selectedTask, updateData);

      let moveOn = false;

      for (let i = 0; i < newState.taskRows.length; i++) {
        let tasks = newState.taskRows[i].tasks;

        for (let j = 0; j < tasks.length; j++) {
          if (tasks[j].id === newState.selectedTask) {
            newState.taskRows[i].tasks[j].status = newState.newStatus;

            this.setState({
              taskRows: newState.taskRows,
              taskStatus: newState.newStatus,
              taskPercentage: newState.percentage,
            });
            moveOn = true;
            break;
          }
        }

        if (moveOn) break;
      }

      //Update original data used on filtering
      for (let i = 0; i < newState.tasksData.length; i++) {
        if (newState.tasksData[i]._id === newState.selectedTask) {
          newState.tasksData[i].status = newState.newStatus;

          this.setState({
            tasksData: newState.tasksData,
          });
          break;
        }
      }

      //Update state
      const userData = await api.get(
        "/user/read/" + localStorage.getItem(process.env.REACT_APP_USERID_KEY)
      );

      newTaskInteraction.data.user = userData.data;
      newTaskInteraction.data = {
        ...newTaskInteraction.data,
        files: newDocs,
      };

      let taskInteractions = [newTaskInteraction.data];

      for (let i = 0; i < this.state.taskInteractions.length; i++) {
        if (
          this.state.taskInteractions[i].comments ===
          "Sem interações nesta atividade."
        ) {
          continue;
        }

        taskInteractions.push(this.state.taskInteractions[i]);
      }

      this.setState({
        newCommentOpened: false,
        taskInteractions: taskInteractions,
      });
    } catch (err) {
      this.warningAlert("Ocorreu um erro ao tentar incluir. Tente novamente.");
      this.setState({ saving: false });
      return;
    }

    this.setState({
      saving: false,
      newComment: "",
      newStatus: "",
      customStyles: {
        newCommentState: null,
        newStatusState: null,
      },
    });
    this.loadData();
  };

  openModalDocument(e, row, crud) {
    e.preventDefault();

    var title;

    if (crud === "C") {
      title = "Novo Documento";
    } else {
      title = "Excluindo Documento";
    }

    if (crud === "D") {
      this.setState({
        documentOfId: row._id,
        documentId: row.document,
        documentDescription: row.description,
        documentKind: row.kind,
        documentURL: row.url,
      });
    } else {
      this.setState({
        documentKind: "U",
        documentURL: "",
      });
    }

    this.setState({
      documentModalTitle: title,
      crud: crud,
      readOnly: crud === "D",
      documentModalOpened: true,
    });
  }

  documentFileChange(e) {
    var file = null;

    if (e.target.files.length > 0) {
      file = e.target.files[0];
    }

    this.setState({
      documentFile: file,
    });
  }

  closeModalDocument = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true, isLoading: true });

      var success = true;
      var letSave = true;
      var newState = this.state;
      var taskDocuments = this.state.taskDocuments;

      if (this.state.crud === "D") {
        try {
          await api.delete("/taskDocument/delete/" + this.state.documentOfId);

          if (this.state.documentKind !== "U") {
            await api.delete("/document/delete/" + this.state.documentId);
          }

          //Update grid of documents
          for (let i = 0; i < taskDocuments.length; i++) {
            if (taskDocuments[i]._id === this.state.documentOfId) {
              taskDocuments.splice(i, 1);
              break;
            }
          }
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          );
          success = false;
          this.setState({ saving: false, isLoading: false });
          return;
        }
      } else {
        if (newState.documentDescription === "") {
          newState.customStyles.documentDescriptionState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.documentDescriptionState = "valid";
        }

        if (this.state.documentKind === "U") {
          if (newState.documentURL === "") {
            newState.customStyles.documentURLState = "invalid";
            letSave = false;
          } else {
            newState.customStyles.documentURLState = "valid";
          }
        } else {
          if (!this.state.documentFile || this.state.documentFile === null) {
            this.warningAlert("Selecione um arquivo");
            letSave = false;
          }
        }

        this.setState({
          customStyles: newState.customStyles,
        });

        if (!letSave) {
          this.setState({ saving: false, isLoading: false });
          return;
        }

        let newTaskDoc = null;

        if (this.state.documentKind === "U") {
          try {
            newTaskDoc = await api.post("/taskDocument/create", {
              task: this.state.selectedTask,
              kind: this.state.documentKind,
              url: this.state.documentURL,
              description: this.state.documentDescription,
              document: null,
            });

            let newDocument = {
              _id: newTaskDoc.data._id,
              description: this.state.documentDescription,
              kind: this.state.documentKind,
              document: null,
              name: "URL",
              url: newTaskDoc.data.url,
            };

            if (taskDocuments.length === 0) {
              taskDocuments = [newDocument];
            } else {
              taskDocuments.push(newDocument);
            }
          } catch (err) {
            this.warningAlert("Erro ao criar documento");
            this.setState({ saving: false, isLoading: false });
            return;
          }
        } else {
          const file = this.state.documentFile;

          const uploadedFile = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            upload: true,
            url: null,
          };

          var fileData = new FormData();

          fileData.append("file", uploadedFile.file, uploadedFile.name);

          await api
            .post("/document/create/", fileData)
            .then(async (response) => {
              await api.put("/document/update/" + response.data._id, {
                description: this.state.documentDescription,
              });

              newTaskDoc = await api.post("/taskDocument/create", {
                task: this.state.selectedTask,
                kind: this.state.documentKind,
                document: response.data._id,
              });

              let newDocument = {
                _id: newTaskDoc.data._id,
                description: this.state.documentDescription,
                kind: this.state.documentKind,
                document: response.data._id,
                name: response.data.name,
                url: response.data.url,
              };

              if (taskDocuments.length === 0) {
                taskDocuments = [newDocument];
              } else {
                taskDocuments.push(newDocument);
              }
            })
            .catch(() => {
              this.warningAlert("Erro ao anexar arquivo");
              this.setState({ saving: false, isLoading: false });
              return;
            });
        }
      }

      if (success) {
        //Update task hasDocs
        await api.put("/task/update/" + this.state.selectedTask, {
          hasDocs: taskDocuments.length > 0,
        });
      }

      this.setState({
        taskDocuments: taskDocuments,
        documentModalOpened: false,
        crud: "",
        readOnly: true,
        documentModalTitle: "",
        documentId: "",
        documentOfId: "",
        documentDescription: "",
        saving: false,
        isLoading: false,
        customStyles: {
          documentDescriptionState: null,
        },
      });
    }

    this.setState({
      crud: "",
      documentModalTitle: "",
      readOnly: true,
      documentModalOpened: false,
    });
  };

  getInvolvedUsers = (involvedUsers) => {
    return involvedUsers.map((prop, key) => {
      return (
        <AvatarLinkGL8
          id={"listUser" + prop.id}
          filterId={prop.id}
          fullName={prop.fullName}
          image={prop.image}
          size={48} //xs = 24 | sm = 36 | -- = 48 | lg = 58 | xl = 74
          avatarClass="avatar rounded-circle"
          key={key}
          filterName={prop.filterName}
          onClick={this.avatarFilterClick.bind(this)}
        />
      );
    });
  };

  getTaskRows = (taskRows) => {
    return taskRows.map((prop, key) => {
      return (
        <TaskRow
          tasks={prop.tasks}
          key={key}
          statusIndex={this.state.statusIndex}
          priorityIndex={this.state.priorityIndex}
          isManagerConsulting={this.state.isManagerConsulting}
          isResourceConsulting={this.state.isResourceConsulting}
          openModalTask={this.openModalTask.bind(this)}
          isLoading={this.state.isLoading}
          avatarFilterClick={this.avatarFilterClick.bind(this)}
          openModalTaskConsole={this.openModalTaskConsole.bind(this)}
        />
      );
    });
  };

  taskInteractionsToggleNavs = (e, tab) => {
    e.preventDefault();
    this.setState({
      taskActiveTab: tab,
    });
  };

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }
    if (stateName === "newStatus") {
      this.setState({
        [stateName]: e.target.value,
      });

      this.setState({
        percentage: this.STATUS_PERCENTAGES[e.target.value] || 0,
      });
    } else if (stateName === "percentage") {
      let number = parseFloat(e.target.value);
      if (number < 0)
        this.setState({
          [stateName]: 0,
        });
      else if (number > 100) {
        this.setState({
          [stateName]: 100,
        });
      } else
        this.setState({
          [stateName]: e.target.value,
        });
    } else if (stateName === "taskStatus") {
      if (
        (this.state.crud === "C" || this.state.crud === "U") &&
        e.target.value !== "CL"
      ) {
        this.setState({ taskConclusionDate: "" });
      }
    } else if (stateName === "documentKind") {
      const kind = e.target.value;

      if (kind === "V") {
        this.setState({ testDocumentFileTypes: ".mp4" });
      } else {
        if (kind === "I") {
          this.setState({
            testDocumentFileTypes:
              ".xbm,.tif,.pjp,.svgz,.pjpeg,.gif,.png,.jpg,.jpeg",
          });
        } else {
          this.setState({ testDocumentFileTypes: "" });
        }
      }
    } else
      this.setState({
        [stateName]: e.target.value,
      });
  };

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  async saveGeneralTaskDueDate() {
    this.setState({ isLoading: true });
    try {
      let newtasks = await api.patch("/task/updateAll", {
        tasks: this.state.tasksData.map((task) => task._id),
        changes: { dueDate: this.state.changeAllTaskDueDate },
      });
      this.loadData();
    } catch (error) {
    } finally {
      this.setState({ isLoading: false, changeAllTaskDueDate: null });
    }
  }

  render() {
    return (
      <>
        <SimpleHeader
          name={this.state.businessArea}
          items={[
            {
              level: "parent",
              name: this.state.customerShortName,
            },
            {
              level: "parent",
              name: this.state.projectName,
            },
            {
              level: "nav",
              name: this.state.name,
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="5">
                      <h5 className="h3 mb-0">
                        Lista de Atividades: {this.state.name}
                      </h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      <Button
                        color="primary"
                        href="#editList"
                        size="sm"
                        id="buttonEditList"
                        onClick={(e) => this.openModalList(e, "U")}
                        hidden={!this.state.isManagerConsulting}
                        disabled={this.state.isLoading}
                      >
                        <i className="fas fa-edit" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonEditList"}
                        >
                          Alterar a Lista
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="danger"
                        href="#deleteList"
                        size="sm"
                        id="buttonDeleteList"
                        hidden={!this.state.isManagerConsulting}
                        onClick={(e) => this.openModalList(e, "D")}
                        disabled={this.state.isLoading}
                      >
                        <i className="fas fa-trash" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonDeleteList"}
                        >
                          Excluir a Lista
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        id="buttonFilterToggler"
                        onClick={(e) => this.buttonFilterClick(e)}
                        disabled={this.state.isLoading}
                      >
                        <i className="fas fa-filter" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonFilterToggler"}
                        >
                          {this.state.buttonFiltersLabel}
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="warning"
                        size="sm"
                        onClick={(e) => this.openModalTask(e, "C")}
                        hidden={!this.state.isManagerConsulting}
                        disabled={this.state.isLoading}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Nova Atividade</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row hidden={this.state.isLoading}>
                    <Col xs="12" className="text-left">
                      <div className="card-text">
                        <h4 className="mb-2">
                          Gestor Golive:
                          <span className="font-weight-light">
                            {" " + this.state.managerCGFullName}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row hidden={this.state.isLoading}>
                    <Col xs="6" className="text-left">
                      <div className="card-text">
                        <h4 className="mb-2">
                          Gestor Cliente:
                          <span className="font-weight-light">
                            {" " + this.state.managerCRFullName}
                          </span>
                        </h4>
                      </div>
                      <div className="card-text">
                        <Badge color="info">
                          {"Criado em: " +
                            Moment(this.state.createdAt).format("D MMM YYYY")}
                        </Badge>
                        {this.state.lastInteraction ? (
                          <Badge color="info">
                            {"Última interação: " +
                              Moment(this.state.lastInteraction).format(
                                "D MMM YYYY"
                              )}
                          </Badge>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs="6" className="text-right">
                      {this.getInvolvedUsers(this.state.involvedUsers)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div
                        className="text-center text-muted my-4"
                        hidden={!this.state.isLoading}
                      >
                        <Progress animated color="danger" value="100" />
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "2vh" }}>
                    <Col xs="12">
                      <h5 className="h3 mb-0">Ações gerais</h5>
                    </Col>
                    <Col xs={3}>
                      <label className="form-control-label">Prazo geral</label>
                      <Input
                        autoFocus
                        id="changeAllTaskDueDate"
                        type="date"
                        value={this.props.changeAllTaskDueDate}
                        onChange={(e) => {
                          this.setState({
                            changeAllTaskDueDate: e.target.value,
                          });
                        }}
                      />
                    </Col>
                    <Col xs={3} style={{ paddingTop: "6vh" }}>
                      <Button
                        color="info"
                        size="sm"
                        id="btn-changeAllTaskDueDate"
                        onClick={(e) => this.saveGeneralTaskDueDate()}
                        disabled={!this.state.changeAllTaskDueDate}
                      >
                        {" "}
                        Salvar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter
                  hidden={
                    (this.state.filterText === null ||
                      this.state.filterText === "") &&
                    this.state.filterStatus.length === 0 &&
                    this.state.filterRequester.length === 0 &&
                    this.state.filterResponsible.length === 0 &&
                    this.state.filterPriority.length === 0 &&
                    this.state.filterDueDateFrom === "" &&
                    this.state.filterDueDateTo === ""
                  }
                >
                  <Row>
                    <Col xs="1" className="text-left">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters1"
                        onClick={(e) =>
                          this.fillTaskRows(
                            null,
                            this.state.tasksData,
                            "remove"
                          )
                        }
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters1"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                    <Col xs="10" className="text-center">
                      <small className="text-danger mb-0">
                        Atenção! As atividades abaixo estão filtradas. Alguns
                        itens podem não aparecer.
                      </small>
                    </Col>
                    <Col xs="1" className="text-right">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters2"
                        onClick={(e) =>
                          this.fillTaskRows(
                            null,
                            this.state.tasksData,
                            "remove"
                          )
                        }
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters2"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler,#buttonFilterToggler">
            <Row hidden={this.state.isLoading}>
              <Col xs="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6">
                        <h5 className="h3 mb-0">Filtros</h5>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button
                          color="secondary"
                          size="sm"
                          id="buttonFilterToggler"
                          onClick={(e) => this.buttonFilterClick(e)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-filter" />
                          </span>
                          <span className="btn-inner--text">
                            Ocultar Filtros
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="4">
                        <FormGroup>
                          <label className="form-control-label">
                            Contém o texto
                          </label>
                          <Input
                            placeholder="Digite parte do título ou descrição da atividade"
                            type="text"
                            value={this.state.filterText}
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterText"
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Status
                          </label>
                          <Input
                            multiple="multiple"
                            type="select"
                            id="selectTaskStatus"
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterStatus"
                              )
                            }
                          >
                            {this.getFilterStatusList(this.state.statusList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Solicitante
                          </label>
                          <Input
                            multiple="multiple"
                            type="select"
                            id="selectTaskRequester"
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterRequester"
                              )
                            }
                          >
                            {this.getFilterRequesterList(
                              this.state.filterRequesterList
                            )}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Responsável
                          </label>
                          <Input
                            multiple="multiple"
                            type="select"
                            id="selectTaskResponsible"
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterResponsible"
                              )
                            }
                          >
                            {this.getFilterResponsibleList(
                              this.state.filterResponsibleList
                            )}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Prioridade
                          </label>
                          <Input
                            multiple="multiple"
                            type="select"
                            id="selectTaskPriority"
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterPriority"
                              )
                            }
                          >
                            {this.getFilterPriorityList(
                              this.state.priorityList
                            )}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example-date-input"
                          >
                            Prazo De
                          </label>
                          <Input
                            id="example-date-input"
                            type="date"
                            value={this.state.filterDueDateFrom}
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterDueDateFrom"
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="example-date-input"
                          >
                            Prazo Até
                          </label>
                          <Input
                            id="example-date-input"
                            type="date"
                            value={this.state.filterDueDateTo}
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterDueDateTo"
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="orderBy"
                          >
                            Ordenar Por
                          </label>
                          <Input
                            id="filterOrderBy"
                            type="select"
                            value={this.state.filterOrderBy}
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterOrderBy"
                              )
                            }
                          >
                            <option value="P">Prazo</option>
                            <option value="S">Sequencial</option>
                            <option value="T">Título</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="2">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="orderBy"
                          >
                            Módulo
                          </label>
                          <Input
                            id="filterOrderBy"
                            type="select"
                            value={this.state.filterModule}
                            onChange={(e) =>
                              this.fillTaskRows(
                                e,
                                this.state.tasksData,
                                "filterModule"
                              )
                            }
                          >
                            <option value={null}>Todos</option>
                            {this.state.plansList.map((module) => (
                              <option value={module._id}>
                                {module.businessArea}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </UncontrolledCollapse>
          {this.getTaskRows(this.state.taskRows)}
          <List
            modalOpened={this.state.listModalOpened}
            title={this.state.listModalTitle}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            listName={this.state.listName}
            listManagerCG={this.state.listManagerCG}
            listManagerCR={this.state.listManagerCR}
            modalSave={this.closeModalList.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            managerCGList={this.state.managerCGList}
            managerCRList={this.state.managerCRList}
            isManagerConsulting={this.state.isManagerConsulting}
          />
          <Task
            percentage={this.state.percentage}
            modalOpened={this.state.modalTaskOpened}
            title={this.state.modalTaskMainTitle}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            taskCode={this.state.taskCode}
            taskRequester={this.state.taskRequester}
            taskName={this.state.taskName}
            taskDescription={this.state.taskDescription}
            taskDate={this.state.taskDate}
            taskDueDate={this.state.taskDueDate}
            taskConclusionDate={this.state.taskConclusionDate}
            taskStatus={this.state.taskStatus}
            taskResponsible={this.state.taskResponsible}
            taskPriority={this.state.taskPriority}
            taskModule={this.state.taskModule}
            modalSave={this.closeModalTask.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            requesterList={this.state.requesterList}
            responsibleList={this.state.responsibleList}
            plansList={this.state.plansList}
            statusList={this.state.statusList}
            priorityList={this.state.priorityList}
          />
          <TaskConsole
            modalOpened={this.state.modalTaskConsoleOpened}
            readOnly={this.state.readOnly}
            id={this.state.selectedTask}
            taskCode={this.state.taskCode}
            taskRequester={this.state.taskRequester}
            taskRequesterFullName={this.state.taskRequesterFullName}
            taskRequesterImage={this.state.taskRequesterImage}
            taskName={this.state.taskName}
            taskDescription={this.state.taskDescription}
            taskDate={this.state.taskDate}
            taskDueDate={this.state.taskDueDate}
            taskConclusionDate={this.state.taskConclusionDate}
            taskStatus={this.state.taskStatus}
            taskResponsible={this.state.taskResponsible}
            taskResponsibleFullName={this.state.taskResponsibleFullName}
            taskResponsibleImage={this.state.taskResponsibleImage}
            taskPriority={this.state.taskPriority}
            taskModule={this.state.taskModule}
            taskInteractions={this.state.taskInteractions}
            taskDocuments={this.state.taskDocuments}
            isConsulting={
              this.state.isManagerConsulting || this.state.isResourceConsulting
            }
            closeModal={this.closeModalTaskConsole.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            requesterList={this.state.requesterList}
            responsibleList={this.state.responsibleList}
            statusList={this.state.statusList}
            priorityList={this.state.priorityList}
            avatarFilterClick={this.avatarFilterClick.bind(this)}
            statusIndex={this.state.statusIndex}
            priorityIndex={this.state.priorityIndex}
            newComment={this.state.newComment}
            newStatus={this.state.newStatus}
            newCommentOpened={this.state.newCommentOpened}
            openNewComment={this.openNewComment.bind(this)}
            closeNewComment={this.closeNewComment.bind(this)}
            saveNewInteraction={this.saveNewInteraction.bind(this)}
            taskInteractionsToggleNavs={this.taskInteractionsToggleNavs.bind(
              this
            )}
            taskActiveTab={this.state.taskActiveTab}
            openModalDocument={this.openModalDocument.bind(this)}
            percentage={this.state.percentage}
            STATUS_PERCENTAGES={this.STATUS_PERCENTAGES}
          />
          <Document
            readOnly={this.state.readOnly}
            crud={this.state.crud}
            modalTitle={this.state.documentModalTitle}
            modalOpened={this.state.documentModalOpened}
            documentDescription={this.state.documentDescription}
            documentKind={this.state.documentKind}
            documentURL={this.state.documentURL}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            acceptedFileTypes={this.state.taskDocumentFileTypes}
            onFileChange={this.documentFileChange.bind(this)}
            modalSave={this.closeModalDocument.bind(this)}
            saving={this.state.saving}
          />
          <Loading modalOpened={this.state.isLoading} />
        </Container>
        {this.state.alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
      </>
    );
  }
}

export default ListConsole;
