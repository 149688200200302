import React, { useCallback, useEffect, useState } from 'react'
// reactstrap components
import '../../assets/scss/custom/_projectDriveFiles.scss'
import api from 'services/api'
import paginationFactory from 'react-bootstrap-table2-paginator'
import {
  Button,
  Card,
  CardBody,
  Modal,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Row,
  Col,
  Form,
  FormGroup
} from 'reactstrap'

import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import { ThreeDots } from 'react-loader-spinner'
import { saveAs } from 'file-saver'
import { isManager } from 'services/auth'
import { isConsulting } from 'services/auth'
import { isArray } from 'lodash'

//chose to use functional component to use react hooks for better data sync

function ProjectDriveFiles (props) {
  // File variables
  const [files, setFiles] = useState([])
  const [hiddenRows, setHiddenRows] = useState([])
  const [openedFolders, setOpenedFolders] = useState([])
  const [closeChildrenHelper, setcloseChildrenHelper] = useState(0)
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [nameFilter, setNameFilter] = useState('')
  const [fileType, setFileType] = useState('')
  const [fileViewClass, setFileViewClass] = useState('')

  // File methods

  /**
   *
   */
  const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: 25,
    paginationTotalRenderer: (from, to, size) => (
      <span className='react-bootstrap-table-pagination-total'>
        {' [ '} Mostrando linhas {from} a {to} de {size} ]
      </span>
    ),
    sizePerPageRenderer: ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className='dataTables_length' id='datatable-basic_length'>
        <label>
          Mostrar{' '}
          {
            <select
              name='datatable-basic_length'
              aria-controls='datatable-basic'
              className='form-control form-control-sm'
              onChange={e => onSizePerPageChange(e.target.value)}
            >
              <option value='25'>25</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          }{' '}
          registros.
        </label>
      </div>
    )
  })

  /**
   *
   * @param {Object} file
   * @returns
   */
  const generateDriveUrl = file => {
    if (file.mimeType === 'application/vnd.google-apps.document') {
      return `https://docs.google.com/document/d/${file.id}/edit`
    } else if (file.mimeType === 'application/vnd.google-apps.presentation') {
      return `https://docs.google.com/presentation/d/${file.id}/edit#slide=id.p`
    } else if (file.mimeType === 'application/vnd.google-apps.spreadsheet') {
      return `https://docs.google.com/spreadsheets/d/${file.id}/edit#gid=0`
    } else {
      return `https://drive.google.com/file/d/${file.id}/view`
    }
  }
  /**
   *
   * @param {number} id
   */
  function downloadstyleSheet (id, fileName) {
    api
      .get('/file/get/' + id, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        let reader = new FileReader()
        var blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          saveAs(reader.result, fileName + '.xlsx')
          setIsLoading(false)
        }
      })
      .then(() => {})
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }
  /**
   *
   * @param {number} id
   */
  function downloadPresentation (id, fileName) {
    api
      .get('/file/get/' + id, {
        responseType: 'blob'
      })
      .then(response => {
        let uriContent = URL.createObjectURL(response.data, {
          type:
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        })
        let link = document.createElement('a')
        link.setAttribute('href', uriContent)
        link.setAttribute('download', fileName + '.pptx')
        let event = new MouseEvent('click')
        link.dispatchEvent(event)
        setIsLoading(false)
      })
      .then(() => {})
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }

  /**
   *
   * @param {number} id
   */
  function viewPdfOrImage (id) {
    api
      .get('/file/get/' + id, {
        responseType: 'blob'
      })
      .then(response => {
        const fileURL = window.URL.createObjectURL(response.data)
        // Setting various property values
        let alink = document.createElement('a')
        alink.href = fileURL
        const newFile = {
          data: fileURL,
          type: response.data.type
        }
        setFileViewClass('drive-modal')
        setFile(newFile)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        setIsLoading(false)
      })
  }
  /**
   *
   * @param {number} id
   * @param String} mimeType
   */
  const getFile = (id, mimeType, fileName) => {
    setIsLoading(true)
    if (mimeType === 'application/vnd.google-apps.spreadsheet') {
      downloadstyleSheet(id, fileName)
    } else if (mimeType === 'application/vnd.google-apps.presentation') {
      downloadPresentation(id, fileName)
    } else {
      viewPdfOrImage(id)
    }
  }

  /**
   *
   * @param {string} type
   */
  const createDocument = type => {
    setIsLoading(true)
    api
      .post('file/create', {
        folderId: props.driveFolder,
        type: type
      })
      .then(response => {
        window.open(generateDriveUrl(response.data), '_blank').focus()
        listFiles()
      })
    setIsLoading(false)
  }

  async function getFilesFromFolder (fileId, params) {
    return await api
      .get(`/file/list/${fileId}`, { params })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(error)
      })
  }

  /**
   *
   */
  const listFiles = useCallback(async () => {
    setOpenedFolders([])
    setIsLoading(true)
    const params = {
      name: nameFilter,
      fileType: fileType,
      isManager: isManager(),
      isConsulting: isConsulting()
    }

    try {
      const folderFiles = await getFilesFromFolder(props.driveFolder, params)

      let hidden = []
      folderFiles.forEach(file => {
        if (file.mimeType === 'application/vnd.google-apps.folder') {
          if (!props.isManager) {
            hidden.push(file.id)
          }
        }
        if (file.mimeType === 'application/vnd.google-apps.shortcut') {
          file.id = file.shortcutDetails.targetId
          file.mimeType = file.shortcutDetails.targetMimeType
        }
      })

      setHiddenRows(hidden)
      setFiles(folderFiles)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [nameFilter, fileType, props.driveFolder, props.isManager])

  function closeFolderAndChildren (row) {
    let count = 0
    if (row.mimeType === 'application/vnd.google-apps.folder') {
      row.isOpen = false
      row.children.forEach(file => {
        if (file.mimeType === 'application/vnd.google-apps.folder') {
          if (file.isOpen) {
            count += closeFolderAndChildren(file) + 1
          } else {
            count++
          }
        } else {
          count++
        }
      })
    } else {
      count++
    }

    return count
  }

  useEffect(() => {
    if (props.modalOpened && props.driveFolder) {
      listFiles()
    }
  }, [props.driveFolder, props.modalOpened, nameFilter, fileType, listFiles])

  return (
    <>
      <Modal
        className={`modal-dialog-centered ${fileViewClass}`}
        size='xl'
        isOpen={props.modalOpened}
        autoFocus={false}
      >
        <div className='modal-header'>
          <h5 className='modal-title' id='exampleModalLabel'>
            Drive do projeto
          </h5>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={e => {
              setFile(null)
              setNameFilter('')
              setFileType('')
              props.closeModal()
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Card>
          {/* Adds buttons to open drive and create file (requires isConsulting === true) */}
          {props.isConsulting && !file && (
            <CardHeader>
              <Col xs='12' className={'px-3'}>
                <Row>
                  <Button
                    color='default'
                    onClick={() => {
                      window
                        .open(
                          'https://drive.google.com/drive/folders/' +
                            props.driveFolder,
                          '_blank'
                        )
                        .focus()
                      setFile(null)
                      setNameFilter('')
                      setFileType('')
                      props.closeModal()
                    }}
                  >
                    Abrir Drive
                  </Button>
                  <UncontrolledDropdown group>
                    <DropdownToggle caret color='default'>
                      <span className='btn-inner--icon'>
                        <i className='fas fa-plus' />
                      </span>
                      <span className='btn-inner--text'> Criar arquivo</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => createDocument('doc')}>
                        <span className='btn-inner--icon'></span>
                        <span className='btn-inner--text'> Documento</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => createDocument('presentation')}
                      >
                        <span className='btn-inner--icon'></span>
                        <span className='btn-inner--text'> Apresentação</span>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => createDocument('spreadsheet')}
                      >
                        <span className='btn-inner--icon'></span>
                        <span className='btn-inner--text'> Planilha</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Row>
              </Col>
            </CardHeader>
          )}
          <CardBody>
            {} {/* List filters  */}
            <Form className='needs-validation' noValidate autoComplete='off'>
              {!file && (
                <div className='form-row'>
                  <Col className='mb-3' md='4'>
                    <FormGroup>
                      <label className='form-control-label'>Nome</label>
                      <Input
                        placeholder='digite o nome do arquivo ou pastas'
                        type='text'
                        value={props.nameFilter}
                        onChange={e => {
                          setNameFilter(e.target.value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className='mb-3' md='4'>
                    <FormGroup>
                      <label
                        className='form-control-label'
                        htmlFor='exampleFormControlSelect2'
                      >
                        Tipo
                      </label>
                      <Input
                        type='select'
                        id='selectItemStatus'
                        onChange={e => {
                          setFileType(e.target.value)
                        }}
                      >
                        <option value=''>Tudo</option>
                        {isConsulting() && (
                          <option value='document'>Documento</option>
                        )}
                        <option value='spreadsheet'>Planilha</option>
                        <option value='presentation'>Apresentação</option>
                        <option value='video'>Video</option>
                        {props.isManager && (
                          <option value='folder'>Pasta</option>
                        )}
                        <option value='other'>Outros</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </div>
              )}
            </Form>
            {/* Project has no folder assigned */}
            {!props.driveFolder && !isLoading && (
              <h2> Não há pasta salva para esse projeto</h2>
            )}
            {/* The project has a folder bou it is empty */}
            {props.driveFolder &&
              files.length <= 0 &&
              !nameFilter &&
              !fileType &&
              !isLoading && <h2>Não há Arquivos salvos nessa pasta</h2>}
            {/* The Search has found no files */}
            {props.driveFolder &&
              files.length <= 0 &&
              (nameFilter || fileType) && (
                <h2>A busca não retornou nenhum arquivo</h2>
              )}
            {/* List is loading */}
            {isLoading && (
              <center>
                <ThreeDots
                  height='50'
                  width='50'
                  color='primary'
                  ariaLabel='loading'
                />
              </center>
            )}
            {/* Files list exists and is loaded */}
            {files.length > 0 && !isLoading && !file && (
              <div>
                <ToolkitProvider
                  keyField='id'
                  search
                  data={files}
                  columns={[
                    {
                      dataField: 'id',
                      text: 'Id',
                      hidden: true,
                      disabled: isLoading
                    },
                    {
                      text: 'Tipo',
                      dataField: 'iconLink',
                      disabled: isLoading,
                      formatter: (cell, row) => {
                        return <img alt='icon' src={row.iconLink}></img>
                      }
                    },
                    {
                      dataField: 'parent',
                      text: 'Caminho no drive',
                      disabled: isLoading,
                      hidden: !props.isManager
                    },
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      disabled: isLoading
                    },
                    {
                      dataField: 'createdTime',
                      text: 'Criado em',
                      sort: true,
                      disabled: isLoading,
                      formatter: (cell, row) => {
                        let date = new Date(row.createdTime)
                        return new Intl.DateTimeFormat('pt-BR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(date)
                      }
                    },
                    {
                      dataField: 'modifiedTime',
                      text: 'Última modificação',
                      sort: true,
                      disabled: isLoading,
                      formatter: (cell, row) => {
                        let date = new Date(row.modifiedTime)
                        return new Intl.DateTimeFormat('pt-BR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit'
                        }).format(date)
                      }
                    }
                  ]}
                >
                  {props => (
                    <div className='py-4 table-responsive'>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover
                        condensed
                        size='xl'
                        pagination={pagination}
                        rowStyle={{ cursor: 'pointer' }}
                        rowEvents={{
                          onClick: async (e, row, rowIndex) => {
                            if (
                              row.mimeType ===
                              'application/vnd.google-apps.folder'
                            ) {
                              let currentFiles = files.map(file => {
                                return file
                              })
                              if (row.isOpen) {
                                let spliceNumber = closeFolderAndChildren(row)

                                currentFiles.splice(
                                  rowIndex,
                                  spliceNumber + 1,
                                  row
                                )

                                setFiles(currentFiles)
                              } else {
                                if (
                                  isArray(row.children) &&
                                  row.children?.length > 0
                                ) {
                                  currentFiles[rowIndex].isOpen = true
                                  let children = row.children

                                  children.forEach((file, index) => {
                                    if (row.parent) {
                                      file.parent = row.parent + row.name + ' >'
                                    } else {
                                      file.parent = row.name + ' >'
                                    }
                                    currentFiles.splice(
                                      rowIndex + 1 + index,
                                      0,
                                      file
                                    )
                                  })
                                  setFiles(currentFiles)
                                }
                              }
                            } else {
                              if (props.isConsulting) {
                                window
                                  .open(generateDriveUrl(row), '_blank')
                                  .focus()
                              } else {
                                getFile(row.id, row.mimeType, row.name)
                              }
                            }
                          }
                        }}
                        hiddenRows={hiddenRows}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            )}
            {/* File is selected to viewing */}
            {file &&
              file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && (
                <div className={'client-view'}>
                  <Button
                    color='danger'
                    type='button'
                    onClick={() => {
                      setFile(null)
                      setFileViewClass('')
                    }}
                  >
                    Fechar Arquivo
                  </Button>
                  <iframe title='file' frameBorder={0} src={file.data}></iframe>
                </div>
              )}
          </CardBody>
        </Card>
      </Modal>
    </>
  )
}

export default ProjectDriveFiles
