import React from 'react';
import ReactPlayer from "react-player"
import {
    Modal,
} from "reactstrap";

class PlayYouTubeVideo extends React.Component {

    render() {
      return (
        <>
          <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={this.props.modalOpened}
              autoFocus={false}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.props.title}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.modalClose(e)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div>
                <ReactPlayer
                    url={this.props.source}
                    width="800px"
                    height="600px"
                    controls={true}
                />
            </div>
          </Modal>
        </>
      );
    }
  }
  
  export default PlayYouTubeVideo;