import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";

//Custom components
import api from "services/api";
import { isConsulting } from "services/auth";
import { isManager } from "services/auth";
import { isDemo } from "services/auth";
import { userFullName } from "services/auth";

import LatestInteractions from "components/Feeds/LatestInteractions.js";
import TotalTests from "components/Feeds/TotalTests.js";

class Feed extends React.Component {

  state = {
    isLoading: true,
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    isResourceCustomer: false,
    currentUserFullName: "",

    totalTests: 0,
    concludedTests: 0,
    inProgressTests: 0,
    notStartedTests: 0,
    concludedTestsPerc: 0,
    inProgressTestsPerc: 0,
    notStartedTestsPerc: 0,

    feedTitle: "Carregando feed...",
    totalTestsTitle: "Carregando estatísticas...",
    interactions: [],
  }

  componentDidMount(){

    this.setState( {
      isManagerConsulting: isManager() && isConsulting(),
      isResourceConsulting: !isManager() && isConsulting(),
      isManagerCustomer: isManager() && !isConsulting(),
      isResourceCustomer: !isManager() && !isConsulting(),
      currentUserFullName: userFullName(),
    } )

    this.loadData();

  }

  loadData = async() => {

    const isDemoAccess = isDemo();
    const isManagerConsulting = isManager() && isConsulting();
    const isResourceConsulting = !isManager() && isConsulting();
    const isManagerCustomer = isManager() && !isConsulting();
    const currentUserId = localStorage.getItem( process.env.REACT_APP_USERID_KEY );

    var feedTitle = userFullName();
    var totalTestsTitle = "Sob sua responsabilidade"

    if( String( feedTitle ).indexOf( " " ) > 0 ){

      feedTitle = String(feedTitle).substring( 0, String( feedTitle ).indexOf( " " ) );

    }

    if( isManagerConsulting || isManagerCustomer ){

      feedTitle += ", essas são as últimas atualizações relacionadas às equipes de trabalho:"
      totalTestsTitle = "Sob responsabilidade das equipes"

    } else {

      feedTitle += ", essas são as úlitmas atualizações relacionadas à você:";

    }

    var filters = {
      deleted: "N",
      reviewed: "N"
    }

    var filtersTaskInteractions = {
      deleted: "N"
    }

    if( !isManagerConsulting ){

      if( isManagerCustomer ){

        filters = {
          ...filters,
          manager: currentUserId
        }

      } else {

        if( isResourceConsulting ){

          filters = {
            ...filters,
            analyst: currentUserId
          }

        } else {

          filters = {
            ...filters,
            keyUser: currentUserId
          }

        }

      }

    }

    this.setState({
      isLoading: true,
      feedTitle: feedTitle,
      totalTestsTitle: totalTestsTitle,
    })
    
    const respTests = await api.post( "/test/totalTests", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    } );

    this.setState( {
      totalTests: respTests.data.totalTests,
      concludedTests: respTests.data.concludedTests,
      inProgressTests: respTests.data.inProgressTests,
      notStartedTests: respTests.data.notStartedTests,
      concludedTestsPerc: respTests.data.concludedTestsPerc,
      inProgressTestsPerc: respTests.data.inProgressTestsPerc,
      notStartedTestsPerc: respTests.data.notStartedTestsPerc,
    } )

    filters = { ...filters, showOnFeed: "Y" };

    const respInteractions = await api.post( "/interaction/feed", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    } )

    const respTaskInteractions = await api.post( "/taskInteraction/feed", {
      filters: filtersTaskInteractions,
      isDemoAccess: isDemoAccess,
      isManagerConsulting: isManagerConsulting,
      userId: currentUserId,
    } );

    var interactions = [];

    if( respInteractions.data[ 0 ].status === "WA" && respTaskInteractions.data[ 0 ].status === "WA" ){

      interactions = respInteractions.data;

    } else {

      if( respInteractions.data[ 0 ].status !== "WA" ){

        interactions = respInteractions.data;

      }

      if( respTaskInteractions.data[ 0 ].status !== "WA" ){
  
        interactions = [...interactions, ...respTaskInteractions.data]
      
      }

    }

    interactions.sort((a, b) => a.dateTime > b.dateTime ? -1 : 1)

    this.setState( {
      isLoading: false,
      interactions: interactions,
    } )

  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Feed"
          items={[
            { level: "parent", name: "Dashboards" },
            { level: "nav", name: "Feed" },
          ]}
        />
        <Container className="mt--6" fluid>
          <TotalTests
            totalTestsTitle={this.state.totalTestsTitle}
            totalTests={this.state.totalTests}
            concludedTests={this.state.concludedTests}
            inProgressTests={this.state.inProgressTests}
            notStartedTests={this.state.notStartedTests}
            concludedTestsPerc={this.state.concludedTestsPerc}
            inProgressTestsPerc={this.state.inProgressTestsPerc}
            notStartedTestsPerc={this.state.notStartedTestsPerc}
          />
          <Row>
            <Col xl="12">
              <LatestInteractions
                {...this.props}
                title={this.state.feedTitle}
                interactions={this.state.interactions}
                isLoading={this.state.isLoading}
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Feed;