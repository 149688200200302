import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Col,
  Table,
  ModalBody,
} from "reactstrap"
import FormGroup from "reactstrap/lib/FormGroup"

class Document extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.modalOpened}
          autoFocus={false}
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <div className="form-row">
                        <Col className="mb-3" md="8">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Descrição
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Descrição do documento"
                            type="text"
                            value={this.props.documentDescription}
                            readOnly={this.props.readOnly}
                            disabled={
                              this.props.saving || this.props.crud === "U"
                            }
                            valid={
                              this.props.customStyles
                                .documentDescriptionState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .documentDescriptionState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "documentDescription")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a descrição do documento.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label">Tipo</label>
                          <Input
                            id="validationCustom03"
                            type="select"
                            value={this.props.documentKind}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.crud === "U"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "documentKind")
                            }
                          >
                            <option value="U">URL do YouTube</option>
                            <option value="V">Vídeo do meu computador</option>
                            <option value="I">Imagem</option>
                            <option value="D">Documento</option>
                          </Input>
                        </Col>
                      </div>
                      <div
                        className="form-row"
                        hidden={this.props.documentKind !== "U"}
                      >
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            URL
                          </label>
                          <Input
                            id="validationCustom01"
                            placeholder="URL do vídeo"
                            type="text"
                            value={this.props.documentURL}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.documentURLState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.documentURLState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "documentURL")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a URL do vídeo.
                          </div>
                        </Col>
                      </div>
                      <div
                        className="form-row"
                        hidden={
                          this.props.readOnly || this.props.documentKind === "U"
                        }
                      >
                        <Col className="mb-3" md="12">
                          <FormGroup>
                            <label className="form-control-label">
                              Arquivo
                            </label>
                            <div className="custom-file">
                              <input
                                className="custom-file-input"
                                id="customFileLang"
                                lang="en"
                                type="file"
                                accept={this.props.acceptedFileTypes}
                                disabled={this.props.saving}
                                onChange={(e) => this.props.onFileChange(e)}
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="customFileLang"
                              ></label>
                            </div>
                          </FormGroup>
                        </Col>
                      </div>
                      {Array.isArray(this.props.affectedItemDocuments) &&
                      this.props.affectedItemDocuments.length > 0 ? (
                        <>
                          <h3 className="mx-4 mt-4">
                            Os seguintes Documentos de Itens serão afetados:
                          </h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>Cliente</th>
                                <th>Projeto</th>
                                <th>Plano</th>
                                <th>Item</th>
                                <th>CIclo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.affectedItemDocuments.map(
                                (document, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {document.plan.customer.shortName}
                                      </td>
                                      <td>{document.plan.projectName}</td>

                                      <td>{document.plan.businessArea}</td>
                                      <td>{document.item.process}</td>
                                      <td>{document.cicle.title}</td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <></>
                      )}
                      {Array.isArray(this.props.affectedTestDocuments) &&
                      this.props.affectedTestDocuments.length > 0 ? (
                        <>
                          <h3 className="mx-4 mt-4">
                            Os seguintes Documentos de Testes serão afetados:
                          </h3>
                          <Table>
                            <thead>
                              <tr>
                                <th>Cliente</th>
                                <th>Projeto</th>
                                <th>Plano</th>
                                <th>Item</th>
                                <th>CIclo</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.affectedTestDocuments.map(
                                (document, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        {document.plan.customer.shortName}
                                      </td>
                                      <td>{document.plan.projectName}</td>

                                      <td>{document.plan.businessArea}</td>
                                      <td>{document.item.process}</td>
                                      <td>{document.cicle.title}</td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <></>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </ModalBody>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Document
