import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

class Plan extends React.Component {

  render() {
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="lg"
            isOpen={this.props.modalOpened}
            autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate autoComplete="off">
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          Área de Negócio
                        </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Área de negócio"
                            type="text"
                            value={this.props.modalPlanBusinessArea}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                            this.props.customStyles.modalPlanBusinessAreaState === "valid"
                          }
                          invalid={
                            this.props.customStyles.modalPlanBusinessAreaState === "invalid"
                          }
                          onChange={e =>
                            this.props.stylesForm(e, "modalPlanBusinessArea")
                          }
                        />
                        <div className="invalid-feedback">
                          Digite a área de negócio.
                        </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Analista
                        </label>
                        <Select2
                            className="form-control"
                            type="text"
                            value={this.props.modalPlanAnalyst}
                            disabled={this.props.readOnly || this.props.saving || !this.props.isManagerConsulting}
                            onSelect={e => this.props.stylesForm(e, "modalPlanAnalyst")}
                            options={{
                              placeholder:"Selecione um analista",
                              language: {
                                noResults: function() {
                                  return "Nenhum analista encontrado."
                                },
                              },
                            }}
                            data={this.props.analystList}
                          />
                          <Input
                            hidden
                            valid={this.props.customStyles.modalPlanAnalystState === "valid"}
                            invalid={this.props.customStyles.modalPlanAnalystState === "invalid"}
                          />
                          <div className="invalid-feedback">
                            Analista inválido ou não selecionado
                          </div>
                      </Col>
                    </div>
                    <div className="form-row">
                    <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Usuário Chave
                        </label>
                        <Select2
                            className="form-control"
                            type="text"
                            value={this.props.modalPlanKeyUser}
                            disabled={this.props.readOnly || this.props.saving}
                            onSelect={e => this.props.stylesForm(e, "modalPlanKeyUser")}
                            options={{
                              placeholder:"Selecione um usuário",
                              language: {
                                noResults: function() {
                                  return "Nenhum usuário encontrado."
                                },
                              },
                            }}
                            data={this.props.keyUserList}
                          />
                          <Input
                            hidden
                            valid={this.props.customStyles.modalPlanKeyUserState === "valid"}
                            invalid={this.props.customStyles.modalPlanKeyUserState === "invalid"}
                          />
                          <div className="invalid-feedback">
                            Usuário inválido ou não selecionado.
                          </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Gerente Funcional
                        </label>
                        <Select2
                            className="form-control"
                            type="text"
                            value={this.props.modalPlanManager}
                            disabled={this.props.readOnly || this.props.saving}
                            onSelect={e => this.props.stylesForm(e, "modalPlanManager")}
                            options={{
                              placeholder:"Selecione um gerente",
                              language: {
                                noResults: function() {
                                  return "Nenhum gerente encontrado."
                                },
                              },
                            }}
                            data={this.props.managerList}
                          />
                          <Input
                            hidden
                            valid={this.props.customStyles.modalPlanManagerState === "valid"}
                            invalid={this.props.customStyles.modalPlanManagerState === "invalid"}
                          />
                          <div className="invalid-feedback">
                            Gerente inválido ou não selecionado.
                          </div>
                      </Col>
                    </div>
                  </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.modalSave(e, "abort")}
                disabled={this.props.saving}
            >
                Cancelar
            </Button>
            <Button
                color="success"
                type="button"
                onClick={(e) => this.props.modalSave(e)}
                hidden={this.props.crud === "R"}
                disabled={this.props.saving}
            >
                {this.props.crud === "D" ? ("Excluir"):("Salvar")}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Plan;