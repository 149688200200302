/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Progress } from "reactstrap";

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";

import { login } from "services/auth";
import api from "services/api";

class Login extends React.Component {
  state = {
    email: "",
    password: "",
    error: "",
    isLoading: false
  };

  emailChange(e){
    this.setState({ email: e.target.value, error: "" })
  }

  passwordChange(e){
    this.setState({ password: e.target.value, error: "" })
  }

  signIn = async e => {

    this.setState( { isLoading: true } );

    e.preventDefault();
    const { email, password } = this.state;

    if (!email || !password) {

      this.setState({ error: "Digite e-mail e senha para continuar!" });

    } else {

      try {
        
        const response = await api.post("/auth/signin", { email, password } );
        
        if (!response.data.token) {
          this.setState({
            error: "Credenciais inválidas... Tente novamente."
          });
        } else {
          
          this.setState({
            error: "Carregando seus dados..."
          });
          
          login(response.data);
          
          this.props.history.push("/admin/feed");

        }

      } catch (err) {

        this.setState({
          error: "Credenciais inválidas... Tente novamente."
        });
        
      }
    }

    this.setState( { isLoading: false } );
  };

  render() {
    return (
      <>
        <AuthHeader
          title="Bem Vindo !"
          lead="Faça o seu login ou solicite acesso."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Digite seu e-mail e senha de acesso</small>
                  </div>
                  <Form role="form" onSubmit={this.signIn}>
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onFocus={() => this.setState({ focusedEmail: true })}
                          // onBlur={() => this.setState({ focusedEmail: false })}
                          onChange={(e) => this.emailChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onFocus={ () => this.setState({ focusedPassword: true }) }
                          //onBlur={ () => this.setState({ focusedPassword: false }) }
                          onChange={(e) => this.passwordChange(e)}
                        />
                      </InputGroup>
                    </FormGroup>
                      <div className="text-center text-muted my-4">
                        <span className="text-muted">{this.state.error}</span>
                      <Progress animated color="danger" value="100" hidden={!this.state.isLoading}/>
                    </div>
                    <div className="text-center">
                      <Button
                        className="my-4"
                        color="danger"
                        type="submit">
                          Entrar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    className="text-light"
                    href="#forgotPwd"
                    onClick={e => e.preventDefault()}
                  >
                    <small>Esqueceu sua senha ?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Login;
