import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

class User extends React.Component {

  render() {
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            size="xl"
            isOpen={this.props.modalOpened}
            autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate autoComplete="off">
                      <div className="form-row">
                        <Col className="mb-3" md="5">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Nome completo
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            placeholder="Nome completo"
                            type="text"
                            value={this.props.fullName}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.fullNameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.fullNameState === "invalid"
                            }
                            onChange={e =>
                              this.props.stylesForm(e, "fullName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o nome do usuário.
                          </div>
                        </Col>
                        <Col className="mb-3" md="5">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            E-mail
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="E-mail"
                            type="text"
                            autoComplete="new-password"
                            value={this.props.email}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.emailState === "valid"
                            }
                            invalid={
                              this.props.customStyles.emailState === "invalid"
                            }
                            onChange={e => this.props.stylesForm(e, "email")}
                          />
                          <div className="invalid-feedback">
                            Digite um e-mail.
                          </div>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustomUsername"
                          >
                            Senha
                          </label>
                          <Input
                            aria-describedby="inputGroupPrepend"
                            id="validationCustomUsername"
                            placeholder="Senha"
                            type="password"
                            autoComplete="new-password"
                            value={this.props.password}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.passwordState === "valid"
                            }
                            invalid={
                              this.props.customStyles.passwordState === "invalid"
                            }
                            onChange={e => this.props.stylesForm(e, "password")}
                          />
                          <div className="invalid-feedback">
                            Digite uma senha.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Tipo
                          </label>
                          <Input
                            id="validationCustom03"
                            type="select"
                            value={this.props.kind}
                            disabled={this.props.readOnly || this.props.saving}
                            valid={
                                this.props.customStyles.kindState === "valid"
                              }
                              invalid={
                                this.props.customStyles.kindState === "invalid"
                              }
                            onChange={e => this.props.stylesForm(e, "kind")}
                          >
                            <option value="M">Gerente</option>
                            <option value="R">Recurso</option>
                          </Input>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Classificação
                          </label>
                          <Input
                            id="validationCustom03"
                            type="select"
                            value={this.props.class}
                            disabled={this.props.readOnly || this.props.saving || !this.props.fromUsersBrowse}
                            valid={
                                this.props.customStyles.kindState === "valid"
                              }
                              invalid={
                                this.props.customStyles.kindState === "invalid"
                              }
                            onChange={e => this.props.stylesForm(e, "class")}
                          >
                            <option value="CR">Cliente</option>
                          </Input>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Cliente
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.customer}
                            disabled={this.props.saving || this.props.readOnly || this.props.class === "CG" || !this.props.fromUsersBrowse}
                            onSelect={e => this.props.stylesForm(e, "customer")}
                            options={{
                              placeholder:"Selecione um cliente",
                              language: {
                                noResults: function() {
                                  return "Nenhum cliente encontrado."
                                },
                              },
                            }}
                            data={this.props.customersList}
                          />
                          <Input
                            hidden
                            valid={this.props.customStyles.customerState === "valid"}
                            invalid={this.props.customStyles.customerState === "invalid"}
                          />
                          <div className="invalid-feedback">
                            Cliente inválido ou não encontrado.
                          </div>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom06"
                          >
                            Responsável Cliente
                          </label>
                          <div>
                            <label className="custom-toggle mr-1">
                              <input
                                defaultChecked={this.props.mainUser}
                                type="checkbox"
                                value={this.props.mainUser}
                                disabled={this.props.saving || this.props.readOnly || this.props.class === "CG"}
                                onChange={e => this.props.stylesForm(e, "mainUser")}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                                />
                            </label>
                          </div>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom05"
                          >
                            Ativo
                          </label>
                          <div>
                            <label className="custom-toggle mr-1">
                              <input
                                defaultChecked={this.props.active}
                                type="checkbox"
                                value={this.props.active}
                                disabled={this.props.readOnly || this.props.saving}
                                onChange={e => this.props.stylesForm(e, "active")}
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                                />
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.closeModal(e, "abort")}
                disabled={this.props.saving}
            >
                Cancelar
            </Button>
            <Button
                color="success"
                type="button"
                onClick={(e) => this.props.modalSave(e)}
                hidden={this.props.crud === "R"}
                disabled={this.props.saving}
            >
                {this.props.crud === "D" ? ("Excluir"):("Salvar")}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default User;