import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

class NewItem extends React.Component {

  render() {
    return (
      <>
        <Modal
            className="modal-dialog-centered"
            isOpen={this.props.modalOpened}
            autoFocus={false}
            size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form className="needs-validation" noValidate autoComplete="off">
                      <div className="form-row">
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Sequencial
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom01"
                            type="text"
                            value={this.props.itemSequence}
                            maxLength="2"
                            readOnly={this.props.readOnly || this.props.cicleTitle !== "A"}
                            onChange={e => this.props.stylesForm(e, "itemSequence")}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.itemSequenceState === "valid"
                            }
                            invalid={
                              this.props.customStyles.itemSequenceState === "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Sequencial inválido.
                          </div>
                        </Col>
                        <Col className="mb-3" md="10">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Descrição do Processo
                          </label>
                          <Input
                            id="validationCustom02"
                            placeholder="Descrição do processo (ex.: Cadastro de produto)"
                            type="text"
                            value={this.props.itemProcess}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.itemProcessState === "valid"
                            }
                            invalid={
                              this.props.customStyles.itemProcessState === "invalid"
                            }
                            onChange={e => this.props.stylesForm(e, "itemProcess")}
                          />
                          <div className="invalid-feedback">
                            Digite a descrição do processo.
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={(e) => this.props.modalSave(e, "abort")}
                disabled={this.props.saving}
            >
                Cancelar
            </Button>
            <Button
                color="success"
                type="button"
                onClick={(e) => this.props.modalSave(e)}
                disabled={this.props.saving}
            >
                {this.props.crud === "D" ? ("Excluir"):("Salvar")}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default NewItem;