import React from "react";
import { uniqueId } from "lodash";
import filesize from "filesize";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Badge,
  Button,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCollapse,
  UncontrolledTooltip,
  Progress,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import NotificationAlert from "react-notification-alert";
import ReactBSAlert from "react-bootstrap-sweetalert";

// default components
import Moment from "moment";

//Custom components
import api from "services/api";
import { userFullName } from "services/auth";
import { isConsulting } from "services/auth";
import { isManager } from "services/auth";
import { userId } from "services/auth";

import Plan from "components/Modals/Plan.js";
import ItemCard from "components/PlanElements/ItemCard.js";
import Item from "components/Modals/Item.js";
import Test from "components/Modals/Test.js";
import TestInteractions from "components/Modals/TestInteractions.js";
import Interaction from "components/Modals/Interaction.js";
import Document from "components/Modals/Document.js";
import Loading from "components/Modals/Loading.js";
import ImageZoom from "components/Modals/ImageZoom.js";
import ItemDocuments from "components/Modals/ItemDocuments.js";
import apiSap from "services/sapApi";

const statusEmpty = { value: "", text: "" };
const statusNS = { value: "NS", text: "Não Iniciado" };
const statusIP = { value: "IP", text: "Em andamento" };
const statusAP = { value: "AP", text: "Aprovado" };
const statusNC = { value: "NC", text: "Cenário parado" };
const statusNK = { value: "NK", text: "Cenário continua" };
const statusNF = { value: "NF", text: "Cenário corrigido" };
const statusCA = { value: "CA", text: "Cancelado" };

class PlanConsole extends React.Component {
  componentWillUnmount() {}
  state = {
    disableQuery: false,
    loading: false,
    goliveCustomer: false,
    isLoading: false,
    loadingData: true,
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    isResourceCustomer: false,
    currentUserFullName: "",

    //Plan data
    id: "",
    businessArea: "",
    project: "",
    projectName: "",
    projectServiceLayerUrl: "",
    customer: "",
    customerShortName: "",
    analyst: "",
    analystFullName: "",
    keyUser: "",
    keyUserFullName: "",
    manager: "",
    managerFullName: "",
    version: "",
    versionTitle: "",
    cicle: "",
    cicleTitle: "",
    conclusion: 0,
    status: "",
    flowchart: null,
    createdAt: "",
    cicles: [
      {
        versionTitle: "999",
        title: "Z",
      },
    ],
    versionCreatedAt: "",
    buttonFiltersLabel: "Exibir filtros",
    statusList: [],
    statusEmpty: { value: "", text: "" },
    statusNS: { value: "NS", text: "Não Iniciado" },
    statusIP: { value: "IP", text: "Em andamento" },
    statusAP: { value: "AP", text: "Aprovado" },
    statusNC: { value: "NC", text: "Cenário parado" },
    statusNK: { value: "NK", text: "Cenário continua" },
    statusNF: { value: "NF", text: "Cenário corrigido" },
    statusCA: { value: "CA", text: "Cancelado" },
    alert: null,
    analystList: [],
    keyUserList: [],
    managerList: [],
    nonconformityList: [],

    //Selected cicle data
    selectedVersion: "",
    selectedVersionTitle: "",
    selectedCicle: "",
    selectedCicleTitle: "",
    selectedCicleStatus: "",
    selectedCicleCreatedAt: "",
    selectedCicleConclusion: 0,
    selectedItem: "",
    selectedItemSequence: "",
    selectedTest: "",
    selectedTestSequence: "",
    selectedTestStatus: "",
    selectedTestNonconformity: "",
    selectedTestAnalyst: "",
    selectedTestKeyUser: "",
    selectedTestSqlQuery: {},
    items: [],
    saving: false,

    //Modal plan variables
    planModalOpened: false,
    planModalTitle: "",
    modalPlanBusinessArea: "",
    modalPlanAnalyst: "",
    modalPlanKeyUser: "",
    modalPlanManager: "",

    //Modal item variables
    itemModalOpened: false,
    itemModalTitle: "",
    itemSequence: "",
    itemProcess: "",

    //Modal test variables
    testModalOpened: false,
    testModalTitle: "",
    testSequence: "",
    testDescription: "",
    testStatus: "",
    testAnalyst: "",
    testKeyUser: "",
    testOpitionalKeyUser: "",
    testCondition: "",
    testComments: "",
    baseNumber: "",
    sqlQuery: "",
    crud: "",
    readOnly: false,
    newTestIncluded: false,

    //Modals validations
    customStyles: {
      modalPlanBusinessAreaState: null,
      modalPlanAnalystState: null,
      modalPlanKeyUserState: null,
      modalPlanManagerState: null,
      itemSequenceState: null,
      itemProcessState: null,
      testSequenceState: null,
      testDescriptionState: null,
      testAnalystState: null,
      testKeyUserState: null,
      testConditionState: null,
      nonconformityState: null,
      interactionStatusState: null,
      interactionCommentsState: null,
      documentDescriptionState: null,
      documentURLState: null,
    },

    //Modal Test Interactions variables
    testInteractionsModalOpened: false,
    testInteractionsModalTitle: "",
    testInteractionsData: [],
    testDocumentsData: [],
    interactionsActiveTab: 1,
    canInteract: false,

    //Modal Interaction variables
    interactionModalOpened: false,
    interactionModalTitle: "",
    nonconformity: "",
    interactionStatus: "",
    interactionComments: "",
    interactionDocumentData: [],
    canChangeInteractionStatus: false,
    possibleStatusList: [],

    //Modal document variables
    documentModalOpened: false,
    documentModalTitle: "",
    documentId: "",
    documentOfId: "",
    documentDescription: "",
    documentKind: "",
    documentURL: "",
    documentOldUrl: "",
    documentFile: null,
    testDocumentFileTypes: "",
    documentOf: "",
    affectedItemDocuments: [],
    affectedTestDocuments: [],

    //Modal flowchart zoom
    flowchartModalOpened: false,
    flowchartModalTitle: "",
    flowchartUrl: "",
    flochartFile: null,

    //Modal item documents
    itemDocumentsModalOpened: false,
    itemDocumentsModalTitle: "",
    itemDocumentsData: [],

    //Filters variables
    filteringItem: false,
    filteringItemStatus: false,
    filteringTest: false,
    filteringTestStatus: false,
    filterItemDescription: "",
    filterStatusItemSelection: [],
    filterTestDescription: "",
    filterStatusTestSelection: [],
    considerHistory: false,
    testOptions: {},
  };

  componentDidMount() {
    let statusList = [
      statusEmpty,
      statusNS,
      statusIP,
      statusAP,
      statusNC,
      statusNK,
      statusNF,
      statusCA,
    ];
    this.setState({
      isManagerConsulting: isManager() && isConsulting(),
      isResourceConsulting: !isManager() && isConsulting(),
      isManagerCustomer: isManager() && !isConsulting(),
      isResourceCustomer: !isManager() && !isConsulting(),
      currentUserFullName: userFullName(),
      statusList: [
        statusEmpty,
        statusNS,
        statusIP,
        statusAP,
        statusNC,
        statusNK,
        statusNF,
        statusCA,
      ],
    });

    Moment.locale("pt-br");

    this.loadData(
      null,
      this.props.match.params.parentId,
      this.props.match.params.cicleId,
      this.props.match.params.itemId,
      this.props.match.params.testId
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.id) {
      this.loadData(
        null,
        nextProps.match.params.parentId,
        nextProps.match.params.cicleId,
        nextProps.match.params.itemId,
        nextProps.match.params.testId
      );
    }
  }

  loadData = async (
    cicleData,
    paramId,
    feedCicleId,
    feedItemId,
    feedTestId
  ) => {
    var id = this.props.match.params.parentId;

    if (paramId && paramId !== null) {
      id = paramId;
    }

    this.setState({ id: id, items: [], loadingData: true });

    var propCicle = cicleData;

    if (feedCicleId && feedCicleId !== "c" && feedCicleId !== null) {
      const feedCicleData = await api.get("/cicle/read/" + feedCicleId);

      propCicle = {
        version: feedCicleData.data.version,
        versionTitle: feedCicleData.data.versionTitle,
        id: feedCicleData.data._id,
        title: feedCicleData.data.title,
        status: feedCicleData.data.status,
        createdAt: feedCicleData.data.createdAt,
        conclusion: Number(feedCicleData.data.conclusion).toFixed(0),
      };
    }

    const plan = await api.get("/plan/read/" + id);
    const version = await api.get("/version/read/" + plan.data.version);

    var versionCreatedAt = "";
    var cicle = {};
    var ciclesList = [];
    var selectedVersion = "";
    var selectedVersionTitle = "";
    var selectedCicle = "";
    var selectedCicleTitle = "";
    var selectedCicleStatus = "";
    var selectedCicleCreatedAt = "";
    var selectedCicleConclusion = 0;

    versionCreatedAt = Moment(version.data.createdAt).format("D MMM YYYY");

    const cicles = await api.post("cicle/search", {
      plan: plan.data._id,
      deleted: "N",
    });

    for (let i = 0; i < cicles.data.length; i++) {
      cicle = {
        version: cicles.data[i].version,
        versionTitle: cicles.data[i].versionTitle,
        id: cicles.data[i]._id,
        title: cicles.data[i].title,
        status: cicles.data[i].status,
        createdAt: cicles.data[i].createdAt,
        conclusion: Number(cicles.data[i].conclusion).toFixed(0),
      };

      if (ciclesList.length === 0) {
        ciclesList = [cicle];
      } else {
        ciclesList.push(cicle);
      }

      selectedVersion = cicles.data[i].version;
      selectedVersionTitle = cicles.data[i].versionTitle;
      selectedCicle = cicles.data[i]._id;
      selectedCicleTitle = cicles.data[i].title;
      selectedCicleStatus = cicles.data[i].status;
      selectedCicleCreatedAt = Moment(cicles.data[i].createdAt).format(
        "D MMM YYYY"
      );
      selectedCicleConclusion = Number(cicles.data[i].conclusion).toFixed(0);
    }

    if (propCicle && propCicle !== null) {
      selectedCicle = propCicle.id;
      selectedCicleTitle = propCicle.title;
      selectedCicleStatus = propCicle.status;
      selectedCicleCreatedAt = Moment(propCicle.createdat).format("D MMM YYYY");
      selectedCicleConclusion = Number(propCicle.conclusion).toFixed(0);
      selectedVersion = propCicle.version;
      selectedVersionTitle = propCicle.versionTitle;
    }

    var items = [];
    var itemsList = [];
    var feedItemData = null;
    var feedTestData = null;

    var itemsFilter = {
      cicle: selectedCicle,
      deleted: "N",
    };

    if (propCicle && propCicle !== null) {
      itemsFilter = {
        cicle: propCicle.id,
        deleted: "N",
      };
    }

    items = await api.post("/item/fullSearch", {
      filter: itemsFilter,
      isManagerConsulting: this.state.isManagerConsulting,
      isManagerCustomer: this.state.isManagerCustomer,
      isResourceConsulting: this.state.isResourceConsulting,
      isAnalyst:
        plan.data.analyst ===
        localStorage.getItem(process.env.REACT_APP_USERID_KEY),
      currentUserId: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
      planCicle: plan.data.cicle,
      feedItemId: feedItemId,
      feedTestId: feedTestId,
    });

    if (items.data.length > 0) {
      itemsList = items.data[0].itemsList;
      feedItemData = items.data[0].feedItemData;
      feedTestData = items.data[0].feedTestData;
    }
    const project = await api.get(
      "/smartflowproject/read/" + plan.data.project
    );

    for (let i = 0; i < itemsList.length; i++) {
      let item = itemsList[i];

      for (const test of item.tests) {
        if (test.sqlQuery && project.data.serviceLayerUrl) {
          let query = "";
          try {
            query = await apiSap.get(
              project.data.serviceLayerUrl +
                `/SQLQueries('${test.sqlQuery.sqlCode}')/List`
            );

            const queryResult = query?.data?.value[0];
            if (queryResult[0]) {
              test.queryResult =
                queryResult[
                  Object.keys(queryResult) ? Object.keys(queryResult)[0] : null
                ];
            } else {
              test.queryResult = null;
            }
          } catch (error) {
            if (
              error.message ===
              "Algum item não foi encontrado, verifique sua existência e tente novamente"
            )
              await api.put("test/update/" + test._id, { sqlQuery: null });
            test.queryResult = null;
          }
        }
      }

      this.setState({ ["itemStatus" + itemsList[i]._id]: itemsList.status });
      this.setState({
        ["itemConclusion" + itemsList._id]: itemsList[i].conclusion,
      });
    }

    let createdAt = Moment(plan.data.createdAt).format("D MMM YYYY");
    this.setState({
      goliveCustomer: plan.data.customer === "60411a8a856e790017f061d7",
      businessArea: plan.data.businessArea,
      createdAt: createdAt,
      project: plan.data.project,
      projectName: plan.data.projectName,
      projectServiceLayerUrl: project.data.serviceLayerUrl,
      customer: plan.data.customer,
      customerShortName: plan.data.customerShortName,
      analyst: plan.data.analyst,
      analystFullName: plan.data.analystFullName,
      keyUser: plan.data.keyUser,
      keyUserFullName: plan.data.keyUserFullName,
      manager: plan.data.manager,
      managerFullName: plan.data.managerFullName,
      version: plan.data.version,
      versionTitle: plan.data.versionTitle,
      versionCreatedAt: versionCreatedAt,
      cicle: plan.data.cicle,
      cicleTitle: plan.data.cicleTitle,
      cicles: ciclesList,
      conclusion: Number(plan.data.conclusion).toFixed(0),
      status: plan.data.status,
      flowchart: plan.data.flowchart,
      selectedVersion: selectedVersion,
      selectedVersionTitle: selectedVersionTitle,
      selectedCicle: selectedCicle,
      selectedCicleTitle: selectedCicleTitle,
      selectedCicleStatus: selectedCicleStatus,
      selectedCicleCreatedAt: selectedCicleCreatedAt,
      selectedCicleConclusion: selectedCicleConclusion,
      items: itemsList,
    });

    this.loadLists();

    if (feedTestData !== null && feedItemData !== null) {
      this.openModalTestInteractions(null, feedTestData, feedItemData, 1);
    }

    this.setState({ loadingData: false });
  };

  loadLists = async () => {
    var user = {};
    var users = [];
    var analystList = [];
    var keyUserList = [];
    var managerList = [];

    //Analysts
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      if (analystList.length === 0) {
        analystList = [user];
      } else {
        analystList.push(user);
      }
    }

    //Key users
    users = await api.post("/user/search", {
      deleted: "N",
      class: "CR",
      customer: this.state.customer,
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      if (keyUserList.length === 0) {
        keyUserList = [user];
      } else {
        keyUserList.push(user);
      }
    }

    //Managers
    users = await api.post("/user/search", {
      deleted: "N",
      kind: "M",
      class: "CR",
      customer: this.state.customer,
    });

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      };

      if (managerList.length === 0) {
        managerList = [user];
      } else {
        managerList.push(user);
      }
    }

    //Nonconformities
    const nonconformities = await api.get("/nonconformity/all");

    var nonconformity = {};
    var nonconformityList = [];

    for (let i = 0; i < nonconformities.data.length; i++) {
      nonconformity = {
        id: nonconformities.data[i]._id,
        text: nonconformities.data[i].name,
      };

      if (nonconformityList.length === 0) {
        nonconformityList = [nonconformity];
      } else {
        nonconformityList.push(nonconformity);
      }
    }

    this.setState({
      analystList: analystList,
      keyUserList: keyUserList,
      managerList: managerList,
      nonconformityList: nonconformityList,
    });
  };
  getTitle(title) {
    if (title === "Parametrização" || title === "Capacitação") {
      return "| " + title;
    } else {
      return " | Ciclo " + title;
    }
  }

  getCicles = (cicles) => {
    return cicles.map((prop, key) => {
      return (
        <DropdownItem
          cicle={prop.id}
          version={prop.version}
          versiontitle={prop.versionTitle}
          title={prop.title}
          status={prop.status}
          createdat={prop.createdAt}
          conclusion={prop.conclusion}
          onClick={() => this.loadData(prop)}
          key={key}
        >
          {"Revisão " + prop.versionTitle + this.getTitle(prop.title)}
        </DropdownItem>
      );
    });
  };

  buttonFilterClick() {
    var label = this.state.buttonFiltersLabel;

    if (label === "Exibir filtros") {
      this.setState({ buttonFiltersLabel: "Ocultar filtros" });
    } else {
      this.setState({ buttonFiltersLabel: "Exibir filtros" });
    }
  }

  getItems = (items) => {
    return items.map((prop, key) => {
      if (!isManager() && !isConsulting()) {
        prop.tests.forEach((test) => {
          if (test.keyUser !== userId() && test.opitionalKeyUser !== userId()) {
            prop.hiddenRows.push(test._id);
          }
        });
      }
      return (
        <ItemCard
          cicleTitle={this.state.cicleTitle}
          item={prop}
          hiddenItem={prop.hiddenItem}
          hiddenRows={prop.hiddenRows}
          lastInteractionDate={prop.lastInteractionDate}
          lastInteractionUserFullName={prop.lastInteractionUserFullName}
          planStatus={this.state.status}
          sequence={prop.sequence}
          process={prop.process}
          status={prop.status}
          statusList={this.state.statusList}
          conclusion={prop.conclusion}
          tests={prop.tests}
          openModalTest={this.openModalTest.bind(this)}
          openModalItem={this.openModalItem.bind(this)}
          openModalTestInteractions={this.openModalTestInteractions.bind(this)}
          openModalItemDocuments={this.openModalItemDocuments.bind(this)}
          isCustomer={
            this.state.isResourceCustomer || this.state.isManagerCustomer
          }
          isOwner={
            this.state.analyst ===
              localStorage.getItem(process.env.REACT_APP_USERID_KEY) ||
            this.state.isManagerConsulting
          }
          defaultOpen={prop.defaultOpen}
          key={key}
        />
      );
    });
  };
  async testSqlQuery(reload = true, test = false, newTest = null) {
    this.setState({ loading: true });
    const selectedTest = test
      ? "testQuery"
      : newTest
      ? newTest
      : this.state.selectedTest;

    let queryExists = null;
    if (this.state.projectServiceLayerUrl) {
      try {
        queryExists = await apiSap.get(
          this.state.projectServiceLayerUrl +
            `/SQLQueries('${selectedTest}')/List`
        );
      } catch (error) {
        queryExists = null;
      }
    }
    const queryBody = {
      SqlCode: selectedTest,
      SqlName: selectedTest,
      SqlText: this.state.sqlQuery,
    };
    try {
      if (!queryExists)
        await apiSap.post(
          this.state.projectServiceLayerUrl + `/SQLQueries`,
          queryBody
        );
      else
        await apiSap.patch(
          this.state.projectServiceLayerUrl + `/SQLQueries('${selectedTest}')`,
          queryBody
        );

      const queryResultResponse = await apiSap.get(
        this.state.projectServiceLayerUrl +
          `/SQLQueries('${selectedTest}')/List`
      );
      const queryResult = queryResultResponse.data?.value[0];
      if (!queryResult) return;
      console.log(typeof queryResult[Object.keys(queryResult)[0]]);
      if (typeof queryResult[Object.keys(queryResult)[0]] !== "number") {
        await apiSap.delete(
          this.state.projectServiceLayerUrl + `/SQLQueries('${selectedTest}')`
        );
        throw Error(
          "A query precisa retornar um número\nResultado: " +
            queryResult[Object.keys(queryResult)[0]]
        );
      } else {
        if (!test) {
          await api.put("/test/update/" + selectedTest, {
            sqlQuery: {
              sqlCode: selectedTest,
              sqlName: selectedTest,
              sqlText: this.state.sqlQuery,
            },
          });
          this.successAlert(
            "Query funciona e foi cadastrada!\n Resultado: " +
              queryResult[Object.keys(queryResult)[0]]
          );
          if (reload) await this.reloadTests(this.state.selectedItem);
        } else if (test) {
          await apiSap.delete(
            this.state.projectServiceLayerUrl + `/SQLQueries('${selectedTest}')`
          );
        }
      }
    } catch (error) {
      this.warningAlert(
        "Houve um erro no cadastro da query\n" + error?.message
      );
      await apiSap.delete(
        this.state.projectServiceLayerUrl + `/SQLQueries('${selectedTest}')`
      );
    } finally {
      this.setState({ loading: false });
    }
  }
  getStatusList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.value} key={key}>
          {prop.text}
        </option>
      );
    });
  };

  openModalPlan(e, crud) {
    var title = "";

    if (crud === "U") {
      title = "Alterando Plano";
    } else {
      title = "Excluindo Plano";
    }

    this.setState({
      crud: crud,
      readOnly: crud === "D",
      modalPlanBusinessArea: this.state.businessArea,
      modalPlanAnalyst: this.state.analyst,
      modalPlanKeyUser: this.state.keyUser,
      modalPlanManager: this.state.manager,
      planModalTitle: title,
      planModalOpened: true,
    });
  }

  closeModalPlan = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true });

      var newState = this.state;
      var letSave = true;
      var success = true;

      if (this.state.crud === "D") {
        //Delete interactions documents
        var response = await api.post("/interactionDocument/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put(
            "/interactionDocument/update/" + response?.data[i]._id,
            {
              deleted: "Y",
            }
          );
        }

        //Delete tests interactions
        response = await api.post("/interaction/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/interaction/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete tests documents
        response = await api.post("/testDocument/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/testDocument/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete tests
        response = await api.post("/test/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/test/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete items
        response = await api.post("/item/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/item/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete items documents
        response = await api.post("/itemDocument/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/itemDocument/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete cicles
        response = await api.post("/cicle/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/cicle/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete versions
        response = await api.post("/version/search", {
          plan: this.state.id,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/version/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete plan
        try {
          await api.put("/plan/update/" + this.state.id, {
            deleted: "Y",
          });
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          window.location.reload();
        }
      } else {
        //Field content validations
        if (newState.modalPlanBusinessArea === "") {
          newState.customStyles.modalPlanBusinessAreaState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.modalPlanBusinessAreaState = "valid";
        }

        if (newState.modalPlanAnalyst === "") {
          newState.customStyles.modalPlanAnalystState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.modalPlanAnalystState = "valid";
        }

        if (newState.modalPlanKeyUser === "") {
          newState.customStyles.modalPlanKeyUserState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.modalPlanKeyUserState = "valid";
        }

        if (newState.modalPlanManager === "") {
          newState.customStyles.modalPlanManagerState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.modalPlanManagerState = "valid";
        }

        this.setState({ customStyles: newState.customStyles });

        if (!letSave) {
          this.setState({ saving: false });
          return;
        }

        var modalPlanAnalystFullName = "";
        var modalPlanKeyUserFullName = "";
        var modalPlanManagerFullName = "";

        for (let i = 0; i < this.state.analystList.length; i++) {
          if (this.state.analystList[i].id === this.state.modalPlanAnalyst) {
            modalPlanAnalystFullName = this.state.analystList[i].text;
            break;
          }
        }

        for (let i = 0; i < this.state.keyUserList.length; i++) {
          if (this.state.keyUserList[i].id === this.state.modalPlanKeyUser) {
            modalPlanKeyUserFullName = this.state.keyUserList[i].text;
            break;
          }
        }

        for (let i = 0; i < this.state.managerList.length; i++) {
          if (this.state.managerList[i].id === this.state.modalPlanManager) {
            modalPlanManagerFullName = this.state.managerList[i].text;
            break;
          }
        }

        try {
          //Update plan
          await api.put("/plan/update/" + this.state.id, {
            businessArea: this.state.modalPlanBusinessArea,
            analyst: this.state.modalPlanAnalyst,
            analystFullName: modalPlanAnalystFullName,
            keyUser: this.state.modalPlanKeyUser,
            keyUserFullName: modalPlanKeyUserFullName,
            manager: this.state.modalPlanManager,
            managerFullName: modalPlanManagerFullName,
          });

          //Update versions
          response = await api.post("/version/search", {
            deleted: "N",
            plan: this.state.id,
          });

          const versions = response?.data;

          if (versions.length > 0) {
            for (let i = 0; i < versions.length; i++) {
              await api.put("/version/update/" + versions[i]._id, {
                planBusinessArea: this.state.modalPlanBusinessArea,
              });
            }
          }

          //Update cicles
          response = await api.post("/cicle/search", {
            deleted: "N",
            plan: this.state.id,
          });

          const cicles = response?.data;

          if (cicles.length > 0) {
            for (let i = 0; i < cicles.length; i++) {
              await api.put("/cicle/update/" + cicles[i]._id, {
                planBusinessArea: this.state.modalPlanBusinessArea,
              });
            }
          }
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar alterar. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          if (
            this.state.analyst !== this.state.modalPlanAnalyst ||
            this.state.keyUser !== this.state.modalPlanKeyUser ||
            this.state.manager !== this.state.modalPlanManager
          ) {
            this.confirmAlterUsersPlan(
              this.state.modalPlanAnalyst,
              modalPlanAnalystFullName,
              this.state.modalPlanKeyUser,
              modalPlanKeyUserFullName,
              this.state.modalPlanManager
            );
          } else {
            localStorage.setItem(
              process.env.REACT_APP_NEWREGID_KEY,
              this.state.id
            );
            localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "PL");
            window.location.reload();
          }
        }
      }
    }

    this.setState({
      crud: "",
      planModalOpened: false,
      modalPlanBusinessArea: null,
      modalPlanAnalyst: null,
      modalPlanKeyUser: null,
      modalPlanManager: null,
      saving: false,
      customStyles: {
        modalPlanBusinessAreaState: null,
        modalPlanAnalystState: null,
        modalPlanKeyUserState: null,
        modalPlanManagerState: null,
        itemSequenceState: null,
        itemProcessState: null,
        testSequenceState: null,
        testDescriptionState: null,
        testConditionState: null,
        nonconformityState: null,
        interactionStatusState: null,
        interactionCommentsState: null,
      },
    });
  };

  openModalItem = async (e, crud, item) => {
    var title = "";

    if (crud === "C") {
      title = "Novo Processo";
    } else {
      if (crud === "U") {
        title = "Alterando Processo";
      } else {
        title = "Excluindo Processo";
      }
    }

    this.setState({
      crud: crud,
      readOnly: crud === "D",
      itemModalTitle: title,
    });

    if (crud !== "C") {
      this.setState({
        itemSequence: item.sequence,
        itemProcess: item.process,
        selectedItem: item.id,
        selectedItemSequence: item.sequence,
        itemModalOpened: true,
      });
    } else {
      const response = await api.post("/item/maxSequence", {
        cicle: this.state.selectedCicle,
        deleted: "N",
      });

      var newSequence = "00";

      if (response?.data.length > 0) {
        newSequence = response?.data[0].sequence;
      }

      newSequence++;
      newSequence = ("0" + newSequence).slice(-2);

      this.setState({
        itemSequence: newSequence,
        itemModalOpened: true,
      });
    }
  };

  closeModalItem = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true });

      var newState = this.state;
      var letSave = true;
      var success = true;

      var response = {};

      if (this.state.crud === "D") {
        //Delete interactions documents
        response = await api.post("/interactionDocument/search", {
          item: this.state.selectedItem,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put(
            "/interactionDocument/update/" + response?.data[i]._id,
            {
              deleted: "Y",
            }
          );
        }

        //Delete tests interactions
        response = await api.post("/interaction/search", {
          item: this.state.selectedItem,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/interaction/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete tests documents
        response = await api.post("/testDocument/search", {
          item: this.state.selectedItem,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/testDocument/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete tests
        response = await api.post("/test/search", {
          item: this.state.selectedItem,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/test/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete item documents
        response = await api.post("/itemDocument/search", {
          item: this.state.selectedItem,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/itemDocument/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        try {
          await api.put("/item/update/" + this.state.selectedItem, {
            deleted: "Y",
          });
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          this.updateConclusions(
            true,
            this.state.selectedItem,
            this.state.selectedCicle
          );

          let newItems = this.state.items;

          for (let i = 0; i < newItems.length; i++) {
            if (newItems[i].id === this.state.selectedItem) {
              let deletedRow = newItems[i];

              newItems.splice(newItems.indexOf(deletedRow), 1);
            }
          }
        }
      } else {
        //Field content validations
        const validNumber =
          Number(newState.itemSequence) >= 1 &&
          Number(newState.itemSequence) <= 99;

        const newItemSequence = ("0" + this.state.itemSequence).slice(-2);

        if (newState.itemSequence === "" || !validNumber) {
          newState.customStyles.itemSequenceState = "invalid";
          letSave = false;
        } else {
          if (this.state.selectedItemSequence !== newItemSequence) {
            const existentItem = await api.post("/item/search", {
              plan: this.state.plan,
              cicle: this.state.cicle,
              sequence: newItemSequence,
              deleted: "N",
            });

            if (existentItem.data.length > 0) {
              newState.customStyles.itemSequenceState = "invalid";

              this.setState({
                customStyles: newState.customStyles,
                saving: false,
              });

              return;
            }
          } else {
            newState.customStyles.itemSequenceState = "valid";
          }
        }

        if (newState.itemProcess === "") {
          newState.customStyles.itemProcessState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.itemProcessState = "valid";
        }

        this.setState({ customStyles: newState.customStyles });

        if (!letSave) {
          this.setState({ saving: false });
          return;
        }

        if (this.state.crud === "C") {
          try {
            let response = await api.post("/item/create", {
              plan: this.state.id,
              version: this.state.selectedVersion,
              cicle: this.state.selectedCicle,
              sequence: newItemSequence,
              process: this.state.itemProcess,
              status: "P",
              conclusion: 0,
              hasDocs: false,
            });

            let newItems = this.state.items;
            let newItem = {
              id: response?.data._id,
              planCicle: this.state.cicle,
              cicle: this.state.cicle,
              sequence: response?.data.sequence,
              process: response?.data.process,
              status: response?.data.status,
              conclusion: Number(response?.data.conclusion).toFixed(0),
              tests: [],
              lastInteractionDate: "",
              lastInteractionUserFullName: "",
              defaultOpen: false,
              hiddenItem: false,
              hiddenRows: [],
              hasDocs: false,
            };

            newItems.push(newItem);

            this.setState({
              items: newItems,
            });
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar incluir. Tente novamente."
            );
            this.setState({ saving: false });
            success = false;
            return;
          }
        } else {
          try {
            await api.put("/item/update/" + this.state.selectedItem, {
              sequence: newItemSequence,
              process: this.state.itemProcess,
            });

            let newItems = this.state.items;

            for (let i = 0; i < newItems.length; i++) {
              if (newItems[i].id === this.state.selectedItem) {
                newItems[i].sequence = newItemSequence;
                newItems[i].process = this.state.itemProcess;

                if (this.state.selectedItemSequence !== newItemSequence) {
                  //Update tests sequence
                  let newTests = newItems[i].tests;

                  for (let j = 0; j < newTests.length; j++) {
                    let newTestSequence =
                      newItemSequence + "." + newTests[j].sequence.substring(3);

                    await api.put("/test/update/" + newTests[j]._id, {
                      sequence: newTestSequence,
                    });

                    newTests[j].sequence = newTestSequence;
                  }

                  newItems[i].tests = newTests;
                }
              }
            }
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            );
            success = false;
            this.setState({ saving: false });
            return;
          }
        }
      }
    }

    this.setState({
      itemModalOpened: false,
      itemModalTitle: "",
      itemSequence: "",
      itemProcess: "",
      saving: false,
      customStyles: {
        modalPlanBusinessAreaState: null,
        modalPlanAnalystState: null,
        modalPlanKeyUserState: null,
        modalPlanManagerState: null,
        itemSequenceState: null,
        itemProcessState: null,
        testSequenceState: null,
        testDescriptionState: null,
        testConditionState: null,
        nonconformityState: null,
        interactionStatusState: null,
        interactionCommentsState: null,
      },
    });
  };

  openModalTest = async (e, row, crud, item) => {
    let sl = localStorage.getItem("sl_session_id");
    if (!sl) {
      this.setState({ disableQuery: true });
    } else {
      this.setState({ disableQuery: false });
    }
    if (crud === "U") {
      if ((row.status === "CA" || row.status === "AP") && crud === "U") {
        this.warningAlert(
          "Não é possível alterar um teste aprovado ou cancelado."
        );
        return;
      }
    }

    var title = "";

    if (crud === "C") {
      title = "Novo Teste";
    } else {
      if (crud === "R") {
        title = "Visualizando Teste";
      } else {
        if (crud === "U") {
          title = "Alterando Teste";
        } else {
          title = "Excluindo Teste";
        }
      }
    }

    this.setState({
      crud: crud,
      readOnly: crud === "D" || crud === "R",
      selectedItem: item.id,
      selectedItemSequence: item.sequence,
      testModalTitle: title,
    });

    if (crud !== "C") {
      this.setState({
        selectedTest: row._id,
        selectedTestSequence: row.sequence,
        selectedTestSqlQuery: row.sqlQuery,
        testSequence: row.sequence,
        testStatus: row.status,
        testAnalyst: row.analyst,
        testKeyUser: row.keyUser,
        testOpitionalKeyUser: row.opitionalKeyUser,
        testDescription: row.testDescription,
        testCondition: row.testCondition,
        testComments: row.comments,
        sqlQuery: row.sqlQuery?.sqlText || "",
        baseNumber: row.baseNumber || "",
        testModalOpened: true,
      });
    } else {
      const response = await api.post("/test/maxSequence", {
        item: item.id,
        deleted: "N",
      });

      var itemSequence = "01";
      var newSequence = "00";

      if (response?.data.length > 0) {
        itemSequence = response?.data[0].sequence;
        newSequence = itemSequence.substring(3);
        itemSequence = itemSequence.substring(0, 2);
      } else {
        itemSequence = item.sequence;
      }

      newSequence++;
      newSequence = itemSequence + "." + ("0" + newSequence).slice(-2);

      this.setState({
        testSequence: newSequence,
        testStatus: "NS",
        testAnalyst: this.state.analyst,
        testKeyUser: this.state.keyUser,
        testOpitionalKeyUser: this.state.opitionalKeyUser,
        testModalOpened: true,
      });
    }
  };

  closeModalTest = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true });

      var newState = this.state;
      var newData = {};
      var letSave = true;
      var success = true;

      if (this.state.crud === "D") {
        //Delete interactions documents
        await apiSap
          .delete(
            this.state.projectServiceLayerUrl +
              `/SQLQueries('${this.state.selectedTest}')`
          )
          .catch(() => {});

        var response = await api.post("/interactionDocument/search", {
          test: this.state.selectedTest,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put(
            "/interactionDocument/update/" + response?.data[i]._id,
            {
              deleted: "Y",
            }
          );
        }

        //Delete test interactions
        response = await api.post("/interaction/search", {
          test: this.state.selectedTest,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/interaction/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        //Delete test documents
        response = await api.post("/testDocument/search", {
          test: this.state.selectedTest,
          deleted: "N",
        });

        for (let i = 0; i < response?.data.length; i++) {
          await api.put("/testDocument/update/" + response?.data[i]._id, {
            deleted: "Y",
          });
        }

        try {
          await api.put("/test/update/" + this.state.selectedTest, {
            deleted: "Y",
          });
        } catch (err) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          );
          success = false;
          this.setState({ saving: false });
          return;
        }

        if (success) {
          this.updateConclusions(
            true,
            this.state.selectedItem,
            this.state.selectedCicle
          );

          this.reloadTests(this.state.selectedItem);
        }
      } else {
        //Field content validations
        if (
          newState.testSequence === "" ||
          newState.testSequence.length !== 5
        ) {
          newState.customStyles.testSequenceState = "invalid";
          letSave = false;
        } else {
          let newSeqItem = this.state.testSequence.substring(0, 2);
          let newSeqTest = this.state.testSequence.substring(3);
          let spliter = this.state.testSequence[2];

          const validSeqTest =
            Number(newSeqTest) >= 1 && Number(newSeqTest) <= 99;

          if (
            newSeqItem !== this.state.selectedItemSequence ||
            !validSeqTest ||
            spliter !== "."
          ) {
            newState.customStyles.testSequenceState = "invalid";
            letSave = false;
          } else {
            if (this.state.selectedTestSequence !== this.state.testSequence) {
              const existentTest = await api.post("/test/search", {
                item: this.state.selectedItem,
                cicle: this.state.cicle,
                sequence: this.state.testSequence,
                deleted: "N",
              });

              if (existentTest.data.length > 0) {
                newState.customStyles.testSequenceState = "invalid";
                letSave = false;
              } else {
                newState.customStyles.testSequenceState = "valid";
              }
            } else {
              newState.customStyles.testSequenceState = "valid";
            }
          }
        }

        if (newState.testDescription === "") {
          newState.customStyles.testDescriptionState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.testDescriptionState = "valid";
        }

        if (newState.testAnalyst === "") {
          newState.customStyles.testAnalystState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.testAnalystState = "valid";
        }

        if (newState.testKeyUser === "") {
          newState.customStyles.testKeyUserState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.testKeyUserState = "valid";
        }

        if (newState.testCondition === "") {
          newState.customStyles.testConditionState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.testConditionState = "valid";
        }

        this.setState({
          customStyles: newState.customStyles,
        });

        if (!letSave) {
          this.setState({ saving: false });
          return;
        }

        var testAnalystFullName = "";
        var testKeyUserFullName = "";
        var testOpitionalKeyUserFullName = "";

        if (this.state.crud === "U") {
          if (this.state.sqlQuery) {
            try {
              await this.testSqlQuery(false);
            } catch (error) {
              this.setState({ saving: false });
              return;
            }
          }
          for (let i = 0; i < this.state.analystList.length; i++) {
            if (this.state.analystList[i].id === this.state.testAnalyst) {
              testAnalystFullName = this.state.analystList[i].text;
              break;
            }
          }

          for (let i = 0; i < this.state.keyUserList.length; i++) {
            if (this.state.keyUserList[i].id === this.state.testKeyUser) {
              testKeyUserFullName = this.state.keyUserList[i].text;
              break;
            }
          }

          for (let i = 0; i < this.state.keyUserList.length; i++) {
            if (
              this.state.keyUserList[i].id === this.state.testOpitionalKeyUser
            ) {
              testOpitionalKeyUserFullName = this.state.keyUserList[i].text;
              break;
            }
          }
          newData = {
            sequence: this.state.testSequence,
            testCondition: this.state.testCondition,
            testDescription: this.state.testDescription,
            status: this.state.testStatus,
            manager: this.state.manager,
            analyst: this.state.testAnalyst,
            analystFullName: testAnalystFullName,
            keyUser: this.state.testKeyUser,
            keyUserFullName: testKeyUserFullName,
            opitionalKeyUser: this.state.testOpitionalKeyUser,
            opitionalKeyUserFullName: testOpitionalKeyUserFullName,
            baseNumber: this.state.baseNumber,
            sqlQuery: {
              sqlCode: this.state.selectedTest,
              sqlName: this.state.selectedTest,
              sqlText: this.state.sqlQuery,
            },
            comments: this.state.testComments,
          };

          try {
            await api.put("/test/update/" + this.state.selectedTest, newData);
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            );
            success = false;
            this.setState({ saving: false });
            return;
          }

          if (success) {
            this.reloadTests(this.state.selectedItem);
          }
        } else {
          for (let i = 0; i < this.state.analystList.length; i++) {
            if (this.state.analystList[i].id === this.state.testAnalyst) {
              testAnalystFullName = this.state.analystList[i].text;
              break;
            }
          }

          for (let i = 0; i < this.state.keyUserList.length; i++) {
            if (this.state.keyUserList[i].id === this.state.testKeyUser) {
              testKeyUserFullName = this.state.keyUserList[i].text;
              break;
            }
          }
          for (let i = 0; i < this.state.keyUserList.length; i++) {
            if (
              this.state.keyUserList[i].id === this.state.testOpitionalKeyUser
            ) {
              testOpitionalKeyUserFullName = this.state.keyUserList[i].text;
              break;
            }
          }

          newData = {
            customer: this.state.customer,
            project: this.state.project,
            plan: this.state.id,
            version: this.state.selectedVersion,
            cicle: this.state.selectedCicle,
            item: this.state.selectedItem,
            sequence: this.state.testSequence,
            testCondition: this.state.testCondition,
            testDescription: this.state.testDescription,
            status: this.state.testStatus,
            manager: this.state.manager,
            analyst: this.state.testAnalyst,
            analystFullName: testAnalystFullName,
            keyUser: this.state.testKeyUser,
            keyUserFullName: testKeyUserFullName,
            opitionalKeyUser: this.state.testOpitionalKeyUser,
            opitionalKeyUserFullName: testOpitionalKeyUserFullName,
            comments: this.state.testComments,
          };

          try {
            if (this.state.sqlQuery) {
              await this.testSqlQuery(false, true);
            }

            let test = await api.post("/test/create", newData);
            if (this.state.sqlQuery) {
              await this.testSqlQuery(false, false, test.data._id);
            }
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar incluir. Tente novamente."
            );
            success = false;
            this.setState({ saving: false });
            return;
          }

          if (success) {
            this.updateConclusions(
              true,
              this.state.selectedItem,
              this.state.selectedCicle
            );

            this.setState({ newTestIncluded: true });

            //Success message
            this.notify("success");

            //Get next sequence
            var itemSequence = this.state.testSequence;
            var newSequence = itemSequence.substring(3);

            itemSequence = itemSequence.substring(0, 2);

            newSequence++;
            newSequence = itemSequence + "." + ("0" + newSequence).slice(-2);

            //New test
            this.setState({
              testSequence: newSequence,
              testDescription: "",
              testStatus: "NS",
              testAnalyst: this.state.analyst,
              testKeyUser: this.state.keyUser,
              testOpitionalKeyUser: this.state.opitionalKeyUser,
              testCondition: "",
              testComments: "",
              saving: false,

              customStyles: {
                testDescriptionState: null,
                testAnalystState: null,
                testKeyUserState: null,
                testConditionState: null,
              },
            });

            return;
          }
        }
      }
    } else {
      if (this.state.newTestIncluded) {
        this.reloadTests(this.state.selectedItem);
      }
    }

    this.setState({
      selectedItem: "",
      selectedItemSequence: "",
      selectedTestSequence: "",
      selectedTestSqlQuery: {},
      selectedTest: "",
      testModalOpened: false,
      testModalTitle: "",
      testSequence: "",
      testDescription: "",
      testStatus: "",
      testAnalyst: "",
      testKeyUser: "",
      testOpitionalKeyUser: "",
      testCondition: "",
      testComments: "",
      crud: "",
      readOnly: false,
      saving: false,
      newTestIncluded: false,
      baseNumber: "this.state.baseNumber",
      sqlQuery: {},

      customStyles: {
        modalPlanBusinessAreaState: null,
        modalPlanAnalystState: null,
        modalPlanKeyUserState: null,
        modalPlanManagerState: null,
        itemSequenceState: null,
        itemProcessState: null,
        testDescriptionState: null,
        testAnalystState: null,
        testKeyUserState: null,
        testConditionState: null,
        nonconformityState: null,
        interactionStatusState: null,
        interactionCommentsState: null,
      },
    });
  };

  reloadTests = async (selectedItem) => {
    var newItems = this.state.items;

    var testsList = [];
    var test = {};

    let filters = {
      item: selectedItem,
      deleted: "N",
    };

    if (
      !this.state.isManagerConsulting &&
      !this.state.isManagerCustomer &&
      this.state.analyst !==
        localStorage.getItem(process.env.REACT_APP_USERID_KEY)
    ) {
      if (this.state.isResourceConsulting) {
        filters = {
          ...filters,
          analyst: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
        };
      } else {
        filters = {
          ...filters,
          keyUser: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
        };
      }
    }

    var tests = await api.post("/test/search", filters);

    for (let j = 0; j < tests.data.length; j++) {
      test = {
        _id: tests.data[j]._id,
        sequence: tests.data[j].sequence,
        testCondition: tests.data[j].testCondition,
        testDescription: tests.data[j].testDescription,
        status: tests.data[j].status,
        nonconformity: tests.data[j].nonconformity,
        analyst: tests.data[j].analyst,
        analystFullName: tests.data[j].analystFullName,
        keyUser: tests.data[j].keyUser,
        keyUserFullName: tests.data[j].keyUserFullName,
        opitionalKeyUser: tests.data[j].opotionalKeyUser,
        opitionalKeyUserFullName: tests.data[j].opitionalKeyUserFullName,
        comments: tests.data[j].comments,
        hasDocs: tests.data[j].hasDocs,
        hasInteractions: tests.data[j].hasInteractions,
        sqlQuery: tests.data[j].sqlQuery,
        baseNumber: tests.data[j].baseNumber,
      };

      if (tests.data[j].sqlQuery?.sqlCode) {
        try {
          let query = await apiSap.get(
            this.state.projectServiceLayerUrl +
              `/SQLQueries('${tests.data[j].sqlQuery.sqlCode}')/List`
          );

          const queryResult = query?.data?.value[0] || null;
          if (queryResult) {
            test.queryResult = queryResult[Object.keys(queryResult)[0]];
          } else {
            test.queryResult = null;
          }
        } catch (error) {
          if (
            error.message ===
            "Algum item não foi encontrado, verifique sua existência e tente novamente"
          )
            await api.put("test/update/" + tests.data[j], { sqlQuery: null });
          test.queryResult = null;
          console.error(error);
        }
      }

      if (testsList.length === 0) {
        testsList = [test];
      } else {
        testsList.push(test);
      }
    }

    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].id === selectedItem) {
        newItems[i].tests = testsList;
      }
    }

    this.setState({ items: newItems });
  };

  notify = (type) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Sucesso!
          </span>
          <span data-notify="message">O teste foi salvo com sucesso.</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 5,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  stylesForm = async (e, stateName) => {
    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    if (stateName === "sqlQuery" && e.target.value === "")
      this.setState({
        baseNumber: "",
        customStyles: newState,
      });

    if (stateName === "baseNumber" && parseFloat(e.target.value) < 0) {
      this.setState({
        baseNumber: e.target.value * -1,
      });
      return;
    }

    if (stateName === "baseNumber" && parseFloat(e.target.value) === 0) {
      this.setState({
        baseNumber: 1,
      });
      return;
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    });

    if (stateName === "interactionStatus") {
      if (e.target.value !== "NC" && e.target.value !== "NK") {
        this.setState({
          nonconformity: "",
        });
      }
    }

    if (stateName === "documentKind") {
      const kind = e.target.value;

      if (kind === "V") {
        this.setState({ testDocumentFileTypes: ".mp4" });
      } else {
        if (kind === "I") {
          this.setState({
            testDocumentFileTypes:
              ".xbm,.tif,.pjp,.svgz,.pjpeg,.gif,.png,.jpg,.jpeg",
          });
        } else {
          this.setState({ testDocumentFileTypes: "" });
        }
      }
    }
  };

  openModalTestInteractions = async (e, row, item, tab) => {
    if (e !== null) {
      e.preventDefault();
      this.setState({ isLoading: true });
    }

    var response = await api.post("/interaction/fullSearch", {
      test: row._id,
      deleted: "N",
    });

    var interactions = [];
    var interaction = {};
    var nonconformity = "";
    var nonconformityName = "";
    var interactionDocs = [];

    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].nonconformity) {
        nonconformity = response?.data[i].nonconformity._id;
        nonconformityName = response?.data[i].nonconformity.name;
      } else {
        nonconformity = "";
        nonconformityName = "";
      }

      interactionDocs = await api.post("/interactionDocument/search", {
        interaction: response?.data[i]._id,
        deleted: "N",
      });

      interaction = {
        _id: response?.data[i]._id,
        date: Moment(response?.data[i].createdAt).format("D MMM YYYY"),
        time: Moment(response?.data[i].createdAt).format("HH:mm"),
        userFullName: response?.data[i].userFullName,
        comments: response?.data[i].comments,
        status: response?.data[i].status,
        nonconformity: nonconformity,
        nonconformityName: nonconformityName,
        hasDocs: interactionDocs.data.length > 0,
      };

      if (interactions.length === 0) {
        interactions = [interaction];
      } else {
        interactions.push(interaction);
      }
    }

    response = await api.post("/testDocument/fullSearch", {
      test: row._id,
      deleted: "N",
    });

    var documents = [];
    var document = {};
    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].kind === "U") {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i]?.description,
          kind: response?.data[i].kind,
          document: null,
          name: "URL",
          url: response?.data[i].url,
        };
      } else {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i].document?.description,
          kind: response?.data[i].kind,
          document: response?.data[i].document._id,
          name: response?.data[i].document.name,
          url: response?.data[i].document.url,
        };
      }

      if (documents.length === 0) {
        documents = [document];
      } else {
        documents.push(document);
      }
    }

    var canInteract = false;

    // TODO: remember to investigate this
    // if (
    //   row.status !== "AP" &&
    //   row.status !== "CA" &&
    //   this.state.cicle === this.state.selectedCicle
    // ) {
    //   canInteract = true
    // }
    if (row.status !== "AP" && row.status !== "CA") {
      canInteract = true;
    }
    console.log(this.state.cicle, this.state.selectedCicle);
    this.setState({
      testInteractionsModalOpened: true,
      selectedTest: row._id,
      selectedItem: item.id,
      selectedItemSequence: item.sequence,
      selectedTestStatus: row.status,
      selectedTestQueryResult: row.queryResult,
      selectedTestBaseNumber: row.baseNumber,
      selectedTestNonconformity: row.nonconformity,
      selectedTestAnalyst: row.analyst,
      selectedTestKeyUser: row.keyUser,
      testInteractionsModalTitle:
        this.state.businessArea +
        " > " +
        item.process +
        " > " +
        row.testDescription,
      testInteractionTestCondition: row.testCondition,
      testInteractionsData: interactions,
      testDocumentsData: documents,
      interactionsActiveTab: tab,
      canInteract: canInteract,
      testComments: row.comments,
    });

    if (e !== null) {
      this.setState({ isLoading: false });
    }
  };

  reloadInteractions = async (selectedItem) => {
    const response = await api.post("/interaction/fullSearch", {
      test: this.state.selectedTest,
      deleted: "N",
    });

    var interactions = [];
    var interaction = {};
    var nonconformity = "";
    var nonconformityName = "";

    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].nonconformity) {
        nonconformity = response?.data[i].nonconformity._id;
        nonconformityName = response?.data[i].nonconformity.name;
      } else {
        nonconformity = "";
        nonconformityName = "";
      }

      let interactionDocs = await api.post("/interactionDocument/search", {
        interaction: response?.data[i]._id,
        deleted: "N",
      });

      interaction = {
        _id: response?.data[i]._id,
        date: Moment(response?.data[i].createdAt).format("D MMM YYYY"),
        time: Moment(response?.data[i].createdAt).format("HH:mm"),
        userFullName: response?.data[i].userFullName,
        comments: response?.data[i].comments,
        status: response?.data[i].status,
        nonconformity: nonconformity,
        nonconformityName: nonconformityName,
        hasDocs: interactionDocs.data.length > 0,
      };

      if (interactions.length === 0) {
        interactions = [interaction];
      } else {
        interactions.push(interaction);
      }
    }

    this.setState({
      selectedTestStatus: this.state.interactionStatus,
      selectedTestNonconformity: this.state.nonconformity,
      testInteractionsData: interactions,
    });

    this.reloadTests(selectedItem);
  };

  reloadDocuments = async () => {
    var response;

    if (this.state.documentOf === "test") {
      response = await api.post("/testDocument/fullSearch", {
        test: this.state.selectedTest,
        deleted: "N",
      });
    } else {
      response = await api.post("/itemDocument/fullSearch", {
        item: this.state.selectedItem,
        deleted: "N",
      });
    }

    var documents = [];
    var document = {};

    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].kind === "U") {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i]?.description,
          name: "URL",
          kind: response?.data[i].kind,
          document: null,
          url: response?.data[i].url,
        };
      } else {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i].document?.description,
          name: response?.data[i].document.name,
          kind: response?.data[i].kind,
          document: response?.data[i].document._id,
          url: response?.data[i].document.url,
        };
      }

      if (documents.length === 0) {
        documents = [document];
      } else {
        documents.push(document);
      }
    }

    if (this.state.documentOf === "test") {
      this.setState({
        testDocumentsData: documents,
      });
    } else {
      this.setState({
        itemDocumentsData: documents,
      });
    }
  };

  closeModalTestInteractions(e) {
    e.preventDefault();
    this.setState({ testInteractionsModalOpened: false });
  }

  interactionsToggleNavs = (e, tab) => {
    e.preventDefault();
    this.setState({
      interactionsActiveTab: tab,
    });
  };

  openModalInteraction = async (e, row, crud) => {
    if (e !== null) {
      e.preventDefault();
      this.setState({ isLoading: true });
    }

    if (crud === "R") {
      const response = await api.post("/interactionDocument/fullSearch", {
        interaction: row._id,
        deleted: "N",
      });

      var document = null;
      var documents = [];

      for (let i = 0; i < response?.data.length; i++) {
        document = {
          _id: response?.data[i].document._id,
          url: response?.data[i].document.url,
          name: response?.data[i].document.name,
        };

        if (documents.length === 0) {
          documents = [document];
        } else {
          documents.push(document);
        }
      }

      this.setState({
        isLoading: false,
        crud: crud,
        interactionDocumentData: documents,
        interactionModalTitle: "Visualizando Interação",
        interactionStatus: row.status,
        nonconformity: row.nonconformity,
        interactionComments: row.comments,
        readOnly: true,
        interactionModalOpened: true,
        possibleStatusList: this.state.statusList,
      });
    } else {
      let canChangeInteractionStatus = true;
      let possibleStatusList = this.state.statusList;
      let newInteractionStatus = "";

      if (
        this.state.selectedTestStatus === "IP" ||
        this.state.selectedTestStatus === "NS"
      ) {
        possibleStatusList = [
          statusEmpty,
          statusIP,
          statusAP,
          statusNC,
          statusNK,
        ];
        if (isConsulting()) {
          possibleStatusList.push(statusCA);
        }

        //newInteractionStatus = "IP";
      } else {
        if (this.state.selectedTestStatus === "NC") {
          possibleStatusList = [statusEmpty, statusAP, statusNC, statusNK];
          if (isConsulting()) {
            possibleStatusList.push(statusCA);
          }

          //newInteractionStatus = "NK";

          if (
            (this.state.isManagerConsulting ||
              this.state.isResourceConsulting) &&
            this.state.cicleTitle !== "A"
          ) {
            possibleStatusList = [statusEmpty, statusNC, statusNK, statusNF];

            newInteractionStatus = "NC";
          }
        } else {
          if (this.state.selectedTestStatus === "NK") {
            possibleStatusList = [statusEmpty, statusAP, statusNC, statusNK];
            if (isConsulting()) {
              possibleStatusList.push(statusCA);
            }

            if (
              (this.state.isManagerConsulting ||
                this.state.isResourceConsulting) &&
              this.state.cicleTitle !== "A"
            ) {
              possibleStatusList = [statusEmpty, statusNC, statusNK, statusNF];
            }

            newInteractionStatus = "NK";
          } else {
            if (this.state.selectedTestStatus === "NF") {
              possibleStatusList = [statusEmpty, statusAP, statusNC, statusNK];
              if (isConsulting()) {
                possibleStatusList.push(statusCA);
              }

              //newInteractionStatus = "NK";
            }
          }
        }
      }

      if (
        this.state.selectedTestStatus === "NC" ||
        this.state.selectedTestStatus === "NK"
      ) {
        if (
          this.state.selectedTestNonconformity === "" ||
          this.state.selectedTestNonconformity === null
        ) {
          if (
            this.state.isManagerConsulting ||
            this.state.isResourceConsulting
          ) {
            canChangeInteractionStatus = false;

            this.warningAlert(
              "Utilize essa interação para classificar a não conformidade."
            );
          }
        }
      }

      this.setState({
        crud: crud,
        interactionModalTitle: "Nova Interação",
        interactionStatus: newInteractionStatus,
        readOnly: false,
        interactionModalOpened: true,
        canChangeInteractionStatus: canChangeInteractionStatus,
        possibleStatusList: possibleStatusList,
      });

      if (e !== null) {
        this.setState({ isLoading: false });
      }
    }
  };

  closeModalInteraction = async (e, action) => {
    e.preventDefault();

    var newState = this.state;
    var letSave = true;
    var success = true;

    if (action === "save") {
      this.setState({ saving: true, isLoading: true });

      if (newState.interactionStatus === "") {
        newState.customStyles.interactionStatusState = "invalid";
        letSave = false;
      } else {
        newState.customStyles.interactionStatusState = "valid";
      }

      if (this.state.isManagerConsulting || this.state.isResourceConsulting) {
        if (
          (newState.interactionStatus === "NC" ||
            newState.interactionStatus === "NK") &&
          newState.nonconformity === ""
        ) {
          newState.customStyles.nonconformityState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.nonconformityState = "valid";
        }
      }
      if (newState.interactionStatus === "AP" && newState.cicleTitle === "B") {
        let validateQuery =
          newState.selectedTestQueryResult ===
            newState.selectedTestBaseNumber ||
          (!newState.selectedTestQueryResult &&
            newState.selectedTestBaseNumber === 0);
        if (!newState.selectedTestBaseNumber) validateQuery = true;

        if (!validateQuery) {
          this.warningAlert(
            `O número mínimo de testes para aprovação é ${newState.selectedTestBaseNumber} e foram executados apenas ${newState.selectedTestQueryResult}.`
          );
          this.setState({ saving: false, isLoading: false });
          return;
        }
      }

      if (newState.interactionComments === "") {
        newState.customStyles.interactionCommentsState = "invalid";
        letSave = false;
      } else {
        newState.customStyles.interactionCommentsState = "valid";
      }

      this.setState({
        customStyles: newState.customStyles,
      });

      if (!letSave) {
        this.setState({ saving: false, isLoading: false });
        return;
      }

      var nonconformity = null;

      if (
        this.state.nonconformity !== null &&
        this.state.nonconformity !== ""
      ) {
        nonconformity = this.state.nonconformity;
      }

      var newData = {
        plan: this.state.id,
        version: this.state.selectedVersion,
        cicle: this.state.selectedCicle,
        item: this.state.selectedItem,
        test: this.state.selectedTest,
        user: localStorage.getItem(process.env.REACT_APP_USERID_KEY),
        userFullName: this.state.currentUserFullName,
        status: this.state.interactionStatus,
        nonconformity: nonconformity,
        comments: this.state.interactionComments,
        customer: this.state.customer,
        project: this.state.project,
        manager: this.state.manager,
        analyst: this.state.selectedTestAnalyst,
        keyUser: this.state.selectedTestKeyUser,
        showOnFeed: "Y",
      };

      var newInteraction = {};

      try {
        newInteraction = await api.post("/interaction/create", newData);

        await api.put("/test/updateHasInteractions/" + this.state.selectedTest);

        this.reloadTests(this.state.selectedItem);
      } catch (err) {
        this.warningAlert(
          "Ocorreu um erro ao tentar incluir. Tente novamente."
        );
        success = false;
        this.setState({ saving: false, isLoading: false });
        return;
      }

      if (success) {
        var canInteract = false;

        if (
          this.state.interactionStatus !== "AP" &&
          this.state.interactionStatus !== "CA"
        ) {
          if (
            this.state.isManagerConsulting ||
            this.state.isResourceConsulting
          ) {
            if (this.state.cicleTitle === "A") {
              canInteract = true;
            } else {
              canInteract =
                this.state.interactionStatus === "NC" ||
                this.state.interactionStatus === "NK";
            }
          } else {
            canInteract = true;
          }
        }

        this.setState({
          _canInteract: canInteract,
          get canInteract() {
            return this._canInteract;
          },
          set canInteract(value) {
            this._canInteract = value;
          },
        });

        const newFiles = document.getElementById("newFiles");

        var uploadedFile;
        var fileData;

        if (newFiles !== null) {
          if (newFiles.uploadfileslist) {
            if (newFiles.uploadfileslist.length > 0) {
              for (let i = 0; i < newFiles.uploadfileslist.length; i++) {
                uploadedFile = newFiles.uploadfileslist[i];

                if (uploadedFile.upload) {
                  fileData = new FormData();

                  fileData.append("file", uploadedFile.file, uploadedFile.name);

                  await api
                    .post("/document/create/", fileData)
                    .then(async (response) => {
                      await api.post("/interactionDocument/create", {
                        plan: this.state.id,
                        version: this.state.selectedVersion,
                        cicle: this.state.selectedCicle,
                        item: this.state.selectedItem,
                        test: this.state.selectedTest,
                        interaction: newInteraction.data._id,
                        document: response?.data._id,
                      });
                    })
                    .catch(() => {
                      this.warningAlert("Erro ao anexar arquivo");
                    });
                }
              }
            }
          }
        }

        await api.put("/test/update/" + this.state.selectedTest, {
          status: this.state.interactionStatus,
          nonconformity: nonconformity,
        });

        this.reloadInteractions(this.state.selectedItem);
        this.updateConclusions(
          true,
          this.state.selectedItem,
          this.state.selectedCicle
        );
      }
    }

    this.setState({
      interactionModalTitle: "",
      interactionComments: "",
      interactionModalOpened: false,
      saving: false,
      isLoading: false,
      customStyles: {
        modalPlanBusinessAreaState: null,
        modalPlanAnalystState: null,
        modalPlanKeyUserState: null,
        modalPlanManagerState: null,
        itemSequenceState: null,
        itemProcessState: null,
        testDescriptionState: null,
        testAnalystState: null,
        testKeyUserState: null,
        testConditionState: null,
        nonconformityState: null,
        interactionStatusState: null,
        interactionCommentsState: null,
      },
    });
  };

  async openModalDocument(e, row, crud, documentOf) {
    e.preventDefault();

    var title;

    if (crud === "C") {
      title = "Novo Documento";
    } else if (crud === "U") {
      title = "Alterando Documento";
      const itemDocumentResponse = await api.post("/itemDocument/search", {
        deleted: "N",
        url: row.url,
      });
      const testDocumentResponse = await api.post("/testDocument/search", {
        deleted: "N",
        url: row.url,
      });
      this.setState({
        affectedItemDocuments: itemDocumentResponse.data,
        affectedTestDocuments: testDocumentResponse.data,
      });
    } else {
      title = "Excluindo Documento";
    }

    if (crud === "D" || crud === "U") {
      this.setState({
        documentOfId: row._id,
        documentId: row.document,
        documentDescription: row?.description,
        documentKind: row.kind,
        documentURL: row.url,
        documentOldUrl: row.url,
      });
    } else {
      this.setState({
        documentKind: "U",
        documentURL: "",
        documentOldUrl: "",
      });
    }

    this.setState({
      documentModalTitle: title,
      crud: crud,
      readOnly: crud === "D",
      documentModalOpened: true,
      documentOf: documentOf,
    });
  }

  documentFileChange(e) {
    var file = null;

    if (e.target.files.length > 0) {
      file = e.target.files[0];
    }

    this.setState({
      documentFile: file,
    });
  }

  closeModalDocument = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true, isLoading: true });

      var success = true;
      var letSave = true;
      var newState = this.state;
      if (this.state.crud === "U") {
        try {
          await api.patch("/testDocument/updateAllUrl", {
            oldUrl: this.state.documentOldUrl,
            url: this.state.documentURL,
          });
          await api.patch("/itemDocument/updateAllUrl", {
            oldUrl: this.state.documentOldUrl,
            url: this.state.documentURL,
          });
        } catch (error) {
          this.warningAlert(
            "Ocorreu um erro ao tentar Alterar. Tente novamente."
          );
          success = false;
        } finally {
          this.setState({ saving: false, isLoading: false });
        }
      } else if (this.state.crud === "D") {
        if (this.state.documentKind === "U") {
          try {
            if (this.state.documentOf === "test") {
              await api.put("/testDocument/update/" + this.state.documentOfId, {
                deleted: "Y",
              });

              await api.put("/test/updateHasDocs/" + this.state.selectedTest);

              this.reloadTests(this.state.selectedItem);
            } else {
              await api.put("/itemDocument/update/" + this.state.documentOfId, {
                deleted: "Y",
              });

              api.put("/item/updateHasDocs/" + this.state.selectedItem);
            }
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar excluir. Tente novamente."
            );
            success = false;
            this.setState({ saving: false, isLoading: false });
            return;
          }
        } else {
          try {
            if (this.state.documentOf === "test") {
              await api.delete(
                "/testDocument/delete/" + this.state.documentOfId
              );

              await api.put("/test/updateHasDocs/" + this.state.selectedTest);

              this.reloadTests(this.state.selectedItem);
            } else {
              await api.delete(
                "/itemDocument/delete/" + this.state.documentOfId
              );

              api.put("/item/updateHasDocs/" + this.state.selectedItem);
            }

            await api.delete("/document/delete/" + this.state.documentId);
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar excluir. Tente novamente."
            );
            success = false;
            this.setState({ saving: false, isLoading: false });
            return;
          }
        }

        if (success) {
          this.reloadDocuments();

          if (this.state.documentOf === "test") {
            this.reloadTests(this.state.selectedItem);
          }
        }
      } else {
        if (newState.documentDescription === "") {
          newState.customStyles.documentDescriptionState = "invalid";
          letSave = false;
        } else {
          newState.customStyles.documentDescriptionState = "valid";
        }

        if (this.state.documentKind === "U") {
          if (newState.documentURL === "") {
            newState.customStyles.documentURLState = "invalid";
            letSave = false;
          } else {
            newState.customStyles.documentURLState = "valid";
          }
        } else {
          if (!this.state.documentFile || this.state.documentFile === null) {
            this.warningAlert("Selecione um arquivo");
            letSave = false;
          }
        }

        this.setState({
          customStyles: newState.customStyles,
        });

        if (!letSave) {
          this.setState({ saving: false, isLoading: false });
          return;
        }

        if (this.state.documentKind === "U") {
          try {
            if (this.state.documentOf === "test") {
              await api.post("/testDocument/create", {
                plan: this.state.id,
                version: this.state.selectedVersion,
                cicle: this.state.selectedCicle,
                item: this.state.selectedItem,
                test: this.state.selectedTest,
                kind: this.state.documentKind,
                url: this.state.documentURL,
                description: this.state.documentDescription,
                document: null,
              });

              await api.put("/test/updateHasDocs/" + this.state.selectedTest);

              this.reloadTests(this.state.selectedItem);
            } else {
              await api.post("/itemDocument/create", {
                plan: this.state.id,
                version: this.state.selectedVersion,
                cicle: this.state.selectedCicle,
                item: this.state.selectedItem,
                kind: this.state.documentKind,
                url: this.state.documentURL,
                description: this.state.documentDescription,
                document: null,
              });

              api.put("/item/updateHasDocs/" + this.state.selectedItem);
            }
          } catch (err) {
            this.warningAlert("Erro ao criar documento");
            this.setState({ saving: false, isLoading: false });
            return;
          }
        } else {
          const file = this.state.documentFile;

          const uploadedFile = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            upload: true,
            url: null,
          };

          var fileData = new FormData();

          fileData.append("file", uploadedFile.file, uploadedFile.name);

          await api
            .post("/document/create/", fileData)
            .then(async (response) => {
              await api.put("/document/update/" + response?.data._id, {
                description: this.state.documentDescription,
              });

              if (this.state.documentOf === "test") {
                await api.post("/testDocument/create", {
                  plan: this.state.id,
                  version: this.state.selectedVersion,
                  cicle: this.state.selectedCicle,
                  item: this.state.selectedItem,
                  test: this.state.selectedTest,
                  kind: this.state.documentKind,
                  document: response?.data._id,
                });

                await api.put("/test/updateHasDocs/" + this.state.selectedTest);

                this.reloadTests(this.state.selectedItem);
              } else {
                await api.post("/itemDocument/create", {
                  plan: this.state.id,
                  version: this.state.selectedVersion,
                  cicle: this.state.selectedCicle,
                  item: this.state.selectedItem,
                  kind: this.state.documentKind,
                  document: response?.data._id,
                });

                api.put("/item/updateHasDocs/" + this.state.selectedItem);
              }
            })
            .catch(() => {
              this.warningAlert("Erro ao anexar arquivo");
              this.setState({ saving: false, isLoading: false });
              return;
            });
        }
      }

      if (success) {
        this.reloadDocuments();
        if (this.state.documentOf === "test") {
          this.reloadTests(this.state.selectedItem);
        }
      }

      this.setState({
        documentModalOpened: false,
        affectedItemDocuments: [],
        affectedTestDocuments: [],
        crud: "",
        readOnly: true,
        documentModalTitle: "",
        documentId: "",
        documentOfId: "",
        documentDescription: "",
        saving: false,
        isLoading: false,
        customStyles: {
          documentDescriptionState: null,
        },
      });
    }

    this.setState({
      crud: "",
      documentModalTitle: "",
      readOnly: true,
      documentModalOpened: false,

      customStyles: {
        modalPlanBusinessAreaState: null,
        modalPlanAnalystState: null,
        modalPlanKeyUserState: null,
        modalPlanManagerState: null,
        itemSequenceState: null,
        itemProcessState: null,
        testDescriptionState: null,
        testAnalystState: null,
        testKeyUserState: null,
        testConditionState: null,
        nonconformityState: null,
        interactionStatusState: null,
        interactionCommentsState: null,
        documentDescriptionState: null,
      },
    });
  };
  successAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Sucesso!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="success"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    });
  };
  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    });
  };

  confirmAlterUsersPlan(
    analyst,
    analystFullName,
    keyUser,
    keyUserFullName,
    manager
  ) {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Responsáveis"
          onConfirm={() =>
            this.confirmedAlterUsersPlan(
              "all",
              analyst,
              analystFullName,
              keyUser,
              keyUserFullName,
              manager
            )
          }
          onCancel={() =>
            this.confirmedAlterUsersPlan(
              "",
              analyst,
              analystFullName,
              keyUser,
              keyUserFullName,
              manager
            )
          }
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Todos" //Cancelar
          cancelBtnBsStyle="success"
          cancelBtnText="Somente iguais aos do plano"
          btnSize=""
        >
          Escolha uma forma de atualizar os responsáveis pelos testes.
        </ReactBSAlert>
      ),
    });
  }

  confirmedAlterUsersPlan = async (
    who,
    analyst,
    analystFullName,
    keyUser,
    keyUserFullName,
    manager
  ) => {
    this.hideAlert();

    this.setState({ isLoading: true });

    var success = true;

    const response = await api.post("/test/search", {
      plan: this.state.id,
      version: this.state.version,
      cicle: this.state.cicle,
      deleted: "N",
    });

    const tests = response?.data;

    for (let i = 0; i < tests.length; i++) {
      if (who === "all") {
        await api.put("/test/update/" + tests[i]._id, {
          analyst: analyst,
          analystFullName: analystFullName,
          keyUser: keyUser,
          keyUserFullName: keyUserFullName,
          manager: manager,
        });
      } else {
        if (tests[i].analyst === this.state.analyst) {
          await api.put("/test/update/" + tests[i]._id, {
            analyst: analyst,
            analystFullName: analystFullName,
            manager: manager,
          });
        }

        if (tests[i].keyUser === this.state.keyUser) {
          await api.put("/test/update/" + tests[i]._id, {
            keyUser: keyUser,
            keyUserFullName: keyUserFullName,
            manager: manager,
          });
        }
      }
    }

    if (success) {
      localStorage.setItem(process.env.REACT_APP_NEWREGID_KEY, this.state.id);
      localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "PL");
      window.location.reload();
    }
  };

  confirmNewCicle = async () => {
    const maxCicle = await api.post("/cicle/maxSequence", {
      plan: this.state.id,
      version: this.state.version,
      deleted: "N",
    });

    if (maxCicle.data[0].status !== "C") {
      this.warningAlert(
        "O ciclo " + maxCicle.data[0].title + " ainda não foi concluído."
      );
      return;
    }

    var nextCicle = maxCicle.data[0].title;
    var maxCicleId = maxCicle.data[0]._id;

    nextCicle =
      nextCicle === "Parametrização"
        ? "A"
        : nextCicle === "A"
        ? "Capacitação"
        : nextCicle === "Capacitação"
        ? "B"
        : String.fromCharCode(nextCicle.charCodeAt(0) + 1);

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Tem certeza ?"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.confirmedNewCicle(nextCicle, maxCicleId)}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="success"
          cancelBtnText="Sim, criar novo ciclo!"
          btnSize=""
        >
          {"Será criado o ciclo " + nextCicle + "."}
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  confirmedNewCicle = async (nextCicle, maxCicleId) => {
    this.hideAlert();

    this.setState({ isLoading: true });

    var success = true;

    try {
      const newCicle = await api.post("/cicle/create", {
        title: nextCicle,
        customer: this.state.customer,
        customerShortName: this.state.customerShortName,
        project: this.state.project,
        projectName: this.state.projectName,
        plan: this.state.id,
        planBusinessArea: this.state.businessArea,
        version: this.state.version,
        versionTitle: this.state.versionTitle,
        conclusion: 0,
        status: "P",
      });

      const items = await api.post("/item/search", {
        cicle: maxCicleId,
        deleted: "N",
      });

      //New items
      for (let i = 0; i < items.data.length; i++) {
        let newItem = await api.post("/item/create", {
          plan: this.state.id,
          version: this.state.version,
          cicle: newCicle.data._id,
          sequence: items.data[i].sequence,
          process: items.data[i].process,
          status: "P",
          conclusion: 0,
          hasDocs: items.data[i].hasDocs,
        });

        let tests = await api.post("/test/search", {
          item: items.data[i]._id,
          deleted: "N",
        });

        let validTestsCount = 0;

        //New tests
        for (let j = 0; j < tests.data.length; j++) {
          if (tests.data[j].status !== "CA") {
            validTestsCount++;

            let newTest = await api.post("/test/create", {
              customer: tests.data[j].customer,
              project: tests.data[j].project,
              plan: this.state.id,
              version: this.state.version,
              cicle: newCicle.data._id,
              item: newItem.data._id,
              sequence: tests.data[j].sequence,
              testCondition: tests.data[j].testCondition,
              testDescription: tests.data[j].testDescription,
              status: "NS",
              manager: tests.data[j].manager,
              analyst: tests.data[j].analyst,
              analystFullName: tests.data[j].analystFullName,
              keyUser: tests.data[j].keyUser,
              keyUserFullName: tests.data[j].keyUserFullName,
              hasDocs: tests.data[j].hasDocs,
              baseNumber: tests.data[j].baseNumber,
            });
            if (tests.data[j].sqlQuery && this.state.projectServiceLayerUrl) {
              let sqlQuery = {
                sqlCode: newTest.data._id,
                sqlName: newTest.data._id,
                sqlText: tests.data[j].sqlQuery?.sqlText,
              };
              await apiSap.post(
                this.state.projectServiceLayerUrl + `/SQLQueries`,
                {
                  SqlCode: newTest.data._id,
                  SqlName: newTest.data._id,
                  SqlText: tests.data[j].sqlQuery?.sqlText,
                }
              );
              await api.put("/test/update/" + newTest.data._id, {
                ...newTest.data,
                sqlQuery: sqlQuery,
              });
            }

            let testDocuments = await api.post("/testDocument/search", {
              test: tests.data[j]._id,
              deleted: "N",
            });

            //New tests documents
            for (let k = 0; k < testDocuments.data.length; k++) {
              await api.post("/testDocument/create", {
                plan: this.state.id,
                version: this.state.version,
                cicle: newCicle.data._id,
                item: newItem.data._id,
                test: newTest.data._id,
                kind: testDocuments.data[k].kind,
                description: testDocuments.data[k]?.description,
                url: testDocuments.data[k].url,
                document: testDocuments.data[k].document,
              });
            }
          }
        }

        if (validTestsCount > 0) {
          let itemDocuments = await api.post("/itemDocument/search", {
            item: items.data[i]._id,
            deleted: "N",
            kind: "U",
          });

          //New item documents
          for (let j = 0; j < itemDocuments.data.length; j++) {
            await api.post("/itemDocument/create", {
              plan: this.state.id,
              version: this.state.version,
              cicle: newCicle.data._id,
              item: newItem.data._id,
              kind: itemDocuments.data[j].kind,
              description: itemDocuments.data[j]?.description,
              url: itemDocuments.data[j].url,
              document: itemDocuments.data[j].document,
            });
          }
        } else {
          await api.delete("/item/delete/" + newItem.data._id);
        }
      }

      //Update plan
      await api.put("/plan/update/" + this.state.id, {
        cicle: newCicle.data._id,
        cicleTitle: nextCicle,
      });

      //Update plan conclusion
      await api.put("/plan/updateConclusion", {
        plan: this.state.id,
        reviewed: "N",
        deleted: "N",
      });
    } catch (err) {
      console.error(err);
      success = false;
      this.setState({ isLoading: false });
      this.warningAlert(
        "Não foi possível criar o novo ciclo. Tente novamente."
      );
    }

    if (success) {
      this.setState({ isLoading: false });
      this.loadData();
    }
  };

  confirmNewVersion = async () => {
    const maxVersion = await api.post("/version/maxSequence", {
      plan: this.state.id,
      deleted: "N",
    });

    var nextVersion = maxVersion.data[0].title;

    nextVersion++;
    nextVersion = ("00" + nextVersion).slice(-3);

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Tem certeza ?"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.confirmedNewVerion(nextVersion)}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="success"
          cancelBtnText="Sim, criar nova revisão!"
          btnSize=""
        >
          {"Será criada a revisão " +
            nextVersion +
            " do Ciclo " +
            this.state.cicleTitle +
            "."}
        </ReactBSAlert>
      ),
    });
  };

  confirmedNewVerion = async (nextVersion) => {
    this.hideAlert();

    var success = true;
    var newCicleId = "";
    var newCicleTitle = "";
    var newVersionId = "";
    var newVersionTitle = "";

    try {
      await api.put("/cicle/update/" + this.state.cicle, { reviewed: "Y" });

      const newVersion = await api.post("/version/create", {
        title: nextVersion,
        plan: this.state.id,
        planBusinessArea: this.state.businessArea,
      });

      newVersionId = newVersion.data._id;
      newVersionTitle = newVersion.data.title;

      const currentCicle = await api.get("/cicle/read/" + this.state.cicle);

      let newCicle = await api.post("/cicle/create", {
        title: currentCicle.data.title,
        customer: this.state.customer,
        customerShortName: this.state.customerShortName,
        project: this.state.project,
        projectName: this.state.projectName,
        plan: this.state.id,
        planBusinessArea: this.state.businessArea,
        version: newVersion.data._id,
        versionTitle: nextVersion,
        conclusion: 0,
        status: "P",
      });

      newCicleId = newCicle.data._id;
      newCicleTitle = newCicle.data.title;

      let items = await api.post("/item/search", {
        cicle: currentCicle.data._id,
        deleted: "N",
      });

      //New items
      for (let i = 0; i < items.data.length; i++) {
        await api.put("/item/update/" + items.data[i]._id, { reviewed: "Y" });

        let newItem = await api.post("/item/create", {
          plan: this.state.id,
          version: newVersion.data._id,
          cicle: newCicle.data._id,
          sequence: items.data[i].sequence,
          process: items.data[i].process,
          status: "P",
          conclusion: 0,
          hasDocs: items.data[i].hasDocs,
        });

        let itemDocuments = await api.post("/itemDocument/search", {
          item: items.data[i]._id,
          deleted: "N",
        });

        //New item documents
        for (let j = 0; j < itemDocuments.data.length; j++) {
          await api.post("/itemDocument/create", {
            plan: this.state.id,
            version: newVersion.data._id,
            cicle: newCicle.data._id,
            item: newItem.data._id,
            kind: itemDocuments.data[j].kind,
            description: itemDocuments.data[j]?.description,
            url: itemDocuments.data[j].url,
            document: itemDocuments.data[j].document,
          });
        }

        let tests = await api.post("/test/search", {
          item: items.data[i]._id,
          deleted: "N",
        });

        //New tests
        for (let j = 0; j < tests.data.length; j++) {
          await api.put("/test/update/" + tests.data[j]._id, { reviewed: "Y" });

          let newTest = await api.post("/test/create", {
            customer: this.state.customer,
            project: this.state.project,
            plan: this.state.id,
            version: newVersion.data._id,
            cicle: newCicle.data._id,
            item: newItem.data._id,
            sequence: tests.data[j].sequence,
            testCondition: tests.data[j].testCondition,
            testDescription: tests.data[j].testDescription,
            status: "NS",
            manager: tests.data[j].manager,
            analyst: tests.data[j].analyst,
            analystFullName: tests.data[j].analystFullName,
            keyUser: tests.data[j].keyUser,
            keyUserFullName: tests.data[j].keyUserFullName,
            hasDocs: tests.data[j].hasDocs,
            sqlQuery: tests.data[j].sqlQuery,
          });

          let testDocuments = await api.post("/testDocument/search", {
            test: tests.data[j]._id,
            deleted: "N",
          });

          //New tests documents
          for (let k = 0; k < testDocuments.data.length; k++) {
            await api.put("/testDocument/update/" + testDocuments.data[k]._id, {
              reviewed: "Y",
            });

            await api.post("/testDocument/create", {
              plan: this.state.id,
              version: this.state.version,
              cicle: newCicle.data._id,
              item: newItem.data._id,
              test: newTest.data._id,
              kind: testDocuments.data[k].kind,
              description: testDocuments.data[k]?.description,
              url: testDocuments.data[k].url,
              document: testDocuments.data[k].document,
            });
          }

          let interactions = await api.post("/interaction/search", {
            test: tests.data[j]._id,
            deleted: "N",
          });

          for (let k = 0; k < interactions.data.length; k++) {
            await api.put("/interaction/update/" + interactions.data[k]._id, {
              reviewed: "Y",
              showOnFeed: "N",
            });

            let newInteraction = await api.post("/interaction/create", {
              plan: this.state.id,
              version: newVersion.data._id,
              cicle: newCicle.data._id,
              item: newItem.data._id,
              test: newTest.data._id,
              user: interactions.data[k].user,
              userFullName: interactions.data[k].userFullName,
              status: "IP",
              nonconformity: interactions.data[k].nonconformity,
              comments: interactions.data[k].comments,
              customer: interactions.data[k].customer,
              project: interactions.data[k].project,
              manager: interactions.data[k].manager,
              analyst: interactions.data[k].analyst,
              keyUser: interactions.data[k].keyUser,
              showOnFeed: "Y",
            });

            let interactionDocuments = await api.post(
              "/interactionDocument/search",
              {
                interaction: interactions.data[k]._id,
                deleted: "N",
              }
            );

            for (let m = 0; m < interactionDocuments.data.length; m++) {
              await api.put(
                "/interactionDocument/update/" +
                  interactionDocuments.data[m]._id,
                { reviewed: "Y" }
              );

              await api.post("/interactionDocument/create", {
                plan: this.state.id,
                version: newVersion.data._id,
                cicle: newCicle.data._id,
                item: newItem.data._id,
                test: newTest.data._id,
                interaction: newInteraction.data._id,
                document: interactionDocuments.data[m].document,
              });
            }
          }
        }
      }

      //Update plan
      await api.put("/plan/update/" + this.state.id, {
        cicle: newCicleId,
        cicleTitle: newCicleTitle,
        version: newVersionId,
        versionTitle: newVersionTitle,
      });

      //Update plan conclusion
      await api.put("/plan/updateConclusion", {
        plan: this.state.id,
        reviewed: "N",
        deleted: "N",
      });
    } catch (err) {
      success = false;
      this.warningAlert(
        "Não foi possível criar a nova revisão. Tente novamente."
      );
    }

    if (success) {
      this.loadData();
    }
  };

  updateConclusions = async (updatePlan, itemId, cicleId) => {
    if (updatePlan) {
      const updatedPlan = await api.put("/plan/updateConclusion", {
        plan: this.state.id,
        reviewed: "N",
        deleted: "N",
      });

      this.setState({
        conclusion: Number(updatedPlan.data.conclusion).toFixed(0),
        status: updatedPlan.data.status,
      });
    }

    if (itemId && itemId !== "" && itemId !== null) {
      const updatedItem = await api.put("/item/updateConclusion/" + itemId);

      var newItems = this.state.items;

      for (let i = 0; i < newItems.length; i++) {
        if (newItems[i].id === itemId) {
          newItems[i].status = updatedItem.data.status;
          newItems[i].conclusion = Number(updatedItem.data.conclusion).toFixed(
            0
          );
          newItems[i].lastInteractionDate = Moment(Date()).format("D MMM YYYY");
          newItems[i].lastInteractionUserFullName = userFullName();
        }
      }

      this.setState({ items: newItems });
    }

    if (cicleId && cicleId !== "" && cicleId !== null) {
      const updatedCicle = await api.put("/cicle/updateConclusion/" + cicleId);

      this.setState({
        selectedCicleConclusion: Number(updatedCicle.data.conclusion).toFixed(
          0
        ),
        selectedCicleStatus: updatedCicle.data.status,
      });
    }
  };

  openModalFlowchart = async () => {
    const flowchart = this.state.flowchart;

    if (flowchart && flowchart !== null && flowchart !== "") {
      const response = await api.get("/document/read/" + flowchart);
      const document = response?.data;

      this.setState({
        crud: "R",
        flowchartUrl: document.url,
        flowchartModalTitle:
          "Fluxograma do Plano de Protótipo > " + this.state.businessArea,
        flowchartModalOpened: true,
      });
    } else {
      this.setState({
        crud: "C",
        flowchartUrl: "",
        flowchartModalTitle:
          "Fluxograma do Plano de Protótipo > " + this.state.businessArea,
        flowchartModalOpened: true,
      });
    }
  };

  closeModalFlowchart = async (e, action) => {
    if (action === "delete") {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block" }}
            title="Tem certeza ?"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.confirmedDeleteFlowchart()}
            showCancel
            confirmBtnBsStyle="secondary"
            confirmBtnText="Cancelar"
            cancelBtnBsStyle="success"
            cancelBtnText="Sim, excluir o fluxograma!"
            btnSize=""
          >
            {"O fluxograma será excluído."}
          </ReactBSAlert>
        ),
      });
    } else {
      if (action === "save") {
        this.setState({ saving: true });

        const newFlowchartFile = document.getElementById("newFlowchartFile");

        if (newFlowchartFile !== null) {
          if (newFlowchartFile.uploadedfile !== null) {
            const fileName = newFlowchartFile.uploadedfile.name;

            if (
              !fileName.includes(".xbm") &&
              !fileName.includes(".tif") &&
              !fileName.includes(".pjp") &&
              !fileName.includes(".svgz") &&
              !fileName.includes(".jpg") &&
              !fileName.includes(".jpeg") &&
              !fileName.includes(".ico") &&
              !fileName.includes(".tiff") &&
              !fileName.includes(".gif") &&
              !fileName.includes(".svg") &&
              !fileName.includes(".jfif") &&
              !fileName.includes(".webp") &&
              !fileName.includes(".png") &&
              !fileName.includes(".bmp") &&
              !fileName.includes(".pjpeg") &&
              !fileName.includes(".avif")
            ) {
              this.warningAlert("Formato de imagem não suportado.");

              this.setState({ saving: false });
              return;
            }

            var fileData = new FormData();

            fileData.append(
              "file",
              newFlowchartFile.uploadedfile.file,
              newFlowchartFile.uploadedfile.name
            );

            await api
              .post("/document/create", fileData)
              .then(async (response) => {
                await api.put("/plan/update/" + this.state.id, {
                  flowchart: response?.data._id,
                });

                this.setState({
                  flowchart: response?.data._id,
                  flowchartModalOpened: false,
                });
              })
              .catch(() => {
                this.warningAlert(
                  "Erro ao incluir o fluxograma. Tente novamente."
                );
              });
          }
        }

        this.setState({ saving: false });
      } else {
        this.setState({ flowchartModalOpened: false });
      }
    }
  };

  confirmedDeleteFlowchart = async () => {
    this.setState({ isLoading: true, alert: null });

    try {
      await api.put("/plan/update/" + this.state.id, {
        flowchart: null,
      });

      await api.delete("/document/delete/" + this.state.flowchart);

      this.setState({
        flowchart: null,
        crud: "",
        flowchartUrl: "",
        flowchartModalTitle: "",
        flowchartModalOpened: false,
      });
    } catch (err) {
      this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.");
    }

    this.setState({ isLoading: false });
  };

  masterFilter(e, filterName, considerHistory) {
    var filterItemDescription = "";
    var filterTestDescription = "";
    var filterStatusItemSelection = [];
    var filterStatusTestSelection = [];

    var filteringItem = false;
    var filteringTest = false;
    var filteringItemStatus = false;
    var filteringTestStatus = false;

    if (filterName === "filterItemDescription") {
      this.setState({
        filterItemDescription: e.target.value,
        filteringItem: e.target.value !== "",
      });

      filterItemDescription = e.target.value;
      filterTestDescription = this.state.filterTestDescription;
      filterStatusItemSelection = this.state.filterStatusItemSelection;
      filterStatusTestSelection = this.state.filterStatusTestSelection;

      filteringItem = e.target.value !== "";
      filteringTest = this.state.filteringTest;
      filteringItemStatus = this.state.filteringItemStatus;
      filteringTestStatus = this.state.filteringTestStatus;
    }

    if (filterName === "filterTestDescription") {
      this.setState({
        filterTestDescription: e.target.value,
        filteringTest: e.target.value !== "",
      });

      filterItemDescription = this.state.filterItemDescription;
      filterTestDescription = e.target.value;
      filterStatusItemSelection = this.state.filterStatusItemSelection;
      filterStatusTestSelection = this.state.filterStatusTestSelection;

      filteringItem = this.state.filteringItem;
      filteringTest = e.target.value !== "";
      filteringItemStatus = this.state.filteringItemStatus;
      filteringTestStatus = this.state.filteringTestStatus;
    }

    if (filterName === "filterStatusItemSelection") {
      let options = e.target.options;

      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          filterStatusItemSelection.push(options[i].value);
        }
      }

      this.setState({
        filterStatusItemSelection: filterStatusItemSelection,
        filteringItemStatus: filterStatusItemSelection.length > 0,
      });

      filterItemDescription = this.state.filterItemDescription;
      filterTestDescription = this.state.filterTestDescription;
      filterStatusTestSelection = this.state.filterStatusTestSelection;

      filteringItem = this.state.filteringItem;
      filteringTest = this.state.filteringTest;
      filteringItemStatus = filterStatusItemSelection.length > 0;
      filteringTestStatus = this.state.filteringTestStatus;
    }

    if (filterName === "filterStatusTestSelection") {
      if (e) {
        let options = e.target.options;

        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            filterStatusTestSelection.push(options[i].value);
          }
        }
      } else {
        filterStatusTestSelection = this.state.testOptions.map((opt) => {
          return opt;
        });
      }

      this.setState({
        filterStatusTestSelection: filterStatusTestSelection,
        filteringTestStatus: filterStatusTestSelection.length > 0,
      });

      filterItemDescription = this.state.filterItemDescription;
      filterTestDescription = this.state.filterTestDescription;
      filterStatusItemSelection = this.state.filterStatusItemSelection;

      filteringItem = this.state.filteringItem;
      filteringTest = this.state.filteringTest;
      filteringItemStatus = this.state.filteringItemStatus;
      filteringTestStatus = filterStatusTestSelection.length > 0;
    }

    var filteredItems = this.state.items;

    //Clear filters
    for (let i = 0; i < filteredItems.length; i++) {
      filteredItems[i].hiddenItem = false;
      filteredItems[i].hiddenRows = [];
    }

    var canAddTestByDescription = true;
    var canAddTestByStatus = true;

    var canAddItemByDescription = true;
    var canAddItemByStatus = true;

    //Check all item tests
    for (let i = 0; i < filteredItems.length; i++) {
      let tests = filteredItems[i].tests;
      let hiddenRows = [];

      for (let j = 0; j < tests.length; j++) {
        //Check test description
        if (filteringTest) {
          let searchTest = filterTestDescription.toLowerCase();
          let testDescription = tests[j].testDescription;

          testDescription = testDescription.toLowerCase();

          canAddTestByDescription = testDescription.includes(searchTest);
        } else {
          canAddTestByDescription = true;
        }

        //Check test status
        if (filteringTestStatus) {
          if (considerHistory) {
            const intersection = tests[j].statusHistory.filter((element) =>
              filterStatusTestSelection.includes(element)
            );

            canAddTestByStatus = intersection.length > 0;
          } else if (!considerHistory) {
            canAddTestByStatus = this.itemOnTheList(
              filterStatusTestSelection,
              tests[j].status
            );
          }
        } else {
          canAddTestByStatus = true;
        }

        if (!canAddTestByDescription || !canAddTestByStatus) {
          hiddenRows.push(tests[j]._id);
        }
      }

      filteredItems[i].hiddenRows = hiddenRows;

      if (filteredItems[i].tests.length === hiddenRows.length) {
        filteredItems[i].hiddenItem = true;
      } else {
        //Check item description
        if (filteringItem) {
          let searchItem = filterItemDescription.toLowerCase();
          let process = filteredItems[i].process;

          process = process.toLowerCase();

          canAddItemByDescription = process.includes(searchItem);
        } else {
          canAddItemByDescription = true;
        }

        //Check item status
        if (filteringItemStatus) {
          canAddItemByStatus = this.itemOnTheList(
            filterStatusItemSelection,
            filteredItems[i].status
          );
        } else {
          canAddItemByStatus = true;
        }

        filteredItems[i].hiddenItem =
          !canAddItemByDescription || !canAddItemByStatus;
      }
    }

    this.setState({ items: filteredItems });
  }

  itemOnTheList(list, item) {
    if (!list) {
      return false;
    }

    if (list.length === 0) {
      return false;
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i] === item) {
        return true;
      }
    }

    return false;
  }

  removeItemFromTheList(list, item) {
    let newList = [];

    for (let i = 0; i < list.length; i++) {
      if (list[i] !== item) {
        newList.push(item);
      }
    }

    return newList;
  }

  removeFilters(e) {
    var filteredItems = this.state.items;

    for (let i = 0; i < filteredItems.length; i++) {
      filteredItems[i].hiddenItem = false;
      filteredItems[i].hiddenRows = [];
    }

    this.setState({
      items: filteredItems,
      filterItemDescription: "",
      filterTestDescription: "",
      filterStatusItemSelection: [],
      filterStatusTestSelection: [],
      filteringItem: false,
      filteringItemStatus: false,
      filteringTest: false,
      filteringTestStatus: false,
    });

    var selectItemStatus = document.getElementById("selectItemStatus");

    if (selectItemStatus && selectItemStatus !== null) {
      for (let i = 0; i < selectItemStatus.options.length; i++) {
        selectItemStatus.options[i].selected = false;
      }
    }

    var selectTestStatus = document.getElementById("selectTestStatus");

    if (selectTestStatus && selectTestStatus !== null) {
      for (let i = 0; i < selectTestStatus.options.length; i++) {
        selectTestStatus.options[i].selected = false;
      }
    }
  }

  closeModalItemDocuments(e) {
    e.preventDefault();
    this.setState({ itemDocumentsModalOpened: false });
  }

  openModalItemDocuments = async (e, item) => {
    if (e !== null) {
      e.preventDefault();
      this.setState({ isLoading: true });
    }

    var response = await api.post("/itemDocument/fullSearch", {
      item: item.id,
      deleted: "N",
    });

    var documents = [];
    var document = {};
    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].kind === "U") {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i]?.description,
          kind: response?.data[i].kind,
          document: null,
          name: "URL",
          url: response?.data[i].url,
        };
      } else {
        if (!response?.data[i].document)
          document = {
            _id: response?.data[i]._id,
            description: "",
            kind: response?.data[i].kind,
            document: "",
            name: "",
            url: "",
          };
        else
          document = {
            _id: response?.data[i]._id,
            description: response?.data[i].document?.description || "",
            kind: response?.data[i].kind,
            document: response?.data[i].document?._id || "",
            name: response?.data[i].document?.name,
            url: response?.data[i].document?.url,
          };
      }

      if (documents.length === 0) {
        documents = [document];
      } else {
        documents.push(document);
      }
    }

    this.setState({
      itemDocumentsModalOpened: true,
      selectedItem: item.id,
      selectedItemSequence: item.sequence,
      itemDocumentsModalTitle: this.state.businessArea + " > " + item.process,
      itemDocumentsData: documents,
      documentOf: "test",
    });

    if (e !== null) {
      this.setState({ isLoading: false });
    }
  };

  reloadItemDocuments = async () => {
    const response = await api.post("/itemDocument/fullSearch", {
      item: this.state.selectedItem,
      deleted: "N",
    });
    var documents = [];
    var document = {};

    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].kind === "U") {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i]?.description,
          name: "URL",
          kind: response?.data[i].kind,
          document: null,
          url: response?.data[i].url,
        };
      } else {
        document = {
          _id: response?.data[i]._id,
          description: response?.data[i].document?.description,
          name: response?.data[i].document.name,
          kind: response?.data[i].kind,
          document: response?.data[i].document._id,
          url: response?.data[i].document.url,
        };
      }

      if (documents.length === 0) {
        documents = [document];
      } else {
        documents.push(document);
      }
    }

    this.setState({
      itemDocumentsData: documents,
    });
  };

  render() {
    return (
      <>
        <SimpleHeader
          name={this.state.businessArea}
          items={[
            {
              level: "parent",
              name: this.state.customerShortName,
            },
            {
              level: "parent",
              name: this.state.projectName,
            },
            {
              level: "nav",
              name: this.state.businessArea,
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="4">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="5">
                      <h5 className="h3 mb-0">Plano</h5>
                    </Col>
                    <Col xs="7" className="text-right">
                      <Button
                        color="primary"
                        href="#editPlan"
                        size="sm"
                        id="buttonEditPlan"
                        onClick={(e) => this.openModalPlan(e, "U")}
                        hidden={
                          this.state.isManagerCustomer ||
                          this.state.isResourceCustomer ||
                          (this.state.analyst !==
                            localStorage.getItem(
                              process.env.REACT_APP_USERID_KEY
                            ) &&
                            !this.state.isManagerConsulting) ||
                          this.state.status === "C"
                        }
                      >
                        <i className="fas fa-edit" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonEditPlan"}
                        >
                          Alterar o Plano
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="danger"
                        href="#deletePlan"
                        size="sm"
                        id="buttonDeletePlan"
                        hidden={
                          this.state.isManagerCustomer ||
                          this.state.isResourceCustomer ||
                          (this.state.analyst !==
                            localStorage.getItem(
                              process.env.REACT_APP_USERID_KEY
                            ) &&
                            !this.state.isManagerConsulting) ||
                          this.state.status !== "P"
                        }
                        onClick={(e) => this.openModalPlan(e, "D")}
                      >
                        <i className="fas fa-trash" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonDeletePlan"}
                        >
                          Excluir o Plano
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="info"
                        href="#flowchart"
                        size="sm"
                        id="buttonFlowchart"
                        hidden={
                          (!this.state.flowchart ||
                            this.state.flowchart === null) &&
                          (this.state.isManagerCustomer ||
                            this.state.isResourceCustomer)
                        }
                        onClick={(e) => this.openModalFlowchart(e)}
                      >
                        <i className="ni ni-map-big" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonFlowchart"}
                        >
                          Fluxograma
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="secondary"
                        size="sm"
                        id="buttonFilterToggler"
                        onClick={(e) => this.buttonFilterClick(e)}
                      >
                        <i className="fas fa-filter" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonFilterToggler"}
                        >
                          {this.state.buttonFiltersLabel}
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Analista:
                      <span className="font-weight-light">
                        {" " + this.state.analystFullName}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Usuário Chave:
                      <span className="font-weight-light">
                        {" " + this.state.keyUserFullName}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Gerente Funcional:
                      <span className="font-weight-light">
                        {" " + this.state.managerFullName}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <Badge color="info">
                      {"Criado em: " + this.state.createdAt}
                    </Badge>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h5 className="h3 mb-0">Posição Atual</h5>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button
                        color="success"
                        size="sm"
                        id="buttonSee"
                        onClick={() =>
                          this.loadData(
                            this.state.cicles[this.state.cicles.length - 1]
                          )
                        }
                      >
                        <i className="fas fa-glasses" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonSee"}
                        >
                          Ver o ciclo atual (mais recente)
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Revisão:
                      <span className="font-weight-light">
                        {" " + this.state.versionTitle}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Ciclo:
                      <span className="font-weight-light">
                        {" " + this.state.cicleTitle}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h4 className="mb-2">
                          Conclusão %:
                          <span className="font-weight-light">
                            {" " + this.state.conclusion}
                          </span>
                        </h4>
                      </Col>
                      <Col xs="6">
                        {this.state.status === "P" ? (
                          <Progress
                            max="100"
                            value={this.state.conclusion}
                            color="danger"
                          />
                        ) : null}
                        {this.state.status === "I" ? (
                          <Progress
                            max="100"
                            value={this.state.conclusion}
                            color="warning"
                          />
                        ) : null}
                        {this.state.status === "C" ? (
                          <Progress
                            max="100"
                            value={this.state.conclusion}
                            color="success"
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div className="card-text">
                    <Badge color="info">
                      {"Última revisão: " + this.state.versionCreatedAt}
                    </Badge>
                    {this.state.status === "P" ? (
                      <Badge color="danger">Pendente</Badge>
                    ) : null}
                    {this.state.status === "I" ? (
                      <Badge color="warning">Em Andamento</Badge>
                    ) : null}
                    {this.state.status === "C" ? (
                      <Badge color="success">Concluído</Badge>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="4">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="5">
                      <h5 className="h3 mb-0">Visualizando</h5>
                    </Col>
                    <Col xs="7" className="text-right pl-0">
                      {this.state.isManagerCustomer ||
                      this.state.isResourceCustomer ||
                      (this.state.analyst !==
                        localStorage.getItem(
                          process.env.REACT_APP_USERID_KEY
                        ) &&
                        !this.state.isManagerConsulting) ? (
                        <UncontrolledDropdown group size="sm">
                          <DropdownToggle caret color="warning">
                            {"Revisão " +
                              this.state.selectedVersionTitle +
                              " | " +
                              this.state.selectedCicleTitle}
                          </DropdownToggle>
                          <DropdownMenu>
                            {this.getCicles(this.state.cicles)}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      ) : (
                        <UncontrolledDropdown group size="sm">
                          <DropdownToggle caret color="warning">
                            {"Revisão " +
                              this.state.selectedVersionTitle +
                              this.getTitle(this.state.selectedCicleTitle)}
                          </DropdownToggle>
                          <DropdownMenu>
                            {this.getCicles(this.state.cicles)}
                            <DropdownItem divider />
                            <DropdownItem
                              href="#pablo"
                              onClick={(e) => this.confirmNewVersion(e)}
                            >
                              Nova Revisão
                            </DropdownItem>
                            <DropdownItem
                              href="#newCicle"
                              onClick={(e) => this.confirmNewCicle(e)}
                            >
                              Novo Ciclo
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Revisão:
                      <span className="font-weight-light">
                        {" " + this.state.selectedVersionTitle}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <h4 className="mb-2">
                      Ciclo:
                      <span className="font-weight-light">
                        {" " + this.state.selectedCicleTitle}
                      </span>
                    </h4>
                  </div>
                  <div className="card-text">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <h4 className="mb-2">
                          Conclusão %:
                          <span className="font-weight-light">
                            {" " + this.state.selectedCicleConclusion}
                          </span>
                        </h4>
                      </Col>
                      <Col xs="6">
                        {this.state.selectedCicleStatus === "P" ? (
                          <Progress
                            max="100"
                            value={this.state.selectedCicleConclusion}
                            color="danger"
                          />
                        ) : null}
                        {this.state.selectedCicleStatus === "I" ? (
                          <Progress
                            max="100"
                            value={this.state.selectedCicleConclusion}
                            color="warning"
                          />
                        ) : null}
                        {this.state.selectedCicleStatus === "C" ? (
                          <Progress
                            max="100"
                            value={this.state.selectedCicleConclusion}
                            color="success"
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div className="card-text">
                    <Badge color="info">
                      {"Criado em: " + this.state.selectedCicleCreatedAt}
                    </Badge>
                    {this.state.selectedCicleStatus === "P" ? (
                      <Badge color="danger">Pendente</Badge>
                    ) : null}
                    {this.state.selectedCicleStatus === "I" ? (
                      <Badge color="warning">Em Andamento</Badge>
                    ) : null}
                    {this.state.selectedCicleStatus === "C" ? (
                      <Badge color="success">Concluído</Badge>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <UncontrolledCollapse toggler="#linkToggler,#buttonFilterToggler">
            <Row hidden={this.state.loadingData}>
              <Col xs="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col xs="6">
                        <h5 className="h3 mb-0">Filtros</h5>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button
                          color="secondary"
                          size="sm"
                          id="buttonFilterToggler"
                          onClick={(e) => this.buttonFilterClick(e)}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-filter" />
                          </span>
                          <span className="btn-inner--text">
                            Ocultar Filtros
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </CardHeader>

                  <Card>
                    <CardBody>
                      <Form
                        className="needs-validation"
                        noValidate
                        autoComplete="off"
                      >
                        <div className="form-row">
                          <Col className="mb-3" md="3">
                            <FormGroup>
                              <label className="form-control-label">
                                Processo
                              </label>
                              <Input
                                placeholder="Digite a descrição do processo"
                                type="text"
                                value={this.state.filterItemDescription}
                                onChange={(e) =>
                                  this.masterFilter(e, "filterItemDescription")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" md="3">
                            <FormGroup>
                              <label className="form-control-label">
                                Descrição do teste
                              </label>
                              <Input
                                placeholder="Digite a descrição do teste"
                                type="text"
                                value={this.state.filterTestDescription}
                                onChange={(e) =>
                                  this.masterFilter(e, "filterTestDescription")
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect2"
                              >
                                Status do processo
                              </label>
                              <Input
                                multiple="multiple"
                                type="select"
                                id="selectItemStatus"
                                onChange={(e) =>
                                  this.masterFilter(
                                    e,
                                    "filterStatusItemSelection"
                                  )
                                }
                              >
                                <option value="P">Pendente</option>
                                <option value="I">Em Andamento</option>
                                <option value="C">Concluído</option>
                              </Input>
                              <small className="text-muted mb-0">
                                *Ctrl + click para multiseleção
                              </small>
                            </FormGroup>
                          </Col>
                          <Col className="mb-3" md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect2"
                              >
                                Status do teste
                              </label>
                              <Input
                                multiple="multiple"
                                type="select"
                                id="selectTestStatus"
                                onChange={(e) => {
                                  let eventOptions = e.target.options;
                                  let options = [];

                                  for (
                                    let i = 0;
                                    i < eventOptions.length;
                                    i++
                                  ) {
                                    if (eventOptions[i].selected) {
                                      options.push(eventOptions[i].value);
                                    }
                                  }

                                  this.setState({
                                    testOptions: options,
                                  });

                                  this.masterFilter(
                                    e,
                                    "filterStatusTestSelection"
                                  );
                                }}
                              >
                                {this.getStatusList(this.state.statusList)}
                              </Input>
                              <small className="text-muted mb-0">
                                *Ctrl + click para multiseleção
                              </small>

                              <div className="custom-control custom-control-alternative custom-checkbox">
                                <input
                                  className="custom-control-input"
                                  id="customCheckRegister"
                                  type="checkbox"
                                  value={this.state.considerHistory}
                                  onChange={(e) => {
                                    this.masterFilter(
                                      null,
                                      "filterStatusTestSelection",
                                      !this.state.considerHistory
                                    );
                                    this.setState({
                                      considerHistory:
                                        !this.state.considerHistory,
                                    });
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckRegister"
                                >
                                  <span className="text-muted">
                                    Considerar histórico
                                  </span>
                                </label>
                              </div>
                            </FormGroup>
                          </Col>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Card>
              </Col>
            </Row>
          </UncontrolledCollapse>
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h5 className="h3 mb-0">
                        {this.state.loadingData
                          ? "Carregando processos de Teste..."
                          : "Processos de Teste"}
                      </h5>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button
                        color="primary"
                        size="sm"
                        id="showAllTests"
                        hidden={this.state.loadingData}
                      >
                        <i className="ni ni-bold-down" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"showAllTests"}
                        >
                          Exibir/Ocultar todos os testes
                        </UncontrolledTooltip>
                      </Button>
                      <Button
                        color="primary"
                        size="sm"
                        onClick={(e) => this.openModalItem(e, "C")}
                        hidden={
                          this.state.selectedCicle !== this.state.cicle ||
                          this.state.isResourceCustomer ||
                          this.state.isManagerCustomer ||
                          (this.state.analyst !==
                            localStorage.getItem(
                              process.env.REACT_APP_USERID_KEY
                            ) &&
                            !this.state.isManagerConsulting) ||
                          this.state.status === "C" ||
                          this.state.loadingData
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Processo</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Card hidden={!this.state.loadingData}>
                  <CardBody>
                    <div className="text-center text-muted my-4">
                      <Progress animated color="danger" value="100" />
                    </div>
                  </CardBody>
                </Card>
                <Card
                  hidden={
                    !this.state.filteringItem &&
                    !this.state.filteringItemStatus &&
                    !this.state.filteringTest &&
                    !this.state.filteringTestStatus
                  }
                >
                  <CardBody>
                    <Row>
                      <Col xs="1" className="text-left">
                        <Button
                          color="danger"
                          size="sm"
                          id="buttonRemoveFilters1"
                          onClick={(e) => this.removeFilters(e)}
                        >
                          <i className="ni ni-fat-remove" />
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={"buttonRemoveFilters1"}
                          >
                            Remover filtros
                          </UncontrolledTooltip>
                        </Button>
                      </Col>
                      <Col xs="10" className="text-center">
                        <small className="text-danger mb-0">
                          Atenção! Os processos e testes abaixo estão filtrados.
                          Alguns itens podem não aparecer.
                        </small>
                      </Col>
                      <Col xs="1" className="text-right">
                        <Button
                          color="danger"
                          size="sm"
                          id="buttonRemoveFilters2"
                          onClick={(e) => this.removeFilters(e)}
                        >
                          <i className="ni ni-fat-remove" />
                          <UncontrolledTooltip
                            delay={0}
                            placement="top"
                            target={"buttonRemoveFilters2"}
                          >
                            Remover filtros
                          </UncontrolledTooltip>
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {this.getItems(this.state.items)}
              </Card>
            </Col>
          </Row>
          <Plan
            modalOpened={this.state.planModalOpened}
            title={this.state.planModalTitle}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            modalPlanBusinessArea={this.state.modalPlanBusinessArea}
            modalPlanAnalyst={this.state.modalPlanAnalyst}
            modalPlanKeyUser={this.state.modalPlanKeyUser}
            modalPlanManager={this.state.modalPlanManager}
            modalSave={this.closeModalPlan.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            analystList={this.state.analystList}
            keyUserList={this.state.keyUserList}
            managerList={this.state.managerList}
            isManagerConsulting={this.state.isManagerConsulting}
          />
          <Item
            modalOpened={this.state.itemModalOpened}
            title={this.state.itemModalTitle}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            cicleTitle={this.state.cicleTitle}
            itemSequence={this.state.itemSequence}
            itemProcess={this.state.itemProcess}
            modalSave={this.closeModalItem.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
          />
          <Test
            modalOpened={this.state.testModalOpened}
            title={this.state.testModalTitle}
            testSequence={this.state.testSequence}
            testDescription={this.state.testDescription}
            testStatus={this.state.testStatus}
            testAnalyst={this.state.testAnalyst}
            testKeyUser={this.state.testKeyUser}
            testOpitionalKeyUser={this.state.testOpitionalKeyUser}
            testCondition={this.state.testCondition}
            testComments={this.state.testComments}
            sqlQuery={this.state.sqlQuery}
            baseNumber={this.state.baseNumber}
            crud={this.state.crud}
            cicleTitle={this.state.cicleTitle}
            readOnly={this.state.readOnly}
            modalSave={this.closeModalTest.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            statusList={this.state.statusList}
            getStatusList={this.getStatusList.bind(this)}
            testSqlQuery={this.testSqlQuery.bind(this)}
            saving={this.state.saving}
            analystList={this.state.analystList}
            keyUserList={this.state.keyUserList}
            disableQuery={this.state.disableQuery}
          />
          <TestInteractions
            modalOpened={this.state.testInteractionsModalOpened}
            title={this.state.testInteractionsModalTitle}
            testCondition={this.state.testInteractionTestCondition}
            testComments={this.state.testComments}
            interactionsData={this.state.testInteractionsData}
            statusList={this.state.statusList}
            documentsData={this.state.testDocumentsData}
            interactionsActiveTab={this.state.interactionsActiveTab}
            interactionsToggleNavs={this.interactionsToggleNavs.bind(this)}
            closeModal={this.closeModalTestInteractions.bind(this)}
            openModalInteraction={this.openModalInteraction.bind(this)}
            openModalDocument={this.openModalDocument.bind(this)}
            cicleTitle={this.state.cicleTitle}
            canInteract={this.state.canInteract}
            isConsulting={
              this.state.isManagerConsulting || this.state.isResourceConsulting
            }
          />
          <Interaction
            modalOpened={this.state.interactionModalOpened}
            crud={this.state.crud}
            title={this.state.interactionModalTitle}
            readOnly={this.state.readOnly}
            statusList={this.state.statusList}
            possibleStatusList={this.state.possibleStatusList}
            getStatusList={this.getStatusList.bind(this)}
            modalSave={this.closeModalInteraction.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            nonconformity={this.state.nonconformity}
            documentData={this.state.interactionDocumentData}
            interactionStatus={this.state.interactionStatus}
            interactionComments={this.state.interactionComments}
            saving={this.state.saving}
            nonconformityList={this.state.nonconformityList}
            isUserConsulting={
              this.state.isManagerConsulting || this.state.isResourceConsulting
            }
            canChangeStatus={this.state.canChangeInteractionStatus}
          />
          <Document
            readOnly={this.state.readOnly}
            crud={this.state.crud}
            modalTitle={this.state.documentModalTitle}
            modalOpened={this.state.documentModalOpened}
            documentDescription={this.state.documentDescription}
            documentKind={this.state.documentKind}
            documentURL={this.state.documentURL}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
            acceptedFileTypes={this.state.testDocumentFileTypes}
            onFileChange={this.documentFileChange.bind(this)}
            modalSave={this.closeModalDocument.bind(this)}
            saving={this.state.saving}
            affectedItemDocuments={this.state.affectedItemDocuments}
            affectedTestDocuments={this.state.affectedTestDocuments}
          />
          <Loading modalOpened={this.state.isLoading} />
          <ImageZoom
            title={this.state.flowchartModalTitle}
            url={this.state.flowchartUrl}
            file={this.state.flochartFile}
            crud={this.state.crud}
            saving={this.state.saving}
            modalOpened={this.state.flowchartModalOpened}
            modalClose={this.closeModalFlowchart.bind(this)}
            isConsulting={
              this.state.isManagerConsulting || this.state.isResourceConsulting
            }
          />
          <ItemDocuments
            style={{ borderColor: "black" }}
            goliveCustomer={this.state.goliveCustomer}
            modalOpened={this.state.itemDocumentsModalOpened}
            title={this.state.itemDocumentsModalTitle}
            documentsData={this.state.itemDocumentsData}
            closeModal={this.closeModalItemDocuments.bind(this)}
            openModalDocument={this.openModalDocument.bind(this)}
            isConsulting={
              this.state.isManagerConsulting || this.state.isResourceConsulting
            }
          />
        </Container>
        {this.state.alert}
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Loading modalOpened={this.state.loading}></Loading>
      </>
    );
  }
}

export default PlanConsole;
