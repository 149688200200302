/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom"
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js"
import AdminFooter from "components/Footers/AdminFooter.js"
import Sidebar from "components/Sidebar/Sidebar.js"

import Nonconformities from "views/pages/registrations/Nonconformities.js"
import Users from "views/pages/registrations/Users.js"
import Feed from "views/pages/dashboards/Feed.js"
import DashProject from "views/pages/dashboards/DashProject.js"
import DashList from "views/pages/dashboards/DashList.js"
import Help from "views/pages/help/Help.js"
import DashNonconformities from "views/pages/dashboards/DashNonconformities.js"
import NewCustomer from "views/pages/registrations/NewCustomer.js"
import NewProject from "views/pages/registrations/NewProject.js"
import NewPlan from "views/pages/registrations/NewPlan.js"
import CustomerConsole from "views/pages/workareas/CustomerConsole.js"
import ProjectConsole from "views/pages/workareas/ProjectConsole.js"
import PlanConsole from "views/pages/workareas/PlanConsole.js"
import Profile from "views/pages/access/Profile.js"
import NewList from "views/pages/registrations/NewList.js"
import ListConsole from "views/pages/workareas/ListConsole.js"

import api from "services/api"
import { isManager } from "services/auth"
import { isConsulting } from "services/auth"
import { isDemo } from "services/auth"
import { isTokenValid } from "services/auth"
import Customers from "views/pages/customers/Customers"

class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    routes: [],
    customersRoutes: [],
    toPortal: "auth/login",
    customersIds: [],
    isManagerConsulting: false,
    isDemoAccess: false,
    searchExp: "",
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      this.refs.mainContent.scrollTop = 0
    }
  }

  componentDidMount() {
    this.setState({
      isManagerConsulting: isManager() && isConsulting(),
      isDemoAccess: isDemo(),
    })

    this.loadRoutes()

    isTokenValid().then((result) => {
      if (!result) {
        this.setState({ toPortal: "/auth/login" })
      } else {
        const newRegId = localStorage.getItem(
          process.env.REACT_APP_NEWREGID_KEY
        )
        const newRegKind = localStorage.getItem(
          process.env.REACT_APP_NEWREGKIND_KEY
        )
        var route = ""

        if (newRegId && newRegId !== null && newRegId !== "") {
          if (newRegKind === "CR") {
            route = "/admin/customerConsole/" + newRegId
          } else {
            if (newRegKind === "PR") {
              route = "/admin/projectConsole/" + newRegId
            } else {
              if (newRegKind === "PL") {
                route = "/admin/planConsole/" + newRegId + "/c/i/t"
              } else {
                route = "/admin/listConsole/" + newRegId + "/t"
              }
            }
          }

          //route = "/admin/" + route + "/" + newRegId + "/c/i/t";

          localStorage.removeItem(process.env.REACT_APP_NEWREGID_KEY)
          localStorage.removeItem(process.env.REACT_APP_NEWREGKIND_KEY)
        } else {
          route = "/admin/feed"
        }

        this.setState({ toPortal: route })
      }
    })
  }

  loadRoutes = async () => {
    const currentUserId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)

    const response = await api.post("/customer/sidebar", {
      currentUserId: currentUserId,
      isDemo: isDemo(),
      isManagerConsulting: isManager() && isConsulting(),
    })

    const sidebarMenu = response.data

    const userProfile = await api.get("/user/read/" + currentUserId)

    const isMainUser = userProfile.data.mainUser

    const userProjects = await api.post("/smartflowproject/search", {
      responsible: currentUserId,
      deleted: "N",
    })
    const isProjectResponsible = userProjects.data.length > 0

    var customer = {}
    var customersRoutes = []

    var project = {}
    var projectsRoutes = []

    var plan = {}
    var plansRoutes = []

    var customersIds = []
    var customerId = {}

    for (let i = 0; i < sidebarMenu.length; i++) {
      customer = {
        key: sidebarMenu[i].id,
        name: sidebarMenu[i].shortName,
        layout: "/admin",
        path: "/customerConsole",
        component: CustomerConsole,
        icon: "ni ni-building text-red",
        parentId: sidebarMenu[i].id,
        collapse: false,
        state: sidebarMenu[i].id + "Collapse",
        sideBar: true,
        views: [],
      }

      projectsRoutes = [
        {
          collapse: false,
          key: sidebarMenu[i].id,
          layout: "/admin",
          path: "/customerConsole",
          component: CustomerConsole,
          parentId: sidebarMenu[i].id,
          sideBar: false,
        },
      ]

      for (let j = 0; j < sidebarMenu[i].projects.length; j++) {
        project = {
          key: sidebarMenu[i].projects[j].id,
          name: sidebarMenu[i].projects[j].name,
          collapse: false,
          miniName: sidebarMenu[i].projects[j].name.substring(0, 1),
          state: "multiCollapse" + sidebarMenu[i].projects[j].id,
          layout: "/admin",
          path: "/projectConsole",
          component: ProjectConsole,
          parentId: sidebarMenu[i].projects[j].id,
          sideBar: true,
          views: [],
        }

        plansRoutes = [
          {
            collapse: false,
            key: sidebarMenu[i].projects[j].id,
            layout: "/admin",
            path: "/projectConsole",
            component: ProjectConsole,
            parentId: sidebarMenu[i].projects[j].id,
            sideBar: false,
          },
        ]

        for (let k = 0; k < sidebarMenu[i].projects[j].plans.length; k++) {
          plan = {
            key: sidebarMenu[i].projects[j].plans[k].id,
            name: sidebarMenu[i].projects[j].plans[k].businessArea,
            path: "/planConsole",
            icon: "ni ni-collection text-green",
            component: PlanConsole,
            layout: "/admin",
            parentId: sidebarMenu[i].projects[j].plans[k].id,
            isPlan: true,
            sideBar: true,
          }

          if (!plansRoutes.length) {
            plansRoutes = [plan]
          } else {
            plansRoutes.push(plan)
          }
        }

        if (this.state.isManagerConsulting) {
          plan = {
            icon: "ni ni-collection text-green",
            miniName: "N",
            name: "< Novo plano >",
            key: "newPlan",
            component: NewPlan,
            layout: "/admin",
            path: "/newPlan",
            parentId: sidebarMenu[i].projects[j].id,
            sideBar: true,
          }

          if (!plansRoutes.length) {
            plansRoutes = [plan]
          } else {
            plansRoutes.push(plan)
          }
        }

        for (let k = 0; k < sidebarMenu[i].projects[j].lists.length; k++) {
          plan = {
            key: sidebarMenu[i].projects[j].lists[k].id,
            name: sidebarMenu[i].projects[j].lists[k].name,
            path: "/listConsole",
            icon: "ni ni-bullet-list-67 text-warning",
            component: ListConsole,
            layout: "/admin",
            parentId: sidebarMenu[i].projects[j].lists[k].id,
            isList: true,
            sideBar: true,
          }

          if (!plansRoutes.length) {
            plansRoutes = [plan]
          } else {
            plansRoutes.push(plan)
          }
        }

        if (this.state.isManagerConsulting) {
          plan = {
            icon: "ni ni-bullet-list-67 text-warning",
            miniName: "N",
            name: "< Nova lista >",
            key: "newList",
            component: NewList,
            layout: "/admin",
            path: "/newList",
            parentId: sidebarMenu[i].projects[j].id,
            sideBar: true,
          }

          if (!plansRoutes.length) {
            plansRoutes = [plan]
          } else {
            plansRoutes.push(plan)
          }
        }

        if (plansRoutes.length) {
          project.collapse = true
          project.views = plansRoutes
        }

        if (!projectsRoutes.length) {
          projectsRoutes = [project]
        } else {
          projectsRoutes.push(project)
        }
      }

      if (this.state.isManagerConsulting) {
        project = {
          collapse: false,
          name: "< Novo projeto >",
          key: "newProject",
          miniName: "N",
          state: "multiCollapse" + sidebarMenu[i].id + i,
          layout: "/admin",
          path: "/newProject",
          parentId: sidebarMenu[i].id,
          component: NewProject,
          sideBar: true,
          views: [],
        }

        if (!projectsRoutes) {
          projectsRoutes = [project]
        } else {
          projectsRoutes.push(project)
        }
      }

      if (projectsRoutes.length) {
        customer.views = projectsRoutes
        customer.collapse = true
      }

      if (!customersRoutes) {
        customersRoutes = [customer]
      } else {
        customersRoutes.push(customer)
      }

      //Ids
      customerId = {
        id: sidebarMenu[i].id,
        shortName: sidebarMenu[i].name,
      }

      if (!customersIds) {
        customersIds = [customerId]
      } else {
        customersIds.push(customerId)
      }
    }

    this.setState({ customersIds: customersIds })

    if (this.state.isManagerConsulting) {
      customer = {
        path: "/newCustomer",
        name: "< Novo Cliente >",
        key: "newCustomer",
        icon: "ni ni-building text-red",
        component: NewCustomer,
        layout: "/admin",
        sideBar: true,
        collapse: false,
      }

      if (!customersRoutes) {
        customersRoutes = [customer]
      } else {
        customersRoutes.push(customer)
      }
    }

    var routes = [
      {
        collapse: true,
        name: "Dashboards",
        icon: "ni ni-chart-pie-35 text-primary",
        state: "dashboardsCollapse",
        key: "dashboards",
        sideBar: true,
        views: [
          {
            path: "/feed",
            name: "Feed",
            key: "feed",
            miniName: "F",
            component: Feed,
            layout: "/admin",
            sideBar: true,
          },
        ],
      },
    ]

    routes.push({
      path: "/profile",
      name: "Perfil",
      key: "profile",
      miniName: "P",
      component: Profile,
      layout: "/admin",
      sideBar: false,
    })

    if (this.state.isManagerConsulting || isMainUser || isProjectResponsible) {
      routes[0].views.push({
        path: "/dashProject",
        name: "Análise de Projeto",
        key: "dashProject",
        miniName: "P",
        component: DashProject,
        layout: "/admin",
        sideBar: true,
      })

      routes[0].views.push({
        path: "/dashList",
        name: "Análise de Atividades",
        key: "dashLista",
        miniName: "L",
        component: DashList,
        layout: "/admin",
        sideBar: true,
      })

      if (this.state.isManagerConsulting) {
        routes[0].views.push({
          path: "/dashNonconformities",
          name: "Análise Não Conformidades",
          key: "dashNonconformities",
          miniName: "N",
          component: DashNonconformities,
          layout: "/admin",
          sideBar: true,
        })

        routes.push({
          collapse: true,
          name: "Cadastros",
          icon: "ni ni-single-copy-04 text-info",
          state: "cadastrosCollapse",
          key: "registers",
          sideBar: true,
          views: [
            {
              path: "/users",
              name: "Usuários",
              key: "users",
              miniName: "U",
              component: Users,
              layout: "/admin",
              sideBar: true,
            },
            {
              path: "/customers",
              name: "Clientes",
              key: "customers",
              miniName: "C",
              component: Customers,
              layout: "/admin",
              sideBar: true,
            },
            {
              path: "/nonconformities",
              name: "Não conformidades",
              key: "nonConformities",
              miniName: "N",
              component: Nonconformities,
              layout: "/admin",
              sideBar: true,
            },
          ],
        })
      }
    }
    routes.push({
      name: "Ajude-me",
      icon: "fa fa-question-circle text-primary",
      key: "help",
      sideBar: true,
      path: "/help",
      miniName: "H",
      component: Help,
      layout: "/admin",
    })

    this.setState({ routes: routes, customersRoutes: customersRoutes })
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }

  getCustomers = (customers) => {
    return customers.map((prop, key) => {
      if (prop.collapse) {
        return this.getCustomers(prop.views)
      }

      if (prop.parentId) {
        let paramPath = "/:parentId"

        if (prop.isPlan) paramPath += "/:cicleId/:itemId/:testId"

        if (prop.isList) paramPath += "/:taskId"

        return (
          <Route
            path={prop.layout + prop.path + paramPath}
            component={prop.component}
            key={key}
            parentId={prop.parentId}
          />
        )
      }

      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  getBrandText = (path) => {
    for (let i = 0; i < this.state.routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          this.state.routes[i].layout + this.state.routes[i].path
        ) !== -1
      ) {
        return this.state.routes[i].name
      }
    }
    return "Brand"
  }
  getBrandTextC = (path) => {
    for (let i = 0; i < this.state.customersRoutes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          this.state.customersRoutes[i].layout +
            this.state.customersRoutes[i].path
        ) !== -1
      ) {
        return this.state.customersRoutes[i].name
      }
    }
    return "Brand"
  }
  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned")
      document.body.classList.add("g-sidenav-hidden")
    } else {
      document.body.classList.add("g-sidenav-pinned")
      document.body.classList.remove("g-sidenav-hidden")
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    })
  }
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light"
  }

  searchChange = (e) => {
    this.setState({ searchExp: e.target.value })

    const searchExp = e.target.value.toLowerCase()

    var customersRoutes = this.state.customersRoutes
    var canAddCustomer = true
    var canAddProject = true
    var canAddPlan = true

    for (let i = 0; i < customersRoutes.length; i++) {
      if (customersRoutes[i].collapse) {
        canAddCustomer = false

        let projects = customersRoutes[i].views

        for (let j = 0; j < projects.length; j++) {
          canAddProject = false

          if (projects[j].collapse) {
            let plans = projects[j].views

            for (let k = 0; k < plans.length; k++) {
              if (plans[k].name) {
                let name = plans[k].name
                name = name.toLowerCase()

                canAddPlan = name.includes(searchExp)

                customersRoutes[i].views[j].views[k].sideBar = canAddPlan

                if (canAddPlan) {
                  canAddProject = true
                }
              }
            }
          } else {
            if (projects[j].name) {
              let name = projects[j].name
              name = name.toLowerCase()

              canAddProject = name.includes(searchExp)
            }
          }

          if (projects[j].name) {
            let projName = projects[j].name

            projName = projName.toLowerCase()

            // customersRoutes[i].views[j].sideBar = canAddProject || projName.includes( searchExp );
            customersRoutes[i].views[j].sideBar = canAddProject
          }

          if (canAddProject) {
            canAddCustomer = true
          }
        }
      } else {
        let name = customersRoutes[i].name

        name = name.toLowerCase()

        canAddCustomer = name.includes(searchExp)
      }

      if (customersRoutes[i].name) {
        let custName = customersRoutes[i].name

        custName = custName.toLowerCase()

        // customersRoutes[ i ].sideBar = canAddCustomer || custName.includes( searchExp );
        customersRoutes[i].sideBar = canAddCustomer
      }
    }

    this.setState({ customersRoutes: customersRoutes })
  }

  searchKeyPress = (e) => {
    if (e.key === "Enter") {
      // alert('Em breve fará uma pesquisa pela expressão: ' + e.target.value)
      e.preventDefault()
    }
  }

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          customers={this.state.customersRoutes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/smartflow.png"),
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
            brandTextC={this.getBrandTextC(this.props.location.pathname)}
            searchExp={this.state.searchExp}
            searchChange={this.searchChange.bind(this)}
            searchKeyPress={this.searchKeyPress.bind(this)}
          />
          <Switch>
            {this.getRoutes(this.state.routes)}
            {this.getCustomers(this.state.customersRoutes)}
            <Redirect from="*" to={this.state.toPortal} />
          </Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    )
  }
}

export default Admin
