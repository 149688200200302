import React from "react"
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"

//Custom components
import User from "components/Modals/User.js"
import api from "services/api"
import Loading from "components/Modals/Loading"
import Avatar from "react-avatar"

const kinds = { M: "Gerente", R: "Recurso" }

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class Users extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,

    currentUserId: "",

    //Modal user variables
    modalUserOpened: false,
    modalTitle: "",
    id: "",
    crud: "",
    readOnly: false,
    fullName: "",
    email: "",
    password: "",
    kind: "",
    class: "",
    active: false,
    mainUser: false,
    image: null,
    customer: "",
    customersList: [],

    //Modal user fields validations
    customStyles: {
      fullNameState: null,
      emailState: null,
      passwordState: null,
      customerState: null,
    },
  }

  componentDidMount() {
    this.loadData()
    this.loadCustomersList()
  }

  loadCustomersList = async () => {
    const currentUserId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)

    this.setState({
      currentUserId: currentUserId,
    })

    const customers = await api.get("/customer/all")

    var customer = {}
    var customersList = []

    for (let i = 0; i < customers.data.length; i++) {
      customer = {
        id: customers.data[i]._id,
        text: customers.data[i].shortName,
      }

      if (customersList.length === 0) {
        customersList = [customer]
      } else {
        customersList.push(customer)
      }
    }

    this.setState({ customersList: customersList, isLoading: false })
  }

  loadData = async () => {
    this.setState({ isLoading: true })

    var users = []
    var row = {}
    var response = await api.get("/user/all")

    const usersAux = response.data

    for (let i = 0; i < usersAux.length; i++) {
      row = {
        _id: usersAux[i]._id,
        fullName: usersAux[i].fullName,
        email: usersAux[i].email,
        kind: usersAux[i].kind,
        class: usersAux[i].class,
        customer: usersAux[i].customer,
        customerShortName: usersAux[i].customerShortName,
        active: usersAux[i].active,
        mainUser: usersAux[i].mainUser,
        image: usersAux[i].image,
      }

      // if ( row.image && row.image !== ""){
      //   response = await api.get( "/document/read/" + row.image )

      //   if ( response.data ){
      //     row = { ...row, urlImage: response.data.url }
      //   }
      // }

      if (users.length === 0) {
        users = [row]
      } else {
        users.push(row)
      }
    }

    this.setState({ data: users, isLoading: false })
  }

  openModalUser = async (e, row, crud) => {
    this.setState({ crud: crud })

    if (crud === "C") {
      this.setState({ modalTitle: "Novo Usuário" })
    } else if (crud === "R") {
      this.setState({ modalTitle: "Visualizando Usuário" })
    } else if (crud === "U") {
      this.setState({ modalTitle: "Alterando Usuário" })
    } else if (crud === "D") {
      this.setState({ modalTitle: "Excluindo Usuário" })
    }

    if (crud === "C") {
      this.setState({
        crud: crud,
        kind: "R",
        class: "CR",
      })
    } else {
      this.setState({
        id: row._id,
        crud: crud,
        readOnly: crud === "R" || crud === "D",
        fullName: row.fullName,
        email: row.email,
        password: process.env.REACT_APP_DEFPWD,
        kind: row.kind,
        class: row.class,
        active: row.active,
        mainUser: row.mainUser,
        customer: row.customer,
        image: row.image,
      })
    }

    this.setState({ modalUserOpened: true })
  }

  closeModalUser(e) {
    this.setState({ modalUserOpened: false })

    this.clearModalState()
  }

  clearModalState() {
    this.setState({
      id: "",
      crud: "",
      readOnly: false,
      fullName: "",
      email: "",
      password: "",
      kind: "",
      class: "",
      active: false,
      mainUser: false,
      customer: "",
      saving: false,

      //Validations
      customStyles: {
        fullNameState: null,
        emailState: null,
        passwordState: null,
        customerState: null,
      },
    })
  }

  modalSave = async (e) => {
    this.setState({ saving: true })

    const isManagerConsulting =
      this.state.class === "CG" && this.state.kind === "M"
    const isResourceConsulting =
      this.state.class === "CG" && this.state.kind === "R"
    const isManagerCustomer =
      this.state.class === "CR" && this.state.kind === "M"

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    if (this.state.crud === "D") {
      if (this.state.id === this.state.currentUserId) {
        this.warningAlert("Você não pode excluir seu próprio perfil.")
        this.setState({ saving: false })
        return
      }

      var filters = { deleted: "N" }

      if (isManagerConsulting || isResourceConsulting) {
        filters = { ...filters, analyst: this.state.id }
      } else {
        if (isManagerCustomer) {
          filters = { ...filters, manager: this.state.id }
        } else {
          filters = { ...filters, keyUser: this.state.id }
        }
      }

      var response = await api.post("/plan/search", filters)

      if (response.data.length > 0) {
        this.warningAlert("Este usuário possui planos atribuídos a ele.")
        this.setState({ saving: false })
        return
      }

      response = await api.post("/test/search", filters)

      if (response.data.length > 0) {
        this.warningAlert("Este usuário possui testes atribuídos a ele.")
        this.setState({ saving: false })
        return
      }

      if (this.state.image && this.state.image !== null) {
        await api.delete("/document/delete/" + this.state.image)
      }

      try {
        await api.put("/user/update/" + this.state.id, {
          deleted: "Y",
          image: null,
        })
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    } else if (this.state.crud === "C" || this.state.crud === "U") {
      //Field content validations
      if (newState.fullName === "") {
        newState.fullNameState = "invalid"
        letSave = false
      } else {
        newState.fullNameState = "valid"
      }

      if (newState.email === "") {
        newState.emailState = "invalid"
        letSave = false
      } else {
        newState.emailState = "valid"
      }

      if (newState.password === "") {
        newState.passwordState = "invalid"
        letSave = false
      } else {
        newState.passwordState = "valid"
      }

      if (newState.class === "CR") {
        if (this.state.customer === "") {
          letSave = false
          newState.customerState = "invalid"
        }
      } else {
        newState.customerState = "valid"
      }

      this.setState({
        customStyles: newState,
      })

      if (!letSave) {
        this.setState({ saving: false })
        return
      } else {
        const existUser = await api.post("/user/search", {
          email: this.state.email,
          deleted: "N",
        })

        if (existUser.data.length > 0) {
          if (existUser.data[0]._id !== this.state.id) {
            this.warningAlert(
              "Já existe um usuário cadastrado com este e-mail."
            )
            this.setState({ saving: false })
            return
          }
        }

        var customer = null

        if (this.state.customer && this.state.customer !== "") {
          customer = this.state.customer
        }

        var customerShortName = ""

        for (let i = 0; i < this.state.customersList.length; i++) {
          if (this.state.customersList[i].id === this.state.customer) {
            customerShortName = this.state.customersList[i].text
            break
          }
        }

        newData = {
          fullName: this.state.fullName,
          email: this.state.email,
          kind: this.state.kind,
          class: this.state.class,
          customer: customer,
          customerShortName: customerShortName,
          active: this.state.active,
          mainUser: this.state.mainUser,
          image: null,
        }

        if (
          this.state.password !== "" &&
          this.state.password !== process.env.REACT_APP_DEFPWD
        ) {
          newData = { ...newData, password: this.state.password }
        }

        //Looking for another main user on customer
        if (this.state.class === "CR" && this.state.mainUser) {
          const mainUser = await api.post("/user/search", {
            customer: this.state.customer,
            mainUser: true,
            deleted: "N",
          })

          if (mainUser.data.length > 0) {
            if (mainUser.data[0]._id !== this.state.id) {
              this.confirmMainUser(newData, mainUser.data[0]._id)
              return
            }
          }
        }

        this.saveUserData(newData)
      }
    }
  }

  confirmMainUser = async (newData, currentMainUserId) => {
    var firstName = this.state.fullName

    if (String(firstName).indexOf(" ") > 0) {
      firstName = String(firstName).substring(0, String(firstName).indexOf(" "))
    }

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Tem certeza ?"
          onConfirm={() => {
            this.setState({ saving: false })
            this.hideAlert()
          }}
          onCancel={async () => {
            this.hideAlert()
            await api.put("/user/update/" + currentMainUserId, {
              mainUser: false,
            })
            this.saveUserData(newData)
          }}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="success"
          cancelBtnText={"Atribuir " + firstName + " como responsável!"}
          btnSize=""
        >
          {"Já existe um responsável pelo cliente."}
        </ReactBSAlert>
      ),
    })
  }

  saveUserData = async (newData) => {
    const isManagerConsulting =
      this.state.class === "CG" && this.state.kind === "M"
    const isResourceConsulting =
      this.state.class === "CG" && this.state.kind === "R"
    const isManagerCustomer =
      this.state.class === "CR" && this.state.kind === "M"

    var success = true

    if (this.state.crud === "C") {
      try {
        await api.post("/user/create", newData)
      } catch (err) {
        this.warningAlert("Já existe um usuário cadastrado com este e-mail.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    } else {
      newData = { ...newData, oldEmail: this.state.email }

      try {
        //Update user
        await api.put("/user/update/" + this.state.id, newData)

        //Update interactions
        var response = await api.post("/interaction/search", {
          deleted: "N",
          user: this.state.id,
        })

        const interactions = response.data

        if (interactions.length > 0) {
          for (let i = 0; i < interactions.length; i++) {
            await api.put("/interaction/update/" + interactions[i]._id, {
              userFullName: this.state.fullName,
            })
          }
        }

        var filterChildren = {
          deleted: "N",
        }

        if (isManagerConsulting || isResourceConsulting) {
          filterChildren = { ...filterChildren, analyst: this.state.id }
        } else {
          if (isManagerCustomer) {
            //Update projects
            response = await api.post("/smartflowproject/search", {
              deleted: "N",
              responsible: this.state.id,
            })

            let projects = response.data

            if (projects.length > 0) {
              for (let i = 0; i < projects.length; i++) {
                await api.put("/smartflowproject/update/" + projects[i]._id, {
                  responsibleFullName: this.state.fullName,
                })
              }
            }

            filterChildren = { ...filterChildren, manager: this.state.id }
          } else {
            filterChildren = { ...filterChildren, keyUser: this.state.id }
          }
        }

        //Update plans
        response = await api.post("/plan/search", filterChildren)

        const plans = response.data

        if (plans.length > 0) {
          for (let i = 0; i < plans.length; i++) {
            if (isManagerConsulting || isResourceConsulting) {
              await api.put("/plan/update/" + plans[i]._id, {
                analystFullName: this.state.fullName,
              })
            } else {
              if (isManagerCustomer) {
                await api.put("/plan/update/" + plans[i]._id, {
                  managerFullName: this.state.fullName,
                })
              } else {
                await api.put("/plan/update/" + plans[i]._id, {
                  keyUserFullName: this.state.fullName,
                })
              }
            }
          }
        }

        //Update tests
        response = await api.post("/test/search", filterChildren)

        const tests = response.data

        if (tests.length > 0) {
          for (let i = 0; i < tests.length; i++) {
            if (isManagerConsulting || isResourceConsulting) {
              await api.put("/test/update/" + tests[i]._id, {
                analystFullName: this.state.fullName,
              })
            } else {
              if (isManagerCustomer) {
                await api.put("/test/update/" + tests[i]._id, {
                  managerFullName: this.state.fullName,
                })
              } else {
                await api.put("/test/update/" + tests[i]._id, {
                  keyUserFullName: this.state.fullName,
                })
              }
            }
          }
        }
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar alterar. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeModalUser()
      }
    }
  }

  stylesForm = async (e, stateName) => {
    if (stateName === "active") {
      this.setState({ active: !this.state.active })
      return
    }

    if (stateName === "mainUser") {
      this.setState({ mainUser: !this.state.mainUser })
      return
    }

    if (stateName === "class") {
      this.setState({ class: e.target.value })

      if (e.target.value === "CG") {
        var newStyles = this.state.customStyles

        newStyles.customerShortNameState = "valid"

        this.setState({
          customer: "",
          customerShortName: "",
          customStyles: newStyles,
          mainUser: false,
        })
      }

      return
    }

    let newState = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid"
    } else {
      newState[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    })
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Usuários"
          items={[
            {
              level: "parent",
              name: "Cadastros",
            },
            {
              level: "nav",
              name: "Usuários",
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Usuários do SmartFlow</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-neutral btn-round btn-icon"
                        color="default"
                        onClick={(e) => this.openModalUser(e, null, "C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Cadastro</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "profileImg",
                      editable: false,
                      text: "Usuário",
                      // isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <div>
                            {row.image ? (
                              <img
                                alt="..."
                                className="avatar rounded-circle mr-3"
                                src={row.image.url}
                              />
                            ) : (
                              <Avatar
                                className="avatar rounded-circle mr-3"
                                name={row.fullName}
                                maxInitials={2}
                                round={true}
                                size={48}
                                title={" "}
                                color={"#172b4d"}
                                fgColor={"#11cdef"}
                              />
                            )}
                            <b>{row.fullName}</b>
                          </div>
                        )
                      },
                    },
                    {
                      dataField: "fullName",
                      text: "Nome Aux",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "email",
                      text: "E-mail",
                      sort: true,
                    },
                    {
                      dataField: "kind",
                      text: "Tipo",
                      sort: true,
                      formatter: (cell, row) => kinds[cell],
                    },
                    {
                      dataField: "class",
                      text: "Classificação",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "customer",
                      text: "Customer Id",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "image",
                      text: "Imagem",
                      sort: true,
                      hidden: true,
                    },
                    {
                      dataField: "customerShortName",
                      text: "Cliente",
                      sort: true,
                    },
                    {
                      dataField: "active",
                      text: "Ativo",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle">
                            <input
                              defaultChecked={row.active}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        )
                      },
                    },
                    {
                      dataField: "mainUser",
                      text: "Responsável Cliente",
                      hidden: true,
                    },
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "U")}
                              >
                                <i className="fas fa-user-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openModalUser(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
          <User
            {...this.props}
            modalOpened={this.state.modalUserOpened}
            closeModal={this.closeModalUser.bind(this)}
            modalTitle={this.state.modalTitle}
            id={this.state.id}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            fullName={this.state.fullName}
            email={this.state.email}
            password={this.state.password}
            kind={this.state.kind}
            class={this.state.class}
            active={this.state.active}
            mainUser={this.state.mainUser}
            customer={this.state.customer}
            customStyles={this.state.customStyles}
            modalSave={this.modalSave.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            customersList={this.state.customersList}
            fromUsersBrowse={true}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default Users
