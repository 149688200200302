import React from "react"
import Select2 from "react-select2-wrapper"

// reactstrap components
import { Card, CardBody, Col, Form, Input, Row } from "reactstrap"

class NewListDataCard extends React.Component {
  render() {
    return (
      <>
        <Card>
          <CardBody>
            <Form className="needs-validation" noValidate autoComplete="off">
              <Row className="align-items-center">
                <Col className="mb-3" md="9">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom01"
                  >
                    Nome
                  </label>
                  <Input
                    autoFocus
                    id="validationCustom01"
                    placeholder="Área de negócio"
                    type="text"
                    disabled
                    value={this.props.name}
                  />
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom03"
                  >
                    Gestor Golive
                  </label>
                  <Select2
                    className="form-control"
                    type="text"
                    value={this.props.managerCG}
                    disabled={!this.props.copyList}
                    onSelect={(e) =>
                      this.props.stylesForm(e, "managerCG", this.props.id)
                    }
                    options={{
                      placeholder: "Selecione",
                      language: {
                        noResults: function () {
                          return ""
                        },
                      },
                    }}
                    data={this.props.analystList}
                  />

                  <div className="invalid-feedback">Obrigatório</div>
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom04"
                  >
                    Gestor Cliente
                  </label>
                  <Select2
                    className="form-control"
                    type="text"
                    value={this.props.managerCR}
                    disabled={!this.props.copyList}
                    onSelect={(e) =>
                      this.props.stylesForm(e, "managerCR", this.props.id)
                    }
                    options={{
                      placeholder: "Selecione",
                      language: {
                        noResults: function () {
                          return ""
                        },
                      },
                    }}
                    data={this.props.keyUserList}
                  />

                  <div className="invalid-feedback">Obrigatório</div>
                </Col>
                <Col className="mb-3" md="3">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom02"
                  >
                    Copiar essa lista ?
                  </label>
                  <div>
                    <label className="custom-toggle mr-1">
                      <input
                        defaultChecked={this.props.copyList}
                        type="checkbox"
                        value={this.props.copyList}
                        onChange={(e) =>
                          this.props.stylesForm(e, "copyList", this.props.id)
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </Col>
              </Row>
              {/* <Row className="align-items-center">
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom03"
                  >
                    Analista
                  </label>
                  <Select2
                    className="form-control"
                    type="text"
                    value={this.props.analyst}
                    disabled={!this.props.copyPlan}
                    onSelect={(e) =>
                      this.props.stylesForm(e, "analyst", this.props.id)
                    }
                    options={{
                      placeholder: "Selecione",
                      language: {
                        noResults: function () {
                          return ""
                        },
                      },
                    }}
                    data={this.props.analystList}
                  />
                  <Input
                    hidden
                    valid={this.props.customStyles.analystState === "valid"}
                    invalid={this.props.customStyles.analystState === "invalid"}
                  />
                  <div className="invalid-feedback">Obrigatório</div>
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom04"
                  >
                    Usuário Chave
                  </label>
                  <Select2
                    className="form-control"
                    type="text"
                    value={this.props.keyUser}
                    disabled={!this.props.copyPlan}
                    onSelect={(e) =>
                      this.props.stylesForm(e, "keyUser", this.props.id)
                    }
                    options={{
                      placeholder: "Selecione",
                      language: {
                        noResults: function () {
                          return ""
                        },
                      },
                    }}
                    data={this.props.keyUserList}
                  />
                  <Input
                    hidden
                    valid={this.props.customStyles.keyUserState === "valid"}
                    invalid={this.props.customStyles.keyUserState === "invalid"}
                  />
                  <div className="invalid-feedback">Obrigatório</div>
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="validationCustom05"
                  >
                    Gerente Funcional
                  </label>
                  <Select2
                    className="form-control"
                    type="text"
                    value={this.props.manager}
                    disabled={!this.props.copyPlan}
                    onSelect={(e) =>
                      this.props.stylesForm(e, "manager", this.props.id)
                    }
                    options={{
                      placeholder: "Selecione",
                      language: {
                        noResults: function () {
                          return ""
                        },
                      },
                    }}
                    data={this.props.managerList}
                  />
                  <Input
                    hidden
                    valid={this.props.customStyles.managerState === "valid"}
                    invalid={this.props.customStyles.managerState === "invalid"}
                  />
                  <div className="invalid-feedback">Obrigatório</div>
                </Col>
              </Row> */}
            </Form>
          </CardBody>
        </Card>
      </>
    )
  }
}

export default NewListDataCard
