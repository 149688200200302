import React from "react";
import Dropzone from "dropzone";
import { uniqueId } from 'lodash';
import filesize from 'filesize';
// reactstrap components
import {
  Input,
  Card,
  CardBody
} from "reactstrap";

Dropzone.autoDiscover = false;

class BackImageDropzone extends React.Component {

  componentDidMount() {

    let currentSingleFile = undefined;
    
    new Dropzone(document.getElementById("dropzone-single"), {
      dictDefaultMessage: "Clique para localizar ou arraste e solte uma imagem",
      url: "https://",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName("dz-preview-single")[0],
      previewTemplate: document.getElementsByClassName("dz-preview-single")[0].innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function() {
        this.on("addedfile", function(file) {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;

          const uploadedfile = {
            file,
            id: uniqueId(),
            name: file.name,
            readableSize: filesize(file.size),
            upload: true,
            url: null,
          };

          var newFlowchartFile = document.getElementById( "newFlowchartFile" );

          if( newFlowchartFile !== null ){

            newFlowchartFile.uploadedfile = uploadedfile

          }

        });
      },
    
    });
    document.getElementsByClassName("dz-preview-single")[0].innerHTML = "";
  }
  render() {
    return (
      <>
        <Card>
            <CardBody>
                <div className="dropzone dropzone-single mb-3" id="dropzone-single">
                <div className="fallback">
                    <div className="custom-file">
                    <input
                        className="custom-file-input"
                        id="projectCoverUploads"
                        type="file"
                    />
                    <label
                        className="custom-file-label"
                        htmlFor="projectCoverUploads"
                    >
                        Choose file
                    </label>
                    </div>
                </div>
                <div className="dz-preview dz-preview-single">
                    <div className="dz-preview-cover">
                    <img
                        alt="..."
                        className="dz-preview-img"
                        data-dz-thumbnail=""
                    />
                    </div>
                </div>
                <Input id="newFlowchartFile" hidden uploadedfile={null}/>
                </div>
            </CardBody>
        </Card>
      </>
    );
  }
}

export default BackImageDropzone;