import React from "react";
// reactstrap components
import {
    UncontrolledTooltip,
} from "reactstrap";

import Avatar from 'react-avatar';

class AvatarLinkGL8 extends React.Component {

    render() {

        return (
            <a
                className={this.props.avatarClass}
                href={"#" + this.props.filterName + this.props.filterId}
                onClick={(e) => this.props.onClick(e, this.props.filterId, this.props.filterName)}
                id={this.props.id}
                style={{overflow: 'hidden'}}
            >
                {this.props.image && this.props.image !== null ?(
                    <img
                        alt="..."
                        src={this.props.image.url}
                    />
                ):(
                    <Avatar
                        name={this.props.fullName}
                        maxInitials={2}
                        round={true}
                        size={this.props.size}
                        title={" "}
                        color={"#172b4d"}
                        fgColor={"#11cdef"}
                    />
                )}
                <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target={this.props.id}
                >
                    {this.props.fullName}
                </UncontrolledTooltip>
            </a>
        );
    }
    
}

export default AvatarLinkGL8;