import React from "react"

// reactstrap components
import { Container, Row, Col } from "reactstrap"
// core components
import ReactBSAlert from "react-bootstrap-sweetalert"

//Custom components
import { isConsulting } from "services/auth"
import { isManager } from "services/auth"
import { userFullName } from "services/auth"
import { isDemo } from "services/auth"
import CustomerHeader from "components/Headers/CustomerHeader.js"
import Customer from "components/Modals/Customer.js"
import api from "services/api"
import LatestInteractions from "components/Feeds/LatestInteractions.js"
import TotalTests from "components/Feeds/TotalTests.js"

class CustomerConsole extends React.Component {
  state = {
    isLoading: true,
    id: "",
    cnpj: "",
    fullName: "",
    shortName: "",
    mainUserFullName: "",
    alert: null,
    customerProjects: [],

    //Customer modal variables
    saving: false,
    crud: "",
    customerModalOpened: false,
    customerModalTitle: "",
    newCnpj: "",
    newFullName: "",
    newShortName: "",
    customStyles: {
      newCnpjState: null,
      newFullNameState: null,
      newShortNameState: null,
    },

    //Current User
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    currentUserFullName: "",

    //Feed
    totalTests: 0,
    concludedTests: 0,
    inProgressTests: 0,
    notStartedTests: 0,
    concludedTestsPerc: 0,
    inProgressTestsPerc: 0,
    notStartedTestsPerc: 0,

    feedTitle: "Carregando últimas atualizações...",
    totalTestsTitle: "Carregando estatísticas...",
    interactions: [],
  }

  componentDidMount() {
    this.loadData(this.props.match.params.parentId)
    this.loadFeed(this.props.match.params.parentId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.id) {
      this.loadData(nextProps.match.params.parentId)
      this.loadFeed(nextProps.match.params.parentId)
    }
  }

  loadData = async (paramId) => {
    const id = paramId

    const customer = await api.get("/customer/read/" + id)

    let projectsResponse = await api.post("smartflowproject/search", {
      customer: id,
    })

    //Search for the main user
    const mainUser = await api.post("/user/search", {
      customer: id,
      mainUser: true,
      deleted: "N",
    })

    let mainUserFullName = "-"

    if (mainUser.data) {
      if (mainUser.data.length > 0) {
        mainUserFullName = mainUser.data[0].fullName
      }
    }
    this.setState({
      id: id,
      cnpj: customer.data.cnpj,
      fullName: customer.data.fullName,
      shortName: customer.data.shortName,
      mainUserFullName: mainUserFullName,
      customerProjects: projectsResponse.data,
    })
  }

  loadFeed = async (paramId) => {
    this.setState({ isLoading: true })

    const id = paramId

    const customer = await api.get("/customer/read/" + id)

    const isManagerConsulting = isManager() && isConsulting()
    const isResourceConsulting = !isManager() && isConsulting()
    const isManagerCustomer = isManager() && !isConsulting()
    const currentUserId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)

    const isDemoAccess = isDemo()

    var feedTitle = userFullName()
    var totalTestsTitle = "Sob sua responsabilidade"

    if (String(feedTitle).indexOf(" ") > 0) {
      feedTitle = String(feedTitle).substring(0, String(feedTitle).indexOf(" "))
    }

    if (isManagerConsulting || isManagerCustomer) {
      feedTitle +=
        ", essas são as últimas atualizações relacionadas à equipe de testes " +
        customer.data.shortName +
        ":"
      totalTestsTitle = "Sob responsabilidade da equipe"
    } else {
      feedTitle +=
        ", essas são as úlitmas atualizações do(a) " +
        customer.data.shortName +
        ", relacionadas à você:"
    }

    this.setState({
      feedTitle: feedTitle,
      totalTestsTitle: totalTestsTitle,
    })

    var filters = {
      deleted: "N",
      reviewed: "N",
    }

    var filtersTaskInteractions = {
      deleted: "N",
      customer: id,
    }

    if (isManagerConsulting) {
      filters = {
        ...filters,
        customer: id,
      }
    } else {
      if (isManagerCustomer) {
        filters = {
          ...filters,
          customer: id,
          manager: currentUserId,
        }
      } else {
        if (isResourceConsulting) {
          filters = {
            ...filters,
            customer: id,
            analyst: currentUserId,
          }
        } else {
          filters = {
            ...filters,
            customer: id,
            keyUser: currentUserId,
          }
        }
      }
    }

    const respTests = await api.post("/test/totalTests", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    })

    if (respTests.data) {
      this.setState({
        totalTests: respTests.data.totalTests,
        concludedTests: respTests.data.concludedTests,
        inProgressTests: respTests.data.inProgressTests,
        notStartedTests: respTests.data.notStartedTests,
        concludedTestsPerc: respTests.data.concludedTestsPerc,
        inProgressTestsPerc: respTests.data.inProgressTestsPerc,
        notStartedTestsPerc: respTests.data.notStartedTestsPerc,
      })
    }

    filters = { ...filters, showOnFeed: "Y" }

    const respInteractions = await api.post("/interaction/feed", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    })

    const respTaskInteractions = await api.post("/taskInteraction/feed", {
      filters: filtersTaskInteractions,
      isDemoAccess: isDemoAccess,
      isManagerConsulting: isManagerConsulting,
      userId: currentUserId,
    })

    var interactions = []

    if (
      respInteractions.data[0].status === "WA" &&
      respTaskInteractions.data[0].status === "WA"
    ) {
      interactions = respInteractions.data
    } else {
      if (respInteractions.data[0].status !== "WA") {
        interactions = respInteractions.data
      }

      if (respTaskInteractions.data[0].status !== "WA") {
        interactions = [...interactions, ...respTaskInteractions.data]
      }
    }

    interactions.sort((a, b) => (a.dateTime > b.dateTime ? -1 : 1))

    this.setState({
      isLoading: false,
      interactions: interactions,
    })
  }

  openCustomerModal(e, crud) {
    var customerModalTitle = "Alterando Cliente"

    if (crud === "D") {
      customerModalTitle = "Excluindo Cliente"
    }

    this.setState({
      customerModalTitle: customerModalTitle,
      customerModalOpened: true,
      crud: crud,

      newCnpj: this.state.cnpj,
      newFullName: this.state.fullName,
      newShortName: this.state.shortName,
    })
  }

  closeCustomerModal = async (e, action) => {
    if (action !== "abort") {
      this.setState({ saving: true })

      var success = true

      if (this.state.crud === "D") {
        try {
          const projects = await api.post("/smartflowproject/search", {
            customer: this.state.id,
            deleted: "N",
          })

          for (let i = 0; i < projects.data.length; i++) {
            await api.put("/smartflowproject/update/" + projects.data[i]._id, {
              deleted: "Y",
            })

            let plans = await api.post("/plan/search", {
              project: projects.data[i]._id,
              deleted: "N",
            })

            for (let j = 0; j < plans.data.length; j++) {
              await api.put("/plan/update/" + plans.data[j]._id, {
                deleted: "Y",
              })

              let versions = await api.post("/version/search", {
                plan: plans.data[j]._id,
                deleted: "N",
              })

              for (let k = 0; k < versions.data.length; k++) {
                await api.put("/version/update/" + versions.data[k]._id, {
                  deleted: "Y",
                })

                let cicles = await api.post("/cicle/search", {
                  version: versions.data[k]._id,
                  deleted: "N",
                })

                for (let l = 0; l < cicles.data.length; l++) {
                  await api.put("/cicle/update/" + cicles.data[l]._id, {
                    deleted: "Y",
                  })

                  let items = await api.post("/item/search", {
                    cicle: cicles.data[l]._id,
                    deleted: "N",
                  })

                  for (let m = 0; m < items.data.length; m++) {
                    await api.put("/item/update/" + items.data[m]._id, {
                      deleted: "Y",
                    })

                    let itemDocuments = await api.post("/itemDocument/search", {
                      item: items.data[m]._id,
                      deleted: "N",
                    })

                    for (let n = 0; n < itemDocuments.data.length; n++) {
                      await api.put(
                        "/itemDocument/update/" + itemDocuments.data[n]._id,
                        {
                          deleted: "Y",
                        }
                      )
                    }

                    let tests = await api.post("/test/search", {
                      item: items.data[m]._id,
                      deleted: "N",
                    })

                    for (let n = 0; n < tests.data.length; n++) {
                      await api.put("/test/update/" + tests.data[n]._id, {
                        deleted: "Y",
                      })

                      let testDocuments = await api.post(
                        "/testDocument/search",
                        {
                          test: tests.data[n]._id,
                          deleted: "N",
                        }
                      )

                      for (let p = 0; p < testDocuments.data.length; p++) {
                        await api.put(
                          "/testDocument/update/" + testDocuments.data[p]._id,
                          {
                            deleted: "Y",
                          }
                        )
                      }

                      let interactions = await api.post("/interaction/search", {
                        test: tests.data[n]._id,
                        deleted: "N",
                      })

                      for (let p = 0; p < interactions.data.length; p++) {
                        await api.put(
                          "/interaction/update/" + interactions.data[p]._id,
                          {
                            deleted: "Y",
                          }
                        )

                        let interactionDocs = await api.post(
                          "/interactionDocument/search",
                          {
                            interaction: interactions.data[p]._id,
                            deleted: "N",
                          }
                        )

                        for (let q = 0; q < interactionDocs.data.length; q++) {
                          await api.put(
                            "interactionDocument/update/" +
                              interactionDocs.data[q]._id,
                            {
                              deleted: "Y",
                            }
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          await api.put("/customer/update/" + this.state.id, {
            deleted: "Y",
          })
        } catch (err) {
          this.warningAlert("Houve um erro ao tentar excluir. Tente novamente.")
          success = false
          this.setState({ saving: false })
          return
        }

        if (success) {
          window.location.reload()
        }
      } else {
        let newState = this.state

        var letSave = true
        var newData = {}

        //Field content validations
        if (newState.newCnpj === "") {
          newState.customStyles.newCnpjState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newCnpjState = "valid"
        }

        if (newState.newFullName === "") {
          newState.customStyles.newFullNameState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newFullNameState = "valid"
        }

        if (newState.newShortName === "") {
          newState.customStyles.newShortNameState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newShortNameState = "valid"
        }

        this.setState({
          customStyles: newState.customStyles,
        })

        if (!letSave) {
          this.setState({ saving: false })
          return
        } else {
          newData = {
            cnpj: this.state.newCnpj,
            oldCnpj: this.state.newCnpj,
            fullName: this.state.newFullName,
            shortName: this.state.newShortName,
          }

          try {
            //Update customer
            await api.put("/customer/update/" + this.state.id, newData)

            //Update projects
            var response = await api.post("/smartflowproject/search", {
              deleted: "N",
              customer: this.state.id,
            })

            const projects = response.data

            if (projects.length > 0) {
              for (let i = 0; i < projects.length; i++) {
                await api.put("/smartflowproject/update/" + projects[i]._id, {
                  customerShortName: this.state.newShortName,
                })
              }
            }

            //Update plans
            response = await api.post("/plan/search", {
              deleted: "N",
              customer: this.state.id,
            })

            const plans = response.data

            if (plans.length > 0) {
              for (let i = 0; i < plans.length; i++) {
                await api.put("/plan/update/" + plans[i]._id, {
                  customerShortName: this.state.newShortName,
                })
              }
            }

            //Update cicles
            response = await api.post("/cicle/search", {
              deleted: "N",
              customer: this.state.id,
            })

            const cicles = response.data

            if (cicles.length > 0) {
              for (let i = 0; i < cicles.length; i++) {
                await api.put("/cicle/update/" + cicles[i]._id, {
                  customerShortName: this.state.newShortName,
                })
              }
            }

            //Update users
            response = await api.post("/user/search", {
              deleted: "N",
              customer: this.state.id,
            })

            const users = response.data

            if (users.length > 0) {
              for (let i = 0; i < users.length; i++) {
                await api.put("/user/update/" + users[i]._id, {
                  customerShortName: this.state.newShortName,
                })
              }
            }
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            )
            this.setState({ saving: false })
            success = false
            return
          }

          if (success) {
            if (this.state.shortName !== this.state.newShortName) {
              localStorage.setItem(
                process.env.REACT_APP_NEWREGID_KEY,
                this.state.id
              )
              localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "CR")
            } else {
              this.setState({
                cnpj: this.state.newCnpj,
                fullName: this.state.newFullName,
                shortName: this.state.newShortName,
              })
            }
            window.location.reload()
            this.setState({ saving: false })
          }
        }
      }
    }

    this.setState({ customerModalOpened: false })
  }

  stylesForm = async (e, stateName) => {
    let customStyles = this.state.customStyles

    if (e.target.value === null || e.target.value === "") {
      customStyles[stateName + "State"] = "invalid"
    } else {
      customStyles[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: customStyles,
    })
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  updateMainUser(newMainUserFullName) {
    this.setState({ mainUserFullName: newMainUserFullName })
  }

  render() {
    return (
      <>
        <CustomerHeader
          id={this.state.id}
          cnpj={this.state.cnpj}
          fullName={this.state.fullName}
          shortName={this.state.shortName}
          mainUserFullName={this.state.mainUserFullName}
          openCustomerModal={this.openCustomerModal.bind(this)}
          updateMainUser={this.updateMainUser.bind(this)}
        />
        <Container className="mt--6" fluid>
          <TotalTests
            totalTestsTitle={this.state.totalTestsTitle}
            totalTests={this.state.totalTests}
            concludedTests={this.state.concludedTests}
            inProgressTests={this.state.inProgressTests}
            notStartedTests={this.state.notStartedTests}
            concludedTestsPerc={this.state.concludedTestsPerc}
            inProgressTestsPerc={this.state.inProgressTestsPerc}
            notStartedTestsPerc={this.state.notStartedTestsPerc}
          />
          <Row>
            <Col xl="12">
              <LatestInteractions
                {...this.props}
                title={this.state.feedTitle}
                interactions={this.state.interactions}
                isLoading={this.state.isLoading}
              />
            </Col>
          </Row>
          <Customer
            modalOpened={this.state.customerModalOpened}
            customerProjects={this.state.customerProjects}
            id={this.state.id}
            cnpj={this.state.newCnpj}
            fullName={this.state.newFullName}
            shortName={this.state.newShortName}
            closeModal={this.closeCustomerModal.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            customStyles={this.state.customStyles}
            crud={this.state.crud}
            title={this.state.customerModalTitle}
            saving={this.state.saving}
            changeUserSidebarStatus={async (id, value, index) => {
              let newProjects = [...this.state.customerProjects]
              newProjects[index].smartflow_sidebar_active = value
              this.setState({
                customerProjects: newProjects,
              })
              await api.put("smartflowproject/update/" + id, {
                smartflow_sidebar_active: value,
              })
            }}
          />
        </Container>
        {this.state.alert}
      </>
    )
  }
}

export default CustomerConsole
