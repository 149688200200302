import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

import { isManager } from "services/auth";
import { isConsulting } from "services/auth";

import User from "components/Modals/User.js";
import api from "services/api";

class CustomerHeader extends React.Component {

  state = {
    alert: null,
    isManagerConsulting: false,
    
    //Modal user variables
    modalUserOpened: false,
    fullName: "",
    email: "",
    password: "",
    kind: "",
    class: "",
    active: false,
    mainUser: false,
    customer: "",
    customersList: [],

    //Modal user fields validations
    customStyles: {
      fullNameState: null,
      emailState: null,
      passwordState: null,
      customerState: null,
    },
  }

  componentDidMount(){
    this.setState({
      isManagerConsulting: isManager() && isConsulting(),
    })
  }

  warningAlert = ( message ) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  newUser(e){

    this.setState( {

      fullName: "",
      email: "",
      password: "",
      kind: "M",
      active: false,
      modalUserOpened: true,

    } );

  }

  closeModalUser = async(e, action) => {

    if( action !== "abort" ){

      this.setState({saving: true});

      let newState = this.state;

      var letSave = true;
      var newData = {};

      //Field content validations
      if (newState.fullName === "") {
        newState.fullNameState = "invalid";
        letSave = false;
      } else {
        newState.fullNameState = "valid";
      }
  
      if (newState.email === "") {
        newState.emailState = "invalid";
        letSave = false;
      } else {
        newState.emailState = "valid";
      }
  
      if (newState.password === "") {
        newState.passwordState = "invalid";
        letSave = false;
      } else {
        newState.passwordState = "valid";
      }
      
      if( newState.class === "CR" ) {
      
        if( this.state.customer === "" ) {
          letSave = false;
          newState.customerState = "invalid"
        }

      } else {
        newState.customerState = "valid"
      }
  
      this.setState({
        customStyles: newState
      });

      if(!letSave){
        this.setState({saving: false});
        return
      
      } else {

        const existUser = await api.post( "/user/search", {
          email: this.state.email,
          deleted: "N"
        } )
  
        if( existUser.data.length > 0 ){
  
          if( existUser.data[0]._id !== this.state.id ){
            this.warningAlert('Já existe um usuário cadastrado com este e-mail.');
            this.setState({saving: false});
            return
          }
        }

        newData = {
          fullName: this.state.fullName,
          email: this.state.email,
          password: this.state.password,
          kind: this.state.kind,
          class: "CR",
          mainUser: this.state.mainUser,
          customer: this.props.id,
          customerShortName: this.props.shortName,
          image: null,
          active: this.state.active
        }

        //Looking for another main user on customer
        if( this.state.mainUser ){

          const mainUser = await api.post( "/user/search", {
            customer: this.props.id,
            mainUser: true,
            deleted: "N"
          } )
          
          if( mainUser.data.length > 0 ){

            this.confirmMainUser( newData, mainUser.data[0]._id );
            return

          }

        }

        this.saveUserData( newData )
        this.setState({
          modalUserOpened: false,
          saving: false
        });

      }

    } else {

      this.setState({
        modalUserOpened: false,
        saving: false
      });

    }

  }

  confirmMainUser = async ( newData, currentMainUserId ) => {

    var firstName = this.state.fullName;

    if( String( firstName ).indexOf( " " ) > 0 ){

      firstName = String(firstName).substring( 0, String( firstName ).indexOf( " " ) );

    }

    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Tem certeza ?"
          onConfirm={() => {
            this.setState( { saving: false } )
            this.hideAlert()
          }}
          onCancel={async () => {
            this.hideAlert();
            await api.put( "/user/update/" + currentMainUserId, {
              mainUser: false
            } );
            this.saveUserData( newData );
          }}
          showCancel
          confirmBtnBsStyle="secondary"
          confirmBtnText="Cancelar"
          cancelBtnBsStyle="success"
          cancelBtnText={"Atribuir " + firstName + " como responsável!"}
          btnSize=""
        >
          {"Já existe um responsável pelo cliente."}
        </ReactBSAlert>
      )
    });
  };

  saveUserData = async( newData ) => {

    var success = true;

    try{
    
      await api.post( "/user/create", newData );

    } catch( err ) {
      success = false;
      this.warningAlert('Já existe um usuário cadastrado com este e-mail.');
      this.setState({saving: false});
      return
    }

    if( success ){
      this.setState({
        modalUserOpened: false,
        saving: false
      });
      this.props.updateMainUser(newData.fullName)

    }

  }

  stylesForm = async (e, stateName) => {

    if( stateName === "active" ){
      this.setState({ active: !this.state.active })
      return
    }

    if( stateName === "mainUser" ){
      this.setState({ mainUser: !this.state.mainUser })
      return
    }

    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState
    });

  };

  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' + require("assets/img/theme/customer_background.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          <span className="mask bg-gradient-danger opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                <h1 className="display-2 text-white">{this.props.shortName}</h1>
                <p className="text-white mt-0 mb-0">
                    {"Razão Social: " + this.props.fullName}
                </p>
                <p className="text-white mt-0 mb-0">
                    {"CNPJ: " + this.props.cnpj}
                </p>
                <p className="text-white mt-0 mb-5">
                    {"Responsável: " + this.props.mainUserFullName}
                </p>
                <Button
                  // className="btn-neutral"
                  color="default"
                  hidden={!this.state.isManagerConsulting}
                  onClick={(e) => this.props.openCustomerModal(e, "U")}
                >
                  Alterar Dados
                </Button>
                <Button
                  // className="btn-neutral"
                  color="default"
                  hidden={!this.state.isManagerConsulting}
                  onClick={(e) => this.props.openCustomerModal(e, "D")}
                >
                  Excluir Cliente
                </Button>
                <UncontrolledDropdown group hidden={!this.state.isManagerConsulting}>
                <DropdownToggle caret color="default">
                  <span className="btn-inner--icon">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">{" " }Novo</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={e => this.newUser(e)}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-circle-08" />
                    </span>
                    <span className="btn-inner--text">{" " }Usuário</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    to={"/admin/newProject/" + this.props.id /*+ "/c/i/t"*/}
                    tag={Link}
                  >
                    <span className="btn-inner--icon">
                      <i className="ni ni-ruler-pencil" />
                    </span>
                    <span className="btn-inner--text">{" " }Projeto</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              </Col>
            </Row>
          </Container>
          <User
            {...this.props}
            modalOpened={this.state.modalUserOpened}
            closeModal={this.closeModalUser.bind(this)}
            modalTitle={"Novo Usuário"}
            crud={"C"}
            readOnly={false}
            fullName={this.state.fullName}
            email={this.state.email}
            password={this.state.password}
            kind={this.state.kind}
            class={"CR"}
            active={this.state.active}
            mainUser={this.state.mainUser}
            customer={this.props.id}
            customStyles={this.state.customStyles}
            modalSave={this.closeModalUser.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            customersList={[
              {
                id: this.props.id,
                text: this.props.shortName
              }
            ]}
            fromUsersBrowse={false}
          />
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default CustomerHeader;
