import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
} from "reactstrap";

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ReactBSAlert from "react-bootstrap-sweetalert";

//Custom components
import Loading from "components/Modals/Loading.js"
import api from "services/api";

class NewCustomer extends React.Component {

  state = {
    cnpj: "",
    fullName: "",
    shortName: "",
    saving: false,
    alert: null,

    //Modal fields validations
    customStyles: {
      cnpjState: null,
      fullNameState: null,
      shortNameState: null,
    },
    
  };

  save = async (e) => {

    this.setState({saving: true})

    let newState = this.state;

    var letSave = true;
    var newData = {};
    var success = true;

    var newCustomer = {};

    //Field content validations
    if (newState.cnpj === "") {
      newState.cnpjState = "invalid";
      letSave = false;
    } else {
    newState.cnpjState = "valid";
    }

    if (newState.fullName === "") {
      newState.fullNameState = "invalid";
      letSave = false;
    } else {
      newState.fullNameState = "valid";
    }

    if (newState.shortName === "") {
      newState.shortNameState = "invalid";
      letSave = false;
    } else {
      newState.shortNameState = "valid";
    }

    this.setState({
      customStyles: newState
    });

    if(!letSave){
      this.setState({saving: false})
      return
    
    } else {

    newData = {
      cnpj: this.state.cnpj,
      fullName: this.state.fullName,
      shortName: this.state.shortName,
    }

    try{  
      newCustomer = await api.post( "/customer/create", newData );
    } catch( err ) {
      this.warningAlert('Ocorreu um erro ao tentar incluir. Tente novamente.');
      this.setState({saving: false})
      success = false;
    }

    if( success ){
      localStorage.setItem( process.env.REACT_APP_NEWREGID_KEY, newCustomer.data._id );
      localStorage.setItem( process.env.REACT_APP_NEWREGKIND_KEY, "CR" );
      window.location.reload();
    }

    }

  };

  stylesForm = async (e, stateName) => {

    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState
    });

  };

  warningAlert = ( message ) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  
  render() {
    return (
      <>
        <SimpleHeader
          name="Novo Cliente"
          items={[
            {
              level: "parent",
              name: "Clientes"
            },
            {
              level: "nav",
              name: "Novo Cliente"
            },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Novo Cliente</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form className="needs-validation" noValidate autoComplete="off">
                    <div className="form-row">
                      <Col className="mb-3" md="3">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom01"
                        >
                          CNPJ
                        </label>
                        <Input
                          autoFocus
                          id="validationCustom01"
                          placeholder="CNPJ do cliente"
                          type="text"
                          value={this.state.cnpj}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.cnpjState === "valid"
                          }
                          invalid={
                            this.state.customStyles.cnpjState === "invalid"
                          }
                          onChange={e =>
                            this.stylesForm(e, "cnpj")
                          }
                        />
                        <div className="invalid-feedback">
                          Digite o CNPJ do cliente.
                        </div>
                      </Col>
                      <Col className="mb-3" md="5">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Razão Social
                        </label>
                        <Input
                          id="validationCustom02"
                          placeholder="Razão social do cliente"
                          type="text"
                          value={this.state.fullName}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.fullNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.fullNameState === "invalid"
                          }
                          onChange={e => this.stylesForm(e, "fullName")}
                        />
                        <div className="invalid-feedback">
                          Digite a razão social do cliente.
                        </div>
                      </Col>
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom02"
                        >
                          Nome Fantasia
                        </label>
                        <Input
                          id="validationCustom02"
                          placeholder="Nome fantasia do cliente"
                          type="text"
                          value={this.state.shortName}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.shortNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.shortNameState === "invalid"
                          }
                          onChange={e => this.stylesForm(e, "shortName")}
                        />
                        <div className="invalid-feedback">
                          Digite o nome fantasia do cliente.
                        </div>
                      </Col>
                    </div>
                    <hr className="my-4" />
                    <Row>
                      <Col lg="12" className="text-right">
                        <Button
                          color="success"
                          type="button"
                          disabled={this.state.saving}
                          onClick={(e) => this.save(e)}
                        >
                          Salvar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>
          <Loading
            modalOpened={this.state.isLoading}
          />
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default NewCustomer;
