import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class TotalTests extends React.Component {

    render() {
        return (
            <>
                <div className="header-body">
                    <Row>
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                            <CardBody>
                                <Row>
                                <div className="col">
                                    <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                    >
                                    Total de testes
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                    {this.props.totalTests}
                                    </span>
                                </div>
                                <Col className="col-auto">
                                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                    <i className="ni ni-single-copy-04" />
                                    </div>
                                </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                <span className="text-nowrap">{this.props.totalTestsTitle}</span>
                                </p>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                            <CardBody>
                                <Row>
                                <div className="col">
                                    <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                    >
                                    Concluídos
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                    {this.props.concludedTests}
                                    </span>
                                </div>
                                <Col className="col-auto">
                                    <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                                    <i className="ni ni-check-bold" />
                                    </div>
                                </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                <span className="text-success mr-2">
                                    <i className="fa fa-check" /> {this.props.concludedTestsPerc}%
                                </span>{" "}
                                <span className="text-nowrap">Testes concluídos</span>
                                </p>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                            <CardBody>
                                <Row>
                                <div className="col">
                                    <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                    >
                                    Em andamento
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">{this.props.inProgressTests}</span>
                                </div>
                                <Col className="col-auto">
                                    <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                                    <i className="ni ni-user-run" />
                                    </div>
                                </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                <span className="text-warning mr-2">
                                    <i className="ni ni-user-run" /> {this.props.inProgressTestsPerc}%
                                </span>{" "}
                                <span className="text-nowrap">Testes em andamento</span>
                                </p>
                            </CardBody>
                            </Card>
                        </Col>
                        <Col md="6" xl="3">
                            <Card className="card-stats">
                            <CardBody>
                                <Row>
                                <div className="col">
                                    <CardTitle
                                    tag="h5"
                                    className="text-uppercase text-muted mb-0"
                                    >
                                    Não iniciados
                                    </CardTitle>
                                    <span className="h2 font-weight-bold mb-0">
                                        {this.props.notStartedTests}
                                    </span>
                                </div>
                                <Col className="col-auto">
                                    <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                                    <i className="ni ni-bell-55" />
                                    </div>
                                </Col>
                                </Row>
                                <p className="mt-3 mb-0 text-sm">
                                <span className="text-danger mr-2">
                                    <i className="ni ni-bell-55" /> {this.props.notStartedTestsPerc}%
                                </span>{" "}
                                <span className="text-nowrap">Testes não iniciados</span>
                                </p>
                            </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default TotalTests;