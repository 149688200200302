import React from "react"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"

class Task extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.props.modalOpened}
          autoFocus={false}
          size="xl"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.title}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom01"
                          >
                            Sequencial
                          </label>
                          <Input
                            id="validationCustom01"
                            type="text"
                            value={this.props.taskCode}
                            readOnly
                            maxLength="5"
                            valid={
                              this.props.customStyles.taskCodeState === "valid"
                            }
                            invalid={
                              this.props.customStyles.taskCodeState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskCode")
                            }
                          />
                          <div className="invalid-feedback">
                            Sequencial inválido.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom02"
                          >
                            Título
                          </label>
                          <Input
                            autoFocus
                            id="validationCustom02"
                            placeholder="Título da atividade (ex. Importação de Dados)"
                            type="text"
                            value={this.props.taskName}
                            readOnly={
                              this.props.readOnly ||
                              this.props.taskStatus === "CL"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.taskNameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.taskNameState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskName")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o título da atividade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label className="form-control-label">
                            Solicitante
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.taskRequester}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.taskStatus === "CL"
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "taskRequester")
                            }
                            options={{
                              placeholder: "Selecione um solicitante",
                              language: {
                                noResults: function () {
                                  return "Nenhum solicitante encontrado."
                                },
                              },
                            }}
                            data={this.props.requesterList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.taskRequesterState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskRequesterState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Solicitante inválido ou não selecionado
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom03"
                          >
                            Descrição da atividade
                          </label>
                          <Input
                            id="validationCustom03"
                            rows="3"
                            type="textarea"
                            value={this.props.taskDescription}
                            readOnly={
                              this.props.readOnly ||
                              this.props.taskStatus === "CL"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.taskDescriptionState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskDescriptionState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskDescription")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a descrição detalhada da atividade.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom04"
                          >
                            Data
                          </label>
                          <Input
                            id="validationCustom04"
                            type="date"
                            value={this.props.taskDate}
                            readOnly
                            // disabled
                            valid={
                              this.props.customStyles.taskDateState === "valid"
                            }
                            invalid={
                              this.props.customStyles.taskDateState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskDate")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data de criação da atividade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom05"
                          >
                            Prazo
                          </label>
                          <Input
                            id="validationCustom05"
                            type="date"
                            value={this.props.taskDueDate}
                            readOnly={
                              this.props.readOnly ||
                              this.props.taskStatus === "CL"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.taskDueDateState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskDueDateState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskDueDate")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o prazo da atividade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom06"
                          >
                            Conclusão
                          </label>
                          <Input
                            id="validationCustom06"
                            type="date"
                            value={this.props.taskConclusionDate}
                            readOnly={
                              this.props.readOnly ||
                              this.props.taskStatus !== "CL" ||
                              this.props.crud !== "C"
                            }
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .taskConclusionDateState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .taskConclusionDateState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "taskConclusionDate")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data de conclusão da atividade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="3">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom07"
                          >
                            Status
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.taskStatus}
                            disabled
                            onSelect={(e) =>
                              this.props.stylesForm(e, "taskStatus")
                            }
                            options={{
                              placeholder: "Selecione um status",
                              language: {
                                noResults: function () {
                                  return "Nenhum status encontrado."
                                },
                              },
                            }}
                            data={this.props.statusList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.taskStatusState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskStatusState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Status inválido ou não selecionado.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom08"
                          >
                            Responsável
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.taskResponsible}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.taskStatus === "CL"
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "taskResponsible")
                            }
                            options={{
                              placeholder: "Selecione um responsável",
                              language: {
                                noResults: function () {
                                  return "Nenhum responsável encontrado."
                                },
                              },
                            }}
                            data={this.props.responsibleList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.taskResponsibleState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskResponsibleState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Responsável inválido ou não selecionado.
                          </div>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom09"
                          >
                            Prioridade
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.taskPriority}
                            disabled={
                              this.props.readOnly ||
                              this.props.saving ||
                              this.props.taskStatus === "CL"
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "taskPriority")
                            }
                            options={{
                              placeholder: "Selecione",
                              language: {
                                noResults: function () {
                                  return "Sem prioridades."
                                },
                              },
                            }}
                            data={this.props.priorityList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.taskPriorityState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.taskPriorityState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Prioridade inválida ou não selecionada.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom10"
                          >
                            Módulo
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.taskModule}
                            disabled={this.props.saving}
                            data={this.props.plansList.map((plan) => {
                              return { id: plan._id, text: plan.businessArea }
                            })}
                            onSelect={(e) =>
                              this.props.stylesForm(e, "taskModule")
                            }
                          ></Select2>

                          <div className="invalid-feedback">
                            Digite o módulo da atividade.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="validationCustom10"
                          >
                            Percentual
                          </label>
                          <Input
                            id="validationCustom04"
                            type="text"
                            value={this.props.percentage}
                            readOnly
                          />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.modalSave(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default Task
