import api from "../services/api"

const jwt = require("jsonwebtoken")

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY
const SECRET = process.env.REACT_APP_SECRET
const USERID_KEY = process.env.REACT_APP_USERID_KEY
const USERFULLNAME_KEY = process.env.REACT_APP_USERFULLNAME_KEY
const USERKIND_KEY = process.env.REACT_APP_USERKIND_KEY
const USERCLASS_KEY = process.env.REACT_APP_USERCLASS_KEY
const USERIMGID_KEY = process.env.REACT_APP_USERIMGID_KEY
const NEWREGID_KEY = process.env.REACT_APP_NEWREGID_KEY
const NEWREGKIND_KEY = process.env.REACT_APP_NEWREGKIND_KEY

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

export const isTokenValid = async () => {
  try {
    await api.get("/user/read/" + localStorage.getItem(USERID_KEY))
    return true
  } catch (err) {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.removeItem(USERID_KEY)
    localStorage.removeItem(USERFULLNAME_KEY)
    localStorage.removeItem(USERKIND_KEY)
    localStorage.removeItem(USERCLASS_KEY)
    localStorage.removeItem(USERIMGID_KEY)
    localStorage.removeItem(NEWREGID_KEY)
    localStorage.removeItem(NEWREGKIND_KEY)
    return false
  }
}

export const isManager = () =>
  jwt.verify(localStorage.getItem(USERKIND_KEY), SECRET, (err, decoded) => {
    if (err) {
      console.log("erro: " + err)
      return false
    } else {
      return decoded.key === "M"
    }
  })

export const isConsulting = () =>
  jwt.verify(localStorage.getItem(USERCLASS_KEY), SECRET, (err, decoded) => {
    if (err) {
      console.log("erro: " + err)
      return false
    } else {
      return decoded.key === "CG"
    }
  })

export const isDemo = () =>
  jwt.verify(localStorage.getItem(USERFULLNAME_KEY), SECRET, (err, decoded) => {
    if (err) {
      console.log("erro: " + err)
      return false
    } else {
      return (
        decoded.key === "Demo Gestor Golive" ||
        decoded.key === "Demo Gestor Cliente" ||
        decoded.key === "Demo Consultor Golive" ||
        decoded.key === "Demo Usuario Chave"
      )
    }
  })

export const userFullName = () =>
  jwt.verify(localStorage.getItem(USERFULLNAME_KEY), SECRET, (err, decoded) => {
    if (err) {
      console.log("erro: " + err)
      return ""
    } else {
      return decoded.key
    }
  })

export const userId = () => {
  return localStorage.getItem(USERID_KEY)
}

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (data) => {
  localStorage.setItem(TOKEN_KEY, data.token)
  localStorage.setItem(USERID_KEY, data.user._id)
  localStorage.setItem(
    USERFULLNAME_KEY,
    jwt.sign({ key: data.user.fullName }, SECRET)
  )
  localStorage.setItem(USERKIND_KEY, jwt.sign({ key: data.user.kind }, SECRET))
  localStorage.setItem(
    USERCLASS_KEY,
    jwt.sign({ key: data.user.class }, SECRET)
  )
  localStorage.setItem(USERIMGID_KEY, data.user.image)
}

export const logout = () => {
  localStorage.removeItem("sl_session_id")
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem(USERID_KEY)
  localStorage.removeItem(USERFULLNAME_KEY)
  localStorage.removeItem(USERKIND_KEY)
  localStorage.removeItem(USERCLASS_KEY)
  localStorage.removeItem(USERIMGID_KEY)
  localStorage.removeItem(NEWREGID_KEY)
  localStorage.removeItem(NEWREGKIND_KEY)
}
