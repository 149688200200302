/**
 *
 * Factory Function that returns Translated error messages for Sap Errors
 * @export
 * @param {Object} error
 * @return {String}
 */
export default function sapSLErrorFactory(error) {
  switch (error?.code) {
    case -1:
      return Error("Apenas valores inteiros são permitidos")
    case -1002:
      return Error(
        "A tabela desse item está indisponível, não foi criada ou existem um problema com a requisição"
      )
    case -2035:
      return Error("Esse código já foi cadastrado")
    case -2028:
      return {
        warning: true,
        message: _notFoundErrorFactory(error.message.value),
      }
    case -5002:
      return Error(
        "Erro interno\n" + _internalErrorFactory(error.message.value)
      )
    case -5006:
      return Error("Essa operação não é permitida nesse item ")
    case 1250000133:
      return Error("Informe um código")
    case 1320000002:
      return Error("Insira um código sem os caracteres e * { } ! ^ = < > ? |")
    case -10:
      return Error(
        "Erro de cadastro \n" + _valueErrorFactory(error.message.value)
      )
    case 10000100:
      return Error("Documento precisa ser cadastrado com produtos")
    case 254009412:
      return Error("insira uma data válida")
    default:
      if (!error)
        return Error(
          "A Service layer parece estar fora do ar ou com algum problema (verifique a política de CORS), tente novamente mais tarde"
        )
      return Error(error?.message?.value)
  }
}
function _valueErrorFactory(message) {
  switch (message) {
    case "Update the exchange rate  , 'EUR'":
      return "Atualize a taxa de câmbio para EUR"
    case "Update the exchange rate  , 'CAD'":
      return "Atualize a taxa de câmbio para CAD"
    case "Update the exchange rate  , 'USD'":
      return "Atualize a taxa de câmbio para USD"
    default:
      return message
  }
}
function _notFoundErrorFactory(message) {
  switch (message) {
    case "Customer record not found ":
      return "Cliente não foi achado"
    case "No matching records found (ODBC -2028)":
      return "Algum item não foi encontrado, verifique sua existência e tente novamente"
    default:
      return message
  }
}
function _internalErrorFactory(message) {
  switch (message) {
    case "Item already defined at a higher level in the product tree  [ProductTreeLines.ItemCode][line: 1]":
      return "Item já foi definido em um nível mais alto da estrutura"
    case " [DocumentLines.ItemCode][line: 1] , 'Business partner catalog number linked to item not specified as purchasing item (1)'":
      return "Item inserido não esta definido como produto de compra"
    default:
      return message
  }
}
