import axios from "axios"
import sapSLErrorFactory from "./utils/sapSLErrorFactory"
const apiSap = axios.create({
  withCredentials: true,
  "B1S-CaseInsensitive": true,
})
apiSap.interceptors.request.use(async (config) => {
  config.url = urlFormatter(config.url)
  return config
})
apiSap.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.data?.error?.code === 301) {
      window.location.reload()
    }
    throw sapSLErrorFactory(error.response?.data?.error)
  }
)

function urlFormatter(url) {
  return url.replace("#", "%23")
}
// const apiSap = {
//   post: () => console.log("post"),
//   get: () => console.log("get"),
//   patch: () => console.log("patch"),
//   put: () => console.log("put"),
//   delete: () => console.log("delete"),
// }

export default apiSap
