import React from "react";
import { Link } from "react-router-dom";

import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import User from "components/Modals/User.js";
import api from "services/api";

import { isManager } from "services/auth";
import { isConsulting } from "services/auth";

class ProjectHeader extends React.Component {

  state = {
    alert: null,
    isManagerConsulting: false,
    
    //Modal user variables
    modalUserOpened: false,
    fullName: "",
    email: "",
    password: "",
    kind: "",
    class: "",
    active: false,
    customer: "",
    customersList: [],

    //Modal user fields validations
    customStyles: {
      fullNameState: null,
      emailState: null,
      passwordState: null,
      customerState: null,
    },
  }

  componentDidMount(){
    this.setState({
      isManagerConsulting: isManager() && isConsulting(),
      isResourceConsulting: !isManager() && isConsulting(),
    })
  }

  warningAlert = ( message ) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  newUser(e){

    this.setState( {

      fullName: "",
      email: "",
      password: "",
      kind: "M",
      active: false,
      modalUserOpened: true,

    } );

  }

  closeModalUser = async(e, action) => {

    if( action !== "abort" ){

      this.setState({saving: true});

      let newState = this.state;

      var letSave = true;
      var newData = {};

      //Field content validations
      if (newState.fullName === "") {
        newState.fullNameState = "invalid";
        letSave = false;
      } else {
        newState.fullNameState = "valid";
      }
  
      if (newState.email === "") {
        newState.emailState = "invalid";
        letSave = false;
      } else {
        newState.emailState = "valid";
      }
  
      if (newState.password === "") {
        newState.passwordState = "invalid";
        letSave = false;
      } else {
        newState.passwordState = "valid";
      }
      
      if( newState.class === "CR" ) {
      
        if( this.state.customer === "" ) {
          letSave = false;
          newState.customerState = "invalid"
        }

      } else {
        newState.customerState = "valid"
      }
  
      this.setState({
        customStyles: newState
      });

      if(!letSave){
        this.setState({saving: false});
        return
      
      } else {

        const existUser = await api.post( "/user/search", {
          email: this.state.email,
          deleted: "N"
        } )
  
        if( existUser.data.length > 0 ){
  
          if( existUser.data[0]._id !== this.state.id ){
            this.warningAlert('Já existe um usuário cadastrado com este e-mail.');
            this.setState({saving: false});
            return
          }
        }

        newData = {
          fullName: this.state.fullName,
          email: this.state.email,
          password: this.state.password,
          kind: this.state.kind,
          class: "CR",
          customer: this.props.customer,
          customerShortName: this.props.customerShortName,
          image: null,
          active: this.state.active
        }

        try{
        
          await api.post( "/user/create", newData );

        } catch( err ) {
          this.warningAlert('Já existe um usuário cadastrado com este e-mail.');
          this.setState({saving: false});
          return
        }

      }

    }

    this.setState({
      modalUserOpened: false,
      saving: false
    });

  }

  stylesForm = async (e, stateName) => {

    if( stateName === "active" ){
      this.setState({ active: !this.state.active })
      return
    }

    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState
    });

  };

  render() {
    return (
      <>
        <div
          className="header pb-6 d-flex align-items-center"
          style={{
            minHeight: "500px",
            backgroundImage:
              'url("' + require("assets/img/theme/project-background.jpg") + '")',
            backgroundSize: "cover",
            backgroundPosition: "center top"
          }}
        >
          <span className="mask bg-gradient-danger opacity-8" />

          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                <h1 className="display-2 text-white">{this.props.name}</h1>
                <p className="text-white mt-0 mb-0">
                    {"Código: " + this.props.code}
                </p>
                <p className="text-white mt-0 mb-0">
                    {"Cliente: " + this.props.customerShortName}
                </p>
                <p className="text-white mt-0 mb-5">
                    {"Responsável: " + this.props.responsibleFullName}
                </p>
                <Button
                  // className="btn-neutral"
                  color="default"
                  // href="#pablo"
                  onClick={(e) => this.props.openProjectModal(e, "U")}
                  hidden={!this.state.isManagerConsulting}
                >
                  Alterar Dados
                </Button>
                <Button
                  // className="btn-neutral"
                  color="default"
                  // href="#pablo"
                  onClick={(e) => this.props.openProjectModal(e, "D")}
                  hidden={!this.state.isManagerConsulting}
                >
                  Excluir Projeto
                </Button>
                <Button
                  // className="btn-neutral"
                  color="default"
                  // href="#pablo"
                  onClick={(e) => this.props.openProjectDriveFilesModal()}
                >
                  Arquivos
                </Button>
                <UncontrolledDropdown group hidden={!this.state.isManagerConsulting}>
                  <DropdownToggle caret color="default">
                    <span className="btn-inner--icon">
                      <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">{" " }Novo</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={e => this.newUser(e)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-circle-08" />
                      </span>
                      <span className="btn-inner--text">{" " }Usuário</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      to={"/admin/newPlan/" + this.props.id /*+ "/c/i/t"*/}
                      tag={Link}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-collection" />
                      </span>
                      <span className="btn-inner--text">{" " }Plano</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </Container>
          <User
            {...this.props}
            modalOpened={this.state.modalUserOpened}
            closeModal={this.closeModalUser.bind(this)}
            modalTitle={"Novo Usuário"}
            crud={"C"}
            readOnly={false}
            fullName={this.state.fullName}
            email={this.state.email}
            password={this.state.password}
            kind={this.state.kind}
            class={"CR"}
            active={this.state.active}
            customer={this.props.customer}
            customStyles={this.state.customStyles}
            modalSave={this.closeModalUser.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
            customersList={[
              {
                id: this.props.customer,
                text: this.props.customerShortName
              }
            ]}
            fromUsersBrowse={false}
          />
          {this.state.alert}
        </div>
      </>
    );
  }
}

export default ProjectHeader;
