import React from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// react plugin used to create DropdownMenu for selecting items
import Select2 from "react-select2-wrapper"
// javascipt plugin for creating charts
import Chart from "chart.js"
// react plugin used to create charts
import { Bar, Doughnut } from "react-chartjs-2"
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  UncontrolledCollapse,
  CardBody,
  Form,
  Input,
  Table,
  UncontrolledTooltip,
} from "reactstrap"

// core components
import SimpleHeader from "components/Headers/SimpleHeader.js"

import { Progress } from "reactstrap"

import { chartOptions, parseOptions } from "variables/charts.js"

//Custom components
import api from "services/api"
import { isManager } from "services/auth"
import { isConsulting } from "services/auth"
import { isDemo } from "services/auth"
import Avatar from "react-avatar"

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class DashProject extends React.Component {
  constructor(props) {
    super(props)
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }
  }

  state = {
    isLoading: false,
    buttonFiltersLabel: "Mais filtros",
    buttonShowTableAnalystCaption: "Ocultar",
    buttonShowTableAnalystIcon: "ni ni-bold-up",
    buttonShowTableKeyUserCaption: "Ocultar",
    buttonShowTableKeyUserIcon: "ni ni-bold-up",
    buttonShowTableManagerCaption: "Ocultar",
    buttonShowTableManagerIcon: "ni ni-bold-up",
    buttonShowTableTestsCaption: "Ocultar",
    buttonShowTableTestsIcon: "ni ni-bold-up",
    statusList: {
      NS: "Não Iniciado",
      IP: "Em Andamento",
      AP: "Aprovado",
      NC: "Cenário Parado",
      NK: "Cenário Continua",
      NF: "Cenário Corrigido",
    },
    project: "",
    projectList: [],
    planList: [],
    managerList: [],
    analystList: [],
    keyUserList: [],
    overviewChartData: [],
    overviewChartLabels: [],
    overviewChartColors: [],
    statusChartDatasets: [],
    cicleBChartDataset: [],
    cicleBChartLabels: [],
    statusChartLabels: [],
    rowsTotalAnalyst: [],
    rowsTotalKeyUser: [],
    rowsTotalManager: [],
    rowsTests: [],
    //filters
    planSelection: [],
    planFilter: false,
    cicleSelection: [],
    cicleFilter: false,
    statusSelection: [],
    statusFilter: false,
    managerSelecion: [],
    managerFilter: false,
    analystSelection: [],
    analystFilter: false,
    keyUserSelection: [],
    keyUserFilter: false,
    considerHistory: false,
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const isDemoAccess = isDemo()

    var searchProject = { deleted: "N" }

    const isManagerConsulting = isManager() && isConsulting()

    if (!isManagerConsulting) {
      const currentUserId = localStorage.getItem(
        process.env.REACT_APP_USERID_KEY
      )
      const userProfile = await api.get("/user/read/" + currentUserId)

      if (userProfile.data.mainUser) {
        searchProject = {
          ...searchProject,
          customer: userProfile.data.customer,
        }
      } else {
        searchProject = { ...searchProject, responsible: currentUserId }
      }
    }

    const projects = await api.post("/smartflowproject/search", searchProject)

    var projectList = []

    if (projects.data) {
      for (let i = 0; i < projects.data.length; i++) {
        if (
          (isDemoAccess &&
            projects.data[i].customerShortName !== "Cliente de Demonstração") ||
          (!isDemoAccess &&
            projects.data[i].customerShortName === "Cliente de Demonstração")
        )
          continue

        let project = {
          id: projects.data[i]._id,
          text:
            "Cliente: " +
            projects.data[i].customerShortName +
            " | Código: " +
            projects.data[i].code +
            " | Nome: " +
            projects.data[i].name,
        }

        if (projectList.length === 0) {
          projectList = [project]
        } else {
          projectList.push(project)
        }
      }

      this.setState({ projectList: projectList })
    }
  }

  buttonFilterClick(e) {
    var label = this.state.buttonFiltersLabel

    if (label === "Mais filtros") {
      this.setState({ buttonFiltersLabel: "Menos filtros" })
    } else {
      this.setState({ buttonFiltersLabel: "Mais filtros" })
    }
  }

  buttonShowTableClick(e, id) {
    var caption

    if (id === "analyst") {
      caption = this.state.buttonShowTableAnalystCaption

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableAnalystCaption: "Ocultar",
          buttonShowTableAnalystIcon: "ni ni-bold-up",
        })
      } else {
        this.setState({
          buttonShowTableAnalystCaption: "Exibir",
          buttonShowTableAnalystIcon: "ni ni-bold-down",
        })
      }
    }

    if (id === "keyUser") {
      caption = this.state.buttonShowTableKeyUserCaption

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableKeyUserCaption: "Ocultar",
          buttonShowTableKeyUserIcon: "ni ni-bold-up",
        })
      } else {
        this.setState({
          buttonShowTableKeyUserCaption: "Exibir",
          buttonShowTableKeyUserIcon: "ni ni-bold-down",
        })
      }
    }

    if (id === "manager") {
      caption = this.state.buttonShowTableManagerCaption

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableManagerCaption: "Ocultar",
          buttonShowTableManagerIcon: "ni ni-bold-up",
        })
      } else {
        this.setState({
          buttonShowTableManagerCaption: "Exibir",
          buttonShowTableManagerIcon: "ni ni-bold-down",
        })
      }
    }

    if (id === "tests") {
      caption = this.state.buttonShowTableTestsCaption

      if (caption === "Exibir") {
        this.setState({
          buttonShowTableTestsCaption: "Ocultar",
          buttonShowTableTestsIcon: "ni ni-bold-up",
        })
      } else {
        this.setState({
          buttonShowTableTestsCaption: "Exibir",
          buttonShowTableTestsIcon: "ni ni-bold-down",
        })
      }
    }
  }

  getRows = (rows) => {
    return rows.map((prop, key) => {
      return (
        <tr key={key}>
          <td className="table-user">
            {prop.image ? (
              <img
                alt="..."
                className="avatar rounded-circle mr-3"
                src={prop.image.url}
              />
            ) : (
              <Avatar
                className="avatar rounded-circle mr-3"
                name={prop.name}
                maxInitials={2}
                round={true}
                size={48}
                title={" "}
                color={"#172b4d"}
                fgColor={"#11cdef"}
              />
            )}
            <b>{prop.name}</b>
          </td>
          <td>
            <span className="text-muted">{prop.total}</span>
          </td>
          <td>
            <span className="text-danger mb-0">{prop.totalNS}</span>
          </td>
          <td>
            <span className="text-warning mb-0">{prop.totalIP}</span>
          </td>
          <td>
            <span className="text-success mb-0">{prop.totalAP}</span>
          </td>
          <td>
            <span className="text-info mb-0">{prop.totalNC}</span>
          </td>
          <td>
            <span className="text-info mb-0">{prop.totalNK}</span>
          </td>
          <td>
            <span className="text-info mb-0">{prop.totalNF}</span>
          </td>
        </tr>
      )
    })
  }

  selectProject = async (e) => {
    let project = e.target.value
    if (!project || project === null || project === "") return

    this.unSelectAll()

    this.setState({
      project: project,
      isLoading: true,
    })

    const dashboardProject = await api.post("/test/dashboardProject", {
      project: project,
      plans: [],
      cicles: [],
      status: [],
      statusHistory: [],
      managers: [],
      analysts: [],
      keyUsers: [],
    })

    if (dashboardProject) {
      this.setState({
        rowsTests: dashboardProject.data.testsList,
        overviewChartData: dashboardProject.data.overviewChartData,
        overviewChartLabels: dashboardProject.data.overviewChartLabels,
        overviewChartColors: dashboardProject.data.overviewChartColors,
        statusChartDatasets: dashboardProject.data.statusChartDatasets,
        cicleBChartDataset: dashboardProject.data.cicleBChartDataset,
        cicleBChartLabels: dashboardProject.data.cicleBChartLabels,

        statusChartLabels: dashboardProject.data.statusChartLabels,
        rowsTotalAnalyst: dashboardProject.data.rowsTotalAnalyst,
        rowsTotalKeyUser: dashboardProject.data.rowsTotalKeyUser,
        rowsTotalManager: dashboardProject.data.rowsTotalManager,
        planList: dashboardProject.data.planList,
        managerList: dashboardProject.data.managerList,
        analystList: dashboardProject.data.analystList,
        keyUserList: dashboardProject.data.keyUserList,
        isLoading: false,
      })
    }
  }

  getFilterList = (list) => {
    return list.map((prop, key) => {
      return (
        <option value={prop.value} key={key}>
          {prop.text}
        </option>
      )
    })
  }

  filter = async (e, filterName) => {
    let state = this.state
    let project = state.project

    if (!project || project === null || project === "") return

    let selection = []
    if (e) {
      let options = e.target.options

      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selection.push(options[i].value)
        }
      }
    }

    this.setState({
      isLoading: true,
      [filterName + "Selection"]: selection,
      [filterName + "Filter"]: selection.length > 0,
    })

    state = {
      ...state,
      [filterName + "Selection"]: selection,
      [filterName + "Filter"]: selection.length > 0,
    }

    const dashboardProject = await api.post("/test/dashboardProject", {
      project: state.project,
      plans: state.planSelection,
      cicles: state.cicleSelection,
      status: state.considerHistory ? [] : state.statusSelection,
      statusHistory: state.considerHistory ? state.statusSelection : [],
      managers: state.managerSelecion,
      analysts: state.analystSelection,
      keyUsers: state.keyUserSelection,
    })

    if (dashboardProject) {
      this.setState({
        rowsTests: dashboardProject.data.testsList,
        overviewChartData: dashboardProject.data.overviewChartData,
        overviewChartLabels: dashboardProject.data.overviewChartLabels,
        overviewChartColors: dashboardProject.data.overviewChartColors,
        statusChartDatasets: dashboardProject.data.statusChartDatasets,
        cicleBChartDataset: dashboardProject.data.cicleBChartDataset,
        cicleBChartLabels: dashboardProject.data.cicleBChartLabels,

        statusChartLabels: dashboardProject.data.statusChartLabels,
        rowsTotalAnalyst: dashboardProject.data.rowsTotalAnalyst,
        rowsTotalKeyUser: dashboardProject.data.rowsTotalKeyUser,
        rowsTotalManager: dashboardProject.data.rowsTotalManager,
        isLoading: false,
      })
    }
  }

  redoFilter = async (considerHistory) => {
    this.setState({ isLoading: true })

    let project = this.state.project

    console.log(this.state.considerHistory)

    if (!project || project === null || project === "") return

    const dashboardProject = await api.post("/test/dashboardProject", {
      project: this.state.project,
      plans: this.state.planSelection,
      cicles: this.state.cicleSelection,
      status: considerHistory ? [] : this.state.statusSelection,
      statusHistory: this.state.considerHistory
        ? this.state.statusSelection
        : [],
      managers: this.state.managerSelecion,
      analysts: this.state.analystSelection,
      keyUsers: this.state.keyUserSelection,
    })
    if (dashboardProject) {
      this.setState({
        rowsTests: dashboardProject.data.testsList,
        overviewChartData: dashboardProject.data.overviewChartData,
        overviewChartLabels: dashboardProject.data.overviewChartLabels,
        overviewChartColors: dashboardProject.data.overviewChartColors,
        statusChartDatasets: dashboardProject.data.statusChartDatasets,
        cicleBChartDataset: dashboardProject.data.cicleBChartDataset,
        cicleBChartLabels: dashboardProject.data.cicleBChartLabels,

        statusChartLabels: dashboardProject.data.statusChartLabels,
        rowsTotalAnalyst: dashboardProject.data.rowsTotalAnalyst,
        rowsTotalKeyUser: dashboardProject.data.rowsTotalKeyUser,
        rowsTotalManager: dashboardProject.data.rowsTotalManager,
        isLoading: false,
      })
    }
  }
  removeFilters = async (e) => {
    this.unSelectAll()

    this.setState({ isLoading: true })

    let project = this.state.project

    if (!project || project === null || project === "") return

    const dashboardProject = await api.post("/test/dashboardProject", {
      project: this.state.project,
      plans: [],
      cicles: [],
      status: [],
      statusHistory: [],
      managers: [],
      analysts: [],
      keyUsers: [],
    })

    if (dashboardProject) {
      this.setState({
        rowsTests: dashboardProject.data.testsList,
        overviewChartData: dashboardProject.data.overviewChartData,
        overviewChartLabels: dashboardProject.data.overviewChartLabels,
        overviewChartColors: dashboardProject.data.overviewChartColors,
        statusChartDatasets: dashboardProject.data.statusChartDatasets,
        cicleBChartDataset: dashboardProject.data.cicleBChartDataset,
        cicleBChartLabels: dashboardProject.data.cicleBChartLabels,
        statusChartLabels: dashboardProject.data.statusChartLabels,
        rowsTotalAnalyst: dashboardProject.data.rowsTotalAnalyst,
        rowsTotalKeyUser: dashboardProject.data.rowsTotalKeyUser,
        rowsTotalManager: dashboardProject.data.rowsTotalManager,
        isLoading: false,
      })
    }
  }

  unSelectAll() {
    this.setState({
      planSelection: [],
      planFilter: false,
      cicleSelection: [],
      cicleFilter: false,
      statusSelection: [],
      statusFilter: false,
      managerSelecion: [],
      managerFilter: false,
      analystSelection: [],
      analystFilter: false,
      keyUserSelection: [],
      keyUserFilter: false,
    })

    var select = document.getElementById("selectPlan")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }

    select = document.getElementById("selectCicle")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }

    select = document.getElementById("selectStatus")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }

    select = document.getElementById("selectManager")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }

    select = document.getElementById("selectAnalyst")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }

    select = document.getElementById("selectKeyUser")

    if (select && select !== null) {
      for (let i = 0; i < select.options.length; i++) {
        select.options[i].selected = false
      }
    }
  }

  render() {
    return (
      <>
        <SimpleHeader
          name="Projeto"
          items={[
            { level: "parent", name: "Dashboards" },
            { level: "nav", name: "Análise de Projeto" },
          ]}
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h5 className="h3 mb-0">Filtros</h5>
                    </Col>
                    <Col xs="6" className="text-right">
                      <Button
                        color="secondary"
                        size="sm"
                        id="buttonToggler"
                        hidden={
                          this.state.project === "" || this.state.isLoading
                        }
                        onClick={(e) => this.buttonFilterClick(e)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-filter" />
                        </span>
                        <span className="btn-inner--text">
                          {this.state.buttonFiltersLabel}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="12">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom04"
                        >
                          Projeto
                        </label>
                        <Select2
                          className="form-control"
                          type="text"
                          value={this.state.project}
                          disabled={this.state.isLoading}
                          onSelect={(e) => this.selectProject(e)}
                          options={{
                            placeholder: "Selecione o projeto",
                            language: {
                              noResults: function () {
                                return "Nenhum projeto encontrado."
                              },
                            },
                          }}
                          data={this.state.projectList}
                        />
                      </Col>
                    </div>
                    <UncontrolledCollapse toggler="#linkToggler,#buttonToggler">
                      <div className="form-row">
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Plano
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectPlan"
                            onChange={(e) => this.filter(e, "plan")}
                          >
                            {this.getFilterList(this.state.planList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Ciclo
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectCicle"
                            onChange={(e) => this.filter(e, "cicle")}
                          >
                            <option value="Parametrização">
                              Ciclo Parametrização
                            </option>
                            <option value="A">Ciclo A</option>
                            <option value="Capacitação">
                              Ciclo Capacitação
                            </option>
                            <option value="B">Ciclo B</option>
                            <option value="C">Ciclo C</option>
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Status
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectStatus"
                            onChange={(e) => this.filter(e, "status")}
                          >
                            <option value="NS">Não Iniciado</option>
                            <option value="IP">Em Andamento</option>
                            <option value="AP">Aprovado</option>
                            <option value="NC">Cenário Parado</option>
                            <option value="AK">Cenário Continua</option>
                            <option value="NF">Cenário Corrigido</option>
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                          <div className="custom-control custom-control-alternative custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheckRegister"
                              type="checkbox"
                              value={this.state.considerHistory}
                              onChange={(e) => {
                                this.redoFilter(!this.state.considerHistory)
                                this.setState({
                                  considerHistory: !this.state.considerHistory,
                                })
                              }}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheckRegister"
                            >
                              <span className="text-muted">
                                Considerar histórico
                              </span>
                            </label>
                          </div>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Gerente
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectManager"
                            onChange={(e) => this.filter(e, "manager")}
                          >
                            {this.getFilterList(this.state.managerList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Analista
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectAnalyst"
                            onChange={(e) => this.filter(e, "analyst")}
                          >
                            {this.getFilterList(this.state.analystList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                        <Col className="mb-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect2"
                          >
                            Usuário Chave
                          </label>
                          <Input
                            disabled={this.state.isLoading}
                            multiple="multiple"
                            type="select"
                            id="selectKeyUser"
                            onChange={(e) => this.filter(e, "keyUser")}
                          >
                            {this.getFilterList(this.state.keyUserList)}
                          </Input>
                          <small className="text-muted mb-0">
                            *Ctrl + click para multiseleção
                          </small>
                        </Col>
                      </div>
                    </UncontrolledCollapse>
                  </Form>
                </CardBody>
                <CardBody hidden={!this.state.isLoading}>
                  <div className="text-center text-muted my-4">
                    <Progress animated color="danger" value="100" />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row
            hidden={
              this.state.project === "" ||
              this.state.isLoading ||
              (!this.state.planFilter &&
                !this.state.cicleFilter &&
                !this.state.statusFilter &&
                !this.state.managerFilter &&
                !this.state.analystFilter &&
                !this.state.keyUserFilter)
            }
          >
            <Col xl="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="1" className="text-left">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters1"
                        onClick={(e) => this.removeFilters(e)}
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters1"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                    <Col xs="10" className="text-center">
                      <small className="text-danger mb-0">
                        Atenção! Os dados abaixo estão filtrados.
                      </small>
                    </Col>
                    <Col xs="1" className="text-right">
                      <Button
                        color="danger"
                        size="sm"
                        id="buttonRemoveFilters2"
                        onClick={(e) => this.removeFilters(e)}
                      >
                        <i className="ni ni-fat-remove" />
                        <UncontrolledTooltip
                          delay={0}
                          placement="top"
                          target={"buttonRemoveFilters2"}
                        >
                          Remover filtros
                        </UncontrolledTooltip>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row hidden={this.state.project === "" || this.state.isLoading}>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Overview</h6>
                  <h5 className="h3 mb-0">Status do Projeto</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Doughnut
                      data={{
                        labels: this.state.overviewChartLabels,
                        datasets: [
                          {
                            label: "Status / Projeto",
                            data: this.state.overviewChartData,
                            backgroundColor: this.state.overviewChartColors,
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        cutoutPercentage: 50,
                        legend: {
                          position: "bottom",
                          display: true,
                        },
                        animation: {
                          animateScale: true,
                          animateRotate: true,
                        },
                      }}
                      className="chart-canvas"
                      id="chart-doughnut"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="6">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Status</h6>
                  <h5 className="h3 mb-0">Por Plano de Protótipo</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={{
                        datasets: this.state.statusChartDatasets,
                        labels: this.state.statusChartLabels,
                      }}
                      options={{
                        tooltips: {
                          mode: "index",
                          intersect: false,
                        },
                        responsive: true,
                        scales: {
                          xAxes: [
                            {
                              stacked: true,
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      className="chart-canvas"
                      id="chart-bar-stacked"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row
            hidden={
              this.state.project === "" ||
              this.state.isLoading ||
              this.state.cicleSelection === "B" ||
              !this.state.cicleSelection
            }
          >
            <Col xs="12">
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Testes</h6>
                  <h5 className="h3 mb-0">Testes ciclo B</h5>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={{
                        datasets: this.state.cicleBChartDataset,
                        labels: this.state.cicleBChartLabels,
                      }}
                      options={{
                        tooltips: {
                          mode: "index",
                          intersect: false,
                        },
                        responsive: true,
                        scales: {
                          xAxes: [
                            {
                              stacked: true,
                            },
                          ],
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      className="chart-canvas"
                      id="chart-bar-stacked"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Card hidden={this.state.project === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Analista</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalAnalyst"
                    onClick={(e) => this.buttonShowTableClick(e, "analyst")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableAnalystIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableAnalystCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalAnalyst"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Analista</th>
                    <th>Total</th>
                    <th>Não Iniciados</th>
                    <th>Em Andamento</th>
                    <th>Aprovados</th>
                    <th>Cenário Parado</th>
                    <th>Cenário Continua</th>
                    <th>Cenário Corrigido</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalAnalyst)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.project === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Usuário Chave</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalKeyUser"
                    onClick={(e) => this.buttonShowTableClick(e, "keyUser")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableKeyUserIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableKeyUserCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalKeyUser"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Total</th>
                    <th>Não Iniciados</th>
                    <th>Em Andamento</th>
                    <th>Aprovados</th>
                    <th>Cenário Parado</th>
                    <th>Cenário Continua</th>
                    <th>Cenário Corrigido</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalKeyUser)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.project === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Total x Gerente</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTotalManager"
                    onClick={(e) => this.buttonShowTableClick(e, "manager")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableManagerIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableManagerCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse
              toggler="#togglerTotalManager"
              defaultOpen="true"
            >
              <Table
                className="align-items-center table-flush"
                responsive
                striped
              >
                <thead className="thead-light">
                  <tr>
                    <th>Gerente</th>
                    <th>Total</th>
                    <th>Não Iniciados</th>
                    <th>Em Andamento</th>
                    <th>Aprovados</th>
                    <th>Cenário Parado</th>
                    <th>Cenário Continua</th>
                    <th>Cenário Corrigido</th>
                  </tr>
                </thead>
                <tbody>{this.getRows(this.state.rowsTotalManager)}</tbody>
              </Table>
            </UncontrolledCollapse>
          </Card>
          <Card hidden={this.state.project === "" || this.state.isLoading}>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Lista de Testes do Projeto</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    id="togglerTests"
                    onClick={(e) => this.buttonShowTableClick(e, "tests")}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className={this.state.buttonShowTableTestsIcon} />
                    </span>
                    <span className="btn-inner--text">
                      {this.state.buttonShowTableTestsCaption}
                    </span>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <UncontrolledCollapse toggler="#togglerTests" defaultOpen="true">
              <ToolkitProvider
                data={this.state.rowsTests}
                keyField="name"
                columns={[
                  {
                    dataField: "planBusinessArea",
                    text: "Plano",
                    sort: true,
                  },
                  {
                    dataField: "cicleTitle",
                    text: "Ciclo",
                    sort: true,
                  },
                  {
                    dataField: "itemProcess",
                    text: "Processo",
                    sort: true,
                  },
                  {
                    dataField: "testDescription",
                    text: "Teste",
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    formatter: (cell, row) => {
                      return row.status === "NS" ? (
                        <span className="text-danger mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "CA" ? (
                        <span className="text-muted mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "AP" ? (
                        <span className="text-success mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "NC" ||
                        row.status === "NK" ||
                        row.status === "NF" ? (
                        <span className="text-info mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : row.status === "IP" ? (
                        <span className="text-warning mb-0">
                          {this.state.statusList[row.status]}
                        </span>
                      ) : null
                    },
                    sort: true,
                  },
                  {
                    dataField: "analystName",
                    text: "Analista",
                    sort: true,
                  },
                  {
                    dataField: "keyUserName",
                    text: "Usuário Chave",
                    sort: true,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter px-4 pb-1 float-left"
                          >
                            <label>
                              Pesquisar:
                              <SearchBar
                                className="form-control-sm"
                                placeholder="Pesquisar por..."
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={(el) => (this.componentRef = el)}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      hover
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </UncontrolledCollapse>
          </Card>
        </Container>
      </>
    )
  }
}

export default DashProject
