import React from "react"
import ReactBSAlert from "react-bootstrap-sweetalert"

// reactstrap components
import { Container, Row, Col } from "reactstrap"
// core components
import ProjectHeader from "components/Headers/ProjectHeader.js"

//Custom components
import { isConsulting } from "services/auth"
import { isManager } from "services/auth"
import { isDemo } from "services/auth"
import { userFullName } from "services/auth"

import api from "services/api"
import Project from "components/Modals/Project.js"
import LatestInteractions from "components/Feeds/LatestInteractions.js"
import TotalTests from "components/Feeds/TotalTests.js"
import ProjectDriveFiles from "components/Modals/ProjectDriveFiles"
import apiSap from "services/sapApi"

class ProjectConsole extends React.Component {
  state = {
    serviceLayerError: false,
    isLoading: true,
    id: "",
    code: "",
    name: "",
    customer: "",
    customerShortName: "",
    responsible: "",
    responsibleFullName: "",
    driveFolder: "",
    serviceLayerUrl: "",
    serviceLayerUser: "",
    serviceLayerPassword: "",
    alert: null,
    serviceLayerDataBase: "",

    //Project modal variables
    saving: false,
    crud: "",
    projectModalOpened: false,
    projectModalTitle: "",
    newCode: "",
    newName: "",
    newResponsible: "",
    newDiveFolder: "",

    usersList: [],
    customStyles: {
      newCodeState: null,
      newNameState: null,
      newResponsibleState: null,
      newDriveFolderState: null,
    },

    //Files modal variables
    filesModalOpened: false,

    //Current User
    isManagerConsulting: false,
    isResourceConsulting: false,
    isManagerCustomer: false,
    isResourceCustomer: false,
    currentUserFullName: "",

    //Feed
    totalTests: 0,
    concludedTests: 0,
    inProgressTests: 0,
    notStartedTests: 0,
    concludedTestsPerc: 0,
    inProgressTestsPerc: 0,
    notStartedTestsPerc: 0,

    feedTitle: "Carregando últimas atualizações...",
    totalTestsTitle: "Carregando estatísticas...",
    interactions: [],
  }

  componentDidMount() {
    this.loadData(this.props.match.params.parentId)
    this.loadFeed(this.props.match.params.parentId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.parentId !== this.state.id) {
      this.loadData(nextProps.match.params.parentId)
      this.loadFeed(nextProps.match.params.parentId)
    }
  }

  loadData = async (paramId) => {
    const id = paramId
    const project = await api.get("/smartflowproject/read/" + id)

    if (project.data.serviceLayerUrl) {
      try {
        document.cookie = `B1SESSION=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        const loginResp = await apiSap.post(
          project.data.serviceLayerUrl + "/Login",
          {
            CompanyDB: project.data.serviceLayerDataBase,
            UserName: project.data.serviceLayerUser,
            Password: project.data.serviceLayerPassword,
          }
        )
        localStorage.setItem("sl_session_id", loginResp.data.SessionId)
      } catch (error) {
        localStorage.removeItem("sl_session_id")
        this.warningAlert(
          "Houve um erro ao ao logar na Service Layer " +
            project.data.serviceLayerUrl +
            "\n" +
            error.message
        )
      }
    } else {
      localStorage.removeItem("sl_session_id")
    }
    this.setState({
      id: id,
      code: project.data.code,
      customer: project.data.customer,
      customerShortName: project.data.customerShortName,
      name: project.data.name,
      responsible: project.data.responsible,
      responsibleFullName: project.data.responsibleFullName,
      driveFolder: project.data.driveFolder,
      serviceLayerUrl: project.data.serviceLayerUrl,
      serviceLayerDataBase: project.data.serviceLayerDataBase,
      serviceLayerUser: project.data.serviceLayerUser,
      serviceLayerPassword: project.data.serviceLayerPassword,
    })
  }

  loadFeed = async (paramId) => {
    this.setState({ isLoading: true })

    const id = paramId

    const project = await api.get("/smartflowproject/read/" + id)

    const isManagerConsulting = isManager() && isConsulting()
    const isResourceConsulting = !isManager() && isConsulting()
    const isManagerCustomer = isManager() && !isConsulting()
    const currentUserId = localStorage.getItem(process.env.REACT_APP_USERID_KEY)

    const isDemoAccess = isDemo()

    var feedTitle = userFullName()
    var totalTestsTitle = "Sob sua responsabilidade"

    if (String(feedTitle).indexOf(" ") > 0) {
      feedTitle = String(feedTitle).substring(0, String(feedTitle).indexOf(" "))
    }

    if (isManagerConsulting || isManagerCustomer) {
      feedTitle +=
        ", essas são as últimas atualizações relacionadas à equipe de testes do projeto " +
        project.data.name +
        ":"
      totalTestsTitle = "Sob responsabilidade da equipe"
    } else {
      feedTitle +=
        ", essas são as úlitmas atualizações do projeto " +
        project.data.name +
        ", relacionadas à você:"
    }

    this.setState({
      feedTitle: feedTitle,
      totalTestsTitle: totalTestsTitle,
    })

    var filters = {
      deleted: "N",
      reviewed: "N",
    }

    var filtersTaskInteractions = {
      deleted: "N",
      project: id,
    }

    if (isManagerConsulting) {
      filters = {
        ...filters,
        project: id,
      }
    } else {
      if (isManagerCustomer) {
        filters = {
          ...filters,
          project: id,
          manager: currentUserId,
        }
      } else {
        if (isResourceConsulting) {
          filters = {
            ...filters,
            project: id,
            analyst: currentUserId,
          }
        } else {
          filters = {
            ...filters,
            project: id,
            keyUser: currentUserId,
          }
        }
      }
    }

    const respTests = await api.post("/test/totalTests", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    })

    this.setState({
      totalTests: respTests.data.totalTests,
      concludedTests: respTests.data.concludedTests,
      inProgressTests: respTests.data.inProgressTests,
      notStartedTests: respTests.data.notStartedTests,
      concludedTestsPerc: respTests.data.concludedTestsPerc,
      inProgressTestsPerc: respTests.data.inProgressTestsPerc,
      notStartedTestsPerc: respTests.data.notStartedTestsPerc,
    })

    filters = { ...filters, showOnFeed: "Y" }

    const respInteractions = await api.post("/interaction/feed", {
      filters: filters,
      isConsulting: isConsulting(),
      isDemoAccess,
    })

    const respTaskInteractions = await api.post("/taskInteraction/feed", {
      filters: filtersTaskInteractions,
      isDemoAccess: isDemoAccess,
      isManagerConsulting: isManagerConsulting,
      userId: currentUserId,
    })

    var interactions = []

    if (
      respInteractions.data[0].status === "WA" &&
      respTaskInteractions.data[0].status === "WA"
    ) {
      interactions = respInteractions.data
    } else {
      if (respInteractions.data[0].status !== "WA") {
        interactions = respInteractions.data
      }

      if (respTaskInteractions.data[0].status !== "WA") {
        interactions = [...interactions, ...respTaskInteractions.data]
      }
    }

    interactions.sort((a, b) => (a.dateTime > b.dateTime ? -1 : 1))

    this.setState({
      isLoading: false,
      interactions: interactions,
    })

    const users = await api.post("/user/search", {
      kind: "M",
      class: "CR",
      customer: project.data.customer,
      deleted: "N",
    })

    var user = {}
    var usersList = []

    for (let i = 0; i < users.data.length; i++) {
      user = {
        id: users.data[i]._id,
        text: users.data[i].fullName,
      }

      if (usersList.length === 0) {
        usersList = [user]
      } else {
        usersList.push(user)
      }
    }

    this.setState({
      usersList: usersList,
    })
  }

  openProjectModal(e, crud) {
    var projectModalTitle = "Alterando Projeto"

    if (crud === "D") {
      projectModalTitle = "Excluindo Projeto"
    }
    this.setState({
      projectModalTitle: projectModalTitle,
      projectModalOpened: true,
      crud: crud,

      newCode: this.state.code,
      newName: this.state.name,
      newResponsible: this.state.responsible,
      newDriveFolder: this.state.driveFolder,
    })
  }

  openProjectDriveFilesModal() {
    this.setState({
      projectDriveFilesModalOpened: true,
    })
  }

  closeProjectDriveFilesModal() {
    this.setState({
      projectDriveFilesModalOpened: false,
    })
  }

  closeProjectModal = async (e, action) => {
    if (action !== "abort") {
      if (this.state.serviceLayerUrl) {
        try {
          await apiSap.post(this.state.serviceLayerUrl + "/Login", {
            UserName: this.state.serviceLayerUser,
            CompanyDB: this.state.serviceLayerDataBase,
            Password: this.state.serviceLayerPassword,
          })
        } catch (error) {
          this.warningAlert(error.message)
          return
        }
      }

      this.setState({ saving: true })

      var success = true

      if (this.state.crud === "D") {
        try {
          let plans = await api.post("/plan/search", {
            project: this.state.id,
            deleted: "N",
          })

          for (let j = 0; j < plans.data.length; j++) {
            await api.put("/plan/update/" + plans.data[j]._id, {
              deleted: "Y",
            })

            let versions = await api.post("/version/search", {
              plan: plans.data[j]._id,
              deleted: "N",
            })

            for (let k = 0; k < versions.data.length; k++) {
              await api.put("/version/update/" + versions.data[k]._id, {
                deleted: "Y",
              })

              let cicles = await api.post("/cicle/search", {
                version: versions.data[k]._id,
                deleted: "N",
              })

              for (let l = 0; l < cicles.data.length; l++) {
                await api.put("/cicle/update/" + cicles.data[l]._id, {
                  deleted: "Y",
                })

                let items = await api.post("/item/search", {
                  cicle: cicles.data[l]._id,
                  deleted: "N",
                })

                for (let m = 0; m < items.data.length; m++) {
                  await api.put("/item/update/" + items.data[m]._id, {
                    deleted: "Y",
                  })

                  let itemDocuments = await api.post("/itemDocument/search", {
                    item: items.data[m]._id,
                    deleted: "N",
                  })

                  for (let n = 0; n < itemDocuments.data.length; n++) {
                    await api.put(
                      "/itemDocument/update/" + itemDocuments.data[n]._id,
                      {
                        deleted: "Y",
                      }
                    )
                  }

                  let tests = await api.post("/test/search", {
                    item: items.data[m]._id,
                    deleted: "N",
                  })

                  for (let n = 0; n < tests.data.length; n++) {
                    await api.put("/test/update/" + tests.data[n]._id, {
                      deleted: "Y",
                    })

                    let testDocuments = await api.post("/testDocument/search", {
                      test: tests.data[n]._id,
                      deleted: "N",
                    })

                    for (let p = 0; p < testDocuments.data.length; p++) {
                      await api.put(
                        "/testDocument/update/" + testDocuments.data[p]._id,
                        {
                          deleted: "Y",
                        }
                      )
                    }

                    let interactions = await api.post("/interaction/search", {
                      test: tests.data[n]._id,
                      deleted: "N",
                    })

                    for (let p = 0; p < interactions.data.length; p++) {
                      await api.put(
                        "/interaction/update/" + interactions.data[p]._id,
                        {
                          deleted: "Y",
                        }
                      )

                      let interactionDocs = await api.post(
                        "/interactionDocument/search",
                        {
                          interaction: interactions.data[p]._id,
                          deleted: "N",
                        }
                      )

                      for (let q = 0; q < interactionDocs.data.length; q++) {
                        await api.put(
                          "interactionDocument/update/" +
                            interactionDocs.data[q]._id,
                          {
                            deleted: "Y",
                          }
                        )
                      }
                    }
                  }
                }
              }
            }
          }

          await api.put("/smartflowproject/update/" + this.state.id, {
            deleted: "Y",
          })
        } catch (err) {
          this.warningAlert("Houve um erro ao tentar excluir. Tente novamente.")
          success = false
          this.setState({ saving: false })
          return
        }

        if (success) {
          window.location.reload()
        }
      } else {
        let newState = this.state

        var letSave = true
        var newData = {}

        //Field content validations
        if (newState.newCode === "") {
          newState.customStyles.newCodeState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newCodeState = "valid"
        }

        if (newState.newName === "") {
          newState.customStyles.newNameState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newNameState = "valid"
        }

        if (newState.newResponsible === "") {
          newState.customStyles.newResponsibleState = "invalid"
          letSave = false
        } else {
          newState.customStyles.newResponsibleState = "valid"
        }

        this.setState({
          customStyles: newState.customStyles,
        })

        if (!letSave) {
          this.setState({ saving: false })
          return
        } else {
          var responsibleFullName = ""

          for (let i = 0; i < this.state.usersList.length; i++) {
            if (this.state.usersList[i].id === this.state.newResponsible) {
              responsibleFullName = this.state.usersList[i].text
              break
            }
          }

          newData = {
            code: this.state.newCode,
            oldCode: this.state.newCode,
            name: this.state.newName,
            responsible: this.state.newResponsible,
            responsibleFullName: responsibleFullName,
            driveFolder: this.state.newDriveFolder,
            serviceLayerPassword: this.state.serviceLayerPassword,
            serviceLayerUrl: this.state.serviceLayerUrl,
            serviceLayerUser: this.state.serviceLayerUser,
            serviceLayerDataBase: this.state.serviceLayerDataBase,
          }

          try {
            //Update project
            await api.put("/smartflowproject/update/" + this.state.id, newData)

            //Update plans
            var response = await api.post("/plan/search", {
              deleted: "N",
              project: this.state.id,
            })

            const plans = response.data

            if (plans.length > 0) {
              for (let i = 0; i < plans.length; i++) {
                await api.put("/plan/update/" + plans[i]._id, {
                  projectName: this.state.newName,
                })
              }
            }

            //Update cicles
            response = await api.post("/cicle/search", {
              deleted: "N",
              project: this.state.id,
            })

            const cicles = response.data

            if (cicles.length > 0) {
              for (let i = 0; i < cicles.length; i++) {
                await api.put("/cicle/update/" + cicles[i]._id, {
                  projectName: this.state.newName,
                })
              }
            }
          } catch (err) {
            this.warningAlert("O código informado já existe. Tente novamente.")
            this.setState({ saving: false })
            success = false
            return
          }

          if (success) {
            if (this.state.name !== this.state.newtName) {
              localStorage.setItem(
                process.env.REACT_APP_NEWREGID_KEY,
                this.state.id
              )
              localStorage.setItem(process.env.REACT_APP_NEWREGKIND_KEY, "PR")
              window.location.reload()
            } else {
              this.setState({
                code: this.state.newCode,
                name: this.state.newName,
                responsibleFullName: responsibleFullName,
                driveFolder: this.newDriveFolder,
              })
            }

            this.setState({ saving: false })
          }
        }
      }
    }

    this.setState({ projectModalOpened: false })
  }

  stylesForm = async (e, stateName) => {
    let customStyles = this.state.customStyles
    if (e.target.value === null || e.target.value === "") {
      customStyles[stateName + "State"] = "invalid"
    } else {
      customStyles[stateName + "State"] = "valid"
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: customStyles,
    })
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  render() {
    return (
      <>
        <ProjectHeader
          id={this.state.id}
          code={this.state.code}
          name={this.state.name}
          responsibleFullName={this.state.responsibleFullName}
          customer={this.state.customer}
          openProjectDriveFilesModal={this.openProjectDriveFilesModal.bind(
            this
          )}
          customerShortName={this.state.customerShortName}
          openProjectModal={this.openProjectModal.bind(this)}
        />
        <Container className="mt--6" fluid>
          <TotalTests
            totalTestsTitle={this.state.totalTestsTitle}
            totalTests={this.state.totalTests}
            concludedTests={this.state.concludedTests}
            inProgressTests={this.state.inProgressTests}
            notStartedTests={this.state.notStartedTests}
            concludedTestsPerc={this.state.concludedTestsPerc}
            inProgressTestsPerc={this.state.inProgressTestsPerc}
            notStartedTestsPerc={this.state.notStartedTestsPerc}
          />
          <Row>
            <Col xl="12">
              <LatestInteractions
                {...this.props}
                title={this.state.feedTitle}
                interactions={this.state.interactions}
                isLoading={this.state.isLoading}
              />
            </Col>
          </Row>
          <Project
            modalOpened={this.state.projectModalOpened}
            isManagerConsulting={isManager() && isConsulting()}
            id={this.state.id}
            code={this.state.newCode}
            customerShortName={this.state.customerShortName}
            name={this.state.newName}
            responsible={this.state.newResponsible}
            driveFolder={this.state.newDriveFolder}
            serviceLayerDataBase={this.state.serviceLayerDataBase}
            serviceLayerPassword={this.state.serviceLayerPassword}
            serviceLayerUser={this.state.serviceLayerUser}
            serviceLayerUrl={this.state.serviceLayerUrl}
            closeModal={this.closeProjectModal.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            customStyles={this.state.customStyles}
            crud={this.state.crud}
            title={this.state.projectModalTitle}
            saving={this.state.saving}
            usersList={this.state.usersList}
          />
          <ProjectDriveFiles
            modalOpened={this.state.projectDriveFilesModalOpened}
            driveFolder={this.state.driveFolder}
            closeModal={() => {
              this.setState({
                projectDriveFilesModalOpened: false,
              })
            }}
            isConsulting={isConsulting()}
            isManager={isManager()}
          />
        </Container>
        {this.state.alert}
      </>
    )
  }
}

export default ProjectConsole
