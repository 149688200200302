import React from "react";
// reactstrap components
import {
    Button,
    Card,
    CardBody,
    Col,
    Modal,
    Row,
} from "reactstrap";

import Zoom from 'react-img-zoom'

import BackImageDropzone from "components/Dropzones/BackImageDropzone.js";

class ImageZoom extends React.Component {

    render() {

        return (
            <>
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.props.modalOpened}
                    autoFocus={false}
                    size="xl"
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            { this.props.url !== ""? (
                                <a
                                    href={this.props.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {this.props.title}
                                </a>
                            ):(this.props.title) }
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={(e) => this.props.modalClose(e, "abort")}
                            disabled={this.props.saving}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <Row hidden={this.props.crud === "C"}>
                        <div className="col">
                            <div className="card-wrapper">
                                <Card>
                                    <CardBody>
                                        <Zoom
                                            img={this.props.url}
                                            zoomScale={3}
                                            width={1100}
                                            height={450}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </Row>
                    <Row hidden={this.props.crud === "R"}>
                        <Col className="mb-3" md="12">
                            <BackImageDropzone/>
                        </Col>
                    </Row>
                    <div className="modal-footer">
                        <Button
                            color="danger"
                            data-dismiss="modal"
                            type="button"
                            onClick={(e) => this.props.modalClose(e)}
                            disabled={this.props.saving}
                        >
                            {this.props.crud === "C" ? ("Cancelar"):("Fechar")}
                        </Button>
                        <Button
                            color="primary"
                            type="button"
                            onClick={(e) => this.props.modalClose(e, "delete")}
                            hidden={this.props.crud === "C" || !this.props.isConsulting}
                            disabled={this.props.saving}
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-trash" />
                            </span>
                            <span className="btn-inner--text">Excluir</span>
                        </Button>
                        <Button
                            color="success"
                            type="button"
                            onClick={(e) => this.props.modalClose(e, "save")}
                            hidden={this.props.crud !== "C"}
                            disabled={this.props.saving}
                        >
                            Salvar
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ImageZoom;